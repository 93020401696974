import styled from "styled-components";
import { screenSizes } from "styles/theme";

interface IPageTitleProps {
    customPadding?: string
    justify?: string
    isMobile?: boolean
    textAlign?: string
}
export const TitleContainer = styled.div<IPageTitleProps>`
    /* display: ${(props: IPageTitleProps) => props.isMobile ? 'none' : 'flex'}; */
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: ${(props: any) => props.justify || 'center'};
    text-align: ${(props: any) => props.textAlign };
    padding: ${(props: any) => props.customPadding};
    a {
        color: ${(props: any) => props.theme.primaryButton};
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
    }
    div {
        opacity: .7;
    }
    @media screen and (max-width: ${screenSizes.M}px) {
        align-items: start;
        /* display: ${(props: IPageTitleProps) => props.isMobile ? 'flex' : 'none'}; */
        max-width: 350px;
        padding: 0 5px;
    }
`
