import { useWeb3React } from "@web3-react/core";
import { ESCROW_INSTANCE, SIGNER } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { parseUnits } from "ethers/lib/utils";
import { setRefetchBalance, setTxHash } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/button";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, validateNumberInput } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { InputBox, InputLabel, RowContainer } from "shared/styled";

export const Withdraw = () => {
  const { refetchBalance, automationBalance, isAutomation } = useSelector((state: any) => state.navbar);
  const [amount, setAmount] = useState<string>("0");
  const [calAmount, setCalAmount] = useState<any>(0);
  const [notifyText, setNotifyText] = useState<string>("");
  const [isErr, setIsErr] = useState<boolean>(false);
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const [convertAutomationBalance, setConvertAutomationBalance] = useState<any>(0);
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [ethInDollar, setEthInDollar] = useState<any>(0);
  const { ethToUSD } = useWallet();
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const dispatch = useDispatch();
  const { account } = useConnectWallet();

  useEffect(() => {
    convertUSD();
  }, [refetchBalance, automationBalance]);

  const convertUSD = async () => {
    const USDValue = await ethToUSD();
    setEthInDollar(USDValue);
    // const _formatBal = parseInt("" + automationBalance * 1000) / 1000;
    const _formatBal = Math.trunc(Number(automationBalance) * Math.pow(10, 4)) / Math.pow(10, 4);
    setConvertAutomationBalance(_formatBal);
    const Convert_ETHBal = USDValue ? Number(USDValue) * Number(automationBalance) : 0.0;
    setConvertEthBalance(Convert_ETHBal);
  };

  const checking = (value: any) => {
    const AMOUNT_TO_USD = ethInDollar ? Number(value) * Number(ethInDollar) : 0.0;
    let tempText = `You are withdrawing ${formatNumbersWithComma(value, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(AMOUNT_TO_USD, 2)})`;
    setNotifyText(tempText);
  };

  const handleClearStates = () => {
    setAmount("");
    setNotifyText("");
    setRangeValue(0);
  };

  const handleInput = (e: any) => {
    if (automationBalance > 0) {
      let value = validateNumberInput(e)?.toString();
      // let value = e.target.value;
      console.log({ automationBalance, value });
      if (Number(value) <= Number(automationBalance)) {
        let percentage = ((value / Number(automationBalance)) * 100).toFixed(0);
        const convertedAmount = value ? parseUnits(value, "ether") : 0
        setRangeValue(Number(percentage));
        setAmount(value);
        setCalAmount(convertedAmount.toString());
        if (isAutomation && Number(value) === Number(automationBalance)) {
          setIsErr(true);
          setNotifyText("Can't withdraw the whole amount since automation is enabled");
        } else {
          setIsErr(false);
          checking(value);
        }
      }
    }

  };

  const handleWithdraw = async () => {
    try {
      // const convertedAmount = parseUnits(calAmount.toString(), "ether");
      console.log({ calAmount: calAmount.toString() });
      const estimateGas = await ESCROW_INSTANCE.connect(SIGNER).estimateGas.withdraw(calAmount);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      startSpinner();
      const res = await ESCROW_INSTANCE.connect(SIGNER).withdraw(calAmount, {
        gasLimit: bufferedGas.toFixed(0),
      });
      dispatch(setTxHash(res.hash));
      await res.wait();
      dispatch(setRefetchBalance(!refetchBalance));
      stopSpinner();
      handleClearStates();
    } catch (err) {
      handleError(err);
      handleClearStates();
    }
  };

  const rangeHandler = async (prop: any) => {
    if (automationBalance > 0) {
      setRangeValue(prop);
      let percentToValue: any;
      const _balance = await ESCROW_INSTANCE?.connect(SIGNER).balanceOf(account);
      percentToValue = _balance.mul(prop).div(100);
      // const percentToValueEth = Number(formatEther(percentToValue));
      let percentToValueDisplay: any = Math.trunc((percentToValue / 10 ** 18) * Math.pow(10, 3)) / Math.pow(10, 3);
      setAmount(percentToValueDisplay);
      setCalAmount(percentToValue.toString());
      if (prop === '100' && isAutomation) {
        setIsErr(true);
        setNotifyText("Can't withdraw the whole amount since automation is enabled");
      } else {
        setIsErr(false)
        checking(percentToValueDisplay);
      }

    }
  };

  const isDisabled = Number(automationBalance) < Number(amount) || amount === "" || Number(amount) <= 0;


  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Withdraw</span>
            <span className="text2">{envAllDescriptionDetails.VAULT_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(automationBalance, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertEthBalance, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput hideIcon placeholder={"Enter Amount"} value={amount} onChange={(e: any) => handleInput(e)}></CustomInput>
        <RangePointerInput rangeValue={rangeValue} rangeHandler={rangeHandler} />
      </InputBox>
      {amount !== "" && Number(amount) > 0 && <Notification message={notifyText} type="error" />}
      <RowContainer>
        <Button btnType={"gradientFilledButton"} wrapperWidth="100%" fSizeMobile="12px" onClick={handleWithdraw} isDisabled={isDisabled || isErr}>
          Confirm
        </Button>
      </RowContainer>
    </>
  );
};
