import axios from "axios";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { envAllDescriptionDetails, formatAddress } from "shared/helpers/utils";
import Pagination from "shared/pagination/Pagination";
import { Table } from "shared/react-table/Table";
import { Tooltip } from "shared/styled";
import { Para } from "shared/Typography";
import { ColumnData, CustomTable } from "../riskyVaults/style";
import { ErrorList, ErrorMsgBox, JsonBox, ShowBox, SuccessMsgBox } from "./Style";
import { useWallet } from "shared/hook/useWallet";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

function Column3({ Lists, errorLog }: any) {
  const [showJSON, setShowJSON] = useState<boolean>(false);
  const Text = showJSON ? "Hide Details" : "Show Details";

  return (
    <>
      <ShowBox onClick={() => setShowJSON((prev: any) => !prev)}>{Text}</ShowBox>
      <JsonBox isShow={showJSON}>
        <ul>
          <>
            {Lists}
            <ErrorList>
              <span>Error</span> : <span>{errorLog}</span>
            </ErrorList>
          </>
        </ul>
      </JsonBox>
    </>
  );
}

export const createData = async ({ _automationLogs, setAllData, currentPage }: any) => {
  try {
    let data = _automationLogs?.map((ele: any, key: any) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const IST = new Date(ele.createdAt).toLocaleString(undefined, { timeZone: timezone });
      const Hash = formatAddress(ele.transactionHash, 4);
      const _link = Number(envChainId) === ChainId?.eth ? `https://goerli.etherscan.io/tx/${ele.transactionHash}` : `https://scan.v4.testnet.pulsechain.com/tx/${ele.transactionHash}`;

      const Lists = Object.entries(ele.rules).map(([key, value]: any) => {
        if (key === "customs") {
          return Object.entries(value).map(([k, v]: any) => {
            return (
              <li key={k}>
                <span>{k}</span> : <span>{v}</span>
              </li>
            );
          });
        }

        if (key === "userContract") {
          return (
            <li key={key}>
              <span>{key}</span>: <span>{formatAddress(value)}</span>
            </li>
          );
        }
        if (key === "Max Cap on Gas") {
          let _formatGasFee: any;
          _formatGasFee = value ? formatEther(value) + envAllDescriptionDetails.VAULT_TOKEN_TEXT : "N/A";
          return (
            <li key={key}>
              <span>{key}</span> : <span>{_formatGasFee}</span>
            </li>
          );
        }
        if (key === "Estimate Gas") {
          let _formatEstimateGas: any;
          _formatEstimateGas = value ? formatEther(value) + envAllDescriptionDetails.VAULT_TOKEN_TEXT : "N/A";
          return (
            <li key={key}>
              <span>{key}</span> : <span>{_formatEstimateGas}</span>
            </li>
          );
        }
        return (
          <li key={key}>
            <span>{key}</span> : <span>{`${value}`}</span>
          </li>
        );
      });

      return {
        col1: (currentPage - 1) * 10 + key + 1,
        col2: ele.strategy.strategyName,
        col3: <Column3 Lists={Lists} errorLog={ele.errorLog} />,
        col4: ele.gasUsed ? `${formatEther(ele.gasUsed)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ` : `0 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`,
        col5:
          ele.status === "Success" ? (
            <SuccessMsgBox>{ele.status}</SuccessMsgBox>
          ) : (
            <Tooltip customWidth="10em" data-tooltip={ele.errorMsg ? ele.errorMsg : "Something went wrong"}>
              <ErrorMsgBox>{ele.status}</ErrorMsgBox>
            </Tooltip>
          ),
        col6: ele.transactionHash ? (
          <a className="transationHash" href={_link} target={"_blank"} rel="noreferrer" style={{ color: "#12f79c", textDecoration: "underline" }}>
            {Hash}
          </a>
        ) : (
          "N/A"
        ),
        col7: IST,
      };
    });
    setAllData(data);
  } catch (err) {
    console.error("fetch", err);
  }
};

export const AutomationLogTable = () => {
  const [allData, setAllData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { authStatus, userContractAddress, isAutomationLogModalOpen } = useSelector((s: any) => s.navbar);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { automaticLogOut, clearStorage } = useWallet();

  const getData = async () => {
    try {
      setIsLoading(true);
      let response: any = await axios.get(`${POST_WALLET_URL}/automation-log?page=${currentPage}&limit=10`);
      let _automationLogs: any = response?.data?.data?.automationLogArray;
      setTotalCount(response?.data?.data?.count);
      await createData({ _automationLogs, setAllData, currentPage });
      setIsLoading(false);
    } catch (e: any) {
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const createTable = async () => {
    await getData();
  };
  useEffect(() => {
    if (isAutomationLogModalOpen) {
      createTable();
    }
  }, [authStatus, userContractAddress, currentPage, isAutomationLogModalOpen]);

  const columns = [
    {
      Header: () => <ColumnData>Sr.no</ColumnData>,
      accessor: "col1", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Strategy Name</ColumnData>,
      accessor: "col2",
    },
    {
      Header: () => <ColumnData>Variables and Rules</ColumnData>,
      accessor: "col3", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Gas Used</ColumnData>,
      accessor: "col4", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Status</ColumnData>,
      accessor: "col5", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>TxHash</ColumnData>,
      accessor: "col6", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Execution Date</ColumnData>,
      accessor: "col7", // accessor is the "key" in the data
    },
  ];

  if (isLoading) {
    return <Para>Loading..</Para>;
  }

  return (
    <>
      <CustomTable noMaxWidth>{allData?.length ? <Table data={allData} columns={columns} /> : <Para>No Data Available</Para>}</CustomTable>
      <Pagination totalCount={totalCount} setCurrentPage={setCurrentPage} currentPage={currentPage} itemsPerPage={10} />
    </>
  );
};
