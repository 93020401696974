// ETH DEV IMPORTS
import ETHDevCompoundAll from "./Ethereum/dev/CompoundAll.json";
import ETHDevActivePool from "./Ethereum/dev/activePool.json";
import ETHDevCustomStragey from "./Ethereum/dev/CustomStrategy.json";
import ETHDevCustomStatic from "./Ethereum/dev/CustomStatic.json";
import ETHDevFactory from "./Ethereum/dev/factory.json";
import ETHDevHalfandHalf from "./Ethereum/dev/Half&HalfStrategy.json";
import ETHDevHalfandHalfStatic from "./Ethereum/dev/Half&HalfStatic.json";
import ETHDevHelpers from "./Ethereum/dev/helpers.json";
import ETHDevLqtyToken from "./Ethereum/dev/LQTYToken.json";
import ETHDevLusdToken from "./Ethereum/dev/LUSDToken.json";
import ETHDevMailBoxMoney from "./Ethereum/dev/MailBoxMoneyStrategy.json";
import ETHDevMultiTrove from "./Ethereum/dev/multiTroveGetter.json";
import ETHDevPassivePulse from "./Ethereum/dev/PassivePulseStrategy.json";
import ETHDevPassivePulseStatic from "./Ethereum/dev/PassivePulseStatic.json";
import ETHDevPayDown from "./Ethereum/dev/payDownMethod.json";
import ETHDevPayDownStatic from "./Ethereum/dev/PaydownMethodsStatic.json";
import ETHDevPriceFeed from "./Ethereum/dev/priceFeed.json";
import ETHDevPulseStaker from "./Ethereum/dev/PulseStackerStrategy.json";
import ETHDevPulseStakerStatic from "./Ethereum/dev/PulseStackerStatic.json";
import ETHDevSortedTroves from "./Ethereum/dev/sortedTroves.json";
import ETHDevStabiltyPool from "./Ethereum/dev/StabilityPool.json";
import ETHDevStakingPool from "./Ethereum/dev/StakingPool.json";
import ETHDevTroveManager from "./Ethereum/dev/troveManager.json";
import ETHDevUniswapRouter from "./Ethereum/dev/uniswapRouterV2.json";
import ETHDevEscrow from "./Ethereum/dev/escrow.json";
import ETHDevUser from "./Ethereum/dev/user.json";

// PULSE DEV IMPORTS
import PULSEDevCompoundAll from "./Pulse/dev/CompoundAll.json";
import PULSEDevActivePool from "./Pulse/dev/activePool.json";
import PULSEDevCustomStragey from "./Pulse/dev/CustomStrategy.json";
import PULSEDevCustomStatic from "./Pulse/dev/CustomStatic.json";
import PULSEDevFactory from "./Pulse/dev/factory.json";
import PULSEDevHalfandHalf from "./Pulse/dev/Half&HalfStrategy.json";
import PULSEDevHalfandHalfStatic from "./Pulse/dev/Half&HalfStatic.json";
import PULSEDevHelpers from "./Pulse/dev/helpers.json";
import PULSEDevLoanToken from "./Pulse/dev/LQTYToken.json";
import PULSEDevUsdlToken from "./Pulse/dev/LUSDToken.json";
import PULSEDevMailBoxMoney from "./Pulse/dev/MailBoxMoneyStrategy.json";
import PULSEDevMultiTrove from "./Pulse/dev/multiTroveGetter.json";
import PULSEDevPassivePulse from "./Pulse/dev/PassivePulseStrategy.json";
import PULSEDevPassivePulseStatic from "./Pulse/dev/PassivePulseStatic.json";
import PULSEDevPayDown from "./Pulse/dev/payDownMethod.json";
import PULSEDevPayDownStatic from "./Pulse/dev/PaydownMethodsStatic.json";
import PULSEDevPriceFeed from "./Pulse/dev/priceFeed.json";
import PULSEDevPulseStaker from "./Pulse/dev/PulseStackerStrategy.json";
import PULSEDevPulseStakerStatic from "./Pulse/dev/PulseStackerStatic.json";
import PULSEDevSortedTroves from "./Pulse/dev/sortedTroves.json";
import PULSEDevStabiltyPool from "./Pulse/dev/StabilityPool.json";
import PULSEDevStakingPool from "./Pulse/dev/StakingPool.json";
import PULSEDevTroveManager from "./Pulse/dev/troveManager.json";
import PULSEDevUniswapRouter from "./Pulse/dev/uniswapRouterV2.json";
import PULSEDevEscrow from "./Pulse/dev/escrow.json";
import PULSEDevUser from "./Pulse/dev/user.json";

// PULSE STAGING IMPORTS
import PULSEStagingCompoundAll from "./Pulse/staging/CompundAll.json";
import PULSEStagingActivePool from "./Pulse/staging/activePool.json";
import PULSEStagingCustomStragey from "./Pulse/staging/CustomStrategy.json";
import PULSEStagingCustomStatic from "./Pulse/staging/CustomStatic.json";
import PULSEStagingFactory from "./Pulse/staging/factory.json";
import PULSEStagingHalfandHalf from "./Pulse/staging/Half&HalfStrategy.json";
import PULSEStagingHalfandHalfStatic from "./Pulse/staging/Half&HalfStatic.json";
import PULSEStagingHelpers from "./Pulse/staging/helpers.json";
import PULSEStagingLoanToken from "./Pulse/staging/LQTYToken.json";
import PULSEStagingUsdlToken from "./Pulse/staging/LUSDToken.json";
import PULSEStagingMailBoxMoney from "./Pulse/staging/MailBoxMoneyStrategy.json";
import PULSEStagingMultiTrove from "./Pulse/staging/multiTroveGetter.json";
import PULSEStagingPassivePulse from "./Pulse/staging/PassivePulseStrategy.json";
import PULSEStagingPassivePulseStatic from "./Pulse/staging/PassivePulseStatic.json";
import PULSEStagingPayDown from "./Pulse/staging/payDownMethod.json";
import PULSEStagingPayDownStatic from "./Pulse/staging/PaydownMethodsStatic.json";
import PULSEStagingPriceFeed from "./Pulse/staging/priceFeed.json";
import PULSEStagingPulseStaker from "./Pulse/staging/PulseStackerStrategy.json";
import PULSEStagingPulseStakerStatic from "./Pulse/staging/PulseStackerStatic.json";
import PULSEStagingSortedTroves from "./Pulse/staging/sortedTroves.json";
import PULSEStagingStabiltyPool from "./Pulse/staging/StabilityPool.json";
import PULSEStagingStakingPool from "./Pulse/staging/StakingPool.json";
import PULSEStagingTroveManager from "./Pulse/staging/troveManager.json";
import PULSEStagingUniswapRouter from "./Pulse/staging/uniswapRouterV2.json";
import PULSEStagingEscrow from "./Pulse/staging/escrow.json";
import PULSEStagingUser from "./Pulse/staging/user.json";

// PULSE UAT IMPORTS
// import PULSEUATCompoundAll from "./Pulse/uat/CompundAll.json";
import PULSEUATActivePool from "./Pulse/uat/activePool.json";
import PULSEUATCustomStragey from "./Pulse/uat/CustomStrategy.json";
import PULSEUATCustomStatic from "./Pulse/uat/CustomStatic.json";
import PULSEUATFactory from "./Pulse/uat/factory.json";
import PULSEUATHalfandHalf from "./Pulse/uat/Half&HalfStrategy.json";
import PULSEUATHalfandHalfStatic from "./Pulse/uat/Half&HalfStatic.json";
import PULSEUATHelpers from "./Pulse/uat/helpers.json";
import PULSEUATLoanToken from "./Pulse/uat/LQTYToken.json";
import PULSEUATUsdlToken from "./Pulse/uat/LUSDToken.json";
import PULSEUATMailBoxMoney from "./Pulse/uat/MailBoxMoneyStrategy.json";
import PULSEUATMultiTrove from "./Pulse/uat/multiTroveGetter.json";
import PULSEUATPassivePulse from "./Pulse/uat/PassivePulseStrategy.json";
import PULSEUATPassivePulseStatic from "./Pulse/uat/PassivePulseStatic.json";
import PULSEUATPayDown from "./Pulse/uat/payDownMethod.json";
import PULSEUATPriceFeed from "./Pulse/uat/priceFeed.json";
import PULSEUATPulseStaker from "./Pulse/uat/PulseStackerStrategy.json";
import PULSEUATPulseStakerStatic from "./Pulse/uat/PulseStackerStatic.json";
import PULSEUATSortedTroves from "./Pulse/uat/sortedTroves.json";
import PULSEUATStabiltyPool from "./Pulse/uat/StabilityPool.json";
import PULSEUATStakingPool from "./Pulse/uat/StakingPool.json";
import PULSEUATTroveManager from "./Pulse/uat/troveManager.json";
import PULSEUATUniswapRouter from "./Pulse/uat/uniswapRouterV2.json";
import PULSEUATEscrow from "./Pulse/uat/escrow.json";
import PULSEUATUser from "./Pulse/uat/user.json";

// ETH STAGING IMPORTS
import ETHStagingCompoundAll from "./Ethereum/staging/CompundAll.json";
import ETHStagingActivePool from "./Ethereum/staging/activePool.json";
import ETHStagingCustomStragey from "./Ethereum/staging/CustomStrategy.json";
import ETHStagingCustomStatic from "./Ethereum/staging/CustomStatic.json";
import ETHStagingFactory from "./Ethereum/staging/factory.json";
import ETHStagingHalfandHalf from "./Ethereum/staging/Half&HalfStrategy.json";
import ETHStagingHalfandHalfStatic from "./Ethereum/staging/Half&HalfStatic.json";
import ETHStagingHelpers from "./Ethereum/staging/helpers.json";
import ETHStagingLqtyToken from "./Ethereum/staging/LQTYToken.json";
import ETHStagingLusdToken from "./Ethereum/staging/LUSDToken.json";
import ETHStagingMailBoxMoney from "./Ethereum/staging/MailBoxMoneyStrategy.json";
import ETHStagingMultiTrove from "./Ethereum/staging/multiTroveGetter.json";
import ETHStagingPassivePulse from "./Ethereum/staging/PassivePulseStrategy.json";
import ETHStagingPassivePulseStatic from "./Ethereum/staging/PassivePulseStatic.json";
import ETHStagingPayDown from "./Ethereum/staging/payDownMethod.json";
import ETHStagingPayDownStatic from "./Ethereum/staging/PaydownMethodsStatic.json";
import ETHStagingPriceFeed from "./Ethereum/staging/priceFeed.json";
import ETHStagingPulseStaker from "./Ethereum/staging/PulseStackerStrategy.json";
import ETHStagingPulseStakerStatic from "./Ethereum/staging/PulseStackerStatic.json";
import ETHStagingSortedTroves from "./Ethereum/staging/sortedTroves.json";
import ETHStagingStabiltyPool from "./Ethereum/staging/StabilityPool.json";
import ETHStagingStakingPool from "./Ethereum/staging/StakingPool.json";
import ETHStagingTroveManager from "./Ethereum/staging/troveManager.json";
import ETHStagingUniswapRouter from "./Ethereum/staging/uniswapRouterV2.json";
import ETHStagingEscrow from "./Ethereum/staging/escrow.json";
import ETHStagingUser from "./Ethereum/staging/user.json";

// ETH UAT IMPORTS
// import ETHUatCompoundAll from "./Ethereum/uat/CompundAll.json";
import ETHUatActivePool from "./Ethereum/uat/activePool.json";
import ETHUatCustomStragey from "./Ethereum/uat/CustomStrategy.json";
import ETHUatCustomStatic from "./Ethereum/uat/CustomStatic.json";
import ETHUatFactory from "./Ethereum/uat/factory.json";
import ETHUatHalfandHalf from "./Ethereum/uat/Half&HalfStrategy.json";
import ETHUatHalfandHalfStatic from "./Ethereum/uat/Half&HalfStatic.json";
import ETHUatHelpers from "./Ethereum/uat/helpers.json";
import ETHUatLqtyToken from "./Ethereum/uat/LQTYToken.json";
import ETHUatLusdToken from "./Ethereum/uat/LUSDToken.json";
import ETHUatMailBoxMoney from "./Ethereum/uat/MailBoxMoneyStrategy.json";
import ETHUatMultiTrove from "./Ethereum/uat/multiTroveGetter.json";
import ETHUatPassivePulse from "./Ethereum/uat/PassivePulseStrategy.json";
import ETHUatPassivePulseStatic from "./Ethereum/uat/PassivePulseStatic.json";
import ETHUatPayDown from "./Ethereum/uat/payDownMethod.json";
import ETHUatPriceFeed from "./Ethereum/uat/priceFeed.json";
import ETHUatPulseStaker from "./Ethereum/uat/PulseStackerStrategy.json";
import ETHUatPulseStakerStatic from "./Ethereum/uat/PulseStackerStatic.json";
import ETHUatSortedTroves from "./Ethereum/uat/sortedTroves.json";
import ETHUatStabiltyPool from "./Ethereum/uat/StabilityPool.json";
import ETHUatStakingPool from "./Ethereum/uat/StakingPool.json";
import ETHUatTroveManager from "./Ethereum/uat/troveManager.json";
import ETHUatUniswapRouter from "./Ethereum/uat/uniswapRouterV2.json";
import ETHUatEscrow from "./Ethereum/uat/escrow.json";
import ETHUatUser from "./Ethereum/uat/user.json";

interface IContracts {
  CompoundAll: [string, Object];
  ActivePool: [string, Object];
  CustomStragey: [string, Object];
  CustomStatic: [string, Object];
  Factory: [string, Object];
  HalfandHalf: [string, Object];
  HalfandHalfStatic: [string, Object];
  Helpers: [string, Object];
  LqtyToken: [string, Object];
  LusdToken: [string, Object];
  MailBoxMoney: [string, Object];
  MultiTrove: [string, Object];
  PassivePulse: [string, Object];
  PassivePulseStatic: [string, Object];
  PayDown: [string, Object];
  PayDownStatic: [string, Object];
  PulseStaker: [string, Object];
  PulseStakerStatic: [string, Object];
  PriceFeed: [string, Object];
  SortedTroves: [string, Object];
  StabiltyPool: [string, Object];
  StakingPool: [string, Object];
  TroveManager: [string, Object];
  UniswapRouter: [string, Object];
  Escrow: [string, Object];
  User: [Object];
}

interface IEnvContracts {
  DEV: IContracts;
  [x: string]: any;
}

export const ethereumContracts: IEnvContracts = {
  DEV: {
    CompoundAll: [ETHDevCompoundAll.address, ETHDevCompoundAll.abi],
    ActivePool: [ETHDevActivePool.address, ETHDevActivePool.abi],
    CustomStragey: [ETHDevCustomStragey.address, ETHDevCustomStragey.abi],
    CustomStatic: [ETHDevCustomStatic.address, ETHDevCustomStatic.abi],
    Factory: [ETHDevFactory.address, ETHDevFactory.abi],
    HalfandHalf: [ETHDevHalfandHalf.address, ETHDevHalfandHalf.abi],
    HalfandHalfStatic: [ETHDevHalfandHalfStatic.address, ETHDevHalfandHalfStatic.abi],
    Helpers: [ETHDevHelpers.address, ETHDevHelpers.abi],
    LqtyToken: [ETHDevLqtyToken.address, ETHDevLqtyToken.abi],
    LusdToken: [ETHDevLusdToken.address, ETHDevLusdToken.abi],
    MailBoxMoney: [ETHDevMailBoxMoney.address, ETHDevMailBoxMoney.abi],
    MultiTrove: [ETHDevMultiTrove.address, ETHDevMultiTrove.abi],
    PassivePulse: [ETHDevPassivePulse.address, ETHDevPassivePulse.abi],
    PassivePulseStatic: [ETHDevPassivePulseStatic.address, ETHDevPassivePulseStatic.abi],
    PayDown: [ETHDevPayDown.address, ETHDevPayDown.abi],
    PayDownStatic: [ETHDevPayDownStatic.address, ETHDevPayDownStatic.abi],
    PulseStaker: [ETHDevPulseStaker.address, ETHDevPulseStaker.abi],
    PulseStakerStatic: [ETHDevPulseStakerStatic.address, ETHDevPulseStakerStatic.abi],
    PriceFeed: [ETHDevPriceFeed.address, ETHDevPriceFeed.abi],
    SortedTroves: [ETHDevSortedTroves.address, ETHDevSortedTroves.abi],
    StabiltyPool: [ETHDevStabiltyPool.address, ETHDevStabiltyPool.abi],
    StakingPool: [ETHDevStakingPool.address, ETHDevStakingPool.abi],
    TroveManager: [ETHDevTroveManager.address, ETHDevTroveManager.abi],
    UniswapRouter: [ETHDevUniswapRouter.address, ETHDevUniswapRouter.abi],
    Escrow: [ETHDevEscrow.address, ETHDevEscrow.abi],
    User: [ETHDevUser.abi],
  },
  STAGING: {
    CompoundAll: [ETHStagingCompoundAll.address, ETHStagingCompoundAll.abi],
    ActivePool: [ETHStagingActivePool.address, ETHStagingActivePool.abi],
    CustomStragey: [ETHStagingCustomStragey.address, ETHStagingCustomStragey.abi],
    CustomStatic: [ETHStagingCustomStatic.address, ETHStagingCustomStatic.abi],
    Factory: [ETHStagingFactory.address, ETHStagingFactory.abi],
    HalfandHalf: [ETHStagingHalfandHalf.address, ETHStagingHalfandHalf.abi],
    HalfandHalfStatic: [ETHStagingHalfandHalfStatic.address, ETHStagingHalfandHalfStatic.abi],
    Helpers: [ETHStagingHelpers.address, ETHStagingHelpers.abi],
    LqtyToken: [ETHStagingLqtyToken.address, ETHStagingLqtyToken.abi],
    LusdToken: [ETHStagingLusdToken.address, ETHStagingLusdToken.abi],
    MailBoxMoney: [ETHStagingMailBoxMoney.address, ETHStagingMailBoxMoney.abi],
    MultiTrove: [ETHStagingMultiTrove.address, ETHStagingMultiTrove.abi],
    PassivePulse: [ETHStagingPassivePulse.address, ETHStagingPassivePulse.abi],
    PassivePulseStatic: [ETHStagingPassivePulseStatic.address, ETHStagingPassivePulseStatic.abi],
    PayDown: [ETHStagingPayDown.address, ETHStagingPayDown.abi],
    PayDownStatic: [ETHStagingPayDownStatic.address, ETHStagingPayDownStatic.abi],
    PulseStaker: [ETHStagingPulseStaker.address, ETHStagingPulseStaker.abi],
    PulseStakerStatic: [ETHStagingPulseStakerStatic.address, ETHStagingPulseStakerStatic.abi],
    PriceFeed: [ETHStagingPriceFeed.address, ETHStagingPriceFeed.abi],
    SortedTroves: [ETHStagingSortedTroves.address, ETHStagingSortedTroves.abi],
    StabiltyPool: [ETHStagingStabiltyPool.address, ETHStagingStabiltyPool.abi],
    StakingPool: [ETHStagingStakingPool.address, ETHStagingStakingPool.abi],
    TroveManager: [ETHStagingTroveManager.address, ETHStagingTroveManager.abi],
    UniswapRouter: [ETHStagingUniswapRouter.address, ETHStagingUniswapRouter.abi],
    Escrow: [ETHStagingEscrow.address, ETHStagingEscrow.abi],
    User: [ETHStagingUser.abi],
  },
  UAT: {
    // CompoundAll: [ETHUatCompoundAll.address, ETHUatCompoundAll.abi],
    ActivePool: [ETHUatActivePool.address, ETHUatActivePool.abi],
    CustomStragey: [ETHUatCustomStragey.address, ETHUatCustomStragey.abi],
    CustomStatic: [ETHUatCustomStatic.address, ETHUatCustomStatic.abi],
    Factory: [ETHUatFactory.address, ETHUatFactory.abi],
    HalfandHalf: [ETHUatHalfandHalf.address, ETHUatHalfandHalf.abi],
    HalfandHalfStatic: [ETHUatHalfandHalfStatic.address, ETHUatHalfandHalfStatic.abi],
    Helpers: [ETHUatHelpers.address, ETHUatHelpers.abi],
    LqtyToken: [ETHUatLqtyToken.address, ETHUatLqtyToken.abi],
    LusdToken: [ETHUatLusdToken.address, ETHUatLusdToken.abi],
    MailBoxMoney: [ETHUatMailBoxMoney.address, ETHUatMailBoxMoney.abi],
    MultiTrove: [ETHUatMultiTrove.address, ETHUatMultiTrove.abi],
    PassivePulse: [ETHUatPassivePulse.address, ETHUatPassivePulse.abi],
    PassivePulseStatic: [ETHUatPassivePulseStatic.address, ETHUatPassivePulseStatic.abi],
    PayDown: [ETHUatPayDown.address, ETHUatPayDown.abi],
    PulseStaker: [ETHUatPulseStaker.address, ETHUatPulseStaker.abi],
    PulseStakerStatic: [ETHUatPulseStakerStatic.address, ETHUatPulseStakerStatic.abi],
    PriceFeed: [ETHUatPriceFeed.address, ETHUatPriceFeed.abi],
    SortedTroves: [ETHUatSortedTroves.address, ETHUatSortedTroves.abi],
    StabiltyPool: [ETHUatStabiltyPool.address, ETHUatStabiltyPool.abi],
    StakingPool: [ETHUatStakingPool.address, ETHUatStakingPool.abi],
    TroveManager: [ETHUatTroveManager.address, ETHUatTroveManager.abi],
    UniswapRouter: [ETHUatUniswapRouter.address, ETHUatUniswapRouter.abi],
    Escrow: [ETHUatEscrow.address, ETHUatEscrow.abi],
    User: [ETHUatUser.abi],
  },
};

export const pulseContracts: any = {
  DEV: {
    CompoundAll: [PULSEDevCompoundAll.address, PULSEDevCompoundAll.abi],
    ActivePool: [PULSEDevActivePool.address, PULSEDevActivePool.abi],
    CustomStragey: [PULSEDevCustomStragey.address, PULSEDevCustomStragey.abi],
    CustomStatic: [PULSEDevCustomStatic.address, PULSEDevCustomStatic.abi],
    Factory: [PULSEDevFactory.address, PULSEDevFactory.abi],
    HalfandHalf: [PULSEDevHalfandHalf.address, PULSEDevHalfandHalf.abi],
    HalfandHalfStatic: [PULSEDevHalfandHalfStatic.address, PULSEDevHalfandHalfStatic.abi],
    Helpers: [PULSEDevHelpers.address, PULSEDevHelpers.abi],
    LqtyToken: [PULSEDevLoanToken.address, PULSEDevLoanToken.abi],
    LusdToken: [PULSEDevUsdlToken.address, PULSEDevUsdlToken.abi],
    MailBoxMoney: [PULSEDevMailBoxMoney.address, PULSEDevMailBoxMoney.abi],
    MultiTrove: [PULSEDevMultiTrove.address, PULSEDevMultiTrove.abi],
    PassivePulse: [PULSEDevPassivePulse.address, PULSEDevPassivePulse.abi],
    PassivePulseStatic: [PULSEDevPassivePulseStatic.address, PULSEDevPassivePulseStatic.abi],
    PayDown: [PULSEDevPayDown.address, PULSEDevPayDown.abi],
    PayDownStatic: [PULSEDevPayDownStatic.address, PULSEDevPayDownStatic.abi],
    PulseStaker: [PULSEDevPulseStaker.address, PULSEDevPulseStaker.abi],
    PulseStakerStatic: [PULSEDevPulseStakerStatic.address, PULSEDevPulseStakerStatic.abi],
    PriceFeed: [PULSEDevPriceFeed.address, PULSEDevPriceFeed.abi],
    SortedTroves: [PULSEDevSortedTroves.address, PULSEDevSortedTroves.abi],
    StabiltyPool: [PULSEDevStabiltyPool.address, PULSEDevStabiltyPool.abi],
    StakingPool: [PULSEDevStakingPool.address, PULSEDevStakingPool.abi],
    TroveManager: [PULSEDevTroveManager.address, PULSEDevTroveManager.abi],
    UniswapRouter: [PULSEDevUniswapRouter.address, PULSEDevUniswapRouter.abi],
    Escrow: [PULSEDevEscrow.address, PULSEDevEscrow.abi],
    User: [PULSEDevUser.abi],
  },
  STAGING: {
    CompoundAll: [PULSEStagingCompoundAll.address, PULSEStagingCompoundAll.abi],
    ActivePool: [PULSEStagingActivePool.address, PULSEStagingActivePool.abi],
    CustomStragey: [PULSEStagingCustomStragey.address, PULSEStagingCustomStragey.abi],
    CustomStatic: [PULSEStagingCustomStatic.address, PULSEStagingCustomStatic.abi],
    Factory: [PULSEStagingFactory.address, PULSEStagingFactory.abi],
    HalfandHalf: [PULSEStagingHalfandHalf.address, PULSEStagingHalfandHalf.abi],
    HalfandHalfStatic: [PULSEStagingHalfandHalfStatic.address, PULSEStagingHalfandHalfStatic.abi],
    Helpers: [PULSEStagingHelpers.address, PULSEStagingHelpers.abi],
    LqtyToken: [PULSEStagingLoanToken.address, PULSEStagingLoanToken.abi],
    LusdToken: [PULSEStagingUsdlToken.address, PULSEStagingUsdlToken.abi],
    MailBoxMoney: [PULSEStagingMailBoxMoney.address, PULSEStagingMailBoxMoney.abi],
    MultiTrove: [PULSEStagingMultiTrove.address, PULSEStagingMultiTrove.abi],
    PassivePulse: [PULSEStagingPassivePulse.address, PULSEStagingPassivePulse.abi],
    PassivePulseStatic: [PULSEStagingPassivePulseStatic.address, PULSEStagingPassivePulseStatic.abi],
    PayDown: [PULSEStagingPayDown.address, PULSEStagingPayDown.abi],
    PayDownStatic: [PULSEStagingPayDownStatic.address, PULSEStagingPayDownStatic.abi],
    PulseStaker: [PULSEStagingPulseStaker.address, PULSEStagingPulseStaker.abi],
    PulseStakerStatic: [PULSEStagingPulseStakerStatic.address, PULSEStagingPulseStakerStatic.abi],
    PriceFeed: [PULSEStagingPriceFeed.address, PULSEStagingPriceFeed.abi],
    SortedTroves: [PULSEStagingSortedTroves.address, PULSEStagingSortedTroves.abi],
    StabiltyPool: [PULSEStagingStabiltyPool.address, PULSEStagingStabiltyPool.abi],
    StakingPool: [PULSEStagingStakingPool.address, PULSEStagingStakingPool.abi],
    TroveManager: [PULSEStagingTroveManager.address, PULSEStagingTroveManager.abi],
    UniswapRouter: [PULSEStagingUniswapRouter.address, PULSEStagingUniswapRouter.abi],
    Escrow: [PULSEStagingEscrow.address, PULSEStagingEscrow.abi],
    User: [PULSEStagingUser.abi],
  },
  UAT: {
    ActivePool: [PULSEUATActivePool.address, PULSEUATActivePool.abi],
    CustomStragey: [PULSEUATCustomStragey.address, PULSEUATCustomStragey.abi],
    CustomStatic: [PULSEUATCustomStatic.address, PULSEUATCustomStatic.abi],
    Factory: [PULSEUATFactory.address, PULSEUATFactory.abi],
    HalfandHalf: [PULSEUATHalfandHalf.address, PULSEUATHalfandHalf.abi],
    HalfandHalfStatic: [PULSEUATHalfandHalfStatic.address, PULSEUATHalfandHalfStatic.abi],
    Helpers: [PULSEUATHelpers.address, PULSEUATHelpers.abi],
    LqtyToken: [PULSEUATLoanToken.address, PULSEUATLoanToken.abi],
    LusdToken: [PULSEUATUsdlToken.address, PULSEUATUsdlToken.abi],
    MailBoxMoney: [PULSEUATMailBoxMoney.address, PULSEUATMailBoxMoney.abi],
    MultiTrove: [PULSEUATMultiTrove.address, PULSEUATMultiTrove.abi],
    PassivePulse: [PULSEUATPassivePulse.address, PULSEUATPassivePulse.abi],
    PassivePulseStatic: [PULSEUATPassivePulseStatic.address, PULSEUATPassivePulseStatic.abi],
    PayDown: [PULSEUATPayDown.address, PULSEUATPayDown.abi],
    PulseStaker: [PULSEUATPulseStaker.address, PULSEUATPulseStaker.abi],
    PulseStakerStatic: [PULSEUATPulseStakerStatic.address, PULSEUATPulseStakerStatic.abi],
    PriceFeed: [PULSEUATPriceFeed.address, PULSEUATPriceFeed.abi],
    SortedTroves: [PULSEUATSortedTroves.address, PULSEUATSortedTroves.abi],
    StabiltyPool: [PULSEUATStabiltyPool.address, PULSEUATStabiltyPool.abi],
    StakingPool: [PULSEUATStakingPool.address, PULSEUATStakingPool.abi],
    TroveManager: [PULSEUATTroveManager.address, PULSEUATTroveManager.abi],
    UniswapRouter: [PULSEUATUniswapRouter.address, PULSEUATUniswapRouter.abi],
    Escrow: [PULSEUATEscrow.address, PULSEUATEscrow.abi],
    User: [PULSEUATUser.abi],
  },
};
