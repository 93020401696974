import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const InputBox = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
export const InputLabel = styled.div`
  display: flex;
  justify-content: space-between;
  .leftLabel {
    .text1 {
      color: ${props => props.theme.fadedWhite};
      margin-right: 4px;
    }
    .text2 {
      color: ${props => props.theme.white};
    }
  }
  .rightLabel {
    text-align: right;
    .text1 {
      color: ${props => props.theme.fadedWhite};
      /* font-size: 12px; */
    }
    .text2 {
      color: ${props => props.theme.white};
      /* font-size: 12px; */
    }
  }
`;

interface IBoxProps {
  customGap?: string;
  minWidth?: string;
  maxWidth?: string;
  maxheight?: string;
  align?: string;
  customPadding?: string;
  custFlex?: string;
  heightM?: string;
  mblPad?: string;
  gapM?: string;
  tAlign?: string;
  mblMargin?: string;
  justify?: string;
  isDashboard?: string;
  borderBottom?: string;
  customPaddingBottom?: string;
  marginBottom?: string;
  isMakeCol?: boolean;
  isMakeStart?: boolean;
}

export const ContainerBox = styled.div<IBoxProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 10px rgb(18 247 156);
  position: relative;
  background: ${(props: any) => props.theme.purple};
  border-radius: 10px;
  gap: ${(props: IBoxProps) => (props.customGap ? props.customGap : "1em")};
  flex: ${(props: IBoxProps) => props.custFlex || "1 1 0px"};
  height: ${(props: IBoxProps) => (props.isDashboard ? "auto" : "100%")};
  .btnWrapper {
    width: 50%;
  }
  .amountContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  max-width: ${(props: IBoxProps) => (props.maxWidth ? props.maxWidth : "555px")};
  @media (max-width: ${screenSizes.M}px) {
    padding: ${({ mblPad }: IBoxProps) => mblPad || ""};
    margin: ${({ mblMargin }: IBoxProps) => mblMargin || ""};
    max-width: 310px;
  }
 
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 280px;
  }
  animation: 2000ms forwards, fade-in 600ms forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const Divider = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.12) !important;
  width: 99%;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding-bottom: 50px;
`;

export const ColFlex = styled.div<IBoxProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props: any) => (props.customGap ? props.customGap : "0.5em")};
  align-items: ${(props: any) => props.align};
  padding: ${(props: any) => props.customPadding};
  padding-bottom: ${(props: any) => props.customPaddingBottom};
  text-align: ${(props: any) => props.tAlign};
  border-bottom: ${props => props.borderBottom};
  margin-bottom: ${props => props.marginBottom};

  .amountContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
`;
export const DividerPipe = styled.div`
  height: 1em;
  width: 1px;
  background: ${(props: any) => props.theme.pink1};
  @media screen and (max-width: ${screenSizes.M}px) {
    display: none !important;
  }
`;

export const RightBoxCntr = styled.div<IBoxProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props: any) => (props.customGap ? props.customGap : "0.5em")};
  align-items: ${(props: any) => props.align};
  padding: ${(props: any) => props.customPadding};
  flex: 1 1 0px;
`;

export const RowFlex = styled.div<IBoxProps>`
  display: flex;
  justify-content: ${(props: any) => props.justify || "space-between"};
  padding: ${(props: any) => props.customPadding};
  align-items: ${(props: any) => props.align};
  gap: ${(props: any) => props.customGap};
  width: ${props => props.maxWidth};
  max-width: ${props => props.maxWidth};
  div {
    display: flex;
    align-items: center;
  }
  @media (max-width: ${screenSizes.M}px) {
    gap: ${(props: any) => (props.gapM ? props.gapM : "")};
    flex-direction: ${(props: any) => (props.isMakeCol ? "column" : "row")};
    align-items: ${(props: any) => (props.isMakeStart ? "flex-start" : "center")};
  }
  
`;
interface IImage {
  custHeight?: string;
  custWidth?: string;
}
export const Image = styled.img<IImage>`
  height: ${props => (props.custHeight ? props.custHeight : "28px")};
  width: ${props => (props.custWidth ? props.custWidth : "28px")};
`;
export const NotifyImage = styled.img`
  height: 16px;
  width: 16px;
`;
export const TransactionHeading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  align-item: center;
  justify-content: center;
`;
export const TransactionContent = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;

  img {
    margin-top: 5px;
  }
`;
export const SpinnerImg = styled.img<any>`
  animation: ${(props: any) => (props.isSpinning ? "spinner-animation 1.5s infinite linear" : "")};
  @-webkit-keyframes spinner-animation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spinner-animation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  padding: 15px;
  @media (max-width: ${screenSizes.M}px) {
    padding: 5px;
  }
`;
interface IContainerProps {
  pad?: string;
  width?: string;
  justify?: string;
  isHide?: boolean;
}
export const RowContainer = styled.div`
  display: flex;
  justify-content: ${(props: IContainerProps) => props.justify || "center"};
  align-items: center;
  gap: 12px;
  animation: fade-in 500ms forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled.div<IContainerProps>`
  padding: ${(props: IContainerProps) => props.pad || "0 0 50px 0"};
  width: ${(props: IContainerProps) => props.width || "80%"};
  max-width: 1100px;

  @media (max-width: ${screenSizes.M}px) {
    display: ${(props: IContainerProps) => (props.isHide ? "none" : "")};
    width: unset;
  }
`;
export const FixedContainer = styled.div<IContainerProps>`
  padding: ${(props: IContainerProps) => props.pad || "0 0 50px 0"};
  width: ${(props: IContainerProps) => props.width || "80%"};
  max-width: 1100px;
`;
interface ITooltipProps {
  customWidth?: string;
  customPad?: string;
  left?: string;
  fullWidth?: boolean;
  heading?: string;
  divLeft?: string;
  isBreak? : boolean;
}
export const Tooltip = styled.div<ITooltipProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props: any) => props.customPad};
  left: ${props => props.divLeft};
  &:hover {
    cursor: pointer;
  }
  position: relative;
  ::before,
  ::after {
    --scale: 0;
    --arrow-size: 5px;
    --tooltip-color: ${(props: any) => props.theme.tooltipBg};
    z-index: 999;
    position: absolute;
    top: 50%;
    transform: ${(props: any) => (props.heading ? "translateY(-50%) translateX(180px) scale(var(--scale))" : "translateY(-50%) translateX(10px) scale(var(--scale))")};
    transition: 300ms transform;
    transform-origin: bottom center;
    @media (max-width: ${screenSizes.M}px) {
      transform: ${(props: any) => (props.heading ? "translateY(-50%) translateX(150px) scale(var(--scale))" : "translateY(-50%) translateX(10px) scale(var(--scale))")};
    }
  }

  ::before {
    left: ${(props: any) => props.left || "15px"};
    content: attr(data-tooltip);
    color: ${(props: any) => props.theme.white};
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: center;
    width: ${(props: any) => props.customWidth || "max-content"};
    background: var(--tooltip-color);
    font-size: 12px;
    backdrop-filter: blur(30px);
    @media (max-width: ${screenSizes.M}px) {
      width: 10em;
      left: ${(props: any) => (props.left ? "-12.5em" : "")};
    }
  }

  :hover::before {
    --scale: 1;
  }
  :hover::after {
    transform: ${(props: any) => (props.left ? "rotate(270deg)" : "rotate(90deg)")};
    transition: 300ms transform;
  }

  ::after {
    content: "";
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
    left: ${(props: any) => (props.left ? "-15px" : "20px")};
  }
`;
export const TooltipTop = styled.div<ITooltipProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: any) => (props.fullWidth ? "100%" : "")};
  padding: ${(props: any) => props.customPad};
  &:hover {
    cursor: pointer;
  }
  position: relative;
  ::before,
  ::after {
    --scale: 0;
    --arrow-size: 5px;
    --tooltip-color: ${(props: any) => props.theme.tooltipBg};
    z-index: 999;
    position: absolute;
    bottom: 1.5em;
    transform: scale(var(--scale));
    pointer-events: auto !important;
    transition: 300ms transform;
    transform-origin: bottom center;
  }

  ::before {
    left: ${(props: any) => (props.fullWidth ? "" : "-7em")};
    bottom: ${(props: any) => (props.fullWidth ? "5em" : "")};
    content: attr(data-tooltip);
    color: ${(props: any) => props.theme.white};
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: center;
    width: ${(props: any) => props.customWidth || "max-content"};
    background: var(--tooltip-color);
    font-size: 12px;
    backdrop-filter: blur(30px);
    @media (max-width: ${screenSizes.M}px) {
      width: ${(props: any) => props.isBreak ? "8em" : "15em"} ;
      word-break: ${(props: any) => props.isBreak ? "break-all" : ""};
    }
  }

  :hover::before {
    --scale: 1;
  }
  :hover::after {
    transform: rotate(0deg);
    transition: 300ms transform;
  }

  ::after {
    content: "";
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
    left: ${(props: any) => (props.fullWidth ? "" : "0em")};
    top: -0.5em;
  }
`;
interface IImageProps {
  height?: string;
  width?: string;
  heightM?: string;
  marginLeft?: string;
  marginBottom?: string;
}
export const SvgIcon = styled.img<IImageProps>`
  height: ${(props: IImageProps) => props.height || "16px"};
  width: ${(props: IImageProps) => props.width};
  margin-left: ${(props: IImageProps) => props.marginLeft};
  margin-bottom: ${(props: IImageProps) => props.marginBottom};
  cursor: pointer;
  @media (max-width: ${screenSizes.M}px) {
    height: ${(props: any) => (props.heightM ? props.heightM : "")};
  }
`;
export const SelectActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: -2;
`;
export const TabBox = styled.div`
  width: 228px;
  height: 40px;
  display: flex;
`;
interface IButtonProps {
  border?: string;
}

export const Tab = styled.button<IButtonProps>`
  background: ${(props: any) => (props.border === "yes" ? "#1C1F2A" : "transparent")};
  border: ${(props: any) => (props.border === "yes" ? "1px solid #12F79C" : "none")};
  border-radius: 6px;
  padding: 8px 42px;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 24px; */
  text-transform: capitalize;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
`;
export const AdjustTabContainer = styled.div`
  background: #21212f;
  border: 1px solid #12f79c;
  border-radius: 50px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
interface IAdjustButtonProps {
  active?: string;
}
export const AdjustTab = styled.button<IAdjustButtonProps>`
  padding: ${(props: any) => (props.active === "yes" ? "8px 28px" : "8px")};
  border-radius: 50px;
  cursor: pointer;
  background: ${(props: any) => (props.active === "yes" ? "#12F79C" : "transparent")};
  border: none;
  outline: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-transform: uppercase;
  color: ${(props: any) => (props.active === "yes" ? "#000000" : "rgba(255, 255, 255, 0.7)")};
  @media (max-width: ${screenSizes.M}px) {
    padding: ${(props: any) => (props.active === "yes" ? "8px 10px" : "8px")};
    font-size: 11px;
    line-height: 18px;
  }
`;
