import { Label } from "./style";
import { Image } from "shared/styled";
import { Button } from "./../button/index";
interface InputProps {
  type?: string;
  disable?: boolean;
  hideIcon?: boolean;
  isReadOnly?: boolean;
  className?: string;
  value?: any;
  maxLimit?: number;
  onChange?: any;
  placeholder?: any;
  maxOut?: any;
  maxBtn?: boolean;
  isMaxDisabled?: boolean;
  disableMaxout?: boolean;
  icon?: any;
  minLimit?: any;
  inputWidth?: string;
  inputHeight?: string;
  padding?: string;
  tAlign?: string;
  fSize?: string;
  lblWidth?: string;
  justify?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  bRadius?: string;
  widthInputCntr?: string;
  onBlur?: any;
  fSizeMobile?: string;
  maxWidthMb?: string;
  cntrMaxWidth?: string;
  custImageHeight?: string;
  custImageWidth?: string;
  maxLength?: number;
  noMax?: boolean;
}

export const CustomInput = (props: InputProps) => {
  const {
    disable,
    className,
    value,
    onChange,
    placeholder,
    maxOut,
    isReadOnly,
    maxBtn,
    isMaxDisabled,
    icon,
    type,
    maxLimit,
    minLimit = 0,
    hideIcon = false,
    inputWidth,
    inputHeight,
    padding,
    tAlign,
    fSize,
    lblWidth,
    justify,
    height,
    minWidth,
    bRadius,
    widthInputCntr,
    onBlur,
    maxWidth,
    fSizeMobile,
    maxWidthMb,
    cntrMaxWidth,
    custImageHeight,
    custImageWidth,
    maxLength,
    noMax,
  } = props;

  return (
    <Label
      maxWidthMb={maxWidthMb}
      cntrMaxWidth={cntrMaxWidth}
      fSizeMobile={fSizeMobile}
      minWidth={minWidth}
      maxWidth={maxWidth}
      widthInputCntr={widthInputCntr}
      bRadius={bRadius}
      height={height}
      lblWidth={lblWidth}
      justify={justify}
      fSize={fSize}
      tAlign={tAlign}
      padding={padding}
      inputWidth={inputWidth}
      inputHeight={inputHeight}
    >
      <div>
        {!hideIcon && <Image custHeight={custImageHeight} custWidth={custImageWidth} src={icon} alt="logo-crypto" />}
        <input
          onChange={onChange}
          onBlur={onBlur}
          readOnly={isReadOnly}
          value={value}
          max={maxLimit}
          min={minLimit}
          className={className}
          disabled={disable}
          placeholder={placeholder}
          type={type}
          maxLength={noMax ? 20 : maxLength ? maxLength : 10}
        />
      </div>
      {maxBtn ? (
        <Button btnType="roundedfilledButton" isDisabled={isMaxDisabled || disable} bRadius="50px" onClick={maxOut} minWidthMb="45px" fSizeMobile="10px" maxHeightMb="25px">
          Max
        </Button>
      ) : (
        ""
      )}
    </Label>
  );
};
