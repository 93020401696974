import { useEffect, useState } from "react";
import { AdjustTab, AdjustTabContainer } from "shared/styled";
import { Borrow } from "./Borrow";
import { Deposit } from "./Deposit";
import { Payback } from "./Payback";
import { Withdraw } from "./Withdraw";

interface IProps {
  changeCurrentTab?: any;
}

export const Adjust = (props: IProps) => {
  const { changeCurrentTab } = props;

  const localTab = sessionStorage.getItem("currentAdjustTab");
  const [active, setActive] = useState<number>(localTab ? Number(localTab) : 1);

  const handleConfirmTabs = (current: number) => {
    setActive(current);
  };

  useEffect(() => {
    changeCurrentTab(active);
  }, [active]);

  return (
    <>
      <AdjustTabContainer>
        <AdjustTab
          active={active === 1 ? "yes" : ""}
          onClick={() => handleConfirmTabs(1)}
        >
          deposit
        </AdjustTab>
        <AdjustTab
          active={active === 2 ? "yes" : ""}
          onClick={() => handleConfirmTabs(2)}
        >
          withdraw
        </AdjustTab>
        <AdjustTab
          active={active === 3 ? "yes" : ""}
          onClick={() => handleConfirmTabs(3)}
        >
          borrow
        </AdjustTab>
        <AdjustTab
          active={active === 4 ? "yes" : ""}
          onClick={() => handleConfirmTabs(4)}
        >
          payback
        </AdjustTab>
      </AdjustTabContainer>
      {active === 1 ? (
        <Deposit />
      ) : active === 2 ? (
        <Withdraw />
      ) : active === 3 ? (
        <Borrow />
      ) : active === 4 ? (
        <Payback />
      ) : (
        ""
      )}
    </>
  );
};
