import React from "react";
import { useSelector } from "react-redux";
import { Button } from "shared/button";
import { TooltipTop } from "shared/styled";

interface IProps {
  tempDisabled?: boolean;
  buttonText: string;
  handleCompoundStaking: () => any;
}

const CompoundStakingNotExecute: any = (props: IProps) => {
  const { tempDisabled, buttonText, handleCompoundStaking } = props;

  const { IsStakingCompound, disablingStakingReason } = useSelector(
    (state: any) => state.strategyRedu
  );

  const tempDisabledReason = tempDisabled
    ? "The strategy selected does not compound Staking pool."
    : disablingStakingReason;

  return (
    <>
      {tempDisabled || !IsStakingCompound ? (
        <TooltipTop
          fullWidth
          customWidth="15em"
          data-tooltip={
            tempDisabledReason
          }
        >
          <Button
            btnType={"borderedfilledLightButton"}
            onClick={handleCompoundStaking}
            isDisabled={tempDisabled || !IsStakingCompound}
            wrapperWidth="100%"
          >
            {buttonText}
          </Button>
        </TooltipTop>
      ) : (
        <Button
          btnType={"borderedfilledLightButton"}
          onClick={handleCompoundStaking}
          isDisabled={tempDisabled || !IsStakingCompound}
          wrapperWidth="100%"
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

export const CompoundStakingNotExecuteMemo = React.memo(
  CompoundStakingNotExecute
);
