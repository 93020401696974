import { InputBox, InputLabel, RowContainer } from "shared/styled";
import { CustomInput } from "shared/customInput";
import { Button } from "shared/button";
import { useEffect, useState } from "react";
import { Notification } from "shared/notification";
import { envAllDescriptionDetails, formatNumbersWithComma, validateNumberInput } from "shared/helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from "shared/hook/useWallet";
import { ESCROW_INSTANCE, SIGNER } from "blockchain/contract/instance";
import { parseUnits } from "ethers/lib/utils";
import { setRefetchBalance, setTxHash } from "logic/redux/actions";
import { useTransaction } from "shared/hook/useTransaction";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";

export const Deposit = () => {
  const { ETH } = useSelector((state: any) => state.trove);
  const { refetchBalance } = useSelector((state: any) => state.navbar);
  const { balance } = ETH;
  const [amount, setAmount] = useState<string>("0");
  const [notifyText, setNotifyText] = useState<string>("");
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const [ethInDollar, setEthInDollar] = useState<any>(0);
  const { ethToUSD } = useWallet();
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const dispatch = useDispatch();

  useEffect(() => {
    convertUSD();
  }, [balance]);

  const convertUSD = async () => {
    const USDValue = await ethToUSD();
    setEthInDollar(USDValue);
    const Convert_ETHBal = USDValue ? Number(USDValue) * Number(balance) : 0.0;
    setConvertEthBalance(Convert_ETHBal);
  };

  const checking = (value:any) =>{
    const AMOUNT_TO_USD = ethInDollar ? Number(value) * Number(ethInDollar) : 0.0;
    let tempText = `You are depositing ${formatNumbersWithComma(value, 4)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(AMOUNT_TO_USD, 2)})`;
    setNotifyText(tempText);
  }

  const handleClearStates = () => {
    setAmount("");
    setNotifyText("");
    setRangeValue(0);
  };

  const handleInput = (e: string) => {
    let value = validateNumberInput(e)?.toString();
    if (Number(value) <= Number(balance)) {
      let percentage = ((value / Number(balance)) * 100).toFixed(0);
      setRangeValue(Number(percentage));
      setAmount(value);
      checking(value);
    }
  };

  const handleDeposit = async () => {
    try {
      const convertedAmount = parseUnits(amount.toString());
      const estimateGas = await ESCROW_INSTANCE.connect(SIGNER).estimateGas.deposit({
        value: convertedAmount,
      });
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      startSpinner();
      const res = await ESCROW_INSTANCE.connect(SIGNER).deposit({
        value: convertedAmount,
        gasLimit: bufferedGas.toFixed(0),
      });
      dispatch(setTxHash(res.hash));
      await res.wait();
      dispatch(setRefetchBalance(!refetchBalance));
      stopSpinner();
      handleClearStates();
    } catch (err) {
      handleError(err);
      handleClearStates();
    }
  };

  const rangeHandler = (prop: any) => {
    if (balance > 0) {
      setRangeValue(prop);
      let percentToValue: any = Number(balance) * (Number(prop) / 100);
      percentToValue = percentToValue?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0];
      setAmount(percentToValue);
      checking(percentToValue);
    }
  };

  const disabledReason = Number(balance) < Number(amount) || amount === "" || Number(amount) <= 0;
  
  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Deposit</span>
            <span className="text2">{envAllDescriptionDetails.VAULT_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(balance, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertEthBalance, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput hideIcon placeholder={"Enter Amount"} value={amount} onChange={(e: any) => handleInput(e)}></CustomInput>
        <RangePointerInput rangeValue={rangeValue} rangeHandler={rangeHandler} />
      </InputBox>
      {amount !== "" && Number(amount) > 0 && <Notification message={notifyText} type="error" />}
      <RowContainer>
        <Button btnType={"gradientFilledButton"} wrapperWidth="100%" fSizeMobile="12px" onClick={handleDeposit} isDisabled={disabledReason}>
          Confirm
        </Button>
      </RowContainer>
    </>
  );
};
