import { screenSizes } from 'styles/theme';
import styled  from 'styled-components';


export const CustomFooter = styled.footer`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200px;
    /* margin-top: 2em; */
    background: ${(props: any) => props.theme.secondary};
    @media (max-width: ${screenSizes.M}px) {
       height: auto;
    }
`
export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    padding: 2em 6em;
    gap: 4.5em;
    text-align: end;
    @media (max-width: ${screenSizes.XL}px) {
        gap: 2em
    }
    @media (max-width: ${screenSizes.M}px) {
        flex-direction: column;
        gap: 1em;
        align-items: start;
        padding: 1em;
    }
`
interface IColPorps {
    align?: string,
    custPadding?: string
}
export const ColContainer = styled.div<IColPorps>`
    display: flex;
    flex-direction: column;
    img {
        height: 40px;
    }
    max-width: 300px;
    gap: 4px;
    align-items: ${(props: any) => props.align ? props.align : 'end'};
    @media (max-width: ${screenSizes.M}px) {
        align-items: start;
        padding: ${(props: any) => props.custPadding};
    }
`
export const HeadColContainer = styled.div<IColPorps>`
    display: flex;
    flex-direction: column;
    img {
        height: 40px;
        cursor: pointer;
    }
    max-width: 300px;
    gap: 12px;
    text-align: start;
    justify-content: start;
    align-items: ${(props: any) => props.align ? props.align : 'end'};
    @media (max-width: ${screenSizes.M}px) {
        align-items: start;
        padding: ${(props: any) => props.custPadding};
        gap: 8px;
    }
`
export const FooterSecondryText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    color: ${(props: any) => props.theme.darkGray};
    cursor: pointer;
`
interface ITextrops {
    pad?: string,
}
export const FooterPrimaryText = styled.p<ITextrops>`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: ${(props: any) => props.theme.gray2};
    padding: ${(props: any) => props.pad};
`