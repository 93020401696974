import styled from "styled-components";
import { colors, screenSizes } from "styles/theme";

interface ITextProps {
  fSize?: string;
  fColor?: string;
  fWeight?: string;
  fOpacity?: string;
  tAlign?: string;
  fLineHeight?: string;
  fcolor?: string;
  fLetterSpace?: string;
  maxWidth?: string;
  maxMobWidth?: string;
  fSizeM?: string;
  fLineHeightMb?: string;
  width?: string;
  isLink?: boolean;
  isSpace?: boolean;
}

export const SemiboldHeading3 = styled.h3`
  color: ${(props: any) => props.theme.gray2};
  font-family: Roboto;
  font-size: 28px;
  font-weight: ${(props: any) => props.fWeight || "500"};
`;
export const Heading3 = styled.span<ITextProps>`
  color: ${(props: any) => props.theme.white};
  font-family: Roboto;
  font-size: ${(props: any) => props.fSize || "22px"};
  font-weight: ${(props: any) => props.fWeight || "500"};
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${(props: any) => props.fSizeM};
    line-height: ${(props: any) => props.fLineHeightMb};
  }
`;
export const Para = styled.p<ITextProps>`
  cursor: ${(props: any) => (props.isLink ? "pointer" : "")};
  text-decoration: ${(props: any) => (props.isLink ? "underline" : "none")};
  color: ${(props: any) => (props.fcolor ? props.fcolor : props.theme.gray2)};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "14px")};
  font-weight: ${(props: any) => props.fWeight || "400"};
  line-height: ${(props: any) => props.fLineHeight || "21px"};
  text-align: ${(props: any) => props.tAlign};
  max-width: ${(props: any) => props.maxWidth};
  margin-left: ${(props: any) => props.isSpace ? "5px" : ""} ;
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${(props: any) => props.fSizeM};
    line-height: ${(props: any) => props.fLineHeightMb};
  }
`;
export const LinkPara = styled.p<ITextProps>`
  color: ${(props: any) => (props.fcolor ? props.fcolor : props.theme.gray2)};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "14px")};
  font-weight: ${(props: any) => props.fWeight || "400"};
  line-height: ${(props: any) => props.fLineHeight || "21px"};
  text-align: ${(props: any) => props.tAlign};
  max-width: ${(props: any) => props.maxWidth};
  span {
    display: flex;
    align-items: center;
  }
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${(props: any) => props.fSizeM};
    line-height: ${(props: any) => props.fLineHeightMb};
  }
  padding: 2px 5px;
  :hover {
    border-radius: 5px;
    cursor: pointer;
    outline: 1px solid ${colors.pink1};
    transition: outline 1.5s ease;
  }
  outline: none;
`;

export const BoldPara = styled.p`
  color: ${(props: any) => props.theme.white};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  line-height: 21px;
`;

export const LightText = styled.p<ITextProps>`
  color: ${(props: any) => (props.fcolor ? props.fcolor : props.theme.white)};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "13px")};
  opacity: ${(props: any) => (props.fOpacity ? props.fOpacity : "54%")};
  line-height: ${(props: any) => props.fLineHeight || ""};
  text-align: ${(props: any) => props.tAlign || ""};
  font-weight: ${(props: any) => props.fWeight || 500};
  word-wrap: break-word;
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${(props: any) => props.fSizeM};
  }
`;
interface IgrayText {
  fontSize?: string;
  Color?: boolean;
  fontWeight?: string;
  marginBottom?: string;
}
export const AmountText = styled.p<IgrayText>`
  color: ${(props: any) => (props.Color ? props.theme.pink1 : props.theme.white)};
  font-family: "Roboto";
  font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
  @media (max-width: ${screenSizes.M}px) {
    font-size: 10px;
  }
`;
export const SecondaryText = styled.p<ITextProps>`
  color: ${(props: any) => props.theme.primaryButton};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "16px")};
  font-weight: ${(props: any) => props.fWeight || "500"};
`;
export const CustomText = styled.p<ITextProps>`
  color: ${(props: any) => (props.fcolor ? props.fcolor : props.theme.white)};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "18px")};
  opacity: ${(props: any) => props.fOpacity || "100%"};
  font-weight: ${(props: any) => props.fWeight || "500"};
  text-align: ${(props: any) => (props.tAlign ? props.tAlign : "")};
  line-height: ${(props: any) => props.fLineHeight || ""};
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${(props: any) => props.fSizeM};
    width: ${(props: any) => props.width};
  }
`;

export const LightLink = styled.a`
  color: ${(props: any) => props.theme.pink3};
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const LinkText = styled.a`
  color: ${(props: any) => props.theme.pink1};
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const InLineText = styled.span<ITextProps>`
  color: ${(props: any) => (props.fcolor ? props.fcolor : props.theme.white)};
  font-family: Roboto;
  font-size: ${(props: any) => (props.fSize ? props.fSize : "13px")};
  opacity: ${(props: any) => (props.fOpacity ? props.fOpacity : "54%")};
  line-height: ${(props: any) => props.fLineHeight || ""};
  text-align: ${(props: any) => props.tAlign || ""};
  font-weight: 500;
  padding-left: 3px;
`;

export const Greytext = styled.span<IgrayText>`
  color: #ffffff80;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  font-weight: ${props => props.fontWeight};
  line-height: 18px;
  margin-bottom: ${props => props.marginBottom};
  @media (max-width: ${screenSizes.M}px) {
    text-align: start;
  }
`;
