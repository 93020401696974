import styled from "styled-components";

export const Body = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${props => `calc( 100vh - ${props.footerHeight}px)`};
  background: #1a1a25;
`;
export const SubBody = styled.body`
  background-color: white;
  color: black;
  height: 100vh;
  width: 100vw;
`;
