import { useEffect, useState } from "react";

export const useIsMobileScreen = (): boolean => {
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 769px)").matches);

  useEffect(() => {
    const WINDOWS: any = window;
    if (WINDOWS?.location?.origin !== "https://google.com") {
      WINDOWS.matchMedia("(min-width: 769px)").addEventListener("change", (e: any) => {
        setMatches(e.matches);
      });
    }
    return () => {
      if (WINDOWS?.location?.origin !== "https://google.com") {
        WINDOWS.matchMedia("(min-width: 769px)").removeEventListener("change", (e: any) => {
          setMatches(e.matches);
        });
      }
    };
  }, []);

  return !matches;
};

export const useIsMouseOnDiv = (id: any): boolean => {
  const [isOnDiv, setIsOnDiv] = useState(false);

  const Doc: any = id;
  useEffect(() => {
    id !== null && Doc.addEventListener("mouseenter", () => setIsOnDiv(true));
    id !== null && Doc.addEventListener("mouseout", () => setIsOnDiv(false));

    return () => {
      id !== null && Doc.removeEventListener("mouseenter", () => setIsOnDiv(true));
      id !== null && Doc.removeEventListener("mouseout", () => setIsOnDiv(false));
    };
  }, []);
  return isOnDiv;
};
