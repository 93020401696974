import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const DashContainer = styled.div`
  display: flex;
  gap: 1.5em;
  @media screen and (max-width: ${screenSizes.M}px) {
    flex-direction: column;
    gap: 1.5em;
  }
`;
export const ColCntr = styled.div`
  display: flex;
  gap: 1em;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0px;

  /* .cardContainer1 {
    :has(.__react_component_tooltip.show) {
      z-index: 3;
    }
  }
  .cardContainer2 {
    :has(.__react_component_tooltip.show) {
      z-index: 3;
    }
  }
  .cardContainer3 {
    :has(.__react_component_tooltip.show) {
      z-index: 3;
    }
  }
  .cardContainer4 {
    :has(.__react_component_tooltip.show) {
      z-index: 3;
    }
  }
  .cardContainer5 {
    :has(.__react_component_tooltip.show) {
      z-index: 3;
    }
  } */

  @media screen and (max-width: ${screenSizes.M}px) {
    flex: 1 1;
    gap: 1.5em;
  }
  @media screen and (max-width: ${screenSizes.XXS}px) {
    margin-left: 1em;
  }
`;
export const InfoBox = styled.div`
  display: flex;
  gap: 0.5em;
`;

export const StakingTextBox = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

export const StakingBtnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  gap: 1.5em;
`;

export const ETHBalContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
