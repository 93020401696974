import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
interface NetworkData {
  urls: string[];
  name: string;
  nativeCurrency: string;
  blockExplorerUrls: string[];
}

interface I_RpcArray {
  [key: number]: NetworkData;
}

const rpcEthArray: I_RpcArray = {
  5: {
    urls: ["https://eth-goerli.g.alchemy.com/v2/s_aMpnUppY8I3sHzu9lZDGwiHScPvfd2"],
    name: "Goerli test network",
    nativeCurrency: "GoerliETH",
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
};
const rpcPulseArray: I_RpcArray = {
  943: {
    urls: ["https://rpc.v4.testnet.pulsechain.com"],
    name: " PulseChain test network",
    nativeCurrency: "tPLS",
    blockExplorerUrls: ["https://scan.v4.testnet.pulsechain.com/"],
  },
};

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

const ethChainId = 5;

const rpcArray = Number(envChainId) === ethChainId ? rpcEthArray : rpcPulseArray;

const [mainnet, ...optionalChains] = Object.keys(rpcArray).map(Number);
export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  actions =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "627ed925ade61d87ab7113e91c3e28b8",
        chains: [Number(envChainId)],
        showQrModal: true,
        rpcMap: { 943: "https://rpc.v4.testnet.pulsechain.com" },
      },
    }),
);
