import { stabilityPoolPath } from "logic/paths";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { envAllDescriptionDetails, formatNumbersWithComma, URL_READ_MORE_STABILITY_SUMMARY } from "shared/helpers/utils";
import { ColFlex, ContainerBox, RowFlex } from "shared/styled";
import { TextBar } from "shared/textbar/TextBar";
import { Heading3 } from "shared/Typography";

import { useWeb3React } from "@web3-react/core";
import { stabilityInitialValues } from "logic/redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompoundMemo } from "shared/compound/Compound";
import { useWallet } from "shared/hook/useWallet";
import { CompoundStabilityNotExecuteMemo } from "shared/compound/CompoundStabilityNotExecute";
import { StabilityLoanCompoundingPath } from "modules/pages/stability/components/loanCompoundingPath/LoanCompoundingPath";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface IProps {
  handleCompoundButton: () => any;
  handleCompoundStability: () => any;
}

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const SPBox = (props: IProps) => {
  const { handleCompoundButton, handleCompoundStability } = props;

  const { selectedCompoundStrategyID, disablingReason } = useSelector((s: any) => s.strategyRedu);

  const { poolShare, stakedLUSD, stakedLUSDInDollar, lqtyGain, lqtyGainInDollar, liquidationEthGain, liquidationEthGainInDollar } = useSelector((state: any) => state.stability);

  const { ethToUSD } = useWallet();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account, library } = useConnectWallet();
  const { userContractAddress } = useSelector((s: any) => s.navbar);

  const handleDeposite = () => {
    navigate(stabilityPoolPath);
  };

  useEffect(() => {
    dispatch(stabilityInitialValues(ethToUSD, account));
  }, [library, userContractAddress]);

  const tempDisabled = selectedCompoundStrategyID !== "2" && selectedCompoundStrategyID !== "3" && selectedCompoundStrategyID !== "5";
  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Stability pool" : disablingReason;
  const isNotExecuteStrategy = selectedCompoundStrategyID === "3" || selectedCompoundStrategyID === "5";

  return (
    <ContainerBox minWidth="555px">
      <ColFlex customGap={"12px"}>
        <RowFlex align="center">
          <TextBar
            leftText="Stability"
            heading="yes"
            custId="stabilityHeadingTooltip"
            toolTip={envAllDescriptionDetails.STABILITY_DASHBOARD_TIP_TEXT}
            link={URL_READ_MORE_STABILITY_SUMMARY}
            linkText="Stability Pool"
          />
          <Button btnType={"borderedfilledButton"} customWidth="9em" customHeight="35px" onClick={handleDeposite}>
            Details
          </Button>
        </RowFlex>
        <RowFlex>
          <div className="amountContainer">
            <img src={envAllDescriptionDetails.STABILITY_LOGO} alt="logo-crypto" height={Number(envChainId) === ChainId?.eth ? 30 : "auto"} />
            <Heading3 fSizeM="16px">
              {Number(stakedLUSD) ? formatNumbersWithComma(stakedLUSD, 3) : "0.00"} {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
            </Heading3>
            <Heading3 fSizeM="16px">
              ($
              {Number(stakedLUSDInDollar) ? formatNumbersWithComma(stakedLUSDInDollar, 2) : "0.00"})
            </Heading3>
          </div>
        </RowFlex>
        <ColFlex customGap=".5em">
          <TextBar leftText="Pool Share" rightText={`${poolShare !== "N/A" && Number(poolShare) ? poolShare + "%" : "0%"}`} />
          <TextBar
            leftText={`${envAllDescriptionDetails.VAULT_TOKEN_TEXT} from Liquidations`}
            rightText={`${Number(liquidationEthGain)
              ? formatNumbersWithComma(liquidationEthGain, 6) +
              " " +
              envAllDescriptionDetails.VAULT_TOKEN_TEXT +
              " " +
              `($${formatNumbersWithComma(liquidationEthGainInDollar, 2)})`
              : ` 0 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`
              }`}
          />
          <TextBar
            marginBottom="1px"
            leftText="Reward"
            custId="spRewardTip"
            toolTip={envAllDescriptionDetails.REWARD_TEXT}
            rightText={`${Number(lqtyGain)
              ? formatNumbersWithComma(lqtyGain, 6) + " " + envAllDescriptionDetails.STAKING_TOKEN_TEXT + " " + `($${formatNumbersWithComma(lqtyGainInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`
              }`}
          />
          <StabilityLoanCompoundingPath />
        </ColFlex>
      </ColFlex>
      <RowFlex>
        {isNotExecuteStrategy ? (
          <CompoundStabilityNotExecuteMemo tempDisabled={tempDisabled} buttonText="Compound Stability" handleCompoundStability={handleCompoundStability} />
        ) : (
          <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText="Compound Stability" handleCompoundButton={handleCompoundButton} />
        )}
      </RowFlex>
    </ContainerBox>
  );
};
