import { useWeb3React } from "@web3-react/core";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { rootPath } from "logic/paths";
import { setIsHideModule, setTxHash } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { envAllDescriptionDetails, formatAddress, formatNumbersWithComma, tokenToUSD } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { Loader } from "shared/loader/loader";
import { Table } from "shared/react-table/Table";
import { SvgIcon, Tooltip, TooltipTop } from "shared/styled";
import { Heading3 } from "shared/Typography";
import { AddressWrapper, ColumnData, Container, CustomTable, TextContainer } from "./style";
import copyIcon from "../../../assets/icons/copy-icon-2.svg";
import copyIconActive from "../../../assets/icons/copy-icon-2-active.svg";
import { MULTI_TROVE_INSTANCE, SORTED_TROVES, TROVE_MANAGER_INSTANCE } from "blockchain/contract/instance";
import Pagination from "shared/pagination/Pagination";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

const perPage = 8;

export const RiskyVaults = () => {
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const { account, active } = useConnectWallet();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ethToUSD } = useWallet();
  const [trimmedData, setTrimmedData] = useState<[]>([]);
  const [allData, setAllData] = useState<[]>([]);
  const [dataLen, setDataLen] = useState<number>(perPage);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [troveCount, setTroveCount] = useState<number>(0);
  const [cannotLiquidateAll, setCannotLiquidateAll] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

  const liquidateChecking = async (owner: any) => {
    try {
      await TROVE_MANAGER_INSTANCE?.callStatic?.liquidate(owner);
      return true;
    } catch (e: any) {
      return false;
    }
  };

  const checkForLiquidateAll = async () => {
    try {
      const troves = await SORTED_TROVES.getSize();
      await TROVE_MANAGER_INSTANCE?.callStatic?.liquidateTroves(troves);
      setCannotLiquidateAll(false);
    } catch (err) {
      console.log({ err });
      setCannotLiquidateAll(true);
    }
  };
  const liquidateAll = async () => {
    try {
      startSpinner();
      const troves = await SORTED_TROVES.getSize();
      const estimateGas = await TROVE_MANAGER_INSTANCE?.estimateGas?.liquidateTroves(troves);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      const final_res = await TROVE_MANAGER_INSTANCE?.liquidateTroves(troves, {
        gasLimit: bufferedGas.toFixed(0)
      });
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      dispatch(setTxHash(final_res.hash));
      setDataLen(perPage);
      await createData();
      stopSpinner();
    } catch (err) {
      console.error("liquidateAll", err);
      handleError(err);
    }
  };

  const liquidate = async (borrowerAdd: string) => {
    try {
      startSpinner();
      const estimateGas = await TROVE_MANAGER_INSTANCE?.estimateGas?.liquidate(borrowerAdd);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      const final_res = await TROVE_MANAGER_INSTANCE?.liquidate(borrowerAdd, {
        gasLimit: bufferedGas.toFixed(0)
      });
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      dispatch(setTxHash(final_res.hash));
      setDataLen(perPage);
      await createData();
      stopSpinner();
    } catch (err) {
      console.error("liquidate", err);
      handleError(err);
    }
  };

  const disable = (rec: boolean, colRatio: string) => {
    if (rec && Number(colRatio) < 150) {
      return false;
    } else if (Number(colRatio) < 110) {
      return false;
    }
    return true;
  };

  const findCollRatio = async (owner: any, price: any) => {
    const tempCollRatio = await TROVE_MANAGER_INSTANCE.getCurrentICR(owner, price);
    const convertCollRatio = (Number(formatEther(tempCollRatio)) * 100).toFixed(2);
    return convertCollRatio;
  };

  const createData = async () => {
    try {
      let res;
      const ethInDollar = await ethToUSD();
      const { lusdUSD } = await tokenToUSD();
      let price = parseUnits(ethInDollar.toString(), "ether");
      const rec = await TROVE_MANAGER_INSTANCE.checkRecoveryMode(price.toString());
      const troves = await SORTED_TROVES.getSize();
      const itemPerPage = 8;
      const totalPages = Math.ceil(troves / itemPerPage);
      const paginationValues: any = [];
      for (let i = 0; i < totalPages; i++) {
        paginationValues.push({ value1: -1 - 8 * i, value2: 8 + 8 * i });
      }
      const { value1, value2 } = paginationValues[currentPage - 1];
      setTroveCount(troves);
      if (Number(envChainId) === ChainId?.eth) {
        res = await MULTI_TROVE_INSTANCE?.getMultipleSortedTroves(value1, value2);
      } else {
        res = await MULTI_TROVE_INSTANCE?.getMultipleSortedVaults(value1, value2);
      }
      let data: any = await Promise.all(
        res?.map(async (ele: any, key: any) => {
          const coll: any = formatEther(ele.coll);
          const debt: any = formatEther(ele.debt);
          const _isLiquidateOne = await liquidateChecking(ele.owner);
          const collUsd = ethInDollar ? Number(ethInDollar) * Number(coll) : 0.0;
          const debtUsd = lusdUSD ? Number(lusdUSD) * Number(debt) : 0.0;
          const _findCollRatio = await findCollRatio(ele.owner, price);
          return {
            col1: <AddressWrapperComponent owner={ele.owner} />,
            col2: (
              <div>
                <span>
                  {formatNumbersWithComma(coll, 3)} {envAllDescriptionDetails.VAULT_TOKEN_TEXT}
                </span>{" "}
                <br /> <span> {`($${formatNumbersWithComma(collUsd, 2)})`} </span>
              </div>
            ),
            col3: (
              <div>
                <span>
                  {formatNumbersWithComma(debt, 3)} {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
                </span>{" "}
                <br /> <span> {`($${formatNumbersWithComma(debtUsd, 2)})`}</span>
              </div>
            ),
            col4: <div style={{ color: "#12f79c" }}>{_findCollRatio}%</div>,
            col5: (
              <>
                {_isLiquidateOne ? (
                  <Button
                    btnType={"borderedfilledButton"}
                    customHeight="30px"
                    customWidth="90px"
                    fSize="13px"
                    fSizeMobile="12px"
                    isDisabled={disable(rec, _findCollRatio) || Number(troveCount) === 1 || !_isLiquidateOne}
                    onClick={() => liquidate(ele.owner)}
                  >
                    Liquidate
                  </Button>
                ) : (
                  <Tooltip left={"-18em"} customWidth="15em" data-tooltip={"Nothing to liquidate"}>
                    <Button
                      btnType={"borderedfilledButton"}
                      customHeight="30px"
                      customWidth="90px"
                      fSize="13px"
                      fSizeMobile="12px"
                      isDisabled={disable(rec, _findCollRatio) || Number(troveCount) === 1 || !_isLiquidateOne}
                      onClick={() => liquidate(ele.owner)}
                    >
                      Liquidate
                    </Button>
                  </Tooltip>
                )}
              </>
            ),
          };
        }),
      );
      const _trimmedData: any = data.slice(0, dataLen);
      setTrimmedData(_trimmedData);
      setAllData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("fetch", err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setTimeout(() => {
      setIsUpdating(false);
    }, 1000);
  }, [navigate]);

  useEffect(() => {
    const connected = sessionStorage.getItem("walletConnected");
    if (connected && account) {
      dispatch(setIsHideModule(true));
      createData();
      checkForLiquidateAll();
    } else if (!connected) {
      navigate(rootPath);
    }
    return () => {
      dispatch(setIsHideModule(false));
    };
  }, [account, currentPage, cannotLiquidateAll]);

  const columns = [
    {
      Header: () => <ColumnData paddingLeft={"55px"}>Address</ColumnData>,
      accessor: "col1", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Collateral</ColumnData>,
      accessor: "col2",
    },
    {
      Header: () => <ColumnData>Debt</ColumnData>,
      accessor: "col3", // accessor is the "key" in the data
    },
    {
      Header: () => <ColumnData>Collateral Ratio</ColumnData>,
      accessor: "col4", // accessor is the "key" in the data
    },
    {
      Header: "",
      accessor: "col5", // accessor is the "key" in the data
    },
  ];

  if (isUpdating || isLoading) {
    return <Loader />;
  }
  console.log({ cannotLiquidateAll });

  return (
    <>
      {!active && !account ? (
        navigate("/")
      ) : (
        <Container>
          <TextContainer>
            <Heading3 fWeight="600">Risky Vaults</Heading3>
            {!cannotLiquidateAll ? (
              <Button
                btnType={"borderedfilledButton"}
                customHeight="50px"
                customWidth="200px"
                fSize="20px"
                isDisabled={cannotLiquidateAll || Number(troveCount) === 1}
                onClick={() => liquidateAll()}
              >
                LIQUIDATE ALL
              </Button>
            ) : (
              <Tooltip left={"-18em"} customWidth="15em" data-tooltip={"Nothing to liquidate"}>
                {" "}
                <Button
                  btnType={"borderedfilledButton"}
                  customHeight="50px"
                  customWidth="200px"
                  fSize="20px"
                  isDisabled={cannotLiquidateAll || Number(troveCount) === 1}
                  onClick={() => liquidateAll()}
                >
                  LIQUIDATE ALL
                </Button>
              </Tooltip>
            )}
          </TextContainer>
          <CustomTable>
            <Table data={trimmedData} columns={columns} />
          </CustomTable>
          <Pagination totalCount={troveCount} setCurrentPage={setCurrentPage} currentPage={currentPage} itemsPerPage={8} />
        </Container>
      )}
    </>
  );
};

const AddressWrapperComponent = (props: any) => {
  const [copyFlag, setCopyFlag] = useState<boolean>(false);
  const { owner } = props;
  const handleCopyText = (text: any) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <AddressWrapper>
      <div>{formatAddress(owner)}</div>
      <SvgIcon
        src={copyFlag ? copyIconActive : copyIcon}
        alt="copyIcon"
        onClick={() => {
          handleCopyText(owner);
          setCopyFlag(true);
          setTimeout(() => {
            setCopyFlag(false);
          }, 500);
        }}
      />
    </AddressWrapper>
  );
};
