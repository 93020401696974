import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const PoolContainer = styled.div`
  display: flex;
  gap: 1.5em;
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
  }

  @media (max-width: ${screenSizes.XS}px) {
    max-width: 350px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 310px;
  }

  animation: 3s forwards, fade-in 700ms forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SuccessMsgBox = styled.div`
  background: rgba(18, 247, 156, 0.2);
  border-radius: 4px;
  padding: 4px;
  color: #12f79c;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  width: fit-content;
`;
export const ErrorMsgBox = styled.div`
  background: rgba(254, 100, 111, 0.2);
  border-radius: 4px;
  padding: 4px;
  color: #fe646f;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  width: fit-content;
`;

interface IProps {
  isShow: boolean;
}

export const JsonBox = styled.div<IProps>`
  display: ${props => (props.isShow ? "block" : "none")};

  ul > li {
    margin-bottom: 6px;
  }
`;
export const ShowBox = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: ${(props: any) => props.theme.primaryButton};
`;

export const ErrorList = styled.li`
span{
  max-width: 250px;
}
`