import { Error, ErrorContainer } from "./style";
import { NotifyImage } from 'shared/styled'
import infoIcon from 'assets/icons/info-icon.svg'

interface Iprops {
  message: string;
  type: string;
}

export const Notification = (props: Iprops) => {
  const { type, message } = props;
  
  return (
    <ErrorContainer type={type}>
      <NotifyImage src={infoIcon}></NotifyImage>
      <Error>
        {message}
      </Error>
    </ErrorContainer>
  );
};

