import { LQTY_TOKEN_INSTANCE, STAKING_POOL_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { formatEther } from "ethers/lib/utils";
import { tokenToUSD } from "shared/helpers/utils";
import { SET_STAKE_ACTIVE_TAB, SET_STAKE_VALUES } from "./constant";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

interface IProps {
  type: string;
  payload: any;
}
export const setStakingValues = (data: any): IProps => {
  return {
    type: SET_STAKE_VALUES,
    payload: data,
  };
};

export const setActiveStakingTab = (data: any): IProps => {
  return {
    type: SET_STAKE_ACTIVE_TAB,
    payload: data,
  };
};

export const stakeInitialValues: any = (userContractAddress: any, ethToUSD: any, account: any) => async (dispatch: any, getState: any) => {
  try {
    const [usrLQTYInDeposits, ttlLQTYInDeposits, ethGain_, lusdGain, lqtyBalance] = await Promise.all([
      STAKING_POOL_INSTANCE?.stakes(userContractAddress),
      Number(envChainId) === ChainId?.eth ? STAKING_POOL_INSTANCE?.totalLQTYStaked() : STAKING_POOL_INSTANCE?.totalLOANStaked(),
      USER_INSTANCE?.getPendingETHGain(),
      USER_INSTANCE?.getPendingLUSDGain(),
      LQTY_TOKEN_INSTANCE?.balanceOf(account),
    ]);
    const convertLQTY = formatEther(lqtyBalance);

    const userStakes = usrLQTYInDeposits.mul(100);
    const poolShareVal = Number(formatEther(userStakes)) / Number(formatEther(ttlLQTYInDeposits));

    const issueGain = Number(formatEther(lusdGain)).toFixed(6);
    const poolShare_ = poolShareVal.toFixed(6);
    const depositLQTY = Number(formatEther(usrLQTYInDeposits)).toFixed(4);
    const ethGainVal = Number(formatEther(ethGain_)).toFixed(6);

    const convertUSD = await ethToUSD();
    const { lusdUSD, lqtyUSD } = await tokenToUSD();
    const lqtyInDollar = lqtyUSD ? Number(convertLQTY) * Number(lqtyUSD) : 0.0;
    const Convert_IssuanceGain = lusdUSD ? Number(lusdUSD) * Number(issueGain) : 0.0;
    const Convert_ETHGain = convertUSD ? Number(convertUSD) * Number(ethGainVal) : 0.0;
    const stakedLQTYInDollar = lqtyUSD ? Number(lqtyUSD) * Number(depositLQTY) : 0.0;

    dispatch(
      setStakingValues({
        stakedLQTYInDollar,
        liquidationEthGainInDollar: Convert_ETHGain,
        lusdGainInDollar: Convert_IssuanceGain,
        lusdGain: issueGain,
        stakedLQTY: Number(depositLQTY),
        liquidationEthGain: ethGainVal,
        poolShare: poolShare_,
        lqtyInWalletDisplay: convertLQTY,
        lqtyInDollar,
        TotalStake: Number(depositLQTY),
      }),
    );
  } catch (e: any) {
    console.error(e);
  }
};
