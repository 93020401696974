import axios from "axios";
import { FACTORY_INSTANCE, LUSD_TOKEN_INSTANCE, TROVE_MANAGER_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { BigNumber, ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { SET_BOTH_VALUES, SET_ETH_VALUES, SET_LUSD_VALUES, TX_HASH } from "./constant";
import { setRefetchBalance } from "./notificataion";
import { envAllDescriptionDetails } from "shared/helpers/utils";

export enum Modes {
  OPEN,
  ADJUST,
}
const maxFee = parseUnits("0.05", "ether").toString();

export const setAdjust: any = (flag: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_BOTH_VALUES,
    payload: {
      isAdjusting: flag,
    },
  });
};

export const handleCancel: any = () => (dispatch: any) => {
  dispatch({
    type: SET_BOTH_VALUES,
    payload: {
      err: "",
    },
  });
  setAdjust(false);
  window.location.pathname = "/";
};

export const clearInputs: any = () => (dispatch: any) => {
  dispatch({
    type: SET_ETH_VALUES,
    payload: {
      priceEthDisplay: "0",
      rangeValue: 0,
    },
  });
  dispatch({
    type: SET_LUSD_VALUES,
    payload: {
      priceUSDLDisplay: "0",
      sliderValue: 0,
    },
  });
  dispatch({
    type: SET_BOTH_VALUES,
    payload: {
      err: "",
    },
  });
};

export const initialValues: any = (library: any, account: any, ethToUSD: any) => async (dispatch: any) => {
  try {
    console.log("in initialValues");
    let tempTotalDebt,
      tempDebt,
      tempDebtDisplay,
      tempColl,
      tempCollDisplay,
      tempPriceEth,
      tempViewCollateralRatio,
      tempMode,
      tempPriceUSDL,
      tempNormalLPrice,
      tempIsAdjustSliderShown,
      tempRecoveryLPrice;
    const [ethInWallet, lusdInWallet, temp_isOpened, temp_liqudation_Reserve, ethInDollar] = await Promise.all([
      library?.getBalance(account),
      LUSD_TOKEN_INSTANCE?.balanceOf(account),
      USER_INSTANCE.getEntireDebtAndColl(),
      FACTORY_INSTANCE.getLiquidationReserve(),
      ethToUSD(),
    ]);
    const convertEthBalance = formatEther(ethInWallet);
    const convertLusdInWallet = Number(formatEther(lusdInWallet)).toFixed(4);
    const convertEthValue = parseUnits(ethInDollar.toString(), "ether").toString();
    const debt = temp_isOpened[0]?.toString();
    const coll = temp_isOpened[1]?.toString();
    const Coll = Number(formatEther(coll));
    const Debt = Number(formatEther(debt)) - Number(200);
    const netDebt = temp_isOpened[0].sub(temp_liqudation_Reserve);
    const ratio = ((Coll * Number(ethInDollar)) / Number(formatEther(debt))) * 100;
    const NormalLPrice = (110 * Number(formatEther(debt))) / (Coll * 100);
    const RecoveryLPrice = (150 * Number(formatEther(debt))) / (Coll * 100);
    const temp_isMarketRecovery = await TROVE_MANAGER_INSTANCE?.checkRecoveryMode(convertEthValue);
    console.log({ ratio });
    if (debt === "0" && coll === "0") {
      tempMode = Modes.OPEN;
      tempTotalDebt = formatEther(temp_liqudation_Reserve);
      tempPriceUSDL = "0";
      tempDebt = 0;
      tempDebtDisplay = 0;
      tempColl = 0;
      tempCollDisplay = 0;
      tempPriceEth = "0";
      tempViewCollateralRatio = 0;
      tempNormalLPrice = 0;
      tempRecoveryLPrice = 0;
      tempIsAdjustSliderShown = true;
    } else {
      tempMode = Modes.ADJUST;
      tempTotalDebt = formatEther(debt);
      tempPriceUSDL = formatEther(netDebt);
      tempDebt = debt;
      tempDebtDisplay = Debt;
      tempColl = coll;
      tempCollDisplay = Coll;
      tempPriceEth = formatEther(coll);
      tempViewCollateralRatio = ratio.toFixed(2);
      tempNormalLPrice = NormalLPrice;
      tempRecoveryLPrice = RecoveryLPrice;
      tempIsAdjustSliderShown = Number(ratio) <= 110 ? false : true;
    }
    dispatch({
      type: SET_ETH_VALUES,
      payload: {
        balance: convertEthBalance,
        priceEth: tempPriceEth,
        priceEthDisplay: "0",
        coll: tempColl,
        collDisplay: tempCollDisplay,
      },
    });
    dispatch({
      type: SET_LUSD_VALUES,
      payload: {
        lusdInWallet,
        lusdInWalletDisplay: convertLusdInWallet,
        liquidationReserve: temp_liqudation_Reserve && formatEther(temp_liqudation_Reserve),
        totalDebt: tempTotalDebt,
        priceUSDL: tempPriceUSDL,
        priceUSDLDisplay: "0",
        debt: tempDebt,
        debtDisplay: tempDebtDisplay,
        borrowingFee: "0",
        sliderMaxValue: 0,
        isAdjustSliderShown: tempIsAdjustSliderShown,
      },
    });
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        mode: tempMode,
        viewCollateralRatio: tempViewCollateralRatio,
        IcollateralRatio: tempViewCollateralRatio,
        recoveryLPrice: tempRecoveryLPrice,
        normalLPrice: tempNormalLPrice,
        isMarketRecovery: temp_isMarketRecovery,
        isLoading: false,
        err: "",
      },
    });
  } catch (e: any) {
    console.error(e);
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        isLoading: false,
      },
    });
  }
};

export const handleOpenTrove: any = (startSpinner: any, stopSpinner: any, handleError: any, automaticLogOut: any, clearStorage: any) => async (dispatch: any, getState: any) => {
  const { trove, navbar } = getState();
  const { ETH, LUSD, BOTH } = trove;
  const { priceEth } = ETH;
  const { priceUSDL } = LUSD;
  const { IcollateralRatio } = BOTH;
  const { refetchBalance } = navbar;
  try {
    setAdjust(true);
    const _convertPriceUSDL = parseUnits(priceUSDL.toString(), "ether");
    const _convertPriceEth = parseUnits(priceEth.toString(), "ether");
    const randNum = (Math.random() * 100).toFixed(0);
    startSpinner();
    const Hints = await USER_INSTANCE?.getHintAddresses(_convertPriceEth, _convertPriceUSDL, randNum);
    const estimateGas = await USER_INSTANCE.estimateGas.openTrove(maxFee, _convertPriceUSDL.toString(), Hints[0]?.toString(), Hints[1]?.toString(), {
      value: _convertPriceEth.toString(),
    });
    const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
    const res = await USER_INSTANCE.openTrove(maxFee, _convertPriceUSDL.toString(), Hints[0]?.toString(), Hints[1]?.toString(), {
      value: _convertPriceEth.toString(),
      gasLimit: bufferedGas.toFixed(0),
    });
    dispatch({
      type: TX_HASH,
      payload: res.hash,
    });
    await res.wait();
    const _userContractAddress = sessionStorage.getItem("deployed");
    try {
      await axios.patch(`${POST_WALLET_URL}/troveliquidationstatus/${_userContractAddress}`);
    } catch (e: any) {
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
    toast.dismiss();
    dispatch(setRefetchBalance(!refetchBalance));
    if (Number(IcollateralRatio) <= 110) {
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          isAdjustSliderShown: false,
        },
      });
    }
    dispatch(clearInputs());
    dispatch(setAdjust(false));
    stopSpinner();
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        mode: Modes.ADJUST,
      },
    });
  } catch (e: any) {
    console.error(e);
    clearInputs();
    handleError(e);
  }
};

export const handleAdjustTrove: any = (startSpinner: any, stopSpinner: any, handleError: any, currentTab: any, account: any) => async (dispatch: any, getState: any) => {
  const { trove, navbar } = getState();
  const { ETH, LUSD, BOTH } = trove;
  const { priceEth, priceEthDisplay } = ETH;
  const { priceUSDL, priceUSDLDisplay } = LUSD;
  const { IcollateralRatio } = BOTH;
  const { refetchBalance, userContractAddress } = navbar;
  try {
    const randNum = (Math.random() * 100).toFixed(0);
    setAdjust(true);
    const _convertPriceUSDLDisplay = parseUnits(priceUSDLDisplay.toString(), "ether");
    const _convertPriceEthDisplay = parseUnits(priceEthDisplay.toString(), "ether");
    const _convertPriceEth = parseUnits(priceEth.toString(), "ether");
    const _convertPriceUSDL = parseUnits(priceUSDL.toString(), "ether");
    const allowance = await LUSD_TOKEN_INSTANCE.allowance(account, userContractAddress);
    const Hints = await USER_INSTANCE.getHintAddresses(_convertPriceEth, _convertPriceUSDL, randNum);

    const diffColl = currentTab === 2 ? _convertPriceEth : "0";
    const paybale = diffColl === "0" ? _convertPriceEthDisplay : "0";

    startSpinner();
    if (currentTab === 4 && !_convertPriceUSDLDisplay.eq(ethers.constants.Zero) && _convertPriceUSDLDisplay.gt(allowance)) {
      const approve = await LUSD_TOKEN_INSTANCE.approve(userContractAddress, _convertPriceUSDLDisplay);
      dispatch({
        type: TX_HASH,
        payload: approve.hash,
      });
      await approve.wait();
    }
    startSpinner();
    const estimateGas = await USER_INSTANCE.estimateGas.adjustTrove(
      maxFee,
      diffColl.toString(),
      _convertPriceUSDLDisplay.toString(),
      currentTab === 3,
      Hints[0]?.toString(),
      Hints[1]?.toString(),
      { value: paybale.toString() },
    );
    const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
    const res = await USER_INSTANCE.adjustTrove(maxFee, diffColl.toString(), _convertPriceUSDLDisplay.toString(), currentTab === 3, Hints[0]?.toString(), Hints[1]?.toString(), {
      value: paybale.toString(),
      gasLimit: bufferedGas.toFixed(0),
    });
    dispatch({
      type: TX_HASH,
      payload: res.hash,
    });
    await res.wait();
    dispatch(setRefetchBalance(!refetchBalance));
    if (Number(IcollateralRatio) <= 110) {
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          isAdjustSliderShown: false,
        },
      });
    } else {
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          isAdjustSliderShown: true,
        },
      });
    }
    dispatch(clearInputs());
    dispatch(setAdjust(false));
    stopSpinner();
  } catch (e: any) {
    if (e?.reason === "BorrowerOps: An operation that would result in TCR < CCR is not permitted") {
      dispatch({
        type: SET_BOTH_VALUES,
        payload: {
          err: "This operation would result in Total Collateral Ratio < 150% which is not permitted",
        },
      });
      stopSpinner();
    } else {
      clearInputs();
      setAdjust(false);
      handleError(e);
    }
  }
};

export const handleCloseTrove: any = (startSpinner: any, stopSpinner: any, handleError: any, account: any) => async (dispatch: any, getState: any) => {
  const { trove, navbar } = getState();
  const { LUSD, BOTH } = trove;
  const { isMarketRecovery } = BOTH;
  const { liquidationReserve, lusdInWalletDisplay } = LUSD;
  const { refetchBalance, userContractAddress } = navbar;

  try {
    let ErrorText: any;
    setAdjust(true);
    const convertLiquidationReserve = parseUnits(liquidationReserve.toString(), "ether");
    const debt_coll = await USER_INSTANCE?.getEntireDebtAndColl();
    const netDebt = debt_coll[0].sub(convertLiquidationReserve);
    let convertNetDebt = formatEther(netDebt);
    if (isMarketRecovery) {
      ErrorText = "You're not allowed to close your Trove during recovery mode.";
    } else if (Number(convertNetDebt) > Number(lusdInWalletDisplay)) {
      const diffDebt = Number(convertNetDebt) - Number(lusdInWalletDisplay);
      ErrorText = `You need ${Number(diffDebt).toFixed(4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} more to close your Trove`;
    } else {
      ErrorText = "";
    }
    if (ErrorText) {
      dispatch({
        type: SET_BOTH_VALUES,
        payload: {
          err: ErrorText,
        },
      });
      return;
    }
    startSpinner();
    const allowance = await LUSD_TOKEN_INSTANCE?.allowance(account, userContractAddress);
    if (netDebt.gt(allowance)) {
      const approve = await LUSD_TOKEN_INSTANCE.approve(userContractAddress, netDebt);
      dispatch({
        type: TX_HASH,
        payload: approve.hash,
      });
      await approve.wait();
    }
    const estimateGas = await USER_INSTANCE.estimateGas.closeTrove();
    const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
    const res = await USER_INSTANCE.closeTrove({
      gasLimit: bufferedGas.toFixed(0),
    });
    dispatch({
      type: TX_HASH,
      payload: res.hash,
    });
    await res.wait();
    dispatch(setRefetchBalance(!refetchBalance));
    stopSpinner();
    sessionStorage.removeItem("currentAdjustTab");
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        isLoading: false,
        mode: Modes.OPEN,
      },
    });
  } catch (e: any) {
    console.error({ e });
    clearInputs();
    handleError(e);
    setAdjust(false);
  }
};
