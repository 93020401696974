import styled from "styled-components";
import { colors, screenSizes } from "styles/theme";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    max-width: 555px;
    margin-bottom: 80px;
  @media (max-width: ${screenSizes.M}px) {
    max-width: 310px;
  }
  @media (max-width: ${screenSizes.XXS}px) {
    max-width: 280px;
  }
  animation: 2000ms forwards, fade-in 600ms forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`

export const RedemptionBox = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    background: linear-gradient(111.13deg, #363646 -0.06%, #21212F 99.94%);
    padding: 20px;
    max-width: 555px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(18 247 156);

`

export const SpanTags = styled.div<any>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.white};
    margin-top: ${(props) => props.isSpace? "20px" : ""};

    span{
      text-align: right;
    }
`

interface Ioffset {
  offset?: number
}

export const FillerDiv = styled.div<Ioffset>`
 position: absolute;
 background-color: red;
 height: 20px;
 top: 0;
 width: ${(props) => `calc(${props.offset}% + (${-0.3 - Number(props.offset) * 0.1}px))`};
 pointer-events: none;
`
