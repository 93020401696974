import {
  setIsModalOn,
  setModalView,
  setSelectedCompoundStrategy,
  setSelectedCompoundStrategyId,
  setSelectedLoanCompoundingTarget,
  setSelectedLoanCompoundingTargetID,
  setSelectedLoanCompoundingTargetUsingTrove,
  setSelectedSpCompoundingTarget,
  setSelectedSpCompoundingTargetId,
  setSelectedStakingCompoundingTarget,
  setSelectedStakingCompoundingTargetId,
  setTargetCollateralRatio,
  setTriggerCollateralRatio,
  setUpdatableKey,
} from "logic/redux/actions/strategy";
import { useDispatch } from "react-redux";
import { commonContractsDescription, envAllDescriptionDetails, validateNumberInput, validateNumberInputOnChange } from "shared/helpers/utils";

export const useUtilityStrategy = () => {
  const reduxDispatch = useDispatch();

  const handleTargetCollRatioInput = (e: any) => {
    let val = validateNumberInput(e, 0).toString();
    reduxDispatch(setTargetCollateralRatio(val));
  };
  const handleOnBlurTargetCollRatio = (e: any) => {
    let val = validateNumberInputOnChange(e).toString();
    reduxDispatch(setTargetCollateralRatio(val));
  };

  const handleTriggerCollRatioInput = (e: any) => {
    let val = validateNumberInput(e, 0).toString();
    reduxDispatch(setTriggerCollateralRatio(val));
  };

  const handleOnBlurTriggerCollRatio = (e: any) => {
    let val = validateNumberInputOnChange(e).toString();
    reduxDispatch(setTriggerCollateralRatio(val));
  };

  const handleToogleModal = async () => {
    //document.body.style.overflow = "unset";
    reduxDispatch(setIsModalOn(false));
  };

  const handleSetCompoundStrategy = (e: any) => {
    if (e) {
      let { id, value } = e.target;
      reduxDispatch(setSelectedCompoundStrategyId(id));
      if (value !== "Custom") {
        reduxDispatch(setSelectedSpCompoundingTarget(""));
        reduxDispatch(setSelectedStakingCompoundingTarget(""));
        reduxDispatch(setSelectedLoanCompoundingTarget(""));
        reduxDispatch(setSelectedLoanCompoundingTargetID(""));
        reduxDispatch(setSelectedLoanCompoundingTargetUsingTrove(""));
      }
      reduxDispatch(setSelectedCompoundStrategy(value));
    }
    reduxDispatch(setIsModalOn(false));
    reduxDispatch(setModalView(""));
  };
  const handleSetLoanCompoundingTarget = (e: any) => {
    if (e) {
      reduxDispatch(setSelectedLoanCompoundingTargetID(e.target.id));
      sessionStorage.setItem("Top Up Staking Pool",envAllDescriptionDetails.DEFAULT_STAKING_COMPOUNDING_TARGET);
      sessionStorage.setItem("Top Up Stability Pool",envAllDescriptionDetails.DEFAULT_STABILITY_COMPOUNDING_TARGET);
      reduxDispatch(setSelectedSpCompoundingTarget(envAllDescriptionDetails.DEFAULT_STABILITY_COMPOUNDING_TARGET));
      reduxDispatch(setSelectedStakingCompoundingTarget(envAllDescriptionDetails.DEFAULT_STAKING_COMPOUNDING_TARGET));
      reduxDispatch(setSelectedSpCompoundingTargetId("0"));
      reduxDispatch(setSelectedStakingCompoundingTargetId("0"));
      reduxDispatch(setSelectedLoanCompoundingTarget(e.target.value));
      if (e.target.value === "Custom") return;
    }
    reduxDispatch(setIsModalOn(false));
    reduxDispatch(setModalView(""));
  };

  const selectCompoundStrategy = () => {
    reduxDispatch(setIsModalOn(true));
    reduxDispatch(setModalView(commonContractsDescription.COMPOUND_STRATEGY));
  };
  const selectLoanCompoundingTarget = () => {
    reduxDispatch(setIsModalOn(true));
    reduxDispatch(setModalView(commonContractsDescription.LOAN_COMPOUNDING_TARGET));
  };
  const selectSPCompoundingTarget = () => {
    reduxDispatch(setIsModalOn(true));
    reduxDispatch(setModalView(commonContractsDescription.SP_COMPOUNDING_TARGET));
  };
  const selectStakingCompoundingTarget = () => {
    reduxDispatch(setIsModalOn(true));
    reduxDispatch(setModalView(commonContractsDescription.STAKING_COMPOUNDING_TARGET));
  };

  const openTopOffSelector = (view: string, key?: string) => {
    reduxDispatch(setModalView(view));
    if (key) reduxDispatch(setUpdatableKey(key));
    else reduxDispatch(setUpdatableKey(""));
  };
  const removeTopOffSelector = (key?: string) => {
    if (key) reduxDispatch(setUpdatableKey(key));
    else reduxDispatch(setUpdatableKey(""));
  };

  return {
    handleTargetCollRatioInput,
    handleOnBlurTargetCollRatio,
    handleTriggerCollRatioInput,
    handleOnBlurTriggerCollRatio,
    handleToogleModal,
    handleSetCompoundStrategy,
    handleSetLoanCompoundingTarget,
    selectCompoundStrategy,
    selectLoanCompoundingTarget,
    selectSPCompoundingTarget,
    selectStakingCompoundingTarget,
    openTopOffSelector,
    removeTopOffSelector,
  };
};
export interface IPayload {
  compoundStrategies?: any;
  targetCollateralRatio?: any;
  triggerCollateralRatio?: any;
  loanCompoundingTarget?: any;
  stabilityCompounding?: any;
  stakingCompounding?: any;
  loanCompoundingCustom?: any;
  trove?: any;
  slippage?: number;
}
