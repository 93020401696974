/* eslint-disable no-nested-ternary */

import { ethers } from "ethers";
import { commonContractsDescription, deepEqual, DISABLE_REASONS } from "shared/helpers/utils";
import {
  DISABLE,
  DISABLINGREASON,
  DISABLINGSTABILITYREASON,
  DISABLINGSTAKINGREASON,
  ERROR_STRAT,
  ESTIMATE_GAS,
  ISCOMPOUND,
  ISLOADING,
  ISMODALON,
  ISRATIOSHOWN,
  ISSTABILITYCOMPOUND,
  ISSTAKINGCOMPOUND,
  ISVALUESCHANGED,
  IS_LIQUIDATE_TROVE,
  LIQUIDATE_TROVE_DATE,
  MODALVIEW,
  SELECTEDCOMPOUNDSTRATEGY,
  SELECTEDCOMPOUNDSTRATEGYID,
  SELECTEDLOANCOMPOUNDINGTARGET,
  SELECTEDLOANCOMPOUNDINGTARGETID,
  SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE,
  SELECTEDSPCOMPOUNDINGTARGET,
  SELECTEDSPCOMPOUNDINGTARGETID,
  SELECTEDSTAKINGCOMPOUNDINGTARGET,
  SELECTEDSTAKINGCOMPOUNDINGTARGETID,
  SLIPPAGE,
  STRATEGYADDRESS,
  STRATEGYINSTANCE,
  TARGETCOLLATERALRATIO,
  TRIGGERCOLLATERALRATIO,
  TX_HASH,
  UPDATABLEKEY,
} from "./constant";

import {
  CUSTOM_INSTANCE,
  CUSTOM_STATIC_INSTANCE,
  envAllContractsDetails,
  HALF_AND_HALF_INSTANCE,
  HALF_AND_HALF_STATIC_INSTANCE,
  MAILBOXMONEY_INSTANCE,
  PASSIVE_PULSE_INSTANCE,
  PASSIVE_PULSE_STATIC_INSTANCE,
  PULSE_STACKER_INSTANCE,
  PULSE_STACKER_STATIC_INSTANCE,
} from "blockchain/contract/instance";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { IPayload } from "modules/pages/strategy/hooks/useUtilityStrategy";
import { setMaxGap } from "./navbar";
import { POST_WALLET_URL } from "shared/helpers/apis";
import axios from "axios";
import { format } from "date-fns";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

const maxFee = parseUnits("0.05", "ether").toString();

export const setError = (payload: any) => {
  return {
    type: ERROR_STRAT,
    payload: payload,
  };
};

export const setIsLoadingStrat = (payload: any) => {
  return {
    type: ISLOADING,
    payload: payload,
  };
};

export const setIsModalOn = (payload: any) => {
  return {
    type: ISMODALON,
    payload: payload,
  };
};

export const setModalView = (payload: any) => {
  return {
    type: MODALVIEW,
    payload: payload,
  };
};

export const setSelectedCompoundStrategy = (payload: any) => {
  return {
    type: SELECTEDCOMPOUNDSTRATEGY,
    payload: payload,
  };
};

export const setSelectedCompoundStrategyId = (payload: any) => {
  return {
    type: SELECTEDCOMPOUNDSTRATEGYID,
    payload: payload,
  };
};
export const setSelectedLoanCompoundingTarget = (payload: any) => {
  return {
    type: SELECTEDLOANCOMPOUNDINGTARGET,
    payload: payload,
  };
};
export const setSelectedLoanCompoundingTargetID = (payload: any) => {
  return {
    type: SELECTEDLOANCOMPOUNDINGTARGETID,
    payload: payload,
  };
};
export const setSelectedLoanCompoundingTargetUsingTrove = (payload: any) => {
  return {
    type: SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE,
    payload: payload,
  };
};
export const setSelectedSpCompoundingTargetId = (payload: any) => {
  return {
    type: SELECTEDSPCOMPOUNDINGTARGETID,
    payload: payload,
  };
};
export const setSelectedStakingCompoundingTargetId = (payload: any) => {
  return {
    type: SELECTEDSTAKINGCOMPOUNDINGTARGETID,
    payload: payload,
  };
};
export const setSelectedSpCompoundingTarget = (payload: any) => {
  return {
    type: SELECTEDSPCOMPOUNDINGTARGET,
    payload: payload,
  };
};
export const setSelectedStakingCompoundingTarget = (payload: any) => {
  return {
    type: SELECTEDSTAKINGCOMPOUNDINGTARGET,
    payload: payload,
  };
};
export const setUpdatableKey = (payload: any) => {
  return {
    type: UPDATABLEKEY,
    payload: payload,
  };
};
export const setIsRatioShown = (payload: any) => {
  return {
    type: ISRATIOSHOWN,
    payload: payload,
  };
};

export const setIsCompound = (payload: any) => {
  return {
    type: ISCOMPOUND,
    payload: payload,
  };
};
export const setIsStabilityCompound = (payload: any) => {
  return {
    type: ISSTABILITYCOMPOUND,
    payload: payload,
  };
};
export const setIsStakingCompound = (payload: any) => {
  return {
    type: ISSTAKINGCOMPOUND,
    payload: payload,
  };
};
export const setStrategyAddress = (payload: any) => {
  return {
    type: STRATEGYADDRESS,
    payload: payload,
  };
};
export const setTargetCollateralRatio = (payload: any) => {
  return {
    type: TARGETCOLLATERALRATIO,
    payload: payload,
  };
};
export const setTriggerCollateralRatio = (payload: any) => {
  return {
    type: TRIGGERCOLLATERALRATIO,
    payload: payload,
  };
};
export const setDisablingReason = (payload: any) => {
  return {
    type: DISABLINGREASON,
    payload: payload,
  };
};
export const setDisablingStabilityReason = (payload: any) => {
  return {
    type: DISABLINGSTABILITYREASON,
    payload: payload,
  };
};
export const setDisablingStakingReason = (payload: any) => {
  return {
    type: DISABLINGSTAKINGREASON,
    payload: payload,
  };
};
export const setDisable = (payload: any) => {
  return {
    type: DISABLE,
    payload: payload,
  };
};
export const setIsValuesChanged = (payload: boolean) => {
  return {
    type: ISVALUESCHANGED,
    payload: payload,
  };
};

export const setEstimateGas = (payload: number) => {
  return {
    type: ESTIMATE_GAS,
    payload: payload,
  };
};

export const setIsLiquidateTrove = (payload: boolean) => {
  return {
    type: IS_LIQUIDATE_TROVE,
    payload: payload,
  };
};

export const setLiquidatedTrovedate = (payload: any) => {
  return {
    type: LIQUIDATE_TROVE_DATE,
    payload: payload,
  };
};
export const setSlippage = (payload: any) => {
  return {
    type: SLIPPAGE,
    payload: payload,
  };
};

export const stratergyEnabled: any = (props: any) => async (dispatch: any, getState: any) => {
  const { strategyRedu } = getState();
  const { account } = props;
  const { selectedLoanCompoundingTargetUsingTrove, selectedCompoundStrategyID } = strategyRedu;

  let tempInstance: any, tempAddress: any;

  try {
    if (selectedCompoundStrategyID) {
      if (selectedCompoundStrategyID === "1") {
        tempAddress = envAllContractsDetails.PulseStaker[0];
        tempInstance = PULSE_STACKER_INSTANCE;

        dispatch(setIsRatioShown(true));
      }
      if (selectedCompoundStrategyID === "2") {
        tempAddress = envAllContractsDetails.MailBoxMoney[0];
        tempInstance = MAILBOXMONEY_INSTANCE;

        dispatch(setIsRatioShown(false));
      }
      if (selectedCompoundStrategyID === "3") {
        tempAddress = envAllContractsDetails.HalfandHalf[0];
        tempInstance = HALF_AND_HALF_INSTANCE;
        dispatch(setIsRatioShown(true));
      }

      if (selectedCompoundStrategyID === "4") {
        tempAddress = envAllContractsDetails.PassivePulse[0];
        tempInstance = PASSIVE_PULSE_INSTANCE;

        dispatch(setIsRatioShown(false));
      }
      if (selectedCompoundStrategyID === "5") {
        tempAddress = envAllContractsDetails.CustomStragey[0];
        tempInstance = CUSTOM_INSTANCE;
        if (selectedLoanCompoundingTargetUsingTrove) {
          dispatch(setIsRatioShown(true));
        } else {
          dispatch(setIsRatioShown(false));
        }
      }
      if (selectedCompoundStrategyID === "6") {
        tempAddress = ethers.constants.AddressZero;

        dispatch(setIsRatioShown(false));
      }
      dispatch({ type: STRATEGYADDRESS, payload: tempAddress });
      dispatch({ type: STRATEGYINSTANCE, payload: tempInstance });
      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "2") {
        let hintAddresses;

        switch (selectedCompoundStrategyID) {
          case "1":
            hintAddresses = await PULSE_STACKER_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
            await tempInstance?.callStatic.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 5));
            break;

          case "3":
            hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
            await tempInstance?.callStatic.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 4));
            break;

          case "4":
            hintAddresses = await PASSIVE_PULSE_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, randNum);
            await tempInstance?.callStatic.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3));
            break;

          case "5":
            hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
            await tempInstance?.callStatic.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 17));
            break;

          default:
            break;
        }
      } else {
        await tempInstance?.callStatic.executeStrategy(account);
      }

      dispatch({ type: DISABLINGREASON, payload: "" });
      dispatch({ type: ISCOMPOUND, payload: true });
    }
  } catch (e: any) {
    dispatch({ type: DISABLINGREASON, payload: DISABLE_REASONS[e.reason] });
    dispatch({ type: ISCOMPOUND, payload: false });
  }
};

export const findEstimateGas: any = (props: any) => async (dispatch: any, getState: any) => {
  const { strategyRedu } = getState();
  const { account } = props;
  const { selectedCompoundStrategyID, strategyInstance } = strategyRedu;
  try {
    let tempEstimateGas: any;
    const randNum = (Math.random() * 100).toFixed(0);
    if (selectedCompoundStrategyID !== "2") {
      let hintAddresses;

      switch (selectedCompoundStrategyID) {
        case "1":
          hintAddresses = await PULSE_STACKER_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
          tempEstimateGas = await strategyInstance?.estimateGas?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 5));
          break;

        case "3":
          hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
          tempEstimateGas = await strategyInstance?.estimateGas?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 4));
          break;

        case "4":
          hintAddresses = await PASSIVE_PULSE_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, randNum);
          tempEstimateGas = await strategyInstance?.estimateGas?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3));
          break;

        case "5":
          hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);
          tempEstimateGas = await strategyInstance?.estimateGas?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 17));
          break;

        default:
          break;
      }
    } else {
      tempEstimateGas = await strategyInstance?.estimateGas.executeStrategy(account);
    }
    const bufferedGas = Number(tempEstimateGas?.toString()) + Number(tempEstimateGas?.toString()) * 0.5;
    dispatch(setEstimateGas(bufferedGas));
  } catch (err: any) {
    console.log(err);
    dispatch(setEstimateGas(0));
  }
};

export const stabilityNotExecuteEnabled: any = (props: any) => async (dispatch: any, getState: any) => {
  const { strategyRedu } = getState();
  const { account, userContractAddress } = props;

  const { selectedCompoundStrategyID, strategyInstance } = strategyRedu;
  try {
    if (selectedCompoundStrategyID) {
      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "5") {
        if (selectedCompoundStrategyID === "3") {
          const hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.compoundStabilityStaticCall(account, randNum);
          await strategyInstance.estimateGas.compoundStability(maxFee, ...hintAddresses.slice(0, 2));
        }
      } else {
        const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.compoundStabilityStaticCall(userContractAddress, account, maxFee, randNum);
        await strategyInstance?.callStatic.compoundStability(userContractAddress, account, maxFee, ...hintAddresses.slice(0, 7));
      }
      dispatch({ type: DISABLINGSTABILITYREASON, payload: "" });
      dispatch({ type: ISSTABILITYCOMPOUND, payload: true });
    }
  } catch (e: any) {
    dispatch({ type: DISABLINGSTABILITYREASON, payload: DISABLE_REASONS[e.reason] });
    dispatch({ type: ISSTABILITYCOMPOUND, payload: false });
  }
};

export const stakingNotExecuteEnabled: any = (props: any) => async (dispatch: any, getState: any) => {
  const { strategyRedu } = getState();
  const { account, userContractAddress } = props;
  const { selectedCompoundStrategyID, strategyInstance } = strategyRedu;
  try {
    if (selectedCompoundStrategyID) {
      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "5") {
        await strategyInstance?.callStatic.compoundStaking();
      } else {
        const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.compoundStakingStaticCall(userContractAddress, account, maxFee, randNum, false);
        await strategyInstance?.callStatic.compoundStaking(userContractAddress, account, maxFee, false, ...hintAddresses.slice(0, 4));
      }
      dispatch({ type: DISABLINGSTAKINGREASON, payload: "" });
      dispatch({ type: ISSTAKINGCOMPOUND, payload: true });
    }
  } catch (e: any) {
    dispatch({ type: DISABLINGSTAKINGREASON, payload: DISABLE_REASONS[e.reason] });
    dispatch({ type: ISSTAKINGCOMPOUND, payload: false });
  }
};

export const isValueChangedChecking: any = (props: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const { customStrategies } = props;
  const {
    selectedCompoundStrategyID,
    selectedLoanCompoundingTargetID,
    selectedLoanCompoundingTargetUsingTrove,
    selectedSPCompoundingTargetID,
    selectedStakingCompoundingTargetID,
    isRatioShown,
    targetCollateralRatio,
    triggerCollateralRatio,
    slippage,
  } = state.strategyRedu;
  let vaultShare: any = "0",
    stabilityShare: any = "0",
    stakingShare: any = "0";
  try {
    const initialLoad: any = sessionStorage.getItem("FETCHED_USER_DATA");
    let payload: IPayload = {};

    if (selectedCompoundStrategyID === "5") {
      if (selectedLoanCompoundingTargetUsingTrove) {
        if (selectedLoanCompoundingTargetID === "4") {
          const customStrat: any = [];
          Object.keys(customStrategies)?.forEach((key: any) => {
            if (key === "Top Up Vault" || key === "Top Up Trove") {
              vaultShare = customStrategies?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(vaultShare) || 0,
                customStability: null,
                customStaking: null,
              };
              customStrat.push(p);
            }
            if (key === "Top Up Stability Pool") {
              stabilityShare = customStrategies?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(stabilityShare) || null,
                customStability: customStrategies?.[key]?.[2] || 0,
                customStaking: null,
              };
              customStrat.push(p);
            }
            if (key === "Top Up Staking Pool") {
              stakingShare = customStrategies?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(stakingShare) || null,
                customStability: null,
                customStaking: customStrategies?.[key]?.[2] || 0,
              };
              customStrat.push(p);
            }
          });
          payload = {
            compoundStrategies: selectedCompoundStrategyID,
            targetCollateralRatio: Number(targetCollateralRatio),
            triggerCollateralRatio: Number(triggerCollateralRatio),
            loanCompoundingTarget: selectedLoanCompoundingTargetID,
            stabilityCompounding: null,
            stakingCompounding: null,
            loanCompoundingCustom: customStrat,
            trove: true,
            slippage: slippage,
          };
        } else {
          payload = {
            compoundStrategies: selectedCompoundStrategyID,
            targetCollateralRatio: Number(targetCollateralRatio),
            triggerCollateralRatio: Number(triggerCollateralRatio),
            loanCompoundingTarget: null,
            stabilityCompounding: null,
            stakingCompounding: null,
            loanCompoundingCustom: [],
            trove: true,
            slippage: slippage,
          };
          if (selectedLoanCompoundingTargetID === "1") {
            vaultShare = "100";
            payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
          }
          if (selectedLoanCompoundingTargetID === "2") {
            stabilityShare = "100";
            payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
            payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
            payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
          }
          if (selectedLoanCompoundingTargetID === "3") {
            stakingShare = "100";
            payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
            payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
            payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
          }
        }
      } else {
        payload = {
          compoundStrategies: selectedCompoundStrategyID,
          targetCollateralRatio: Number(targetCollateralRatio),
          triggerCollateralRatio: Number(triggerCollateralRatio),
          loanCompoundingTarget: null,
          stabilityCompounding: null,
          stakingCompounding: null,
          loanCompoundingCustom: [],
          trove: false,
          slippage: slippage,
        };
        if (selectedLoanCompoundingTargetID === "5") {
          payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
          payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
        }
        if (selectedLoanCompoundingTargetID === "6") {
          payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
          payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
        }
        if (selectedLoanCompoundingTargetID === "7") {
          payload.loanCompoundingTarget = selectedLoanCompoundingTargetID;
          payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
          payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
        }
      }
      if (deepEqual(payload, JSON.parse(initialLoad))) {
        dispatch({ type: ISVALUESCHANGED, payload: false });
        return {};
      }
    }
    if (isRatioShown && selectedCompoundStrategyID !== "5") {
      payload = {
        compoundStrategies: selectedCompoundStrategyID,
        targetCollateralRatio: Number(targetCollateralRatio),
        triggerCollateralRatio: Number(triggerCollateralRatio),
        loanCompoundingTarget: null,
        stabilityCompounding: null,
        stakingCompounding: null,
        loanCompoundingCustom: [],
        slippage: slippage,
      };

      if (deepEqual(payload, JSON.parse(initialLoad))) {
        dispatch({ type: ISVALUESCHANGED, payload: false });
        return {};
      }
    }

    if (!isRatioShown && selectedCompoundStrategyID !== "5") {
      payload = {
        compoundStrategies: selectedCompoundStrategyID,
        targetCollateralRatio: null,
        triggerCollateralRatio: null,
        loanCompoundingTarget: null,
        stabilityCompounding: null,
        stakingCompounding: null,
        loanCompoundingCustom: [],
        slippage: slippage,
      };

      if (deepEqual(payload, JSON.parse(initialLoad))) {
        dispatch({ type: ISVALUESCHANGED, payload: false });
        return {};
      }
    }
    console.log({ payload, 1: initialLoad }, "**");
    return payload;
  } catch (e: any) {
    console.error("isValueChecked", e);
  }
};

export const registerStrategy: any = (props: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const {
    payload,
    account,
    authFailed,
    USER_INSTANCE,
    fetchWalletDetails,
    strategyEnabledViaAction,
    stabilityEnabledViaAction,
    stakingEnabledViaAction,
    handleError,
    startSpinner,
    stopSpinner,
    automaticLogOut,
    clearStorage,
  } = props;
  const {
    selectedCompoundStrategyID,
    selectedLoanCompoundingTargetID,
    selectedLoanCompoundingTargetUsingTrove,
    selectedSPCompoundingTargetID,
    selectedStakingCompoundingTargetID,
    isRatioShown,
    targetCollateralRatio,
    triggerCollateralRatio,
    slippage,
  } = state.strategyRedu;
  const { authStatus } = state.navbar;

  if (authStatus) {
    authFailed();
    return;
  }
  dispatch({ type: DISABLE, payload: true });
  let register,
    vaultShare: any = "0",
    stabilityShare: any = "0";
  try {
    const getCRs = await USER_INSTANCE?.getCRs();
    const getSlippage = await USER_INSTANCE?.getSlippageFactor();
    const _updateSlippage = Number(getSlippage) / 10 !== slippage ? true : false;
    const isVaryThanBefore = Number(getCRs[0]) !== Number(targetCollateralRatio) || Number(getCRs[2]) !== Number(triggerCollateralRatio) ? true : false;
    if (selectedCompoundStrategyID === "5") {
      dispatch({ type: ERROR_STRAT, payload: "" });
      dispatch({ type: DISABLE, payload: true });
      startSpinner();
      let _loanCompoundingTarget;
      if (!selectedLoanCompoundingTargetUsingTrove) {
        if (selectedLoanCompoundingTargetID === "5") {
          _loanCompoundingTarget = "1";
        } else if (selectedLoanCompoundingTargetID === "6") {
          _loanCompoundingTarget = "2";
        } else {
          _loanCompoundingTarget = "0";
        }
      } else {
        _loanCompoundingTarget = "0";
      }

      register = await USER_INSTANCE?.setCustomParams(
        isVaryThanBefore,
        _updateSlippage,
        selectedLoanCompoundingTargetUsingTrove ? 1 : 0,
        _loanCompoundingTarget,
        _updateSlippage ? slippage * 10 : 0,
        targetCollateralRatio,
        triggerCollateralRatio,
        vaultShare,
        stabilityShare,
        selectedSPCompoundingTargetID ? selectedSPCompoundingTargetID : 0,
        selectedStakingCompoundingTargetID ? selectedStakingCompoundingTargetID : 0,
      );
    }
    if (isRatioShown && selectedCompoundStrategyID !== "5") {
      startSpinner();
      register = await USER_INSTANCE?.updateStrategy(
        isVaryThanBefore,
        _updateSlippage,
        _updateSlippage ? slippage * 10 : 0,
        selectedCompoundStrategyID,
        targetCollateralRatio,
        triggerCollateralRatio,
      );
    }

    if (!isRatioShown && selectedCompoundStrategyID !== "5") {
      startSpinner();
      register = await USER_INSTANCE?.updateStrategy(false, _updateSlippage, _updateSlippage ? slippage * 10 : 0, selectedCompoundStrategyID, 0, 0);
    }
    dispatch({ type: ISVALUESCHANGED, payload: true });
    dispatch({ type: TX_HASH, payload: register.hash });
    await register.wait();
    await postStrategies(payload, account, automaticLogOut, clearStorage);
    await fetchWalletDetails();
    strategyEnabledViaAction();
    stabilityEnabledViaAction();
    stakingEnabledViaAction();
    stopSpinner();
    dispatch(setDisable(false));
    return false;
  } catch (e: any) {
    console.error("register", e);
    handleError(e);
    await fetchWalletDetails();
    strategyEnabledViaAction();
    stabilityEnabledViaAction();
    stakingEnabledViaAction();
    dispatch(setDisable(false));
  }
};

const postStrategies = async (payload: IPayload, account: any, automaticLogOut: any, clearStorage: any) => {
  if (!Object.keys(payload).length) return;
  try {
    sessionStorage.setItem("FETCHED_USER_DATA", JSON.stringify(payload));
    await axios.patch(POST_WALLET_URL, payload);
  } catch (error: any) {
    if (error?.response?.status === 401) {
      automaticLogOut();
    } else {
      clearStorage();
    }
    throw new Error();
  }
};

export const setup =
  (props: any): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const { walletInfo, isAutomated, dispatchCustomStrategies } = props;
    const { selectedSPCompoundingTargetID, selectedStakingCompoundingTargetID, selectedLoanCompoundingTargetUsingTrove } = state.strategyRedu;

    if (walletInfo) {
      var {
        targetCollateralRatio: targetCR,
        triggerCollateralRatio: triggerCR,
        compoundStrategies,
        stakingCompounding,
        loanCompoundingTarget,
        stabilityCompounding,
        loanCompoundingCustom,
        trove,
        gasFee,
        isTroveLiquidated,
        troveLiquidationDate,
        slippage,
      } = walletInfo;
    }

    let isRatioToBeShown;
    if ((compoundStrategies?.strategyType === "common" && trove) || compoundStrategies?.strategyType === "trove") {
      isRatioToBeShown = true;
    } else {
      isRatioToBeShown = false;
    }

    dispatch(setIsRatioShown(isRatioToBeShown));
    const stabilityName =
      Number(envChainId) === ChainId?.eth
        ? selectedLoanCompoundingTargetUsingTrove
          ? stabilityCompounding?.ethNameWithTrove
          : stabilityCompounding?.ethNameWithOutTrove
        : selectedLoanCompoundingTargetUsingTrove
        ? stabilityCompounding?.pulseNameWithTrove
        : stabilityCompounding?.pulseNameWithOutTrove;

    const stakingName =
      Number(envChainId) === ChainId?.eth
        ? selectedLoanCompoundingTargetUsingTrove
          ? stakingCompounding?.ethNameWithTrove
          : stakingCompounding?.ethNameWithOutTrove
        : selectedLoanCompoundingTargetUsingTrove
        ? stakingCompounding?.pulseNameWithTrove
        : stakingCompounding?.pulseNameWithOutTrove;

    let vaultShare: any = "0",
      stabilityShare: any = "0",
      stakingShare: any = "0";
    let payload: IPayload = {};
    const loanCompoundingTargetName = Number(envChainId) === ChainId?.eth ? loanCompoundingTarget?.ethName : loanCompoundingTarget?.pulseName;

    if (compoundStrategies?.strategyName === "Custom") {
      if (trove) {
        if (loanCompoundingTarget?.id === "4") {
          const customStrat: any = [];
          Object.keys(loanCompoundingCustom)?.forEach((key: any) => {
            if (key === "Top Up Vault" || key === "Top Up Trove") {
              vaultShare = loanCompoundingCustom?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(vaultShare) || 0,
                customStability: null,
                customStaking: null,
              };
              customStrat.push(p);
            }
            if (key === "Top Up Stability Pool") {
              stabilityShare = loanCompoundingCustom?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(stabilityShare) || null,
                customStability: loanCompoundingCustom?.[key]?.[2] || 0,
                customStaking: null,
              };
              customStrat.push(p);
            }
            if (key === "Top Up Staking Pool") {
              stakingShare = loanCompoundingCustom?.[key]?.[0];
              let p = {
                loanCompoundingStrategies: key,
                inputPercentage: Number(stakingShare) || null,
                customStability: null,
                customStaking: loanCompoundingCustom?.[key]?.[2] || 0,
              };
              customStrat.push(p);
            }
          });

          payload = {
            compoundStrategies: compoundStrategies?.id,
            targetCollateralRatio: Number(targetCR),
            triggerCollateralRatio: Number(triggerCR),
            loanCompoundingTarget: "4",
            stabilityCompounding: null,
            stakingCompounding: null,
            loanCompoundingCustom: customStrat,
            trove: true,
            slippage,
          };
        } else {
          payload = {
            compoundStrategies: compoundStrategies?.id,
            targetCollateralRatio: Number(targetCR),
            triggerCollateralRatio: Number(triggerCR),
            loanCompoundingTarget: null,
            stabilityCompounding: null,
            stakingCompounding: null,
            loanCompoundingCustom: [],
            trove: true,
            slippage,
          };
          if (loanCompoundingTarget?.id === "1") {
            payload.loanCompoundingTarget = loanCompoundingTarget?.id;
          }
          if (loanCompoundingTarget?.id === "2") {
            payload.loanCompoundingTarget = loanCompoundingTarget?.id;
            payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
            payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
          }
          if (loanCompoundingTarget?.id === "3") {
            payload.loanCompoundingTarget = loanCompoundingTarget?.id;
            payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
            payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
          }
        }
      } else {
        payload = {
          compoundStrategies: compoundStrategies?.id,
          targetCollateralRatio: Number(targetCR),
          triggerCollateralRatio: Number(triggerCR),
          loanCompoundingTarget: null,
          stabilityCompounding: null,
          stakingCompounding: null,
          loanCompoundingCustom: [],
          trove: false,
          slippage,
        };
        if (loanCompoundingTarget?.id === "5") {
          payload.loanCompoundingTarget = loanCompoundingTarget?.id;
          payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
        }
        if (loanCompoundingTarget?.id === "6") {
          payload.loanCompoundingTarget = loanCompoundingTarget?.id;
          payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
        }
        if (loanCompoundingTarget?.id === "7") {
          payload.loanCompoundingTarget = loanCompoundingTarget?.id;
          payload.stabilityCompounding = selectedSPCompoundingTargetID || null;
          payload.stakingCompounding = selectedStakingCompoundingTargetID || null;
        }
      }
    }
    if (isRatioToBeShown && compoundStrategies?.strategyName !== "Custom") {
      payload = {
        compoundStrategies: compoundStrategies?.id,
        targetCollateralRatio: Number(targetCR),
        triggerCollateralRatio: Number(triggerCR),
        loanCompoundingTarget: null,
        stabilityCompounding: null,
        stakingCompounding: null,
        loanCompoundingCustom: [],
        slippage,
      };
    }
    if (!isRatioToBeShown && compoundStrategies?.strategyName !== "Custom") {
      payload = {
        compoundStrategies: compoundStrategies?.id,
        targetCollateralRatio: null,
        triggerCollateralRatio: null,
        loanCompoundingTarget: null,
        stabilityCompounding: null,
        stakingCompounding: null,
        loanCompoundingCustom: [],
        slippage,
      };
    }
    const loanCompoundingTargetUsingTrove = loanCompoundingTarget !== null ? (loanCompoundingTarget?.trove ? true : false) : true;
    const formatMaxGap = Number(gasFee) ? formatEther(gasFee) : 0.0;
    const liquidatedTroveDate = new Date(troveLiquidationDate).toUTCString();
    dispatch(setSelectedCompoundStrategy(compoundStrategies?.strategyName ? compoundStrategies?.strategyName : ""));
    dispatch(setSelectedCompoundStrategyId(compoundStrategies?.id ? compoundStrategies?.id : ""));
    dispatch(setTargetCollateralRatio(targetCR && !isAutomated ? targetCR : 1000));
    dispatch(setTriggerCollateralRatio(triggerCR ? triggerCR : 10));
    dispatch(setSelectedSpCompoundingTarget(stabilityName ? stabilityName : ""));
    dispatch(setSelectedSpCompoundingTargetId(stabilityCompounding?.id ? stabilityCompounding?.id : "0"));
    dispatch(setSelectedStakingCompoundingTarget(stakingName ? stakingName : ""));
    dispatch(setSelectedStakingCompoundingTargetId(stakingCompounding?.id ? stakingCompounding?.id : "0"));
    dispatch(setSelectedLoanCompoundingTarget(loanCompoundingTarget ? loanCompoundingTargetName : ""));
    dispatch(setSelectedLoanCompoundingTargetID(loanCompoundingTarget ? loanCompoundingTarget?.id : ""));
    dispatch(setSelectedLoanCompoundingTargetUsingTrove(loanCompoundingTargetUsingTrove));
    dispatch(setMaxGap(formatMaxGap));
    dispatch(setLiquidatedTrovedate(liquidatedTroveDate));
    dispatch(setIsLiquidateTrove(isTroveLiquidated));
    if (loanCompoundingCustom?.length) {
      loanCompoundingCustom?.forEach((strategy: any) => {
        if (strategy?.loanCompoundingStrategies === "Top Up Vault" || strategy?.loanCompoundingStrategies === "Top Up Trove") {
          dispatchCustomStrategies({ type: strategy?.loanCompoundingStrategies, value: [strategy?.inputPercentage] });

          payload.loanCompoundingCustom.push({
            loanCompoundingStrategies: strategy?.loanCompoundingStrategies,
            inputPercentage: strategy?.inputPercentage || null,
            customStability: null,
            customStaking: null,
          });
        }
        if (strategy?.loanCompoundingStrategies === "Top Up Stability Pool") {
          const strategyStabilityName =
            Number(envChainId) === ChainId?.eth
              ? loanCompoundingTargetUsingTrove
                ? strategy?.customStability?.ethNameWithTrove
                : strategy?.customStability?.ethNameWithOutTrove
              : loanCompoundingTargetUsingTrove
              ? strategy?.customStability?.pulseNameWithTrove
              : strategy?.customStability?.pulseNameWithOutTrove;
          dispatchCustomStrategies({ type: strategy?.loanCompoundingStrategies, value: [strategy?.inputPercentage, strategyStabilityName, strategy?.customStability?.id] });
          payload.loanCompoundingCustom.push({
            loanCompoundingStrategies: "Top Up Stability Pool",
            inputPercentage: strategy?.inputPercentage || null,
            customStability: strategy?.customStability?.id || null,
            customStaking: null,
          });
          sessionStorage.setItem(strategy?.loanCompoundingStrategies, strategyStabilityName);
          dispatch(setSelectedSpCompoundingTarget(strategyStabilityName || ""));
        }
        if (strategy?.loanCompoundingStrategies === "Top Up Staking Pool") {
          const strategyStakingName =
            Number(envChainId) === ChainId?.eth
              ? loanCompoundingTargetUsingTrove
                ? strategy?.customStaking?.ethNameWithTrove
                : strategy?.customStaking?.ethNameWithOutTrove
              : loanCompoundingTargetUsingTrove
              ? strategy?.customStaking?.pulseNameWithTrove
              : strategy?.customStaking?.pulseNameWithOutTrove;

          dispatchCustomStrategies({ type: strategy?.loanCompoundingStrategies, value: [strategy?.inputPercentage, strategyStakingName, strategy?.customStaking?.id] });
          payload.loanCompoundingCustom.push({
            loanCompoundingStrategies: "Top Up Staking Pool",
            inputPercentage: strategy?.inputPercentage || null,
            customStability: null,
            customStaking: strategy?.customStaking?.id || null,
          });
          sessionStorage.setItem(strategy?.loanCompoundingStrategies, strategyStakingName);
          dispatch(setSelectedStakingCompoundingTarget(strategyStakingName || ""));
        }
      });
    }
    sessionStorage.setItem("FETCHED_USER_DATA", JSON.stringify(payload));
  };

export const handleCancelCS: any = (props: any) => async (dispatch: any, getState: any) => {
  const { cancel, dispatchCustomStrategies } = props;
  const { strategyRedu } = getState();
  const { selectedLoanCompoundingTargetUsingTrove } = strategyRedu;
  if (!cancel) {
    let initialLoad: any = sessionStorage.getItem("FETCHED_USER_DATA") || "{}";
    initialLoad = JSON.parse(initialLoad);

    if (initialLoad?.loanCompoundingCustom?.length) {
      dispatch(setSelectedLoanCompoundingTargetID("4"));
      dispatch(setSelectedLoanCompoundingTarget("Custom"));
      initialLoad.loanCompoundingCustom?.forEach((strategy: any) => {
        if (strategy?.loanCompoundingStrategies === "Top Up Vault" || strategy?.loanCompoundingStrategies === "Top Up Trove") {
          dispatchCustomStrategies({ type: strategy?.loanCompoundingStrategies, value: [strategy?.inputPercentage] });
        }
        if (strategy?.loanCompoundingStrategies === "Top Up Stability Pool") {
          let allSelectors: any = sessionStorage.getItem(commonContractsDescription.SELECTORS);
          allSelectors = JSON.parse(allSelectors);
          let spCompoundingTarget = allSelectors.stability;
          const stabilityTarget = spCompoundingTarget.filter((el: any) => strategy?.customStability === el.id);
          const stabilityTargetName =
            Number(envChainId) === ChainId?.eth
              ? selectedLoanCompoundingTargetUsingTrove
                ? stabilityTarget?.[0]?.ethNameWithTrove
                : stabilityTarget?.[0]?.ethNameWithOutTrove
              : selectedLoanCompoundingTargetUsingTrove
              ? stabilityTarget?.[0]?.pulseNameWithTrove
              : stabilityTarget?.[0]?.pulseNameWithOutTrove;
          dispatchCustomStrategies({
            type: strategy?.loanCompoundingStrategies,
            value: [strategy?.inputPercentage, stabilityTargetName, stabilityTarget?.[0]?.id],
          });
          dispatch(setSelectedSpCompoundingTarget(stabilityTargetName || ""));
        }
        if (strategy?.loanCompoundingStrategies === "Top Up Staking Pool") {
          let allSelectors: any = sessionStorage.getItem(commonContractsDescription.SELECTORS);
          allSelectors = JSON.parse(allSelectors);
          let stakingCpTarget = allSelectors.staking;
          const stakingTarget = stakingCpTarget.filter((el: any) => strategy?.customStaking === el.id);
          const stakingTargetName =
            Number(envChainId) === ChainId?.eth
              ? selectedLoanCompoundingTargetUsingTrove
                ? stakingTarget?.[0]?.ethNameWithTrove
                : stakingTarget?.[0]?.ethNameWithOutTrove
              : selectedLoanCompoundingTargetUsingTrove
              ? stakingTarget?.[0]?.pulseNameWithTrove
              : stakingTarget?.[0]?.pulseNameWithOutTrove;

          dispatchCustomStrategies({
            type: strategy?.loanCompoundingStrategies,
            value: [strategy?.inputPercentage, stakingTargetName, stakingTarget?.[0]?.id],
          });
          dispatch(setSelectedStakingCompoundingTarget(stakingTargetName || ""));
        }
      });
    } else {
      dispatchCustomStrategies({ type: "reset" });
      dispatch(setSelectedLoanCompoundingTarget(""));
      dispatch(setSelectedLoanCompoundingTargetID(""));
      dispatch(setSelectedLoanCompoundingTargetUsingTrove(""));
    }
  }

  dispatch(setIsModalOn(false));
};
