import {
  BtnContainer,
  ContentBody,
  DarkContainer,
  Disclamer,
  HeaderContainer,
  IconTextContainer,
  IconTextWrapper,
  LogoContainer,
  MetamaskIcon,
  NavBalCntr,
  NavContainer,
  Navigations,
  OpenStatsButton,
  RowContainer,
  Sidebar,
  TextContainer,
} from "./style";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components";
import { commonContractsDescription, envAllDescriptionDetails, formatAddress, formatNumbersWithComma, tokenToUSD, URL_TERMS } from "shared/helpers/utils";
import { FlexBox } from "shared/flexBox";
import CustomModal from "shared/customModal";
import metamask from "assets/icons/metamask.svg";
import warning from "assets/icons/warning.svg";
import walletconnectIcon from "assets/icons/walletconnect.svg";
import metamask2 from "assets/icons/metamask2.svg";
import copyIcon from "assets/icons/copy-icon.svg";
import copyIconGreen from "assets/icons/copy-icon-green.svg";
import openInNewIcon from "assets/icons/open-in-new-icon.svg";
import logo from "assets/icons/Amplifier.png";
import { Button } from "shared/button/index";
import { ChainId, WalletTypes } from "blockchain/wallets/helpers/WalletHelper";
import { useDispatch, useSelector } from "react-redux";
import { setConnectWallet, setConnectorID, setIsFetched, setIsLogOff, setIsSyncedWithAPI, setShowStats, setTxHash } from "logic/redux/actions";
import { automationPath, redemptionPath, riskyVaultPath, rootPath, stabilityPoolPath, stakingPath, strategyPath, trovePath } from "logic/paths";
import { BoldPara, CustomText, LightText, SecondaryText } from "shared/Typography";
import { SpinnerImg, SvgIcon, TransactionContent, TransactionHeading, DividerPipe, RowFlex } from "shared/styled";
import { TransactionContext } from "logic/context/context";
import closeIcon from "assets/icons/closeIcon.svg";
import burgerIcon from "assets/icons/burgerIcon.svg";
import infoIcon from "assets/icons/info-icon-white.svg";
import { Stats } from "modules/pages/home/components/right/Stats";
import { useIsMobileScreen } from "shared/hook/useIsMobileScreen";
import { useWallet } from "shared/hook/useWallet";
import { formatEther } from "ethers/lib/utils";
import { LQTY_TOKEN_INSTANCE, LUSD_TOKEN_INSTANCE } from "blockchain/contract/instance";
import SelectStrategyIcon from "../../../assets/icons/SelectStrategy.svg";
import AutomationIcon from "../../../assets/icons/Automation.svg";
import RedemptionGainIcon from "../../../assets/icons/RedemptionGain.svg";
import StabilityPoolIcon from "../../../assets/icons/StabilityPool.svg";
import StakingPoolIcon from "../../../assets/icons/StakingPool.svg";
import TroveIcon from "../../../assets/icons/Trove.svg";
import RiskyVaultIcon from "../../../assets/icons/RiskyVault.svg";
import failedImg from "assets/icons/failed.svg";
import DangerIcon from "assets/icons/Danger.svg";
import axios from "axios";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { setIsLiquidateTrove } from "logic/redux/actions/strategy";
import { selecWallet } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import useCheckNetwork from "blockchain/wallets/hooks/useCheckNetwork";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const Navbar: React.FC = withTheme((props: any) => {
  const { theme } = props;
  const [disconnectWallet, setDisconnectWallet] = useState(false);
  const [isAccountAdrCopied, setIsAccountAdrCopied] = useState(false);
  const [isContractAdrCopied, setIsContractAdrCopied] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const { transactionStatus: transactionModalStatus, dispatchTransactionContextEvents } = useContext(TransactionContext);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const isMobileScreen = useIsMobileScreen();
  const globalSelector = useSelector((state: any) => state);
  const { connectWallet, isShowStats, userContractAddress, connectorID, isLogOff, automationCanRun, isAutomation } = globalSelector.navbar;
  const { isTroveLiquidated, TroveLiquidatedDate } = globalSelector.strategyRedu;
  const { txHash } = globalSelector.notification;
  const { logOut } = useWallet();
  const { account, deactivate, chainId, library }: any = useConnectWallet();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTermsChange = () => {
    setIsTermsChecked((prev: boolean) => !prev);
  };
  const [transactionStatus, setTransactionStatus] = useState({
    modal: false,
    heading: "",
    content: "",
    image: "",
  });
  const { activate } = useConnectWallet()
  console.log(library, chainId)

  useEffect(() => {
    //@ts-ignore
    const walletType = JSON.parse(sessionStorage.getItem("connectorId"));
    //@ts-ignore
    const walletconnect = JSON.parse(sessionStorage.getItem("walletconnect"));
    if (account) {
      sessionStorage.setItem("walletConnected", JSON.stringify(true));
    } else if (walletType === 2 && walletconnect === null) {
      disConnect();
    }
  }, [account, userContractAddress, library]);

  const disConnect = async () => {
    const id: any = JSON.parse(sessionStorage.getItem("connectorId") || "{}");
    const connectorId = selecWallet(id)
    deactivate(connectorId);
    const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
    delete token[account];
    await logOut();
    deactivate(connectorId);
    dispatch(setIsFetched(false));
    sessionStorage.clear();
    // sessionStorage.setItem("Token", JSON.stringify(token));
    setDisconnectWallet(false);
  };

  useEffect(() => {
    if (chainId) {
      sessionStorage.setItem("chain", chainId.toString());
    }
  }, [chainId, library]);

  let data: any = sessionStorage.getItem("walletBalance") || "{}";
  if (data) {
    data = JSON.parse(data);
  }

  const handleConnectwallet = (p: boolean) => {
    dispatch(setConnectWallet(p));
    //document.body.style.overflow = "hidden";
    setIsTermsChecked(false);
  };

  const handleShowStats = (p: boolean) => {
    dispatch(setShowStats(p));
    //document.body.style.overflow = "hidden";
  };

  const handleLogOff = (p: boolean) => {
    dispatch(setIsLogOff(p));
    //document.body.style.overflow = "hidden";
  };

  const handleDisConnectwallet = (p: boolean) => {
    setDisconnectWallet(p);
    //document.body.style.overflow = "hidden";
  };

  const disconnect = async () => {
    const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
    const id: any = JSON.parse(sessionStorage.getItem("connectorId") || "{}");
    const connectorId = selecWallet(id)

    delete token[account];
    await logOut();
    deactivate(connectorId);
    dispatch(setIsFetched(false));
    sessionStorage.clear();
    // sessionStorage.setItem("Token", JSON.stringify(token));
    dispatch(setIsSyncedWithAPI(false));
    setDisconnectWallet(false);
    //document.body.style.overflow = "unset";
  };

  const connect = async (type: any) => {
    try {
      // login(type);
      const connectorId = selecWallet(type)
      dispatch(setConnectorID(type));
      sessionStorage.setItem("connectorId", JSON.stringify(type));
      console.log(connectorId)
      activate(connectorId, 1)
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenOnEtherscan = (adr: string) => {
    const baseURL = Number(envChainId) === ChainId?.eth ? "https://goerli.etherscan.io/address/" : "https://scan.v4.testnet.pulsechain.com/address/";
    window.open(baseURL + adr);
  };

  const handleCopyText = (adr: string) => {
    if (adr === "account") {
      navigator.clipboard.writeText(account);
      setIsAccountAdrCopied(true);
      setTimeout(() => {
        setIsAccountAdrCopied(false);
      }, 500);
    } else if (adr === "userContractAddress") {
      navigator.clipboard.writeText(userContractAddress);
      setIsContractAdrCopied(true);
      setTimeout(() => {
        setIsContractAdrCopied(false);
      }, 500);
    }
  };

  const getCopyIcon = (isCopied: boolean) => {
    if (isCopied) return copyIconGreen;
    return copyIcon;
  };

  const getCopyTextColor = (isCopied: boolean) => {
    if (isCopied) return "#319C2F";
    return "#FFFFFF";
  };

  const getCopyText = (isCopied: boolean) => {
    if (isCopied) return "Copied";
    return "Copy Address";
  };

  const handleHandBurgerAction = () => {
    setIsNavExpanded(prev => !prev);
  };

  const handleOpenStats = () => {
    dispatch(setShowStats(true));
    //document.body.style.overflow = "hidden";
  };

  const handleUpdateLiquidatedStatus = async () => {
    try {
      const _userContractAddress = sessionStorage.getItem("deployed");
      dispatch(setIsLiquidateTrove(false));
      await axios.patch(`${POST_WALLET_URL}/troveliquidationstatus/${_userContractAddress}`);
    } catch (e: any) {
      console.log(e);
    }
  };
  return (
    <>
      <HeaderContainer>
        <FlexBox>
          <LogoContainer isNavExpanded={isNavExpanded} onClick={() => navigate(rootPath)}>
            <img src={logo} alt="logo" />
          </LogoContainer>
          <NavContainer>
            <Navigations>
              <div>
                {!isNavExpanded && (
                  <button className="hamburger" onClick={handleHandBurgerAction}>
                    <SvgIcon src={burgerIcon} />
                  </button>
                )}
                <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                  <div className="top-nav-btn">
                    <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={rootPath}>
                      <span>Dashboard</span>
                    </NavLink>
                  </div>
                </div>
              </div>
              {account && (
                <OpenStatsButton>
                  <button className="hamburger" onClick={handleOpenStats}>
                    <SvgIcon src={infoIcon} />
                  </button>
                </OpenStatsButton>
              )}
              <OutsideAlerter setIsNavExpanded={setIsNavExpanded} isNavExpanded={isNavExpanded} isMobileScreen={isMobileScreen}>
                <Sidebar customPadding={isNavExpanded && isMobileScreen ? "5em 0 0 0" : ""}>
                  {isNavExpanded && isMobileScreen && (
                    <>
                      <button className="closeMenu" onClick={handleHandBurgerAction}>
                        <SvgIcon src={closeIcon} />
                      </button>
                      <div className="wallet-info">
                        <PurseInfo localData={data} />
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={strategyPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={SelectStrategyIcon} />
                            <span>Strategy</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={trovePath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={TroveIcon} />
                            <span>Trove/Vault</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={stabilityPoolPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={StabilityPoolIcon} />
                            <span>Stability Pool</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={stakingPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={StakingPoolIcon} />
                            <span>Staking</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={automationPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={AutomationIcon} />
                            <span>Automation {isAutomation && automationCanRun <= 3 && <SvgIcon style={{ marginRight: "5px", marginLeft: "2px" }} src={DangerIcon} />}</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={riskyVaultPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={RiskyVaultIcon} />
                            <span>Risky Vaults</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                      <div className="nav-btn">
                        <NavLink
                          className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
                          to={redemptionPath}
                          onClick={e => !userContractAddress && e.preventDefault()}
                        >
                          <IconTextWrapper>
                            <SvgIcon height="20px" src={RedemptionGainIcon} />
                            <span>Redemption</span>
                          </IconTextWrapper>
                        </NavLink>
                      </div>
                    </>
                  )}
                  {account ? (
                    <>
                      <Button btnType={"walletButton"} className={"walletNavButton"} onClick={() => handleDisConnectwallet(true)}>
                        <BtnContainer>
                          <PurseInfo localData={data} />
                          <DividerPipe />
                          <span>{formatAddress(account)}</span>
                          <SvgIcon height="24px" src={connectorID === 1 ? metamask2 : walletconnectIcon} alt="metamask-icon" />
                        </BtnContainer>
                      </Button>
                    </>
                  ) : (
                    <Button btnType={"walletButton"} className={"walletNavButton"} onClick={() => handleConnectwallet(true)}>
                      {"Connect Wallet"}
                    </Button>
                  )}
                </Sidebar>
              </OutsideAlerter>
            </Navigations>
          </NavContainer>
        </FlexBox>
      </HeaderContainer>

      {/* Connect Modal */}
      <CustomModal
        show={connectWallet}
        toggleModal={(p: boolean) => {
          handleConnectwallet(p);
          //document.body.style.overflow = "unset";
        }}
        heading={"Connect a Wallet"}
      >
        <div className="walletconnectcontainer">
          <Disclamer>
            <div className="checkbox">
              <input name="scales" type="checkbox" onChange={handleTermsChange} id="scales" checked={isTermsChecked} />
              <label htmlFor="scales">
                <span>
                  I have read, understand, and agree to the{" "}
                  <Link to="/termservice" target="_blank" onClick={() => handleConnectwallet(false)}>
                    Terms of Service.
                  </Link>
                </span>
              </label>
            </div>
            <div></div>
          </Disclamer>
          <div
            className={"metamask" + (!isTermsChecked ? " diabledWalletConnBtn" : "")}
            onClick={() => {
              if (!isTermsChecked) return;
              handleConnectwallet(false);
              connect(WalletTypes.metamask);
              //document.body.style.overflow = "unset";
            }}
          >
            <img src={metamask} alt="metamask" />
            <p>Metamask</p>
          </div>
          <div
            className={"walletConnect" + (!isTermsChecked ? " diabledWalletConnBtn" : "")}
            onClick={() => {
              if (!isTermsChecked) return;
              handleConnectwallet(false);
              connect(WalletTypes.walletConnect);
              //document.body.style.overflow = "unset";
            }}
          >
            <img src={walletconnectIcon} alt="walletconnect" />
            <p>WalletConnect</p>
          </div>
        </div>
      </CustomModal>

      {/* Disconnect */}
      <CustomModal
        show={disconnectWallet}
        toggleModal={(p: boolean) => {
          handleDisConnectwallet(p);
          //document.body.style.overflow = "unset";
        }}
        headerFSize={"24px"}
        headerFWeight="600"
        heading={"Account"}
        contentMT="0"
        contentTop="56%"
        custMinHeight="30em"
      >
        <CustomText tAlign="start" fWeight="400" fSize="13px" fOpacity="0.5">
          {commonContractsDescription.DISCONNECT_INFO_TEXT}
        </CustomText>
        <ContentBody>
          <BoldPara>Connected with {connectorID === 1 ? "Metamask" : "Wallet Connect"}</BoldPara>
          <DarkContainer>
            <Button rippleColor="#319C2F" onClick={() => handleCopyText("account")} btnType="borderedfilledButton" customPadding="0 1em">
              <TextContainer>
                <CustomText fWeight="700" fSize="14px" fOpacity="0.5">
                  {formatAddress(account, 24)}
                </CustomText>
                <MetamaskIcon src={connectorID === 1 ? metamask : walletconnectIcon} alt="metamask-logo" />
              </TextContainer>
            </Button>
          </DarkContainer>
          <RowContainer>
            <IconTextContainer onClick={() => handleOpenOnEtherscan(account)}>
              <SvgIcon src={openInNewIcon} alt="open-in-new-tab-logo" />
              <SecondaryText fWeight="400" fSize="13px">
                View on Explorer
              </SecondaryText>
            </IconTextContainer>
            <IconTextContainer onClick={e => handleCopyText("account")}>
              <SvgIcon src={getCopyIcon(isAccountAdrCopied)} alt="open-in-new-tab-logo" />
              <LightText fcolor={getCopyTextColor(isAccountAdrCopied)} fOpacity="80%">
                {getCopyText(isAccountAdrCopied)}
              </LightText>
            </IconTextContainer>
          </RowContainer>

          {userContractAddress ? (
            <>
              <BoldPara>Connected with Amplifier User Contract</BoldPara>
              <DarkContainer>
                <Button rippleColor="#319C2F" onClick={() => handleCopyText("userContractAddress")} btnType="borderedfilledButton" customPadding="0 1em">
                  <TextContainer>
                    <CustomText fWeight="700" fSize="14px" fOpacity="0.5">
                      {formatAddress(userContractAddress, 24)}
                    </CustomText>
                  </TextContainer>
                </Button>
              </DarkContainer>
              <RowContainer>
                <IconTextContainer onClick={() => handleOpenOnEtherscan(userContractAddress)}>
                  <SvgIcon src={openInNewIcon} alt="open-in-new-tab-logo" />
                  <SecondaryText fWeight="400" fSize="13px">
                    View on Explorer
                  </SecondaryText>
                </IconTextContainer>
                <IconTextContainer onClick={e => handleCopyText("userContractAddress")}>
                  <SvgIcon src={getCopyIcon(isContractAdrCopied)} alt="open-in-new-tab-logo" />
                  <LightText fcolor={getCopyTextColor(isContractAdrCopied)} fOpacity="80%">
                    {getCopyText(isContractAdrCopied)}
                  </LightText>
                </IconTextContainer>
              </RowContainer>
            </>
          ) : (
            ""
          )}
          <Button fSize="13px" fontLS="0.05em" customBgColor={theme.darkestGray} btnType={"borderedfilledButton"} onClick={() => disconnect()}>
            Disconnect
          </Button>
        </ContentBody>
      </CustomModal>

      <CustomModal
        show={transactionStatus.modal}
        toggleModal={(p: boolean) => {
          setTransactionStatus({ ...transactionStatus, modal: p });
          //document.body.style.overflow = "hidden";
          //document.body.style.overflow = "unset";
        }}
        titlePadding="0"
      >
        <TransactionHeading>{transactionStatus.heading}</TransactionHeading>
        <TransactionContent>
          {transactionStatus.content}
          <img src={transactionStatus.image} alt="transaction-icon" />
        </TransactionContent>
      </CustomModal>

      {/* liquidatedStatus Modal */}
      <CustomModal
        show={isTroveLiquidated}
        isPersistent={true}
        toggleModal={(p: boolean) => handleUpdateLiquidatedStatus()}
        titlePadding="0"
        custMinHeight1="200px"
        contentMT="30px"
      >
        <TransactionHeading>
          <img src={warning} alt="img" style={{ marginRight: "8px" }} /> Trove has liquidated
        </TransactionHeading>
        <TransactionContent style={{ fontSize: "16px" }}>Liquidated Date: {TroveLiquidatedDate}</TransactionContent>
      </CustomModal>

      {/*Transaction Modal */}
      <CustomModal
        hideHead={transactionModalStatus.isPersistent}
        isPersistent={transactionModalStatus.isPersistent}
        show={transactionModalStatus.transactionModal}
        toggleModal={(p: boolean) => {
          if (!p) {
            dispatch(setTxHash(""));
          }
          dispatchTransactionContextEvents();
          //document.body.style.overflow = "hidden";
          //document.body.style.overflow = "unset";
        }}
        titlePadding="0"
      >
        <TransactionHeading>{transactionModalStatus.transactionHeading}</TransactionHeading>
        <TransactionContent>
          <SpinnerImg isSpinning={transactionModalStatus.isSpinner} src={transactionModalStatus.transactionImage} alt="transaction-status" />
        </TransactionContent>
        {txHash && (
          <RowFlex justify="center" customGap="5px">
            <a
              className="transationHash"
              href={Number(envChainId) === ChainId?.eth ? `https://goerli.etherscan.io/tx/${txHash}` : `https://scan.v4.testnet.pulsechain.com/tx/${txHash}`}
              target={"_blank"}
              rel="noreferrer"
            >
              View on explorer
            </a>
          </RowFlex>
        )}
      </CustomModal>
      {isShowStats ? (
        <CustomModal
          isScroll
          show={isShowStats}
          toggleModal={(p: boolean) => {
            handleShowStats(p);
            //document.body.style.overflow = "unset";
          }}
          heading={"Amplify Statistics"}
          custPad=".5em .5em"
          custMaxWidth="480px"
        >
          <Stats hideHead />
        </CustomModal>
      ) : (
        ""
      )}
      {/* logOff Modal */}
      {
        <CustomModal
          show={isLogOff}
          toggleModal={(p: boolean) => {
            handleLogOff(p);
            //document.body.style.overflow = "unset";
          }}
          custPad=".5em .5em"
          custMaxWidth="480px"
        >
          <TransactionHeading>Session Expired</TransactionHeading>
          <TransactionContent>
            <img src={failedImg} alt="transaction-icon" />
          </TransactionContent>
          <TransactionContent>You have reached the maximum time limit. Please Log in again</TransactionContent>
        </CustomModal>
      }
    </>
  );
});

function useOutsideAlerter(ref: any, setIsNavExpanded: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsNavExpanded(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props: any) {
  const { isNavExpanded, isMobileScreen, setIsNavExpanded } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsNavExpanded);
  return (
    <div ref={wrapperRef} className={isNavExpanded && isMobileScreen ? "navigation-menu expanded" : "navigation-menu walletConBtnContainer"}>
      {props.children}{" "}
    </div>
  );
}

interface LOCDATA {
  localData?: any;
}

const PurseInfo = (props: LOCDATA) => {
  const globalSelector = useSelector((state: any) => state);
  const { refetchBalance } = globalSelector.navbar;
  const { fetchBalance, ethToUSD } = useWallet();
  const { account } = useConnectWallet();
  const [ethBalance, setEthBalance] = useState<any>("");
  const [lusdBalance, setLusdBalance] = useState<any>("");
  const [lqtyBalance, setLqtyBalance] = useState<any>("");
  const [ethUSDBalance, setEthUSDBalance] = useState<any>("");
  const [lusdUSDBalance, setLusdUSDBalance] = useState<any>("");
  const [lqtyUSDBalance, setLqtyUSDBalance] = useState<any>("");

  const fetchValue = async () => {
    try {
      let eth_ = "";
      const bal = await fetchBalance();
      eth_ = formatEther(bal);
      const stabilityTokenInWallet = await LUSD_TOKEN_INSTANCE?.balanceOf(account);
      const stakingTokenInWallet = await LQTY_TOKEN_INSTANCE?.balanceOf(account);

      const convertLUSD = formatEther(stabilityTokenInWallet);
      const convertLQTY = formatEther(stakingTokenInWallet);
      const convertUSD = await ethToUSD();
      const { lusdUSD, lqtyUSD } = await tokenToUSD();

      const convertETH_TO_USD = convertUSD ? Number(eth_) * Number(convertUSD) : "0.00";
      const convertLUSD_TO_USD = lusdUSD ? Number(convertLUSD) * Number(lusdUSD) : "0.00";
      const convertLQTY_TO_USD = lqtyUSD ? Number(convertLQTY) * Number(lqtyUSD) : "0.00";

      setLusdBalance(convertLUSD);
      setLqtyBalance(convertLQTY);
      setEthBalance(eth_);
      setEthUSDBalance(convertETH_TO_USD);
      setLusdUSDBalance(convertLUSD_TO_USD);
      setLqtyUSDBalance(convertLQTY_TO_USD);

      const data = {
        lusdBal: formatEther(stabilityTokenInWallet),
        lqtyBal: formatEther(stakingTokenInWallet),
        ethbal: eth_,
        ethUSD: convertETH_TO_USD,
        lusdUSD: convertLUSD_TO_USD,
        lqtyUSD: convertLQTY_TO_USD,
      };
      sessionStorage.setItem("walletBalance", JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchValue();
  }, [account, refetchBalance]);

  return (
    <>
      <NavBalCntr>
        <span>
          {formatNumbersWithComma(Number(ethBalance), 3)} {envAllDescriptionDetails.VAULT_TOKEN_TEXT}
        </span>
        <span>${formatNumbersWithComma(Number(ethUSDBalance), 2)}</span>
      </NavBalCntr>
      <NavBalCntr>
        <span>
          {formatNumbersWithComma(Number(lusdBalance), 3)} {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
        </span>
        <span>${formatNumbersWithComma(Number(lusdUSDBalance), 2)}</span>
      </NavBalCntr>
      <NavBalCntr>
        <span>
          {formatNumbersWithComma(Number(lqtyBalance), 3)} {envAllDescriptionDetails.STAKING_TOKEN_TEXT}
        </span>
        <span>${formatNumbersWithComma(Number(lqtyUSDBalance), 2)}</span>
      </NavBalCntr>
    </>
  );
};
