import { useWeb3React } from "@web3-react/core";
import { Reducer, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "shared/button";
import { ContainerBox, SelectContainer } from "shared/styled";
import { CustomText } from "shared/Typography";

import { FACTORY_INSTANCE, SIGNER, useInstance, USER_INSTANCE } from "blockchain/contract/instance";
import { setTxHash, setUserContract, startLoading, stopLoading } from "logic/redux/actions";
import {
  setSelectedCompoundStrategy,
  setSelectedCompoundStrategyId,
  setSelectedLoanCompoundingTarget,
  setSelectedLoanCompoundingTargetID,
  setSelectedLoanCompoundingTargetUsingTrove,
  setSelectedSpCompoundingTarget,
  setSelectedSpCompoundingTargetId,
  setSelectedStakingCompoundingTarget,
  setSelectedStakingCompoundingTargetId,
} from "logic/redux/actions/strategy";
import { useUtilityStrategy } from "modules/pages/strategy/hooks/useUtilityStrategy";
import CustomModal from "shared/customModal";
import { commonContractsDescription, envAllDescriptionDetails } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { Notification } from "shared/notification";
import { CompoundStrategy } from "shared/selectors/CompoundStrategy";
import { POST_WALLET_URL } from "shared/helpers/apis";
import axios from "axios";
import { StabilityLoanCompoundingPath } from "../../stability/components/loanCompoundingPath/LoanCompoundingPath";
import { StakingLoanCompoundingPath } from "../../staking/components/loanCompoundingPath/LoanCompoundingPath";
import { TroveLoanCompoundingPath } from "../../trove/components/loanCompoundingPath/LoanCompoundingPath";
import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useWallet } from "shared/hook/useWallet";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface IState {
  [key: string]: any | undefined;
  err: string;
  errType: string;
}

interface IAction {
  type: string;
  value?: any;
}
const initialState: IState = {
  err: "",
  errType: "",
};

const reducer = (state: IState, action: IAction) => {
  if (action.type === "reset") {
    return initialState;
  }

  const result: IState = { ...state };
  result[action.type] = action.value;
  return result;
};

export const DeployContractCard = () => {
  const [state, dispatch] = useReducer<Reducer<IState, IAction>, IState>(reducer, initialState, () => initialState);

  const { err, errType } = state;

  const [isContractDeployed, setisContractDeployed] = useState(false);
  const { fetchWalletDetails } = useStrategy();
  const { handleToogleModal, handleSetCompoundStrategy, selectCompoundStrategy } = useUtilityStrategy();
  const { automaticLogOut, clearStorage, InitialLoad } = useWallet();
  const { isModalOn, modalView, selectedCompoundStrategy, selectedCompoundStrategyID } = useSelector((s: any) => s.strategyRedu);
  const { startSpinner, stopSpinner, handleError, authFailed } = useTransaction();
  const { library } = useConnectWallet();
  useInstance();

  const reduxDispatch = useDispatch();
  const { authStatus } = useSelector((s: any) => s.navbar);

  useEffect(() => {
    reduxDispatch(setSelectedCompoundStrategy(""));
    reduxDispatch(setSelectedCompoundStrategyId(""));
  }, []);

  useEffect(() => {
    console.log("in useEffect")
    if (selectedCompoundStrategy) {
      dispatch({
        type: "err",
        value: "",
      });
    }
  }, [selectedCompoundStrategy, isContractDeployed]);

  const handleDeployContracts = async () => {
    reduxDispatch({
      type: 'RESET'
    })
    if (authStatus) {
      authFailed();
      return;
    }
    if (!selectedCompoundStrategy) {
      dispatch({
        type: "err",
        value: "Please select a strategy to deploy the contract",
      });
      dispatch({ type: "errType", value: "error" });
      return;
    }
    if (!library) {
      return;
    }
    try {
      startSpinner();
      const SignerAddress = await SIGNER?.getAddress();
      const estimateGas = await FACTORY_INSTANCE?.estimateGas.createUserContract(selectedCompoundStrategyID);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      const userCreation = await FACTORY_INSTANCE?.connect(SIGNER).createUserContract(selectedCompoundStrategyID, {
        gasLimit: bufferedGas.toFixed(0),
      });
      reduxDispatch(setTxHash(userCreation.hash));
      await userCreation.wait();
      const userContractAddr = await FACTORY_INSTANCE?.getContract(SignerAddress);
      reduxDispatch(setUserContract(userContractAddr));
      await postContractToNodeAPI(userContractAddr);
      sessionStorage.setItem("deployed", userContractAddr);
      stopSpinner();
    } catch (e: any) {
      handleError(e);
    }
  };

  const postContractToNodeAPI = async (userContractAddress: string) => {
    try {
      setisContractDeployed(true)
      dispatch(startLoading());
      let payload: any;
      if (selectedCompoundStrategyID === "5") {
        payload = {
          userContractAddress,
          compoundStrategies: selectedCompoundStrategyID,
          loanCompoundingTarget: "7",
          stabilityCompounding: "0",
          stakingCompounding: "0",
        };
        dispatch(setSelectedSpCompoundingTarget(envAllDescriptionDetails.DEFAULT_STABILITY_COMPOUNDING_TARGET));
        dispatch(setSelectedSpCompoundingTargetId("0"));
        dispatch(setSelectedStakingCompoundingTarget(envAllDescriptionDetails.DEFAULT_STAKING_COMPOUNDING_TARGET));
        dispatch(setSelectedStakingCompoundingTargetId("0"));
        dispatch(setSelectedLoanCompoundingTarget(commonContractsDescription.DEFAULT_LOAN_COMPOUNDING_TARGET));
        dispatch(setSelectedLoanCompoundingTargetID("7"));
        dispatch(setSelectedLoanCompoundingTargetUsingTrove(false));
      } else {
        payload = {
          userContractAddress,
          compoundStrategies: selectedCompoundStrategyID,
        };
      }
      await axios.post(POST_WALLET_URL, payload);
      await fetchWalletDetails();
      InitialLoad();
      dispatch(stopLoading())
    } catch (error: any) {
      dispatch(stopLoading())
      if (error?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  return (
    <>
      <ContainerBox mblMargin="50px 0 0 0" customGap="2em" maxWidth="430px" minWidth="430px">
        <CustomText width="12em" fSizeM="24px" fSize="28px" fLineHeight="36px">
          Amplifier Summary
        </CustomText>
        <div>
          <SelectContainer>
            <Button
              onClick={selectCompoundStrategy}
              btnType="borderedfilledButton"
              fSize="16px"
              customHeight="40px"
              customPadding="0 1em"
              bRadius="6px"
              minWidthMb="125px"
              minWidthSpan="125px"
            >
              {selectedCompoundStrategy || "Select a strategy"}
            </Button>
          </SelectContainer>
          <div style={{ marginTop: "1rem" }}>
            {
              selectedCompoundStrategy &&
              (
                <>
                  <TroveLoanCompoundingPath prefix="Trove" />
                  <StabilityLoanCompoundingPath prefix="Stability" />
                  <StakingLoanCompoundingPath prefix="Staking" />
                </>
              )
            }

          </div>
        </div>
        {err ? <Notification message={err} type={errType} /> : ""}
        <Button justify="start" customWidth="60%" btnType="borderedfilledButton" onClick={handleDeployContracts} fSize="18px">
          Deploy Contract
        </Button>
      </ContainerBox>
      <CustomModal
        show={isModalOn}
        toggleModal={() => {
          handleToogleModal();
        }}
        titlePadding="0"
        contentTop="40%"
        custMinHeight="50%"
      >
        {modalView === commonContractsDescription.COMPOUND_STRATEGY ? <CompoundStrategy setStrategy={handleSetCompoundStrategy} isDeploy="yes" /> : ""}
      </CustomModal>
    </>
  );
};
