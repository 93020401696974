import { useSelector } from "react-redux";

import { Stats } from "./Stats";
import { ColCntr } from "../style";
import { StrategyBox } from "./StrategyBox";
import { useIsMobileScreen } from "shared/hook/useIsMobileScreen";


export const RightBox = () => {

    const { userContractAddress } = useSelector((state: any) => state.navbar);
    const isMobileScreen = useIsMobileScreen();
    
    return (
        <ColCntr>
            {userContractAddress && 
            <div className="cardContainer4">
              <StrategyBox />
            </div>
            }
            {!isMobileScreen &&
            <div className="cardContainer5">
             <Stats />
             </div>
             }
        </ColCntr>
    )
}