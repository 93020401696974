import { useWeb3React } from "@web3-react/core";
import { HELPERS_INSTANCE, SORTED_TROVES, TROVE_MANAGER_INSTANCE } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { setTxHash } from "logic/redux/actions";
import { useDispatch } from "react-redux";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";

export const useRedemption = () => {
  const { account } = useConnectWallet();
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const dispatch = useDispatch();
  const { ethToUSD } = useWallet();

  const redemption = async (amount: any) => {
    try {
      const troves = await SORTED_TROVES?.getSize();
      const randNum = (Math.random() * 100).toFixed(0);
      const numTroves: any = ethers.BigNumber.from(troves);
      const ethInDollar = await ethToUSD();
      const numTrials = ethers.BigNumber.from(Math.sqrt(numTroves).toFixed(0).toString()).mul(ethers.BigNumber.from("15"));

      const {
        0: firstRedemptionHint,
        1: partialRedemptionNewICR,
        2: truncatedLUSDAmount,
      } = await HELPERS_INSTANCE?.getRedemptionHints(
        parseUnits(amount),
        parseUnits(ethInDollar.toString()), // customPrice
        0,
      );

      if (!Number(partialRedemptionNewICR?.toString()) || !Number(truncatedLUSDAmount?.toString())) {
        return 0;
      }
      // Get the approximate partial redemption hint
      const { 0: approxPartialRedemptionHint } = await HELPERS_INSTANCE.getApproxHint(partialRedemptionNewICR.toString(), numTrials, randNum);

      /* Use the approximate partial redemption hint to get the exact partial redemption hint from the
       * deployed SortedTroves contract
       */
      const estimateGas_findInsertPosition = await SORTED_TROVES.estimateGas.findInsertPosition(partialRedemptionNewICR, approxPartialRedemptionHint, approxPartialRedemptionHint);
      const bufferedGas_estimateGas_findInsertPosition = Number(estimateGas_findInsertPosition.toString()) + Number(estimateGas_findInsertPosition.toString()) * 0.5;
      const exactPartialRedemptionHint: any = await SORTED_TROVES.findInsertPosition(partialRedemptionNewICR, approxPartialRedemptionHint, approxPartialRedemptionHint, {
        gasLimit: bufferedGas_estimateGas_findInsertPosition.toFixed(0),
      });

      /* Finally, perform the on-chain redemption, passing the truncated LUSD amount, the correct hints, and the expected
       * ICR of the final partially redeemed trove in the sequence.
       */
      const baseRate = await TROVE_MANAGER_INSTANCE.baseRate();
      const MINIMUM_REDEMPTION_RATE = "500000000000000000";
      const _tempMin = baseRate.add(MINIMUM_REDEMPTION_RATE);
      const _tempMinConvert = formatEther(_tempMin);
      const minRedemptionRate = Math.min(Number(_tempMinConvert), 1);
      const maxRedemptionRate = minRedemptionRate + 0.001;
      const convertTruncatedAmt = formatEther(truncatedLUSDAmount);
      const maxRedemptionRateOrDefault = maxRedemptionRate !== undefined ? maxRedemptionRate : Math.min(Number(convertTruncatedAmt) + 0.001, 1);
      const maxFee = parseUnits(maxRedemptionRateOrDefault.toString());

      startSpinner();
      const estimateGas = await TROVE_MANAGER_INSTANCE.estimateGas.redeemCollateral(
        truncatedLUSDAmount,
        firstRedemptionHint,
        exactPartialRedemptionHint[0],
        exactPartialRedemptionHint[1],
        partialRedemptionNewICR,
        0,
        maxFee,
        { from: account },
      );
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;

      const final_res = await TROVE_MANAGER_INSTANCE.redeemCollateral(
        truncatedLUSDAmount,
        firstRedemptionHint,
        exactPartialRedemptionHint[0],
        exactPartialRedemptionHint[1],
        partialRedemptionNewICR,
        0,
        maxFee,
        { from: account, gasLimit: bufferedGas.toFixed(0) },
      );
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      dispatch(setTxHash(final_res.hash));
      stopSpinner();
    } catch (err: any) {
      console.error("error", err.reason);
      handleError(err);
    }
  };

  return {
    redemption,
  };
};
