import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { CUSTOM_STATIC_INSTANCE, HALF_AND_HALF_STATIC_INSTANCE, PASSIVE_PULSE_STATIC_INSTANCE, PULSE_STACKER_STATIC_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { parseUnits } from "ethers/lib/utils";
import { initialValues, setIsAutomation, setTxHash, stabilityInitialValues, stakeInitialValues } from "logic/redux/actions";
import {
  findEstimateGas,
  handleCancelCS,
  isValueChangedChecking,
  registerStrategy,
  setIsLoadingStrat,
  setIsModalOn,
  setModalView,
  setSelectedLoanCompoundingTargetUsingTrove,
  setSelectedSpCompoundingTarget,
  setSelectedSpCompoundingTargetId,
  setSelectedStakingCompoundingTarget,
  setSelectedStakingCompoundingTargetId,
  setSlippage,
  setTargetCollateralRatio,
  setTriggerCollateralRatio,
  setup,
  stabilityNotExecuteEnabled,
  stakingNotExecuteEnabled,
  stratergyEnabled,
} from "logic/redux/actions/strategy";
import { Reducer, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { commonContractsDescription, envAllDescriptionDetails, validateNumberInput } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";

interface ICustomStrategies {
  [key: string]: any | undefined;
}

interface IAction {
  type: string;
  value?: any;
}

const initialCS: ICustomStrategies = {};

const startReducer = (state: ICustomStrategies, action: IAction) => {
  if (action.type === "reset") {
    return initialCS;
  }
  if (action.type === "delete") {
    const resp: ICustomStrategies = { ...state };
    delete resp[action.value];
    return resp;
  }
  const res: ICustomStrategies = { ...state };
  res[action.type] = action.value;
  return res;
};

export const useStrategy = () => {
  const MAX_COLL_VAL = 2000;
  const maxFee = parseUnits("0.05", "ether").toString();
  const [reRender, setReRender] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const [customStrategies, dispatchCustomStrategies] = useReducer<Reducer<ICustomStrategies, IAction>, ICustomStrategies>(startReducer, initialCS, () => initialCS);

  const { userContractAddress, authStatus, refetchBalance } = useSelector((s: any) => s.navbar);
  const { BOTH, ETH, LUSD } = useSelector((s: any) => s.trove);
  const { mode, isAdjusting } = BOTH;
  const { debt } = LUSD;
  const { coll } = ETH;
  const { stakedLQTY } = useSelector((s: any) => s.stake);
  const { stakedLUSD } = useSelector((s: any) => s.stability);

  const { ethToUSD, automaticLogOut, clearStorage } = useWallet();
  const { account, library, active }: any = useConnectWallet();
  const { startSpinner, stopSpinner, handleError, authFailed }: any = useTransaction();

  const { isLoading, selectedCompoundStrategy, selectedCompoundStrategyID, selectedLoanCompoundingTargetID, updatableKey, strategyInstance, targetCollateralRatio, disable } =
    useSelector((s: any) => s.strategyRedu);

  useEffect(() => {
    if (updatableKey) {
      dispatchCustomStrategies({ type: "delete", value: updatableKey });
    }
  }, [updatableKey]);

  /**
   * All useEffects
   */

  // useEffect(() => {
  //   if (authStatus === false && userContractAddress) {
  //     fetchWalletDetails(userContractAddress);
  //   }
  // }, [library, userContractAddress, authStatus, refetchBalance]);

  useEffect(() => {
    if (active) {
      strategyEnabledViaAction();
      stabilityEnabledViaAction();
      stakingEnabledViaAction();
      reduxDispatch(findEstimateGas({ account }));
    }
  }, [library, debt, coll, mode, stakedLQTY, stakedLUSD, isAdjusting, selectedCompoundStrategy, selectedLoanCompoundingTargetID, disable]);

  const strategyEnabledViaAction = async () => {
    reduxDispatch(
      stratergyEnabled({
        account,
      }),
    );
  };
  const stabilityEnabledViaAction = async () => {
    reduxDispatch(stabilityNotExecuteEnabled({ account, userContractAddress }));
  };
  const stakingEnabledViaAction = async () => {
    reduxDispatch(stakingNotExecuteEnabled({ account, userContractAddress }));
  };

  const isValueChangedCheckingActivation = async () => {
    const tempPayload = await reduxDispatch(isValueChangedChecking({ customStrategies }));
    return tempPayload;
  };

  const registerStrategyViaAction = (payload: any) => {
    const tempAdjustState = reduxDispatch(
      registerStrategy({
        payload,
        account,
        authFailed,
        USER_INSTANCE,
        customStrategies,
        userContractAddress,
        fetchWalletDetails,
        strategyEnabledViaAction,
        stabilityEnabledViaAction,
        stakingEnabledViaAction,
        handleError,
        startSpinner,
        stopSpinner,
        automaticLogOut,
        clearStorage,
      }),
    );
    return tempAdjustState;
  };

  const handleCompoundButton = async () => {
    try {
      if (authStatus) {
        authFailed();
        return;
      }
      let res;
      startSpinner();

      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "2") {
        switch (selectedCompoundStrategyID) {
          case "1":
            //pulse stacker
            {
              const hintAddresses = await PULSE_STACKER_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 5));
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses, {
                gasLimit: bufferedGas.toFixed(0),
              });
              reduxDispatch(setTxHash(res?.hash));
            }
            break;

          case "3":
            // half n half
            {
              const hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 4));
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses, {
                gasLimit: bufferedGas.toFixed(0),
              });
              reduxDispatch(setTxHash(res?.hash));
            }
            break;

          case "4":
            // passive pulse
            {
              const hintAddresses = await PASSIVE_PULSE_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3));
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3), {
                gasLimit: bufferedGas.toFixed(0),
              });
              reduxDispatch(setTxHash(res?.hash));
            }
            break;

          case "5":
            // custom strategy
            {
              const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 17));
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 17), { gasLimit: bufferedGas.toFixed(0) });
              reduxDispatch(setTxHash(res?.hash));
            }
            break;

          default:
            break;
        }
      } else {
        const estimateGas1 = await strategyInstance.estimateGas.executeStrategy(account);
        const bufferedGas1 = Number(estimateGas1.toString()) + Number(estimateGas1.toString()) * 0.5;
        res = await strategyInstance?.executeStrategy(account, {
          gasLimit: bufferedGas1.toFixed(0),
        });
        reduxDispatch(setTxHash(res?.hash));
      }

      await res.wait();
      reduxDispatch(initialValues(library, account, ethToUSD));
      reduxDispatch(stakeInitialValues(userContractAddress, ethToUSD, account));
      reduxDispatch(stabilityInitialValues(ethToUSD, account));
      strategyEnabledViaAction();
      stabilityEnabledViaAction();
      stakingEnabledViaAction();
      stopSpinner();
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleCompoundStability = async () => {
    try {
      if (authStatus) {
        authFailed();
        return;
      }
      let res;
      startSpinner();

      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "5") {
        switch (selectedCompoundStrategyID) {
          case "3":
            {
              const hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.compoundStabilityStaticCall(account, randNum);
              const estimateGas = await strategyInstance.estimateGas.compoundStability(maxFee, hintAddresses[0], hintAddresses[1]);
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.compoundStability(maxFee, hintAddresses[0], hintAddresses[1], {
                gasLimit: bufferedGas.toFixed(0),
              });
            }
            break;
          default:
            break;
        }
      } else {
        const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.compoundStabilityStaticCall(userContractAddress, account, maxFee, randNum);
        const estimateGas1 = await strategyInstance.estimateGas.compoundStability(userContractAddress, account, maxFee, ...hintAddresses.slice(0, 7));

        const bufferedGas1 = Number(estimateGas1.toString()) + Number(estimateGas1.toString()) * 0.5;
        res = await strategyInstance?.compoundStability(userContractAddress, account, maxFee, ...hintAddresses.slice(0, 7), {
          gasLimit: bufferedGas1.toFixed(0),
        });
      }
      reduxDispatch(setTxHash(res?.hash));
      await res.wait();
      reduxDispatch(initialValues(library, account, ethToUSD));
      reduxDispatch(stakeInitialValues(userContractAddress, ethToUSD, account));
      reduxDispatch(stabilityInitialValues(ethToUSD, account));
      stabilityEnabledViaAction();
      stakingEnabledViaAction();
      strategyEnabledViaAction();
      stopSpinner();
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleCompoundStaking = async () => {
    try {
      if (authStatus) {
        authFailed();
        return;
      }
      let res;
      startSpinner();

      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "5") {
        const estimateGas = await strategyInstance.estimateGas.compoundStaking();
        const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
        res = await strategyInstance?.compoundStaking({
          gasLimit: bufferedGas.toFixed(0),
        });
      } else {
        const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.compoundStakingStaticCall(userContractAddress, account, maxFee, randNum, false);

        const estimateGas1 = await strategyInstance.estimateGas.compoundStaking(userContractAddress, account, maxFee, false, ...hintAddresses.slice(0, 5));
        const bufferedGas1 = Number(estimateGas1.toString()) + Number(estimateGas1.toString()) * 0.5;

        res = await strategyInstance?.compoundStaking(userContractAddress, account, maxFee, randNum, false, ...hintAddresses.slice(0, 5), {
          gasLimit: bufferedGas1.toFixed(0),
        });
      }
      reduxDispatch(setTxHash(res?.hash));
      await res.wait();
      reduxDispatch(initialValues(library, account, ethToUSD));
      reduxDispatch(stakeInitialValues(userContractAddress, ethToUSD, account));
      reduxDispatch(stabilityInitialValues(ethToUSD, account));
      stabilityEnabledViaAction();
      stakingEnabledViaAction();
      strategyEnabledViaAction();
      stopSpinner();
    } catch (e: any) {
      handleError(e);
    }
  };
  /**
   * CollateralRatio Bar handlers
   */
  const handleCollateralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    reduxDispatch(setTargetCollateralRatio(value));
  };

  /**
   * CollateralRatio Bar handlers
   */
  const handleTriggerCollateralRatio = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    reduxDispatch(setTriggerCollateralRatio(value));
  };

  /**
   *  Increasing the collateralRatio
   */
  const prevCheck = (prev: any, val: any) => {
    let newVal;
    if (val === 2) {
      if (prev * 2 > Number(MAX_COLL_VAL)) newVal = MAX_COLL_VAL;
      else newVal = prev * 2;
    } else if (val === 5) {
      if (prev * 5 > Number(MAX_COLL_VAL)) newVal = MAX_COLL_VAL;
      else newVal = prev * 5;
    } else {
      newVal = MAX_COLL_VAL;
    }
    return newVal.toString();
  };
  const handleIncreaseColateral = (val: any) => {
    reduxDispatch(setTargetCollateralRatio(prevCheck(targetCollateralRatio, val)));
  };

  const setupViaAction = (walletInfo: any, isAutomated: any) => {
    reduxDispatch(
      setup({
        walletInfo,
        isAutomated,
        dispatchCustomStrategies,
      }),
    );
  };

  const handleCancelCSViaAction = (cancel?: boolean) => {
    reduxDispatch(
      handleCancelCS({
        cancel,
        dispatchCustomStrategies,
      }),
    );
  };

  const fetchWalletDetails = async () => {
    try {
      reduxDispatch(setIsLoadingStrat(true));
      const Automation_Trove_Status = await USER_INSTANCE.getAutomationAndTrove();
      const isAutomated = Automation_Trove_Status[0] === 0 ? false : true;
      const loanCompoundingTargetUsingTrove = Automation_Trove_Status[0] === 0 ? false : true;
      let { data } = await axios.get(POST_WALLET_URL);
      let walletInfo = data?.data;
      // if (walletInfo?.isTroveLiquidated && !toast.isActive("isTroveLiquidated")) {
      //   toast.warning("Trove has liquidated!", {
      //     transition: Flip,
      //     toastId: "isTroveLiquidated",
      //     autoClose: false,
      //     closeButton: toaster()
      //   });
      // }
      reduxDispatch(setIsAutomation(isAutomated));
      reduxDispatch(setSelectedLoanCompoundingTargetUsingTrove(loanCompoundingTargetUsingTrove));
      setupViaAction(walletInfo, isAutomated);
      reduxDispatch(setIsLoadingStrat(false));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      reduxDispatch(setIsLoadingStrat(false));
      throw new Error();
    }
  };

  const handleSetSPCompoundingTarget = (e: any) => {
    const topOff = "Top Up Stability Pool";
    if (selectedLoanCompoundingTargetID === "4" && e) {
      let { id, value } = e.target;
      let val = customStrategies[topOff]?.[0] || "";
      dispatchCustomStrategies({ type: topOff, value: [val, value, id] });
      reduxDispatch(setIsModalOn(false));
      reduxDispatch(setModalView(commonContractsDescription.LOAN_COMPOUNDING_TARGET));
      reduxDispatch(setIsModalOn(true));
      sessionStorage.setItem(topOff, value);
      reduxDispatch(setSelectedSpCompoundingTarget(value));
      reduxDispatch(setSelectedSpCompoundingTargetId(id));
    } else {
      if (e) {
        let { id, value } = e.target;
        sessionStorage.setItem(topOff, value);
        reduxDispatch(setSelectedSpCompoundingTarget(value));
        reduxDispatch(setSelectedSpCompoundingTargetId(id));
      }
      reduxDispatch(setIsModalOn(false));
      reduxDispatch(setModalView(""));
    }
  };

  const handleSetStakingCompoundingTarget = (e: any) => {
    const topOff = "Top Up Staking Pool";
    if (selectedLoanCompoundingTargetID === "4" && e) {
      let { id, value } = e.target;
      let val = customStrategies[topOff]?.[0] || "";
      dispatchCustomStrategies({ type: topOff, value: [val, value, id] });
      reduxDispatch(setIsModalOn(false));
      reduxDispatch(setModalView(commonContractsDescription.LOAN_COMPOUNDING_TARGET));
      reduxDispatch(setIsModalOn(true));
      sessionStorage.setItem(topOff, value);
      reduxDispatch(setSelectedStakingCompoundingTarget(value));
      reduxDispatch(setSelectedStakingCompoundingTargetId(id));
    } else {
      if (e) {
        let { id, value } = e.target;
        sessionStorage.setItem(topOff, value);
        reduxDispatch(setSelectedStakingCompoundingTarget(value));
        reduxDispatch(setSelectedStakingCompoundingTargetId(id));
      }
      reduxDispatch(setIsModalOn(false));
      reduxDispatch(setModalView(""));
    }
  };

  const setStrategyInput = (e: any, key: string) => {
    let amount = validateNumberInput(e)?.toString();
    let val = customStrategies[key]?.[1] || null;
    let arr = val ? [amount, val] : [amount];
    dispatchCustomStrategies({ type: key, value: arr });
  };

  const setCustomStrategies = (e: any) => {
    const topOff = e.target.value;
    let val = customStrategies[topOff] || "";
    if (updatableKey) {
      dispatchCustomStrategies({ type: "delete", value: updatableKey });
    }
    if (topOff === "Top Up Staking Pool") {
      sessionStorage.setItem(topOff, envAllDescriptionDetails.DEFAULT_STAKING_COMPOUNDING_TARGET);
      dispatchCustomStrategies({ type: e.target.value, value: ["", envAllDescriptionDetails.DEFAULT_STAKING_COMPOUNDING_TARGET, "0"] });
    } else if (topOff === "Top Up Stability Pool") {
      sessionStorage.setItem(topOff, envAllDescriptionDetails.DEFAULT_STABILITY_COMPOUNDING_TARGET);
      dispatchCustomStrategies({ type: e.target.value, value: ["", envAllDescriptionDetails.DEFAULT_STABILITY_COMPOUNDING_TARGET, "0"] });
    } else {
      dispatchCustomStrategies({ type: e.target.value, value: [val] });
    }
    reduxDispatch(setIsModalOn(false));
    reduxDispatch(setModalView(commonContractsDescription.LOAN_COMPOUNDING_TARGET));
    reduxDispatch(setIsModalOn(true));
    setReRender(prev => !prev);
  };

  return {
    MAX_COLL_VAL,
    customStrategies,
    reRender,
    strategyEnabledViaAction,
    handleIncreaseColateral,
    isValueChangedCheckingActivation,
    registerStrategyViaAction,
    handleSetSPCompoundingTarget,
    handleSetStakingCompoundingTarget,
    handleTriggerCollateralRatio,
    setStrategyInput,
    setCustomStrategies,
    handleCompoundButton,
    handleCompoundStability,
    handleCompoundStaking,
    handleCollateralChange,
    handleCancelCSViaAction,
    fetchWalletDetails,
  };
};
