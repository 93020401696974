import { ColContainer, HeadColContainer, FooterPrimaryText, FooterSecondryText, CustomFooter, FooterContainer } from "./style";
import powercityLogo from "assets/icons/logo-powercity.svg";

export const Footer: React.FC = (props: any) => {
  const toNavigate = (url: string) => {
    return window.open(url, "_blank");
  };
  return (
    <CustomFooter id="footer">
      <FooterContainer>
        <HeadColContainer custPadding="0 0 20px 0" align="start">
          <img src={powercityLogo} alt="powercity-logo" onClick={() => toNavigate("https://www.powercity.io/")} />
        </HeadColContainer>
        <ColContainer>
          <FooterPrimaryText pad="0 0 8px 0">Help</FooterPrimaryText>
          <FooterSecondryText onClick={() => toNavigate("https://docs.powercity.io/amplifier/")}>
            What is Amplifier?
          </FooterSecondryText>

          <FooterSecondryText onClick={() => toNavigate("https://discord.io/POWERCITYio")}>Ask for help on Discord </FooterSecondryText>
          <FooterSecondryText onClick={() => toNavigate("https://twitter.com/POWERCITYio ")}>Ask for help on Twitter</FooterSecondryText>
          <FooterSecondryText onClick={() => toNavigate("https://t.me/POWERCITYio")}>Ask for help on Telegram</FooterSecondryText>
        </ColContainer>
      </FooterContainer>
    </CustomFooter>
  );
};
