import { PageContainer } from "shared/flexBox/style";
import { LightText } from "shared/Typography";
import { ContentContainer } from "modules/pageNotFound/style";

export const Privacy = () => {
   
    return (
        <PageContainer>
            <ContentContainer>
                <LightText fSizeM='30px' fSize='40px' fOpacity='1'>
                   Privacy Policy
                </LightText>
                <span>
                Content will be available soon
                </span>
            </ContentContainer>
        </PageContainer>
    )
}
