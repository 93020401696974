import { envAllDescriptionDetails } from "shared/helpers/utils";
import { RadioBtn } from "shared/radioBtn/RadioBtn";
import { CustomText } from "shared/Typography";
import { ModalListContainer } from "./style";

interface ILoanCompoundingTargetProps {
  setStrategy?: any;
  customStrategies?: any;
}

export const TopOffSelector = (props: ILoanCompoundingTargetProps) => {
  const { setStrategy, customStrategies = {} } = props;

  
  return (
    <>
      <ModalListContainer>
        <CustomText fSizeM="20px" fLineHeight="28px" fSize="24px" tAlign="start">
          Loan Compounding target
        </CustomText>
        {!customStrategies["Top Up Vault"] &&
          customStrategies["Top Up Vault"] !== "0" && (
            <RadioBtn
              infoMsg={envAllDescriptionDetails.TOPOFF_TOPUP_VAULT_TEXT}
              name="loanCompoundingTarget"
              radioId="topOffVault"
              value="Top Up Vault"
              onChange={setStrategy}
            />
          )}
        {!customStrategies["Top Up Stability Pool"] &&
          customStrategies["Top Up Stability Pool"] !== "0" && (
            <RadioBtn
              infoMsg={envAllDescriptionDetails.TOPOFF_TOPUP_STABILITY_TEXT}
              name="loanCompoundingTarget"
              radioId="topOffStabilityPool"
              value="Top Up Stability Pool"
              onChange={setStrategy}
            />
          )}
        {!customStrategies["Top Up Staking Pool"] &&
          customStrategies["Top Up Staking Pool"] !== "0" && (
            <RadioBtn
              infoMsg={envAllDescriptionDetails.TOPOFF_TOPUP_STAKING_TEXT}
              name="loanCompoundingTarget"
              radioId="topOffStakingPool"
              value="Top Up Staking Pool"
              onChange={setStrategy}
            />
          )}
      </ModalListContainer>
    </>
  );
};
