import { LUSD_TOKEN_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { formatEther } from "ethers/lib/utils";
import { tokenToUSD } from "shared/helpers/utils";
import { SET_STABILITY_VALUES } from "./constant";

interface IProps {
  type: string;
  payload: any;
}
export const setStabilityValues = (data: any): IProps => {
  return {
    type: SET_STABILITY_VALUES,
    payload: data,
  };
};

export const stabilityInitialValues: any = (ethToUSD: any, account: any) => async (dispatch: any, getState: any) => {
  try {
    const [ttlLUSDInDeposits, usrLUSDInDeposits, lqtyGain, ethGain, lusdBalance] = await Promise.all([
      USER_INSTANCE?.getTotalLUSDDeposits(),
      USER_INSTANCE?.getCompoundedLUSDDeposit(),
      USER_INSTANCE?.getDepositorLQTYGain(),
      USER_INSTANCE?.getDepositorETHGain(),
      LUSD_TOKEN_INSTANCE?.balanceOf(account),
    ]);
    const userStakes = usrLUSDInDeposits.mul(100);
    const poolShareVal = Number(formatEther(userStakes)) / Number(formatEther(ttlLUSDInDeposits));
    const reward_ = Number(formatEther(lqtyGain)).toFixed(6);
    const poolShare_ = poolShareVal.toFixed(6);
    // const depositLUSD_ = Number(formatEther(usrLUSDInDeposits)).toFixed(4);
    const depositLUSD_ =  Math.trunc(Number(usrLUSDInDeposits/10 ** 18) * Math.pow(10, 4)) / Math.pow(10, 4);
    const liqGain = Number(formatEther(ethGain)).toFixed(6);

    const convertUSD = await ethToUSD();
    const { lusdUSD, lqtyUSD } = await tokenToUSD();
    const Convert_Reward = lqtyUSD ? Number(lqtyUSD) * Number(reward_) : 0.0;
    const Convert_LiquidationGain = convertUSD ? Number(convertUSD) * Number(liqGain) : 0.0;
    const Convert_DepositLUSD = lusdUSD ? Number(lusdUSD) * Number(depositLUSD_) : 0.0;
    const convertLUSD = Math.trunc(Number(lusdBalance/10 ** 18) * Math.pow(10, 4)) / Math.pow(10, 4);
    const LUSD_TO_USD = lusdUSD ? Number(convertLUSD) * Number(lusdUSD) : 0.0;

    dispatch(
      setStabilityValues({
        lqtyGainInDollar: Convert_Reward,
        stakedLUSDInDollar: Convert_DepositLUSD,
        liquidationEthGainInDollar: Convert_LiquidationGain,
        lqtyGain: reward_,
        poolShare: poolShare_,
        liquidationEthGain: liqGain,
        stakedLUSD: Number(depositLUSD_),
        lusdInWalletDisplay: convertLUSD,
        lusdWalletInDollar: LUSD_TO_USD,
        TotalStake: Number(depositLUSD_),
      }),
    );
  } catch (e: any) {
    console.error(e);
  }
};
