import { SemiboldHeading3, Para, LinkText } from "shared/Typography"
import { TitleContainer } from "./style"

interface IPageTitleProps {
    titleText?: string,
    bodyText?: string,
    readMoreText?: string,
    readMoreURL?: string,
    customPadding?: string,
    justify?: string,
    isMobile?: boolean,
    textAlign?: string,

}


export const PageTitle = (props: IPageTitleProps) => {
    const { titleText, isMobile, justify, bodyText, readMoreText, readMoreURL, customPadding, textAlign} = props
    return (
        <TitleContainer isMobile={isMobile} customPadding={customPadding} justify={justify} textAlign={textAlign}>
            {titleText && <SemiboldHeading3>{titleText}</SemiboldHeading3>}
            {bodyText
                && (
                    <div>
                        <Para>{bodyText}</Para>
                        {readMoreURL && <LinkText href={readMoreURL} target='blank'>{readMoreText}</LinkText>}
                    </div>
                )
            }
        </TitleContainer>
    )
}