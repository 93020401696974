import { SET_STABILITY_VALUES } from "../actions";

const initialState = {
  lqtyGainInDollar: 0,
  stakedLUSDInDollar: 0,
  liquidationEthGainInDollar: 0,
  lqtyGain: "0.000000",
  poolShare: "0.000000",
  liquidationEthGain: "0.000000",
  stakedLUSD: 0,
  newStakeLUSD: 0,
  lusdInWalletDisplay: 0,
  lusdWalletInDollar: 0,
  TotalStake: 0,
};

export const stabilityReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case SET_STABILITY_VALUES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
