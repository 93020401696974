import {
  ERROR_STRAT,
  DISABLE,
  DISABLINGREASON,
  ISCOMPOUND,
  ISLOADING,
  ISMODALON,
  ISRATIOSHOWN,
  MODALVIEW,
  SELECTEDCOMPOUNDSTRATEGY,
  SELECTEDCOMPOUNDSTRATEGYID,
  SELECTEDLOANCOMPOUNDINGTARGET,
  SELECTEDSPCOMPOUNDINGTARGET,
  SELECTEDSPCOMPOUNDINGTARGETID,
  SELECTEDSTAKINGCOMPOUNDINGTARGET,
  SELECTEDSTAKINGCOMPOUNDINGTARGETID,
  STRATEGYADDRESS,
  STRATEGYINSTANCE,
  TARGETCOLLATERALRATIO,
  TRIGGERCOLLATERALRATIO,
  UPDATABLEKEY,
  ISVALUESCHANGED,
  SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE,
  SELECTEDLOANCOMPOUNDINGTARGETID,
  ISSTABILITYCOMPOUND,
  ISSTAKINGCOMPOUND,
  DISABLINGSTABILITYREASON,
  DISABLINGSTAKINGREASON,
  ESTIMATE_GAS,
  IS_LIQUIDATE_TROVE,
  LIQUIDATE_TROVE_DATE,
  SLIPPAGE,
} from "../actions/constant";

interface IState {
  [key: string]: any | undefined;
  err: string;
  isLoading: boolean;
  isModalOn: boolean;
  modalView: string;
  isRatioShown: boolean;
  selectedCompoundStrategy: string;
  selectedCompoundStrategyID: string;
  selectedSPCompoundingTargetID: string;
  selectedStakingCompoundingTargetID: string;
  selectedLoanCompoundingTarget: string;
  selectedLoanCompoundingTargetID: string;
  selectedLoanCompoundingTargetUsingTrove: string;
  selectedSPCompoundingTarget: string;
  selectedStakingCompoundingTarget: string;
  updatableKey: string;
  strategyInstance: any;
  IsCompound: boolean;
  strategyAddress: string;
  targetCollateralRatio: any;
  triggerCollateralRatio: any;
  disablingReason: any;
  disable: boolean;
  isValueChanged: boolean;
  disablingStabilityReason: any;
  IsStabilityCompound: boolean;
  disablingStakingReason: any;
  IsStakingCompound: boolean;
  estimateGas: number;
  isTroveLiquidated: boolean;
  TroveLiquidatedDate: any;
  slippage: number;
}

const initialState: IState = {
  err: "",
  isLoading: false,
  isModalOn: false,
  modalView: "",
  selectedCompoundStrategy: "",
  selectedCompoundStrategyID: "",
  selectedLoanCompoundingTarget: "",
  selectedLoanCompoundingTargetID: "",
  selectedLoanCompoundingTargetUsingTrove: "",
  selectedSPCompoundingTargetID: "0",
  selectedStakingCompoundingTargetID: "0",
  selectedSPCompoundingTarget: "",
  selectedStakingCompoundingTarget: "",
  updatableKey: "",
  isRatioShown: false,
  strategyInstance: "",
  IsCompound: false,
  strategyAddress: "",
  targetCollateralRatio: 110,
  triggerCollateralRatio: 10,
  disablingReason: "",
  disable: false,
  isValueChanged: true,
  disablingStabilityReason: "",
  IsStabilityCompound: false,
  disablingStakingReason: "",
  IsStakingCompound: false,
  estimateGas: 0,
  isTroveLiquidated: false,
  TroveLiquidatedDate: '',
  slippage: 0
};

export const strategyReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case ERROR_STRAT:
      return {
        ...state,
        err: payload,
      };
    case ISLOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ISMODALON:
      return {
        ...state,
        isModalOn: payload,
      };
    case MODALVIEW:
      return {
        ...state,
        modalView: payload,
      };
    case SELECTEDCOMPOUNDSTRATEGY:
      return {
        ...state,
        selectedCompoundStrategy: payload,
      };
    case SELECTEDCOMPOUNDSTRATEGYID:
      return {
        ...state,
        selectedCompoundStrategyID: payload,
      };
    case SELECTEDLOANCOMPOUNDINGTARGET:
      return {
        ...state,
        selectedLoanCompoundingTarget: payload,
      };
    case SELECTEDLOANCOMPOUNDINGTARGETID:
      return {
        ...state,
        selectedLoanCompoundingTargetID: payload,
      };
    case SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE:
      return {
        ...state,
        selectedLoanCompoundingTargetUsingTrove: payload,
      };
    case SELECTEDSTAKINGCOMPOUNDINGTARGETID:
      return {
        ...state,
        selectedStakingCompoundingTargetID: payload,
      };
    case SELECTEDSPCOMPOUNDINGTARGETID:
      return {
        ...state,
        selectedSPCompoundingTargetID: payload,
      };
    case SELECTEDSPCOMPOUNDINGTARGET:
      return {
        ...state,
        selectedSPCompoundingTarget: payload,
      };
    case SELECTEDSTAKINGCOMPOUNDINGTARGET:
      return {
        ...state,
        selectedStakingCompoundingTarget: payload,
      };
    case UPDATABLEKEY:
      return {
        ...state,
        updatableKey: payload,
      };
    case ISRATIOSHOWN:
      return {
        ...state,
        isRatioShown: payload,
      };
    case STRATEGYINSTANCE:
      return {
        ...state,
        strategyInstance: payload,
      };
    case ISCOMPOUND:
      return {
        ...state,
        IsCompound: payload,
      };
    case ISSTABILITYCOMPOUND:
      return {
        ...state,
        IsStabilityCompound: payload,
      };
    case ISSTAKINGCOMPOUND:
      return {
        ...state,
        IsStakingCompound: payload,
      };
    case STRATEGYADDRESS:
      return {
        ...state,
        strategyAddress: payload,
      };
    case TARGETCOLLATERALRATIO:
      return {
        ...state,
        targetCollateralRatio: payload,
      };
    case TRIGGERCOLLATERALRATIO:
      return {
        ...state,
        triggerCollateralRatio: payload,
      };
    case DISABLINGREASON:
      return {
        ...state,
        disablingReason: payload,
      };
    case DISABLINGSTABILITYREASON:
      return {
        ...state,
        disablingStabilityReason: payload,
      };
    case DISABLINGSTAKINGREASON:
      return {
        ...state,
        disablingStakingReason: payload,
      };
    case DISABLE:
      return {
        ...state,
        disable: payload,
      };
    case ISVALUESCHANGED:
      return {
        ...state,
        isValueChanged: payload,
      };
    case ESTIMATE_GAS:
      return {
        ...state,
        estimateGas: payload,
      };
    case IS_LIQUIDATE_TROVE:
      return {
        ...state,
        isTroveLiquidated: payload,
      };
    case LIQUIDATE_TROVE_DATE:
      return {
        ...state,
        TroveLiquidatedDate: payload,
      };
    case SLIPPAGE:
      return {
        ...state,
        slippage: payload,
      };
    default:
      return state;
  }
};
