import { SET_STAKE_ACTIVE_TAB, SET_STAKE_VALUES } from "../actions";

const initialState = {
  stakedLQTYInDollar: 0,
  liquidationEthGainInDollar: 0,
  lusdGainInDollar: 0,
  lusdGain: "0.000000",
  stakedLQTY: 0,
  liquidationEthGain: "0.000000",
  poolShare: "0.000000",
  newStakeLQTY: 0,
  lqtyInWalletDisplay: 0,
  lqtyInDollar: 0,
  TotalStake: 0,
  currentStakingTab: 1,
};

export const stakeReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case SET_STAKE_VALUES:
      return {
        ...state,
        ...payload,
      };
    case SET_STAKE_ACTIVE_TAB:
      return {
        ...state,
        currentStakingTab: payload,
      };
    default:
      return state;
  }
};
