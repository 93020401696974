import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const HeaderContainer = styled.header`
  padding: 20px 30px;
  position: sticky;
  top: 0;
  backdrop-filter: blur(8px);
  background: none;
  width: 100%;
  max-width: 1400px;
  z-index: 890;
  .walletNavButton {
    justify-content: end;
  }
  display: flex;
  .walletConBtnContainer {
    flex-direction: row;
    justify-content: start;
    align-items: center;
    display: inline-block;
    flex: 1 0 1px;
  }
  @media screen and (max-width: ${screenSizes.M}px) {
    .walletConBtnContainer {
      display: none;
    }
    .expanded.walletConBtnContainer {
      display: inline-block;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }
    flex-direction: column;
    justify-content: space-between;
    gap: 7em;
  }
  @media screen and (max-width: ${screenSizes.XS}px) {
    padding: 20px 30px 20px 10px;
  }
`;
export const NavContainer = styled.div`
  width: 100%;
  flex: 1 0 1px;
`;
export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  gap: 12px;
`;

interface ILogoCntrProps {
  isNavExpanded?: boolean;
}

export const LogoContainer = styled.div<ILogoCntrProps>`
  width: 190px;
  min-height: 50px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  img {
    width: 100%;
    height: 100%;
  }
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${screenSizes.M}px) {
    width: 140px;
    height: 28px;
  }
  z-index: ${(props: any) => (props.isNavExpanded ? "100" : "")};
`;

export const Navigations = styled.nav`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 1em;
  div {
    display: flex;
  }
  .wallet-info {
    display: flex;
    min-width: 150px;
    text-align: center;
    justify-content: space-between;
    padding: 10px;
  }
  .nav-btn {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    text-align: center;
    @media (max-width: ${screenSizes.L}px) {
      min-width: 100px;
    }
  }
  a {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    text-decoration: none;
    color: ${(props: any) => props.theme.navPrimaryText};
    padding: 15px 20px;
    margin: 0 10px;
  }
  .top-nav-btn > a {
    font-size: 16px;
    line-height: 21px;
  }
  .active-route {
    color: ${(props: any) => props.theme.navActiveText};
    padding: 15px 0;
  }
  .inactive-route {
    padding: 15px 0;
    opacity: 0.8;
  }
  .borderBottom {
    border-bottom: 2px solid #ff6fff;
    width: 15px;
    visibility: hidden;
    padding-top: 5px;
  }
  .borderTop {
    border-top: 4px solid #dfdfdf66;
    border-radius: 10px;
    transition: border-top ease-in-out 0.2s;
  }
  .active-route > div > .borderBottom {
    visibility: visible;
  }
  .active-route > div > .borderTop {
    border-color: #12f79c;
    animation: right-to-left 500ms linear;
  }
  @keyframes right-to-left {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  .hamburger {
    display: none;
    background: inherit;
    border: none;
  }
  @media screen and (max-width: ${screenSizes.M}px) {
    justify-content: space-between;
    .hamburger {
      display: block;
    }
    .navigation-menu {
      display: none;
    }
    .expanded {
      display: flex;
      height: 100vh;
      width: 100vw;
      background: ${(props: any) => props.theme.secondary};
      flex-direction: column;
      position: absolute;
      top: 0;
      left: -20%;
      align-items: start;
      .closeMenu {
        position: absolute;
        right: 0;
        top: 3em;
        background: inherit;
        border: none;
      }
      .walletNavButton {
        justify-content: end;
      }
      .nav-btn {
        align-items: start;
      }
    }
  }
  @media screen and (max-width: ${screenSizes.S}px) {
    .closeMenu {
      right: 3em;
    }
  }
`;
export const OpenStatsButton = styled.div`
  display: none;
  @media screen and (max-width: ${screenSizes.M}px) {
    display: block;
  }
`;
interface ICntrProps {
  isMbl?: boolean;
}
export const NavBalCntr = styled.div<ICntrProps>`
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  padding-right: 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  :nth-of-type(3) {
    border: none;
  }
  span:first-child {
    color: ${(props: any) => props.theme.white};
  }
  span:nth-child(2) {
    color: ${(props: any) => props.theme.pink2};
    font-weight: 900;
  }
  @media screen and (max-width: ${screenSizes.M}px) {
    display: ${(props: any) => (props.isMbl ? "flex" : "none !important")};
  }
`;

export const Disclamer = styled.div`
  padding: 1em;
  text-align: start;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 400;
  background: ${(props: any) => props.theme.secondary};
  opacity: 0.75;
  span {
    color: ${(props: any) => props.theme.white};
    font-size: 11px;
    width: inherit;
    a {
      color: ${(props: any) => props.theme.pink2};
    }
  }

  .checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
  }
  .checkbox label {
    position: relative;
  }
  .checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2;
    margin: 5px;
    width: 16px;
    height: 16px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 2px solid #ffffff;
  }
  .checkbox label:after {
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 12px;
    left: 10px;
  }
  .checkbox input[type="checkbox"]:checked ~ label::before {
    color: #7bbe72;
  }
  .checkbox input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .checkbox label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    /* display: block; */
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    vertical-align: sub;
  }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
  }
`;

export const DarkContainer = styled.div`
  margin: 8px 0;
  width: 100%;
`;

export const MetamaskIcon = styled.img`
  height: 25px;
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
`;
export const IconTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  cursor: pointer;
`;
export const ContentBody = styled.div`
  padding-top: 30px;
`;
export const NavItems = styled.div`
  display: flex;
  flex: 1 1 0px;
`;
export const NavTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  white-space: nowrap;
`;
interface IBoxProps {
  customGap?: string;
  minWidth?: string;
  maxWidth?: string;
  maxheight?: string;
  align?: string;
  customPadding?: string;
  custFlex?: string;
  heightM?: string;
  mblPad?: string;
  gapM?: string;
  tAlign?: string;
}
export const Sidebar = styled.div<IBoxProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props: any) => (props.customGap ? props.customGap : "0.5em")};
  align-items: ${(props: any) => props.align};
  padding: ${(props: any) => props.customPadding};
  text-align: ${(props: any) => props.tAlign};
  .amountContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  @media screen and (max-width: ${screenSizes.M}px) {
    position: relative;
    left: 25%;
  }
`;

export const TxHashSpan = styled.span`
  color: ${(props: any) => props.theme.white};
`;

export const IconTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    display: flex;
    align-items: center;
  }
`;
