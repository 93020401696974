import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PageContainer } from "shared/flexBox/style";
import { Loader } from "shared/loader/loader";
import { useNavigate } from "react-router-dom";
import { Main } from "./Main";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

/**
 * @returns According to User state it will return the open/view/edit pages
 */

export const TroveForm = () => {
  const { active, account } = useConnectWallet();
  const { isLoading } = useSelector((s: any) => s.notification);
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setTimeout(() => {
      setIsUpdating(false);
    }, 1000);
  }, [navigate]);

  if (isLoading || isUpdating)
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );

  return <PageContainer>{!active && !account ? navigate("/") : <Main />}</PageContainer>;
};
