import { useWeb3React } from "@web3-react/core";
import { LUSD_TOKEN_INSTANCE, SORTED_TROVES } from "blockchain/contract/instance";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { handleAdjustTrove, handleCancel, handleCloseTrove, handleOpenTrove, TX_HASH } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/button";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { ContainerBox, RowContainer, TooltipTop } from "shared/styled";
import { BtnContainer } from "../style";
import { Adjust } from "./Adjust";
import { Open } from "./Open";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface Iprops {
  changeCurrentTab?: any;
}

export const PrimaryBox = (props: Iprops) => {
  const { changeCurrentTab } = props;

  const globalSelector = useSelector((state: any) => state);
  const { BOTH, ETH, LUSD } = globalSelector.trove;
  const { mode, err } = BOTH;
  const { priceEthDisplay } = ETH;
  const { priceUSDLDisplay } = LUSD;

  let notifyText: any;

  const { ethToUSD } = useWallet();
  const [ethUSD, setEthUSD] = useState<any>(0);
  const [lusdUSD, setLusdUSD] = useState<any>(0);

  useEffect(() => {
    fetchNotification();
  }, [priceEthDisplay, priceUSDLDisplay]);

  const fetchNotification = async () => {
    const ethInDollar: any = await ethToUSD();
    const { lusdUSD: _lusdUSD } = await tokenToUSD();
    const temp_ETH = (Number(priceEthDisplay) && Number(ethInDollar)) ? Number(ethInDollar) * Number(priceEthDisplay) : 0.0
    const temp_LUSD = (_lusdUSD && Number(priceUSDLDisplay)) ? Number(_lusdUSD) * Number(priceUSDLDisplay) : 0.0
    setEthUSD(temp_ETH);
    setLusdUSD(temp_LUSD);
  };

  const ethlusdnotifyText =
    Number(priceEthDisplay) > 0 && Number(priceUSDLDisplay) > 0
      ? `You will deposit ${formatNumbersWithComma(priceEthDisplay)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(
        ethUSD,
        2,
      )}) and You will receive ${formatNumbersWithComma(priceUSDLDisplay)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(lusdUSD, 2)}) `
      : "";

  notifyText = mode === 0 && ethlusdnotifyText !== "" ? ethlusdnotifyText : "";

  return (
    <ContainerBox>
      {mode === 0 ? <Open /> : <Adjust changeCurrentTab={changeCurrentTab} />}
      {err ? <Notification message={err} type="error" /> : notifyText ? <Notification message={notifyText} type="error" /> : ""}
      <BtnContainer>
        <ActionButtons />
      </BtnContainer>
    </ContainerBox>
  );
};

const ActionButtons = () => {
  const { account } = useConnectWallet();
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const localTab = sessionStorage.getItem("currentAdjustTab");
  const globalSelector = useSelector((state: any) => state);
  const { userContractAddress } = globalSelector.navbar;
  const { BOTH, ETH, LUSD } = globalSelector.trove;
  const { mode, isAdjusting, IcollateralRatio, isMarketRecovery, err } = BOTH;
  const { priceEthDisplay } = ETH;
  const { priceUSDLDisplay, isAdjustSliderShown, totalDebt } = LUSD;
  const reduxDispatch = useDispatch();
  const canAdjustOrOpen = IcollateralRatio < (isMarketRecovery ? 150 : 110);
  const [active, setActive] = useState<number>(1);
  const [isSingleTrove, setIsSingleTrove] = useState<boolean>(false);
  const [isAllowance, setIsAllowance] = useState<boolean>(false);
  const [toCloseTrove, setToCloseTrove] = useState<any>(0);
  const { automaticLogOut, clearStorage } = useWallet();


  useEffect(() => {
    const _localTab = sessionStorage.getItem("currentAdjustTab");
    setIsSingleTroveCheck();
    convertUSD();
    setActive(_localTab ? Number(_localTab) : 1);
  }, [localTab, totalDebt]);

  useEffect(() => {
    if (active === 4) {
      checkAllowance();
    }
  }, [priceUSDLDisplay, active]);

  const convertUSD = async () => {
    const { lusdUSD } = await tokenToUSD();
    const temp_LUSD = Number(lusdUSD) * Number(totalDebt - 200);
    setToCloseTrove(temp_LUSD);
  }

  const _handleOpenTrove = async () => {
    await reduxDispatch(handleOpenTrove(startSpinner, stopSpinner, handleError, automaticLogOut, clearStorage));
  };

  const setIsSingleTroveCheck = async () => {
    try {
      const troves = await SORTED_TROVES.getSize();
      if (Number(troves) === 1) {
        setIsSingleTrove(true);
      } else {
        setIsSingleTrove(false);
      }
    } catch (err) {
      console.error(err);
      setIsSingleTrove(false);
    }
  };

  const _handleAdjustTrove = async () => {
    await reduxDispatch(handleAdjustTrove(startSpinner, stopSpinner, handleError, active, account));
  };

  const handleApprove = async () => {
    try {
      startSpinner();
      const amountWei = parseUnits(priceUSDLDisplay);
      const approve = await LUSD_TOKEN_INSTANCE.approve(userContractAddress, amountWei);
      reduxDispatch({
        type: TX_HASH,
        payload: approve.hash,
      });
      await approve.wait();
      setIsAllowance(true);
      stopSpinner();
    } catch (err) {
      console.error("handleApprove", err);
      handleError(err);
    }
  };

  const checkAllowance = async () => {
    try {
      const allowance = await LUSD_TOKEN_INSTANCE.allowance(account, userContractAddress);
      if (Number(formatEther(allowance)) >= Number(priceUSDLDisplay)) {
        setIsAllowance(true);
      } else {
        setIsAllowance(false);
      }
    } catch (err) {
      console.error("err", err);
      setIsAllowance(false);
    }
  };

  const _handleCloseTrove = async () => {
    await reduxDispatch(handleCloseTrove(startSpinner, stopSpinner, handleError, account));
  };


  switch (mode) {
    case 0: {
      return (
        <RowContainer>
          <Button
            btnType={"gradientFilledButton"}
            wrapperWidth="100%"

            onClick={() => _handleOpenTrove()}
            isDisabled={canAdjustOrOpen || BOTH?.err !== "" || (active === 1 || active === 2 ? !Number(priceEthDisplay) : !Number(priceUSDLDisplay))}
          >
            Confirm
          </Button>
        </RowContainer>
      );
    }
    case 1: {
      return (
        <>
          <RowContainer>
            {active === 4 && !isAllowance ? (
              <Button btnType={"gradientFilledButton"} className="btnWrapper" onClick={() => handleApprove()} isDisabled={!Number(priceUSDLDisplay) || priceUSDLDisplay === ""}>
                Approve
              </Button>
            ) : (
              <Button
                btnType={"gradientFilledButton"}
                className="btnWrapper"

                onClick={() => _handleAdjustTrove()}
                isDisabled={
                  BOTH?.err !== "" ||
                  isAdjusting ||
                  (active === 1 || active === 2 ? !Number(priceEthDisplay) : !Number(priceUSDLDisplay)) ||
                  (active === 3 && (isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown))) || (active === 2 && (isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown)))
                }
              >
                Confirm
              </Button>
            )}
            <Button btnType={"borderedfilledButton"} className="btnWrapper" onClick={() => reduxDispatch(handleCancel())}>
              Cancel
            </Button>
          </RowContainer>
          <RowContainer>
            {isSingleTrove ? (
              <TooltipTop fullWidth customWidth="15em" data-tooltip={"Single trove cannot be closed"}>
                <Button btnType={"borderedfilledButton"} wrapperWidth="100%" onClick={() => _handleCloseTrove()} isDisabled>
                  Close Trove
                </Button>
              </TooltipTop>
            ) : err === '' ? (
              <TooltipTop fullWidth data-tooltip={`To close a trove, ${formatNumbersWithComma(totalDebt - 200)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(toCloseTrove, 2)}) is required`}>
                <Button btnType={"borderedfilledButton"} wrapperWidth="100%" onClick={() => _handleCloseTrove()} isDisabled={isAdjusting}>
                  Close Trove
                </Button>
              </TooltipTop>
            ) :
              (
                <Button btnType={"borderedfilledButton"} wrapperWidth="100%" onClick={() => _handleCloseTrove()} isDisabled={isAdjusting}>
                  Close Trove
                </Button>
              )
            }
          </RowContainer>
        </>
      );
    }
    default:
      return <></>;
  }
};
