import { useWeb3React } from "@web3-react/core";
import { TROVE_MANAGER_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { ethers, BigNumber } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { initialValues, SET_BOTH_VALUES, SET_ETH_VALUES, SET_LUSD_VALUES } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useWallet } from "shared/hook/useWallet";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { InputBox, InputLabel, RowFlex } from "shared/styled";
import { CustomText, LightText } from "shared/Typography";
import { RangeInputContainer } from "../style";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Open = () => {
  const globalSelector = useSelector((state: any) => state);
  const { LUSD, BOTH, ETH } = globalSelector.trove;
  const { balance } = ETH;
  const { liquidationReserve, lusdInWalletDisplay } = LUSD;
  const { isMarketRecovery, IcollateralRatio } = BOTH;
  const dispatch = useDispatch();

  const [convertEthBalance, setConvertEthBalance] = useState<number>(0);
  const [convertLusdBalance, setConvertLusdBalance] = useState<number>(0);

  const [collateralInputValue, setCollateralInputValue] = useState<string>("0");
  const [debtInputValue, setDebtInputValue] = useState<string>("0");
  const [collRangeValue, setCollRangeValue] = useState<number>(0);
  const [debtRangeValue, setDebtRangeValue] = useState<number>(0);
  const [sliderMaxValue, setSliderMaxValue] = useState<number>(0);
  const [convertSliderMax, setConvertSliderMax] = useState<number>(0);
  const [ethInDollar, setEthInDollar] = useState<any>(0);
  const [isSliderShown, setIsSliderShown] = useState<boolean>(true);

  const debouncedCollateralInputValue = useDebounce(collateralInputValue, 300);
  const debouncedDebtInputValue = useDebounce(debtInputValue, 300);

  const { library, account }: any = useConnectWallet();
  const { ethToUSD } = useWallet();

  useEffect(() => {
    if (isMarketRecovery) {
      if (IcollateralRatio < 150) {
        setSliderMaxValue(1800);
        setDebtInputValue("1800");
        setDebtRangeValue(0);
      }
    } else {
      if (IcollateralRatio < 110) {
        setSliderMaxValue(1800);
        setDebtInputValue("1800");
        setDebtRangeValue(0);
      }
    }
  }, [isMarketRecovery, IcollateralRatio, collRangeValue]);

  useEffect(() => {
    if (Number(debouncedCollateralInputValue) === 0 && Number(debouncedDebtInputValue) === 0) {
      dispatch(initialValues(library, account, ethToUSD));
    } else {
      ethLoanInfo();
      debtLoanInfo();
    }
  }, [account, debouncedCollateralInputValue, debouncedDebtInputValue]);

  useEffect(() => {
    if (balance) {
      fetchBalance();
    }
  }, [balance, lusdInWalletDisplay]);

  useEffect(() => {
    if (debtRangeValue) {
      let percentToValue = (Number(debtRangeValue) * (Number(sliderMaxValue) - 1800)) / 100 + 1800;
      percentToValue = Math.trunc(percentToValue * Math.pow(10, 3)) / Math.pow(10, 3);
      setDebtInputValue(percentToValue.toString());
    }
  }, [sliderMaxValue, debtRangeValue]);

  const fetchBalance = async () => {
    try {
      const ethUSD = await ethToUSD();
      setEthInDollar(ethUSD);
      const ETH_TO_USD = ethUSD ? Number(balance) * Number(ethUSD) : 0.0;
      setConvertEthBalance(ETH_TO_USD);
      const { lusdUSD } = await tokenToUSD();
      const LUSD_TO_USD = lusdUSD ? Number(lusdInWalletDisplay) * Number(lusdUSD) : 0.0;
      setConvertLusdBalance(LUSD_TO_USD);
    } catch (e: any) {
      console.error(e);
    }
  };

  const checking = async (IcollateralRatio: any) => {
    let ErrorText;
    const { lusdUSD } = await tokenToUSD();
    if (Number(collateralInputValue) <= 0) {
      ErrorText = `Collateral ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} cannot be 0`;
    } else if (Number(debtInputValue) < 1800) {
      ErrorText = `You must borrow at least  1,800 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} (${formatNumbersWithComma(1800 * lusdUSD)} USD).`;
    } else if (Number(collateralInputValue) > Number(balance)) {
      let diffBal = (Number(collateralInputValue) - Number(balance)).toFixed(4);
      const USDBal: any = Number(diffBal) * Number(ethInDollar);
      ErrorText = `The amount you're trying to deposit exceeds your balance by ${formatNumbersWithComma(diffBal)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT
        }  (${formatNumbersWithComma(USDBal, 2)} USD)`;
    } else if (isMarketRecovery && Number(IcollateralRatio) < 150) {
      ErrorText = "You're not allowed to open a Trove with less than 150% Collateral Ratio during recovery mode. Please increase your Trove's Collateral Ratio.";
    } else if (!isMarketRecovery && Number(IcollateralRatio) < 110) {
      ErrorText = "You're not allowed to open a Trove with less than 110% Collateral Ratio. Please increase your Trove's Collateral Ratio.";
    } else {
      ErrorText = "";
    }
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        err: ErrorText,
      },
    });
  };

  const fetchSliderMaxValue = async (collDisplay: any) => {
    // sliderMaxValue
    const divValue = isMarketRecovery ? 150 : 110;
    const ethInDollar = await ethToUSD();
    const { lusdUSD } = await tokenToUSD();
    const collUSD = Number(collDisplay) * ethInDollar;
    const convertCollUSD = parseUnits(collUSD.toString());
    const _borrowingFee: any = await fetchBorrowingFeePercentage();
    const borrowFactor = BigNumber.from(ethers.constants.WeiPerEther).add(BigNumber.from(_borrowingFee));
    const liquidationReserve = ethers.utils.parseEther(`200`);
    let amountToBorrow: any;

    if (isMarketRecovery) {
      amountToBorrow = convertCollUSD.mul(100).div(divValue).sub(ethers.BigNumber.from("200000000000000000000"));
    } else {
      const maxDebt = convertCollUSD.mul(100).div(divValue);
      const maxDebtExcludingReserve = maxDebt.sub(liquidationReserve);
      amountToBorrow = maxDebtExcludingReserve.mul(ethers.constants.WeiPerEther).div(borrowFactor);
    }

    let _tempRemaining = Number(formatEther(amountToBorrow));
    _tempRemaining = Math.trunc(_tempRemaining * Math.pow(10, 3)) / Math.pow(10, 3);
    _tempRemaining = Number(_tempRemaining) > 0 ? Number(_tempRemaining) : 0;
    setSliderMaxValue(_tempRemaining);
    let Convert_SliderMax = lusdUSD && Number(_tempRemaining) > 0 ? Number(lusdUSD) * Number(_tempRemaining) : 0.0;
    setConvertSliderMax(Convert_SliderMax);
  };

  const fetchBorrowingFeePercentage = async () => {
    try {
      const rate = TROVE_MANAGER_INSTANCE.getBorrowingRateWithDecay();
      return rate;
    } catch (e: any) {
      console.log({ e: e.reason });
    }
  };
  const fetchBorrowingFee = async (value: any) => {
    try {
      let MAX_BORROWING_FEE = await TROVE_MANAGER_INSTANCE.getBorrowingFeeWithDecay(value);
      MAX_BORROWING_FEE = formatEther(MAX_BORROWING_FEE);
      return MAX_BORROWING_FEE;
    } catch (e: any) {
      console.log({ e: e.reason });
    }
  };

  const handleCollateralInput = async (e: any) => {
    const value = validateNumberInput(e)?.toString();
    const percentage = Number(value) > 0 ? ((Number(value) / Number(balance)) * 100).toFixed(0) : 0;
    if (Number(debtInputValue) < 1800) {
      setDebtInputValue("1800");
      setDebtRangeValue(0);
    }
    if (Number(value) <= Number(balance)) {
      setCollateralInputValue(value);
      setCollRangeValue(Number(percentage));
    }
    fetchSliderMaxValue(value);
  };

  const handleDebtInput = (e: any) => {
    let value = validateNumberInput(e)?.toString();
    const range = Number(sliderMaxValue) - 1800;
    const correctedStartValue = Number(value) - 1800;
    const percentage = (correctedStartValue * 100) / range;
    const tempPercentage = (Number(collateralInputValue) && Number(value)) > 0 ? percentage : 0;
    if (Number(value) === 0) {
      setDebtRangeValue(0);
    }
    if (isSliderShown && Number(value) <= Number(sliderMaxValue)) {
      setDebtInputValue(value);
      if (Number(value) >= 1800) {
        setDebtRangeValue(Math.round(tempPercentage));
      }
    } else if (!isSliderShown) {
      setDebtInputValue(value);
      setDebtRangeValue(Math.round(tempPercentage));
    }
  };

  const handleCollRangeInput = (e: any) => {
    let percentToValue: any = Number(balance) * (Number(e) / 100);
    percentToValue = percentToValue?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0];
    fetchSliderMaxValue(percentToValue);
    if (Number(debtInputValue) < 1800) {
      setDebtInputValue("1800");
    }
    setCollRangeValue(Number(e));
    setCollateralInputValue(percentToValue);
  };

  const handleDebtRangeInput = (e: any) => {
    const value = e.target.value;
    let percentToValue = (Number(value) * (Number(sliderMaxValue) - 1800)) / 100 + 1800;
    setDebtRangeValue(Number(value));
    percentToValue = Math.trunc(percentToValue * Math.pow(10, 3)) / Math.pow(10, 3);
    setDebtInputValue(percentToValue.toString());
  };

  const ethLoanInfo = () => {
    dispatch({
      type: SET_ETH_VALUES,
      payload: {
        priceEthDisplay: collateralInputValue,
        priceEth: collateralInputValue,
      },
    });
  };

  const debtLoanInfo = async () => {
    try {
      let _convertValue, _tempBorrowingFee, _tempTotalDebt;
      if (Number(debtInputValue) === 0) {
        _tempBorrowingFee = "0";
        _tempTotalDebt = liquidationReserve;
      } else {
        _convertValue = parseUnits(debtInputValue.toString(), "ether");
        _tempBorrowingFee = await fetchBorrowingFee(_convertValue);
        _tempTotalDebt = isMarketRecovery ? Number(debtInputValue) + Number(liquidationReserve) : Number(debtInputValue) + Number(liquidationReserve) + Number(_tempBorrowingFee);
      }
      bothLoanInfo(_tempTotalDebt);
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          priceUSDLDisplay: debtInputValue,
          priceUSDL: debtInputValue,
          borrowingFee: _tempBorrowingFee,
          totalDebt: _tempTotalDebt,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  const bothLoanInfo = (tempTotalDebt: any) => {
    let tempNormalLPrice, tempRecoveryLPrice, tempICollateralRatio;
    try {
      if (Number(collateralInputValue) > 0 && Number(debtInputValue) > 0) {
        tempNormalLPrice = (110 * Number(tempTotalDebt)) / (Number(collateralInputValue) * 100);
        tempRecoveryLPrice = (150 * Number(tempTotalDebt)) / (Number(collateralInputValue) * 100);
        let ratio = ((Number(collateralInputValue) * Number(ethInDollar)) / Number(tempTotalDebt)) * 100;
        tempICollateralRatio = ratio.toFixed(2);
      } else {
        tempICollateralRatio = "0";
        tempRecoveryLPrice = 0;
        tempNormalLPrice = 0;
      }
      checking(tempICollateralRatio);
      dispatch({
        type: SET_BOTH_VALUES,
        payload: {
          IcollateralRatio: tempICollateralRatio,
          recoveryLPrice: tempRecoveryLPrice,
          normalLPrice: tempNormalLPrice,
          isMarketRecovery: isMarketRecovery,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Collateral </span>
            <span className="text2">{envAllDescriptionDetails.VAULT_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(balance, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertEthBalance, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput placeholder={"Enter Amount"} value={collateralInputValue} icon={envAllDescriptionDetails.TROVE_LOGO} onChange={handleCollateralInput}></CustomInput>
        <RangePointerInput rangeValue={collRangeValue} rangeHandler={handleCollRangeInput} />
      </InputBox>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Debt </span>
            <span className="text2">{envAllDescriptionDetails.STABILITY_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {`${formatNumbersWithComma(lusdInWalletDisplay, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(convertLusdBalance, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput placeholder={"Enter Amount"} icon={envAllDescriptionDetails.STABILITY_LOGO} value={debtInputValue} onChange={handleDebtInput}></CustomInput>
        {Number(collateralInputValue) !== 0 && isSliderShown ? (
          <RangeInputContainer rangeValue={debtRangeValue}>
            <input
              type="range"
              value={debtRangeValue}
              onChange={isMarketRecovery ? (IcollateralRatio < 150 ? () => null : handleDebtRangeInput) : IcollateralRatio < 110 ? () => null : handleDebtRangeInput}
            />
            <RowFlex>
              <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                1800 {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
              </CustomText>
              <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                {isMarketRecovery ? (IcollateralRatio < 150 ? 1800 : sliderMaxValue || "Max") : IcollateralRatio < 110 ? 1800 : sliderMaxValue || "Max"}
                {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
              </CustomText>
            </RowFlex>
          </RangeInputContainer>
        ) : (
          ""
        )}
      </InputBox>
      <LightText>Excludes liquidation reserve which is reimbursed when closing the trove.</LightText>
    </>
  );
};
