import { useWeb3React } from "@web3-react/core";
import infoIcon from "assets/icons/info-icon-white.svg";
import { strategyPath } from "logic/paths";
import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompoundMemo } from "shared/compound/Compound";
import { CompoundStabilityNotExecuteMemo } from "shared/compound/CompoundStabilityNotExecute";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD } from "shared/helpers/utils";
import { ContainerBox, RightBoxCntr, RowFlex, SvgIcon, Tooltip } from "shared/styled";
import { LightText, LinkPara, Para } from "shared/Typography";
import { StabilityLoanCompoundingPath } from "./loanCompoundingPath/LoanCompoundingPath";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface Iprops {
  theme: any;
}

export const SecondaryBox = (props: Iprops) => {
  const { poolShare, stakedLUSD, stakedLUSDInDollar, newStakeLUSD, lqtyGain, lqtyGainInDollar, liquidationEthGain, liquidationEthGainInDollar, TotalStake } = useSelector(
    (state: any) => state.stability,
  );
  const { selectedCompoundStrategyID, selectedCompoundStrategy, disablingReason } = useSelector((s: any) => s.strategyRedu);
  const { isAutomation } = useSelector((state: any) => state.navbar);

  const navigate = useNavigate();
  const { library }: any = useConnectWallet();
  const { handleCompoundButton, handleCompoundStability } = useStrategy();

  const [newStakeInDollar, setNewStakeInDollar] = useState<number>(0);
  const [TotalStakeInDollar, setTotalStakeInDollar] = useState<number>(0);
  const localTab: any = sessionStorage.getItem("currentStabilityTab");
  const [active, setActive] = useState<number>(1);

  useEffect(() => {
    const _localTab = sessionStorage.getItem("currentStabilityTab");
    setActive(_localTab ? Number(_localTab) : 1);
    convertUSD();
  }, [library, newStakeLUSD, TotalStake, localTab]);

  const convertUSD = async () => {
    const { lusdUSD } = await tokenToUSD();
    const Convert_NewStakedBal = lusdUSD ? Number(lusdUSD) * Number(newStakeLUSD) : 0.0;
    const Convert_TotalStakedBal = lusdUSD ? Number(lusdUSD) * Number(TotalStake) : 0.0;
    setNewStakeInDollar(Convert_NewStakedBal);
    setTotalStakeInDollar(Convert_TotalStakedBal);
  };

  const tempDisabled = selectedCompoundStrategyID !== "2" && selectedCompoundStrategyID !== "3" && selectedCompoundStrategyID !== "5";
  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Stability pool" : disablingReason;
  const isNotExecuteStrategy = selectedCompoundStrategyID === "3" || selectedCompoundStrategyID === "5";

  return (
    <RightBoxCntr>
      <ContainerBox custFlex="0 1 0px" customGap={"0.5em"}>
        <LightText fOpacity="36%" fSize="10px">
          STABILITY POOL INFORMATION
        </LightText>
        <RowFlex>
          <LightText fSize="14px">{active === 1 ? "Your New Stake" : "Your Unstake"}</LightText>
          <Para tAlign="right" fWeight="500">
            {newStakeLUSD
              ? `${formatNumbersWithComma(newStakeLUSD, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(newStakeInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Your Existing Stakes</LightText>
          <Para tAlign="right" fWeight="500">
            {stakedLUSD
              ? `${formatNumbersWithComma(stakedLUSD, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(stakedLUSDInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Your Total Staking Balance</LightText>
          <Para tAlign="right" fWeight="500">
            {TotalStake
              ? `${formatNumbersWithComma(TotalStake, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(TotalStakeInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Pool Share</LightText>
          <Para tAlign="right" fWeight="500">
            {poolShare ? poolShare + "%" : "0%"}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Kickback Rate</LightText>
          <Para fWeight="500" tAlign="right">
            100%
          </Para>
        </RowFlex>
        {liquidationEthGain ? (
          <>
            <RowFlex>
              <LightText fSize="14px">{`${envAllDescriptionDetails.VAULT_TOKEN_TEXT} from Liquidations`}</LightText>
              <Para fWeight="500" tAlign="right">
                {`${Number(liquidationEthGain)
                  ? formatNumbersWithComma(liquidationEthGain, 6) +
                  " " +
                  envAllDescriptionDetails.VAULT_TOKEN_TEXT +
                  " " +
                  `($${formatNumbersWithComma(liquidationEthGainInDollar, 2)})`
                  : ` 0 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`
                  }`}
              </Para>
            </RowFlex>
            <RowFlex>
              <RowFlex align="center" customGap="5px">
                <LightText fSize="14px">Reward</LightText>
                <Tooltip customWidth="15em" customPad="0 10px 0 0" data-tooltip={envAllDescriptionDetails.REWARDS_TIP_TEXT}>
                  <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
                </Tooltip>
              </RowFlex>
              <Para fWeight="500" tAlign="right">
                {`${Number(lqtyGain)
                  ? formatNumbersWithComma(lqtyGain, 6) + " " + envAllDescriptionDetails.STAKING_TOKEN_TEXT + " " + `($${formatNumbersWithComma(lqtyGainInDollar, 2)})`
                  : `0 ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`
                  }`}{" "}
              </Para>
            </RowFlex>
          </>
        ) : (
          ""
        )}
        {selectedCompoundStrategy ? (
          <RowFlex>
            <LightText fSize="14px">Selected Compound Strategy</LightText>
            <LinkPara onClick={() => navigate(strategyPath)} tAlign="end" fWeight="500">
              {selectedCompoundStrategy}
            </LinkPara>
          </RowFlex>
        ) : (
          ""
        )}
        <RowFlex>
          <LightText fSize="14px">Autopilot Enabled?</LightText>
          <Para tAlign="end" fWeight="500">
            {isAutomation ? "Yes" : "No"}
          </Para>
        </RowFlex>
        <StabilityLoanCompoundingPath />
        <RowFlex>
          {isNotExecuteStrategy ? (
            <CompoundStabilityNotExecuteMemo tempDisabled={tempDisabled} buttonText="Compound Stability" handleCompoundStability={handleCompoundStability} />
          ) : (
            <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText="Compound Stability" handleCompoundButton={handleCompoundButton} />
          )}
        </RowFlex>
      </ContainerBox>
    </RightBoxCntr>
  );
};
