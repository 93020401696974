import { Route, Routes, Navigate } from "react-router-dom";
import { automationPath, loadingPath, privacyPath, redemptionPath, riskyVaultPath, rootPath, stabilityPoolPath, stakingPath, strategyPath, termservicePath, termsPath, trovePath } from "logic/paths";
import { withHeader } from "shared/hoc/withHeader";
import { Home } from "modules/pages/home";
import { TroveForm } from "modules/pages/trove";
import { StabilityForm } from "modules/pages/stability";
import { StakingForm } from "modules/pages/staking";
import { AutomationForm } from "modules/pages/automation";
import { StrategyForm } from "modules/pages/strategy";
import { PageNotFound } from "modules/pageNotFound/PageNotFound";
import { Redemption } from "modules/pages/redemption/Redemption";
import { RiskyVaults } from "modules/pages/riskyVaults/RiskyVaults";
import { Terms } from "modules/pages/terms/Terms";
import { Privacy } from "modules/pages/privacy/Privacy";
import { TermServices } from "modules/pages/termservices/TermServices";

const notFoundRoute: any = {
  path: "*",
  element: PageNotFound,
  protected: false,
  title: "Page Not Found",
};

export const routes: any[] = [
  {
    path: rootPath,
    element: Home,
    protected: false,
    title: "Home",
    redirect: Home,
    pathType: 0,
  },
  {
    path: strategyPath,
    element: StrategyForm,
    protected: false,
    title: "Strategy",
    redirect: StrategyForm,
    pathType: 0,
  },
  {
    path: trovePath,
    element: TroveForm,
    protected: false,
    title: "Dashboard",
    redirect: TroveForm,
    pathType: 0,
  },
  {
    path: stabilityPoolPath,
    element: StabilityForm,
    protected: false,
    title: "Stability Pool",
    redirect: StabilityForm,
    pathType: 0,
  },
  {
    path: automationPath,
    element: AutomationForm,
    protected: false,
    title: "Automation Form",
    redirect: AutomationForm,
    pathType: 0,
  },
  {
    path: stakingPath,
    element: StakingForm,
    protected: false,
    title: "Staking Pool",
    redirect: StakingForm,
    pathType: 0,
  },
  {
    path: redemptionPath,
    element: Redemption,
    protected: false,
    title: "Redemption",
    redirect: Redemption,
    pathType: 0,
  },
  {
    path: riskyVaultPath,
    element: RiskyVaults,
    protected: false,
    title: "RiskyVaults",
    redirect: RiskyVaults,
    pathType: 0,
  },
  {
    path: termsPath,
    element: Terms,
    protected: false,
    title: "Terms",
    redirect: Terms,
    pathType: 0,
  },
  {
    path: privacyPath,
    element: Privacy,
    protected: false,
    title: "Privacy",
    redirect: Privacy,
    pathType: 0,
  },
  {
    path: termservicePath,
    element: TermServices,
    protected: false,
    title: "TermServices",
    redirect: TermServices,
    pathType: 0,
  },
  notFoundRoute,
]
  .map(r => ({ ...r, element: withHeader(r.element) }))
  .concat(); // Ensure that notFound is the last route

interface RouteDefinition {
  path: string;
  protected?: boolean;
  redirect?: string;
  element?: any;
  routes?: RouteDefinition[];
  title?: string;
  requires?: any;
  pathType?: number;
}

export interface User {
  id: string;
}

function getRouteRenderWithAuth(isLoggedIn: boolean, route: any) {
  if (isLoggedIn === route.protected || !route.redirect) {
    const RouteComponent = route.requires ? route.requires(route.element) : route.element;
    return { element: <RouteComponent /> };
  } else {
    return { element: <Navigate replace to={route.redirect} /> };
  }
}

export const RoutesComponent = () => {
  const isLoggedIn = false;

  const mapRoutes = (route: RouteDefinition, i: number) => {
    const render = getRouteRenderWithAuth(isLoggedIn, route);
    return <Route key={i} path={route.path} {...render} />;
  };

  return <Routes>{routes.map(mapRoutes)}</Routes>;
};
