import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { envAllDescriptionDetails } from "shared/helpers/utils";
import { RowFlex } from "shared/styled";
import { LightText, Para } from "shared/Typography";

interface IProps {
  prefix?: string;
}
export const StabilityLoanCompoundingPath = (props: IProps) => {
  const { selectedCompoundStrategyID, selectedLoanCompoundingTargetID, selectedSPCompoundingTarget,selectedStakingCompoundingTarget } = useSelector((s: any) => s.strategyRedu);

  const [path, setPath] = useState<string>("");
  const [percentage, setPercentage] = useState<string>("");
  const { prefix } = props;
  const _data: any = JSON.parse(sessionStorage.getItem("FETCHED_USER_DATA") || "{}");
  const _customStrategies = _data?.loanCompoundingCustom?.filter((strategy:any)=>{
    return strategy?.loanCompoundingStrategies === "Top Up Stability Pool"
   })
   const _stakingCompoundingPath: any = sessionStorage.getItem("Top Up Stability Pool");

  useEffect(() => {
    CompoundingPath();
  }, [selectedCompoundStrategyID]);

  const CompoundingPath = () => {
    if (selectedCompoundStrategyID === "2") {
      setPath(envAllDescriptionDetails.STABILITY_LOAN_COMPOUNDING_MAILBOX);
    } else if (selectedCompoundStrategyID === "3") {
      setPath(envAllDescriptionDetails.STABILITY_LOAN_NOEXECUTE_COMPOUNDING_HALF_HALF);
    } else if (
      selectedCompoundStrategyID === "5" &&
      (selectedLoanCompoundingTargetID === "2" || selectedLoanCompoundingTargetID === "3"|| selectedLoanCompoundingTargetID === "5" || selectedLoanCompoundingTargetID === "7")
    ) {
      if (selectedSPCompoundingTarget) {
        setPath(selectedSPCompoundingTarget);
      }else if(selectedStakingCompoundingTarget){
        setPath(selectedStakingCompoundingTarget);
      } else {
        setPath(envAllDescriptionDetails.STABILITY_LOAN_COMPOUNDING_TOPUP_STABILITY);
      }
    } else if (selectedCompoundStrategyID === "5" && _customStrategies?.length > 0) {
      setPath(_stakingCompoundingPath)
      setPercentage(_customStrategies[0]?.inputPercentage);
    }
  };

  const isShow =
    selectedCompoundStrategyID === "2" ||
    selectedCompoundStrategyID === "3" ||
    (selectedCompoundStrategyID === "5" && (selectedLoanCompoundingTargetID === "2" || selectedLoanCompoundingTargetID === "3" || selectedLoanCompoundingTargetID === "5" || selectedLoanCompoundingTargetID === "7")) ||
    _customStrategies?.length > 0

  if (!isShow) {
    return <></>;
  }

  return (
    <>
      {percentage ? (
        <RowFlex>
          <LightText fSize="14px">{`${prefix ? prefix : ""} Compounding Allocation`}</LightText>
          <Para tAlign="end" fWeight="500" isSpace>
            {percentage}%
          </Para>
        </RowFlex>
      ) : (
        ""
      )}
      <RowFlex>
        <LightText fSize="14px">{`${prefix ? prefix : ""} Compounding Target`}</LightText>
        <Para tAlign="end" fWeight="500" isSpace>
          {path}
        </Para>
      </RowFlex>
    </>
  );
};
