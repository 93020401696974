import { ColFlex, ContainerBox, RowFlex } from "shared/styled";

import { useWeb3React } from "@web3-react/core";
import { trovePath } from "logic/paths";
import { initialValues } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CompoundMemo } from "shared/compound/Compound";
import { URL_READ_MORE_TROVE_SUMMARY, envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { TextBar } from "shared/textbar/TextBar";
import { Heading3 } from "shared/Typography";
import { TroveLoanCompoundingPath } from "modules/pages/trove/components/loanCompoundingPath/LoanCompoundingPath";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface IProps {
  handleCompoundButton: () => any;
}

export const TroveBox = (props: IProps) => {
  const { handleCompoundButton } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account, library } = useConnectWallet();
  const { ethToUSD } = useWallet();
  const [collateralUSD, setCollateralUSD] = useState<number>(0);
  const [debtUSD, setDebtUSD] = useState<number>(0);
  const { userContractAddress } = useSelector((s: any) => s.navbar);
  const { ETH, BOTH, LUSD } = useSelector((s: any) => s.trove);
  const { collDisplay } = ETH;
  const { IcollateralRatio } = BOTH;
  const { totalDebt } = LUSD;

  const { disablingReason, selectedCompoundStrategyID, selectedLoanCompoundingTargetUsingTrove, targetCollateralRatio, triggerCollateralRatio } = useSelector(
    (state: any) => state.strategyRedu,
  );

  useEffect(() => {
    if (userContractAddress) {
      dispatch(initialValues(library, account, ethToUSD));
    }
  }, [library, userContractAddress]);

  useEffect(() => {
    console.log({ totalDebt, collDisplay, IcollateralRatio }, "&&")
    fetchUSDValue();
  }, [totalDebt, collDisplay, IcollateralRatio]);

  const fetchUSDValue = async () => {
    const convertUSD = await ethToUSD();
    const { lusdUSD } = await tokenToUSD();
    const Convert_CollVal = (convertUSD && collDisplay) ? Number(convertUSD) * Number(collDisplay) : 0.0;
    const Convert_DebtVal = (lusdUSD && totalDebt) ? Number(lusdUSD) * Number(totalDebt) : 0.0;
    console.log({ convertUSD, lusdUSD, Convert_CollVal, Convert_DebtVal }, "&&")
    setCollateralUSD(Convert_CollVal);
    setDebtUSD(Convert_DebtVal);
  };

  const handleDeposite = () => {
    navigate(trovePath);
  };

  const tempDisabled =
    selectedCompoundStrategyID !== "1" &&
    selectedCompoundStrategyID !== "3" &&
    (selectedCompoundStrategyID !== "5" || (selectedCompoundStrategyID === "5" && !selectedLoanCompoundingTargetUsingTrove));
  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Trove." : disablingReason;

  return (
    <ContainerBox minWidth="555px" isDashboard="yes">
      <ColFlex customGap={"12px"}>
        <RowFlex align="center">
          <TextBar
            leftText="Trove/Vault"
            heading="yes"
            custId="troveTooltip"
            toolTip={envAllDescriptionDetails.TROVE_DASHBOARD_TIP_TEXT}
            link={URL_READ_MORE_TROVE_SUMMARY}
            linkText="Troves"
          />
          <Button btnType={"borderedfilledButton"} customWidth="9em" customHeight="35px" onClick={handleDeposite}>
            Details
          </Button>
        </RowFlex>
        <RowFlex>
          <div className="amountContainer">
            <img src={envAllDescriptionDetails.TROVE_LOGO} alt="logo-crypto" />
            <Heading3 fSizeM="16px">
              {formatNumbersWithComma(collDisplay, 3)} {envAllDescriptionDetails.VAULT_TOKEN_TEXT}
            </Heading3>
            <Heading3 fSizeM="16px">(${formatNumbersWithComma(collateralUSD, 2)})</Heading3>
          </div>
        </RowFlex>
        <ColFlex customGap=".5em">
          <TextBar
            marginBottom="1px"
            leftText="Collateral Ratio"
            custId="troveCRTooltip"
            toolTip={envAllDescriptionDetails.CR_TEXT}
            rightText={`${IcollateralRatio ? IcollateralRatio + "%" : "N/A"}`}
          />
          <TextBar
            marginBottom="1px"
            leftText="Debt"
            custId="troveDebtTooltip"
            toolTip={envAllDescriptionDetails.DEBT_TEXT}
            link={envAllDescriptionDetails.URL_DEBT_TIP_TEXT_RM}
            rightText={`${Number(totalDebt > 200)
              ? formatNumbersWithComma(totalDebt, 3) + " " + envAllDescriptionDetails.STABILITY_TOKEN_TEXT + " " + `($${formatNumbersWithComma(debtUSD, 2)})`
              : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`
              }`}
          />
          {selectedCompoundStrategyID === "1" || selectedCompoundStrategyID === "3" || (selectedCompoundStrategyID === "5" && selectedLoanCompoundingTargetUsingTrove) ? (
            <>
              <TextBar leftText="Target Collateral Ratio" rightText={`${Number(targetCollateralRatio) ? targetCollateralRatio : 0}%`} />
              <TextBar leftText="Trigger Collateral Ratio" rightText={`${Number(triggerCollateralRatio) ? triggerCollateralRatio : 0}%`} />
            </>
          ) : (
            ""
          )}
          <TroveLoanCompoundingPath />
        </ColFlex>
      </ColFlex>
      <RowFlex>
        <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText="Compound Debt" handleCompoundButton={handleCompoundButton} />
      </RowFlex>
    </ContainerBox>
  );
};
