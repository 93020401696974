import { ContainerBox, Tab, TabBox, TooltipTop } from "shared/styled";
import { RowContainer } from "../style";
import { Button } from "shared/button";

import { DISABLE_REASONS } from "shared/helpers/utils";
import { useEffect, useState } from "react";
import { Stake } from "./Stake";
import { UnStake } from "./UnStake";
import { useTransaction } from "shared/hook/useTransaction";
import { useDispatch, useSelector } from "react-redux";
import { setRefetchBalance, setTxHash } from "logic/redux/actions";
import { USER_INSTANCE } from "blockchain/contract/instance";

export const PrimaryBox = () => {
  const localTab = sessionStorage.getItem("currentStakingTab");
  const [active, setActive] = useState<number>(localTab ? Number(localTab) : 1);
  const [disableReason, setDisableReason] = useState<string>("");
  const { lusdGain, liquidationEthGain } = useSelector((state: any) => state.stake);
  const { refetchBalance } = useSelector((state: any) => state.navbar);

  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const dispatch = useDispatch();

  useEffect(() => {
    disablingReason();
  }, [lusdGain, liquidationEthGain]);

  const handleConfirmTabs = (current: number) => {
    setActive(current);
    sessionStorage.setItem("currentStakingTab", current.toString());
  };

  const handleClaimGains = async () => {
    try {
      startSpinner();
      const final_res = await USER_INSTANCE.unstake(0);
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      stopSpinner();
      dispatch(setRefetchBalance(!refetchBalance));
      disablingReason();
    } catch (error) {
      handleError(error);
    }
  };

  const disablingReason = async () => {
    try {
      const response = await USER_INSTANCE.callStatic.unstake(0);
      if (response[0].toString() === "0" && response[1].toString() === "0") {
        setDisableReason("You have no rewards to claim");
        return;
      }
    } catch (e: any) {
      setDisableReason(DISABLE_REASONS[e.reason]);
    }
  };

  return (
    <ContainerBox minWidth="555px">
      <TabBox>
        <Tab border={active === 1 ? "yes" : ""} onClick={() => handleConfirmTabs(1)}>
          Stake
        </Tab>
        <Tab border={active === 2 ? "yes" : ""} onClick={() => handleConfirmTabs(2)}>
          Unstake
        </Tab>
      </TabBox>
      {active === 1 ? <Stake /> : <UnStake />}
      <RowContainer>
        {disableReason === "" ? (
          <Button btnType={"borderedfilledLightButton"} onClick={handleClaimGains} wrapperWidth="100%" fSizeMobile="12px" isDisabled={disableReason !== ""}>
            Claim Rewards
          </Button>
        ) : (
          <TooltipTop fullWidth customWidth="15em" data-tooltip={disableReason}>
            <Button btnType={"borderedfilledLightButton"} onClick={handleClaimGains} wrapperWidth="100%" fSizeMobile="12px" isDisabled={disableReason !== ""}>
              Claim Rewards
            </Button>
          </TooltipTop>
        )}
      </RowContainer>
    </ContainerBox>
  );
};
