import { withTheme } from "styled-components";

import contractIcon from "assets/icons/contract.svg";
import exchangeIcon from "assets/icons/exchange.svg";
import infoIcon from "assets/icons/info-icon-white.svg";
import radioIcon from "assets/icons/radio.svg";

import { commonContractsDescription, envAllDescriptionDetails, formatNumbersWithComma, numberFormatter, tokenToUSD } from "shared/helpers/utils";

import { ColFlex, Divider, SvgIcon, Tooltip } from "shared/styled";

import { useWeb3React } from "@web3-react/core";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { TextBar } from "shared/textbar/TextBar";
import { CustomText, Heading3, LightText, Para } from "shared/Typography";

import axios from "axios";
import {
  ACTIVE_POOL_INSTANCE,
  envAllContractsDetails,
  LUSD_TOKEN_INSTANCE,
  PRICE_FEED_INSTANCE,
  SORTED_TROVES,
  STAKING_POOL_INSTANCE,
  TROVE_MANAGER_INSTANCE,
} from "blockchain/contract/instance";
import { GET_STRATEGIES_URL } from "shared/helpers/apis";
import { useWallet } from "shared/hook/useWallet";
import { CoinContainer, StatsContainerBox } from "../../style";
import { useSelector } from "react-redux";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface IStatsProps {
  theme?: any;
  hideHead?: boolean;
}

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const Stats = withTheme((props: IStatsProps) => {
  const [tvl, setTvl] = useState<any>("0");
  const [troves, setTroves] = useState<any>("0");
  const [lusdSupply, setLusdSupply] = useState<any>("0");
  const [recoveryModePrice, setRecoverModePrice] = useState<any>("$0");
  const [lusdInStability, setLusdInStability] = useState<any>("0");
  const [stakedLqty, setStakedLqty] = useState<any>("0");
  const [collRatio, setCollRatio] = useState<string>("0.00 %");
  const [recoveryMode, setRecoveryMode] = useState<string>("No");
  const [borrowRatePercentage, setBorrowRatePercentage] = useState<number>(0.5);
  const [ethereumPrice, setEthereumPrice] = useState<string>("$0.00");
  const [lqtyPrice, setLqtyPrice] = useState<number>(0.0);
  const [lusdPrice, setLusdPrice] = useState<number>(0.0);
  const [oraclePriceETH, setOracalePriceETH] = useState<string>("0.00");
  const [amplifyStatics, setAmplifyStatics] = useState<any>("{}");
  const [totalEthSupply, setTotalEthSupply] = useState<any>("0");
  const [totalLusdSupply, setTotalLusdSupply] = useState<any>("0");
  const [totalLqtySupply, setTotalLqtySupply] = useState<any>("0");
  const [tvlUSD, setTvlUSD] = useState<number>(0);
  const [totalEthUsdSupply, setTotalEthUsdSupply] = useState<any>("0");
  const [totalLusdUsdSupply, setTotalLusdUsdSupply] = useState<any>("0");
  const [totalLqtyUsdSupply, setTotalLqtyUsdSupply] = useState<any>("0");
  const [totalLusdPer, setTotalLusdPer] = useState<any>("0");
  const [totalLqtyPer, setTotalLqtyPer] = useState<any>("0");
  const [stabilityLusdSupplyPer, setStabilityLusdSupplyPer] = useState<any>("0");
  const [stakedLqtySupplyPer, setStakedLqtySupplyPer] = useState<any>("0");

  const [lusdInSupplyUSD, setLusdInSupplyUSD] = useState<number>(0);
  const [lusdInStabilityUSD, setLusdInStabilityUSD] = useState<number>(0);
  const [stakedLqtyUSD, setstakedLqtyUSD] = useState<number>(0);
  const { theme, hideHead } = props;
  const { account } = useConnectWallet();
  const { isFetched } = useSelector((state: any) => state.navbar);

  const LQTY_TOKEN_ADDRESS = envAllContractsDetails.LqtyToken[0];
  const LUSD_TOKEN_ADDRESS = envAllContractsDetails.LusdToken[0];

  const getLockedPercentage = () => {
    const _totalLusdSupply = Number(totalLusdSupply).toFixed(8);
    const _totalLqtySupply = Number(totalLqtySupply).toFixed(8);
    console.log({ stabilityLusdSupplyPer, stakedLqtySupplyPer });
    const stabilityPer = stabilityLusdSupplyPer !== "0" && totalLusdSupply !== "0" ? ((Number(_totalLusdSupply) / Number(stabilityLusdSupplyPer)) * 100).toFixed(3) : "0";
    setTotalLusdPer(stabilityPer);
    const stakingPer = stakedLqtySupplyPer !== "0" && totalLqtySupply !== "0" ? ((Number(_totalLqtySupply) / Number(stakedLqtySupplyPer)) * 100).toFixed(3) : "0";
    setTotalLqtyPer(stakingPer);
  };

  useEffect(() => {
    if (account) {
      let data: any = sessionStorage.getItem("stats");
      let price: any = sessionStorage.getItem("price");
      if (data) {
        data = JSON.parse(data);
        console.log({ data });
        setTroves(data.troves);
        setTvl(data.tvl);
        setLusdSupply(data.lusdSupply);
        setLusdInStability(data.lusdInStability);
        setStakedLqty(data.stakedLQTY);
        setCollRatio(data.colRatio);
        setRecoveryMode(data.recMode);
        setBorrowRatePercentage(data.borrowPerc);
        setRecoverModePrice(data.recoveryModePrice);
        setOracalePriceETH(data.oraclePrice);
        setTvlUSD(data.Convert_TVL);
        setLusdInSupplyUSD(data.Convert_lusdSupply);
        setLusdInStabilityUSD(data.Convert_lusdInStability);
        setstakedLqtyUSD(data.Convert_stakedLQTY);
        setTotalEthSupply(data?.totalEthSupply);
        setTotalLusdSupply(data?.totalLusdSupply);
        setTotalLqtySupply(data?.totalLqtySupply);
        setTotalEthUsdSupply(data?.totalEthUsdSupply);
        setTotalLusdUsdSupply(data?.totalLusdUsdSupply);
        setTotalLqtyUsdSupply(data?.totalLqtyUsdSupply);
        setAmplifyStatics(data?.amplifyStatics);
        setStabilityLusdSupplyPer(data?.stabilityLusdSupplyPer);
        setStakedLqtySupplyPer(data?.stakedLqtySupplyPer);
      }
      if (price) {
        price = JSON.parse(price);
        console.log({ eth: price?.ethPrice });
        setLqtyPrice(price.lqty);
        setLusdPrice(price.lusd);
        setEthereumPrice(price.ethPrice);
      }
    }
  }, [account, isFetched]);

  useEffect(() => {
    getLockedPercentage();
  }, [stakedLqtySupplyPer, stabilityLusdSupplyPer, totalLqtySupply, totalLusdSupply]);

  return (
    <StatsContainerBox mblPad="5px" minWidth="555px" customGap=".75em">
      {!hideHead && <Heading3 fSize="24px">Amplifier Statistics</Heading3>}
      <CoinContainer customPadding="10px 0">
        <CoinCard
          name={envAllDescriptionDetails.VAULT_TOKEN_TEXT}
          value={Number(oraclePriceETH).toLocaleString()}
          icon={envAllDescriptionDetails.TROVE_LOGO}
          isInfo
          isRadio
          oracle
          infoLink={Number(envChainId) === ChainId?.eth ? "https://www.coingecko.com/en/coins/ethereum" : "https://www.coingecko.com/en/coins/pulsechain"}
        />
        <CoinCard
          name={envAllDescriptionDetails.VAULT_TOKEN_TEXT}
          value={Number(ethereumPrice).toLocaleString()}
          icon={envAllDescriptionDetails.TROVE_LOGO}
          isInfo
          infoLink={Number(envChainId) === ChainId?.eth ? "https://www.coingecko.com/en/coins/ethereum" : "https://www.coingecko.com/en/coins/pulsechain"}
        />
        <CoinCard
          name={envAllDescriptionDetails.STAKING_TOKEN_TEXT}
          value={Number(lqtyPrice).toLocaleString()}
          icon={envAllDescriptionDetails.STAKING_LOGO}
          isInfo
          isExchange
          isContract
          left
          coinAddres={LQTY_TOKEN_ADDRESS}
        // infoLink={"https://www.coingecko.com/en/coins/liquity"}
        />
        <CoinCard
          name={envAllDescriptionDetails.STABILITY_TOKEN_TEXT}
          value={Number(lusdPrice).toLocaleString()}
          icon={envAllDescriptionDetails.STABILITY_LOGO}
          isInfo
          isExchange
          isContract
          left
          coinAddres={LUSD_TOKEN_ADDRESS}
        // infoLink={"https://www.coingecko.com/en/coins/liquity-usd"}
        />
      </CoinContainer>
      <Divider />
      <ColFlex tAlign="start" customGap="12px">
        <LightText fLetterSpace="0.5px" fOpacity="70%" fLineHeight="24px" fSize="16px" fWeight="600">
          {envAllDescriptionDetails.STATS_HEADING}
        </LightText>
        <ColFlex>
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="BORROWING_FEE_TEXT"
            toolTip={envAllDescriptionDetails.BORROWING_FEE_TEXT}
            leftText="Borrowing Fee"
            rightText={(borrowRatePercentage || "") + "%"}
          />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="TVL_TEXT"
            toolTip={envAllDescriptionDetails.TVL_TEXT}
            leftText="TVL"
            rightText={`${tvl} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(tvlUSD, 2)})`}
          />
          <TextBar tAlign="right" marginBottom="1px" custId="TROVES_TEXT" toolTip={commonContractsDescription.TROVES_TEXT} leftText="Troves" rightText={troves} />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="LUSD_SUPPLY_TEXT"
            toolTip={envAllDescriptionDetails.SUPPLY_TEXT}
            leftText={`${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} Supply`}
            rightText={`${lusdSupply} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(lusdInSupplyUSD, 2)})`}
          />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="LUSD_IN_POOL_TEXT"
            toolTip={envAllDescriptionDetails.POOL_TEXT}
            leftText={`${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} in Stability Pool`}
            rightText={`${lusdInStability} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(lusdInStabilityUSD, 2)})`}
          />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="STAKED_LQTY_TEXT"
            toolTip={envAllDescriptionDetails.STAKED_TEXT}
            leftText={`Staked ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
            rightText={`${stakedLqty} ${envAllDescriptionDetails.STAKING_TOKEN_TEXT} ($${formatNumbersWithComma(stakedLqtyUSD, 2)})`}
          />
          <TextBar tAlign="right" marginBottom="1px" custId="TCR_TEXT" toolTip={envAllDescriptionDetails.TCR_TEXT} leftText="Total Collateral Ratio" rightText={collRatio} />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="RECOVERY_MODE_TEXT"
            toolTip={commonContractsDescription.RECOVERY_MODE_TEXT}
            leftText="Recovery Mode"
            rightText={recoveryMode}
            rightTextColor={theme.primaryButton}
          />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            custId="RECOVERY_MODE_PRICE_THRESHHOLD"
            toolTip={envAllDescriptionDetails.RECOVERY_MODE_PRICE_THRESHHOLD}
            leftText="Recovery Mode Price Threshold"
            rightText={recoveryModePrice}
          />
          <TextBar tAlign="right" marginBottom="1px" custId="KICKBACK_FEE" toolTip={envAllDescriptionDetails.KICKBACK_FEE} leftText="Kickback Rate" rightText={"100%"} />
          <TextBar
            tAlign="right"
            marginBottom="1px"
            leftText="POWERCITY Fee"
            custId="POWERCITY_FEE"
            toolTip={envAllDescriptionDetails.POWERCITY_FEE_TEXT}
            rightText="-"
            link={commonContractsDescription.URL_POWERCITY_FEE_TEXT_RM}
          />
        </ColFlex>
      </ColFlex>
      <Divider />
      <ColFlex tAlign="start" customGap="12px">
        <LightText fLetterSpace="0.5px" fOpacity="70%" fLineHeight="24px" fSize="16px" fWeight="600">
          Amplifier Statistics
        </LightText>
        <ColFlex>
          <TextBar
            custId={`Total Value Locked in ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
            leftText={`Total Value Locked in ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
            tAlign="end"
            rightText={`${numberFormatter(totalEthSupply, 4)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(totalEthUsdSupply, 2)})`}
          />
          <TextBar
            tAlign="end"
            custId={`Total Value Locked in ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
            leftText={`Total Value Locked in ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
            rightText={`${numberFormatter(totalLusdSupply, 4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(totalLusdUsdSupply, 2)})`}
          />
          <TextBar
            tAlign="end"
            custId={`Total Value Locked in ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
            leftText={`Total Value Locked in ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
            rightText={`${numberFormatter(totalLqtySupply, 4)} ${envAllDescriptionDetails.STAKING_TOKEN_TEXT} ($${formatNumbersWithComma(totalLqtyUsdSupply, 2)})`}
          />
          <TextBar custId="stabilityPer" leftText="Total % of stability pool" rightText={`${totalLusdPer}%`} tAlign="end" />
          <TextBar custId="stakedPer" leftText="Total % of staking pool" rightText={`${totalLqtyPer}%`} tAlign="end" />
          <TextBar custId="AUTOPILOT_TYPE" leftText="Total contracts/wallets on Autopilot" rightText={amplifyStatics?.isAutomatedCount} tAlign="end" />
          <TextBar custId="PLUSE_STRATEGY_TYPE" leftText="Total contracts/wallets by PulseStacker strategy type" rightText={amplifyStatics?.pulseStacker} tAlign="end" />
          <TextBar custId="MAILBOX_STRATEGY_TYPE" leftText="Total contracts/wallets by MailBoxMoney strategy type" rightText={amplifyStatics?.mailBox} tAlign="end" />
          <TextBar custId="HALF_STRATEGY_TYPE" leftText="Total contracts/wallets by HalfAndHalf strategy type" rightText={amplifyStatics?.halfnHalf} tAlign="end" />
          <TextBar custId="PASSIVE_STRATEGY_TYPE" leftText="Total contracts/wallets by PassivePulse strategy type" rightText={amplifyStatics?.passivePulse} tAlign="end" />
          <TextBar custId="CUSTOM_STRATEGY_TYPE" leftText="Total contracts/wallets by Custom strategy type" rightText={amplifyStatics?.custom} tAlign="end" />
          <TextBar custId="NO_STRATEGY_TYPE" leftText="Total contracts/wallets by No strategy type" rightText={amplifyStatics?.noStrategy} tAlign="end" />
        </ColFlex>
      </ColFlex>
    </StatsContainerBox>
  );
});
interface ICoinCardProps {
  value?: string;
  name?: string;
  icon?: any;
  isRadio?: boolean;
  isExchange?: boolean;
  isContract?: boolean;
  isInfo?: boolean;
  left?: boolean;
  coinAddres?: string;
  oracle?: boolean;
  infoLink?: string;
}
const CoinCard = (props: ICoinCardProps) => {
  const { name, value, icon, isInfo, isRadio, isContract, isExchange, left, coinAddres, oracle, infoLink } = props;
  return (
    <ColFlex align="center">
      <ColFlex align="center">
        <SvgIcon heightM="30px" height="36px" src={icon} alt="logo" />
        <CustomText fSizeM="15px" fLineHeight="25px">
          {name}
        </CustomText>
      </ColFlex>
      <ColFlex align="center" customGap="0">
        <Para fSizeM="12px" fcolor="#fff" fSize="16px" fLineHeight="23px">
          ${value}
        </Para>
        <Para fSizeM="12px" fcolor="#fff">
          {oracle ? "Oracle Price" : "Market Price"}
        </Para>
      </ColFlex>
      <CoinContainer gapM="8px" customGap=".5em">
        {isInfo && (
          <Tooltip left={left ? "-18em" : ""} customWidth="15em" data-tooltip={"Coingecko Information"}>
            <a href={infoLink} target="_blank" rel="noreferrer">
              <SvgIcon heightM="14px" src={infoIcon} alt="info-icon" />
            </a>
          </Tooltip>
        )}

        {isRadio && (
          <Tooltip left={left ? "-18em" : ""} customWidth="15em" data-tooltip={`Chainlink ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}/USD Price Feed`}>
            <a href="https://data.chain.link/ethereum/mainnet/crypto-usd/eth-usd" target="_blank" rel="noreferrer">
              <SvgIcon heightM="14px" src={radioIcon} alt="radio-icon" />
            </a>
          </Tooltip>
        )}

        {isContract && (
          <Tooltip left={left ? "-18em" : ""} customWidth="15em" data-tooltip={"Contract Address"}>
            <a
              href={Number(envChainId) === ChainId?.eth ? `https://goerli.etherscan.io/address/${coinAddres}` : `https://scan.v4.testnet.pulsechain.com/address/${coinAddres}`}
              target="_blank"
              rel="noreferrer"
            >
              <SvgIcon heightM="14px" src={contractIcon} alt="contract-icon" />
            </a>
          </Tooltip>
        )}

        {isExchange && (
          <Tooltip left={left ? "-18em" : ""} customWidth="15em" data-tooltip={Number(envChainId) === ChainId?.eth ? "Swap on Uniswap" : "Swap on PulseX"}>
            <a
              href={Number(envChainId) === ChainId?.eth ? "https://app.uniswap.org/#/swap" : `https://v1-app.v4.testnet.pulsex.com/swap?outputCurrency=${coinAddres}`}
              target="_blank"
              rel="noreferrer"
            >
              <SvgIcon heightM="14px" src={exchangeIcon} alt="exchange-icon" />
            </a>
          </Tooltip>
        )}
      </CoinContainer>
    </ColFlex>
  );
};
