import styled from "styled-components";


interface Iprops{
  type?:string 
}

export const ErrorContainer = styled.div<Iprops>`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  border: 1px solid rgba(249, 250, 251, 0.2);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  background: rgba(0, 139, 139, 0.17);
  width: 100%;
  max-width: 100%;
  gap: 5px;
`;

export const Error = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  overflow: auto;
`;