import { UrlPath, Uuid } from "shared/helpers/utils";

export interface UserProps {
  user: string;
}
export interface UserUrlProps extends UserProps {
  organization: Uuid;
}

// Basic
export const rootPath: UrlPath<{}> = "/";
export const riskyVaultPath: UrlPath<{}> = "/riskyVaults";
export const redemptionPath: UrlPath<{}> = "/redemption";
export const homePath: UrlPath<{}> = "/home";
export const strategyPath: UrlPath<{}> = "/strategy";
export const trovePath: UrlPath<{}> = "/trove";
export const stabilityPoolPath: UrlPath<{}> = "/stability";
export const stakingPath: UrlPath<{}> = "/staking";
export const automationPath: UrlPath<{}> = "/automation";
export const userPath: UrlPath<UserUrlProps> = `${homePath}/user/:user`;
export const loadingPath: UrlPath<{}> = "/loaderio-9a118c25e0578cf572663370eaa32f89/";
export const termsPath:UrlPath<{}> = '/terms'
export const privacyPath:UrlPath<{}> = '/privacy'
export const termservicePath:UrlPath<{}> = '/termservice'

