import { SwitchContainer } from "./style";

interface IButtonProps {
    isEnabled: boolean
    handleAutomationChange: any
    isDisabled?: boolean
}

export const ToggleSwitch = (props: IButtonProps) => {
    const {isEnabled, handleAutomationChange, isDisabled} = props

    return (
        <SwitchContainer>
            <input type="checkbox" checked={isEnabled} disabled={isDisabled} onChange={handleAutomationChange} />
            <span className="switch" />
        </SwitchContainer>
    );
};