import {Tooltip} from "react-tooltip";
import styled from "styled-components";
import { colors } from "styles/theme";
export const TipText = styled.p`
  > a {
    color: ${colors.pink} !important;
  }
`;

export const CustomToolTip = styled(Tooltip)<any>`
background-color: black;
opacity: 1;
z-index: 10000;
width:150px;
isolation:isolate;
`;
