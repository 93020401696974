import { FlexContainer, TroveContainer } from "../style";

import { useWeb3React } from "@web3-react/core";
import { clearInputs } from "logic/redux/actions";
import { PageTitle } from "modules/app/pageTitle/PageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  envAllDescriptionDetails, URL_READ_MORE_TROVE_SUMMARY
} from "shared/helpers/utils";
import { Loader } from "shared/loader/loader";
import { CommonContainer } from "shared/styled";
import { PrimaryBox } from "../components/PrimaryBox";
import { SecondaryBox } from "../components/SecondaryBox";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Main = () => {
  const globalSelector = useSelector((state: any) => state);
  const { BOTH } = globalSelector.trove;
  const { refetchBalance } = globalSelector.navbar;
  const { isLoading, mode } = BOTH;
  const dispatch = useDispatch();
  const { library } = useConnectWallet()

  const [currentTab, setCurrentTab] = useState<number>(1);

  const changeCurrentTab = (tab: number) => {
    setCurrentTab(tab);
    sessionStorage.setItem("currentAdjustTab", tab.toString());
  };

  useEffect(() => {
    if (mode === 1) {
      dispatch(clearInputs());
    }
  }, [library, currentTab, refetchBalance]);

  if (isLoading) return <Loader />;

  return (
    <>
      <FlexContainer>
        <CommonContainer>
          <PageTitle
            readMoreURL={URL_READ_MORE_TROVE_SUMMARY}
            bodyText={envAllDescriptionDetails.TROVE_SUMMAR_TEXT}
            readMoreText="Learn More"
            justify="flex-start"
          />
          <TroveContainer>
            <PrimaryBox changeCurrentTab={changeCurrentTab} />
            <SecondaryBox />
          </TroveContainer>
        </CommonContainer>
      </FlexContainer>
    </>
  );
};
