import { useWeb3React } from "@web3-react/core";
import { USER_INSTANCE } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { formatEther, parseEther, parseUnits } from "ethers/lib/utils";
import { DISABLE, setRefetchBalance, setStabilityValues, setTxHash, stabilityInitialValues } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CustomInput } from "shared/customInput";
import { DISABLE_REASONS, envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { InputBox, InputLabel, RowContainer, TooltipTop } from "shared/styled";

export const UnStake = () => {
  const [amount, setAmount] = useState<string>("");
  const [notifyText, setNotifyText] = useState<string>("");
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [disableCallReason, setDisableCallReason] = useState<string>("");
  const globalSelector = useSelector((state: any) => state);
  const { refetchBalance } = globalSelector.navbar;
  const { stakedLUSD, stakedLUSDInDollar } = globalSelector.stability;
  const { account, library }: any = useConnectWallet();

  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ethToUSD } = useWallet();
  const debouncedAmount = useDebounce(amount, 300);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [navigate])

  useEffect(() => {
    if (debouncedAmount === 0) {
      dispatch(stabilityInitialValues(ethToUSD, account));
    } else {
      setPoolShareVal(amount);
      checking();
    }
  }, [library, debouncedAmount]);

  const disablingReason = async (_amount: any) => {
    try {
      const convertAmt = parseEther(_amount.toString());
      await USER_INSTANCE.callStatic.withdrawFromSP(convertAmt.toString());
    } catch (e: any) {
      setDisableCallReason(e.reason)
    }
  }

  const checking = async () => {
    const { lusdUSD } = await tokenToUSD();
    const AMOUNT_TO_USD = lusdUSD ? Number(amount) * Number(lusdUSD) : 0.0;
    let tempText = `You are withdrawing ${formatNumbersWithComma(amount, 4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(AMOUNT_TO_USD, 2)})`;
    if (Number(stakedLUSD) < Number(amount)) {
      const diffBalance = (Number(amount) - Number(stakedLUSD)).toFixed(4);
      const convertDiffBalance = ((Number(amount) - Number(stakedLUSD)) * lusdUSD).toFixed(2);
      tempText = `The amount you're trying to withdraw exceeds your staked balance by ${formatNumbersWithComma(diffBalance, 4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT
        } (${formatNumbersWithComma(convertDiffBalance)} USD)`;
    }
    setNotifyText(tempText);
  };

  const handleClearStates = () => {
    setAmount("");
    setNotifyText("");
    setRangeValue(0);
  };

  const handleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = validateNumberInput(e)?.toString();
    if (stakedLUSD > 0 && Number(val) <= Number(stakedLUSD)) {
      let percentage = ((val / Number(stakedLUSD)) * 100).toFixed(0);
      setAmount(val);
      setRangeValue(Number(percentage));
      disablingReason(val);
      return;
    } else if (!val) {
      setAmount(val);
      setRangeValue(0);
      return;
    }
  };

  const removeLUSDFromStabilityPool = async () => {
    try {
      const convertAmt = parseEther(amount);
      startSpinner();
      const estimateGas_final_res = await USER_INSTANCE.estimateGas.withdrawFromSP(convertAmt.toString());
      const bufferedGas_final_res = Number(estimateGas_final_res.toString()) + Number(estimateGas_final_res.toString()) * 0.5;
      const final_res = await USER_INSTANCE.withdrawFromSP(convertAmt.toString(), { gasLimit: bufferedGas_final_res.toFixed(0) });
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      dispatch(setRefetchBalance(!refetchBalance));
      stopSpinner();
      dispatch(stabilityInitialValues(ethToUSD, account));
      handleClearStates();
    } catch (error) {
      handleError(error);
      handleClearStates();
    }
  };

  const setPoolShareVal = async (val: any) => {
    let stakingAmount, poolShareVal, poolShare_, newStakes;
    const [totalLUSDInDeposits, userLUSDInDeposits] = await Promise.all([USER_INSTANCE?.getTotalLUSDDeposits(), USER_INSTANCE?.getCompoundedLUSDDeposit(), tokenToUSD()]);
    if (amount) {
      stakingAmount = parseUnits(amount.toString(), "ether");
      newStakes = userLUSDInDeposits.sub(stakingAmount).mul(100);
      const total = totalLUSDInDeposits.sub(stakingAmount);
      poolShareVal = Number(formatEther(newStakes)) / Number(formatEther(total));
    } else {
      const userStakes = userLUSDInDeposits.mul(100);
      poolShareVal = Number(formatEther(userStakes)) / Number(formatEther(totalLUSDInDeposits));
    }
    poolShare_ = Number(poolShareVal).toFixed(6);
    const finalPS = poolShareVal > 100 ? 100 : poolShare_;
    const totalStake = (stakedLUSD - Number(amount)).toFixed(3);
    dispatch(
      setStabilityValues({
        poolShare: poolShare_ ? finalPS : 0,
        newStakeLUSD: Number(amount),
        TotalStake: Number(totalStake),
      }),
    );
  };

  const disabledReason = Number(stakedLUSD) < Number(amount) || amount === "" || Number(amount) <= 0;

  const rangeHandler = (prop: any) => {
    if (stakedLUSD > 0) {
      setRangeValue(prop);
      let percentToValue: any = Number(stakedLUSD) * (Number(prop) / 100);
      percentToValue = percentToValue?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0];
      setAmount(percentToValue);
      disablingReason(percentToValue);
    }
  };

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Unstaking </span>
            <span className="text2">{envAllDescriptionDetails.STABILITY_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {`${formatNumbersWithComma(stakedLUSD, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(stakedLUSDInDollar, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput placeholder={"Enter Amount"} icon={envAllDescriptionDetails.STABILITY_LOGO} value={amount} onChange={(e: any) => handleInput(e)}></CustomInput>
        <RangePointerInput rangeValue={rangeValue} rangeHandler={rangeHandler} />
      </InputBox>
      {amount !== "" && Number(amount) > 0 && <Notification message={notifyText} type="error" />}
      <RowContainer>
        {
          disableCallReason !== "" ?
            <TooltipTop fullWidth customWidth="15em" data-tooltip={disableCallReason}>
              <Button btnType={"gradientFilledButton"} wrapperWidth="100%" fSizeMobile="12px" onClick={() => removeLUSDFromStabilityPool()} isDisabled={disableCallReason !== ""}>
                Confirm
              </Button>
            </TooltipTop>
            :
            <Button btnType={"gradientFilledButton"} wrapperWidth="100%" fSizeMobile="12px" onClick={() => removeLUSDFromStabilityPool()} isDisabled={disabledReason}>
              Confirm
            </Button>
        }

      </RowContainer>
    </>
  );
};

