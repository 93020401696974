import failedImg from 'assets/icons/failed.svg';
import spinnerIcon from "assets/icons/spinner.svg";
import successfulImg from 'assets/icons/successful.svg';
import { useContext } from 'react';
import { TransactionContext } from "logic/context/context";


export const useTransaction = () => {

  const { dispatchTransactionContextEvents }: any = useContext(TransactionContext)

  const startSpinner = () => {
    dispatchTransactionContextEvents('START_SPINNER', {
      transactionHeading: "Confirming Transaction",
      transactionImage: spinnerIcon
    })
  }

  const stopSpinner = () => {
    dispatchTransactionContextEvents('STOP_SPINNER', {
      transactionHeading: "Transaction Successful",
      transactionImage: successfulImg
    })
  }

  const authFailed = ()=>{
    dispatchTransactionContextEvents('STOP_SPINNER', {
      transactionHeading: "Please Signin!",
      transactionImage: failedImg
    })
  }

  const handleError = (error: any) => {    
    if (
      error.code === 4001 ||
      error.code === -32000 || "ACTION_REJECTED"
    ) {
      dispatchTransactionContextEvents('STOP_SPINNER', {
        transactionHeading: "Transaction Rejected",
        transactionImage: failedImg
      })
    } else {
      dispatchTransactionContextEvents('STOP_SPINNER', {
        transactionHeading: "Transaction Failed",
        transactionImage: failedImg
      })

    }
  }

  return {
    handleError,
    startSpinner,
    stopSpinner,
    authFailed
  }

}
