import { ContainerBox, Tab, TabBox, TooltipTop } from "shared/styled";
import { RowContainer } from "../style";
import { Button } from "shared/button";

import { envAllDescriptionDetails, DISABLE_REASONS } from "shared/helpers/utils";
import { useEffect, useState } from "react";
import { Stake } from "./Stake";
import { UnStake } from "./UnStake";
import { useTransaction } from "shared/hook/useTransaction";
import { useDispatch, useSelector } from "react-redux";
import { setRefetchBalance, setTxHash } from "logic/redux/actions";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { useWeb3React } from "@web3-react/core";
import { USER_INSTANCE } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const PrimaryBox = () => {
  const localTab = sessionStorage.getItem("currentStabilityTab");
  const [active, setActive] = useState<number>(localTab ? Number(localTab) : 1);
  const [disableClaimReason, setDisableClaimReason] = useState<string>("");
  const [disableMoveEthReason, setDisableMoveEthReason] = useState<string>("");
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const { account }: any = useConnectWallet();
  const dispatch = useDispatch();
  const randomNum = (Math.random() * 999).toFixed(0).toString();
  const { refetchBalance } = useSelector((state: any) => state.navbar);

  const { lqtyGain, liquidationEthGain } = useSelector((state: any) => state.stability);

  useEffect(() => {
    disablingReason();
  }, [account, lqtyGain, liquidationEthGain]);

  const handleConfirmTabs = (current: number) => {
    setActive(current);
    sessionStorage.setItem("currentStabilityTab", current.toString());
  };

  const handleClaimEthAndLQTY = async () => {
    try {
      startSpinner();
      const estimateGas = await USER_INSTANCE?.estimateGas.withdrawFromSP(0);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      const final_res = await USER_INSTANCE?.withdrawFromSP(0, {
        gasLimit: bufferedGas.toFixed(0),
      });
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      stopSpinner();
      dispatch(setRefetchBalance(!refetchBalance));
      disablingReason();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClaimLqtyAndMoveEth = async () => {
    try {
      startSpinner();
      const debtColl = await USER_INSTANCE?.getDepositorETHGain();
      const entireDebt = await USER_INSTANCE.getEntireDebtAndColl();
      const debt = entireDebt[0];
      const colEth = entireDebt[1];
      const amountEth = Number(formatEther(debtColl)) + Number(formatEther(colEth));
      const [upperHint, lowerHint] = await USER_INSTANCE.getHintAddresses(parseUnits(amountEth.toString(), "ether"), debt, randomNum);
      const estimateGas = await USER_INSTANCE?.estimateGas.withdrawETHGainToTrove(upperHint, lowerHint);
      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
      const resp = await USER_INSTANCE.withdrawETHGainToTrove(upperHint, lowerHint, { gasLimit: bufferedGas.toFixed(0) });
      dispatch(setTxHash(resp.hash));
      await resp.wait();
      stopSpinner();
      dispatch(setRefetchBalance(!refetchBalance));
      disablingReason();
    } catch (error) {
      handleError(error);
    }
  };

  const disablingReason = async () => {
    try {
      await USER_INSTANCE?.callStatic.withdrawFromSP(0);
    } catch (e: any) {
      console.log(e);
      setDisableClaimReason(DISABLE_REASONS[e.reason]);
    }
    try {
      const debtColl = await USER_INSTANCE?.getDepositorETHGain();
      const entireDebt = await USER_INSTANCE.getEntireDebtAndColl();
      const debt = entireDebt[0];
      const colEth = entireDebt[1];
      const amountEth = Number(formatEther(debtColl)) + Number(formatEther(colEth));
      const [upperHint, lowerHint] = await USER_INSTANCE.getHintAddresses(parseUnits(amountEth.toString(), "ether"), debt, randomNum);
      await USER_INSTANCE.callStatic.withdrawETHGainToTrove(upperHint, lowerHint);
    } catch (e: any) {
      setDisableMoveEthReason(DISABLE_REASONS[e.reason]);
    }
  };

  return (
    <ContainerBox minWidth="555px">
      <TabBox>
        <Tab border={active === 1 ? "yes" : ""} onClick={() => handleConfirmTabs(1)}>
          Stake
        </Tab>
        <Tab border={active === 2 ? "yes" : ""} onClick={() => handleConfirmTabs(2)}>
          Unstake
        </Tab>
      </TabBox>
      {active === 1 ? <Stake /> : <UnStake />}
      <RowContainer isMakeCol>
        {disableClaimReason !== "" ? (
          <TooltipTop fullWidth customWidth="15em" data-tooltip={disableClaimReason}>
            <Button btnType={"borderedfilledLightButton"} onClick={handleClaimEthAndLQTY} wrapperWidth="100%" isDisabled={disableClaimReason !== ""} fSizeMobile="12px">
              Claim Rewards
            </Button>
          </TooltipTop>
        ) : (
          <Button btnType={"borderedfilledLightButton"} onClick={handleClaimEthAndLQTY} wrapperWidth="100%" isDisabled={disableClaimReason !== ""} fSizeMobile="12px">
            Claim Rewards
          </Button>
        )}
        {disableMoveEthReason !== "" ? (
          <TooltipTop fullWidth customWidth="15em" data-tooltip={disableMoveEthReason}>
            <Button btnType={"borderedfilledLightButton"} onClick={handleClaimLqtyAndMoveEth} isDisabled={disableMoveEthReason !== ""} wrapperWidth="100%" fSizeMobile="12px">
              {envAllDescriptionDetails.STABILITY_CLAIM_MOVE_REWARD}
            </Button>
          </TooltipTop>
        ) : (
          <Button btnType={"borderedfilledLightButton"} onClick={handleClaimLqtyAndMoveEth} isDisabled={disableMoveEthReason !== ""} wrapperWidth="100%" fSizeMobile="12px">
            {envAllDescriptionDetails.STABILITY_CLAIM_MOVE_REWARD}
          </Button>
        )}
      </RowContainer>
    </ContainerBox>
  );
};
