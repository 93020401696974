import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { PageContainer } from "shared/flexBox/style";
import { Loader } from "shared/loader/loader";
import { Deposit } from "./Deposit";
import { useNavigate } from "react-router-dom";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const StakingForm = () => {
    const { isLoading } = useSelector((s: any) => s.notification);

    const { active, account } = useConnectWallet();

    const [isUpdating, setIsUpdating] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
        setTimeout(() => {
            setIsUpdating(false)
        }, 1000);
    }, [navigate])

    if (isLoading || isUpdating) return <PageContainer>
        <Loader />
    </PageContainer>

    return (
        <PageContainer>
            {!active && !account ? (
                navigate('/')
            ) : <Deposit />
            }
        </PageContainer>
    )
}
