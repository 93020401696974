import { useRef, useState } from "react";
import { Button } from "shared/button";
import CustomModal from "shared/customModal";
import { RowContainer } from "shared/styled";
import { TextBar } from "shared/textbar/TextBar";
import { LightText } from "shared/Typography";

interface IProps {
  heading?: string;
  buttonText?: string;
  text1: string;
  text2: string;
  text3: string;
  show: boolean;
  setShow: any;
  action: any;
  disable?: boolean;
}
export const ConfirmationPopup = (props: IProps) => {
  const { heading, text1, text2, text3, show, setShow, action, disable, buttonText } = props;

  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const listInnerRef: any = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const sh = listInnerRef?.current?.scrollHeight;
      const st = listInnerRef?.current?.scrollTop;
      const ht = listInnerRef?.current?.offsetHeight;
      if (ht === 0 || st + 10 > sh - ht) {
        setIsEnabled(true);
      } else if (isEnabled) {
        setIsEnabled(false);
      }
    }
  };

  const handleToggle = () => {
    setShow(false);
    setIsEnabled(false);
    listInnerRef.current?.firstElementChild?.scrollIntoView();
  };

  return (
    <CustomModal
      show={show}
      toggleModal={() => {
        handleToggle();
      }}
      custMinHeight="50%"
      titlePadding="0"
    >
      {heading && <TextBar leftText={heading} heading="yes" custId={heading} />}
      <ul id="Lists" onScroll={() => onScroll()} ref={listInnerRef}>
        <li>
          <LightText>{text1}</LightText>
        </li>
        <li>
          <LightText>{text2}</LightText>
        </li>
        <li>
          <LightText>{text3}</LightText>
        </li>
      </ul>
      <RowContainer  justify="flex-start">
        <Button
          customWidth="9.5em"
          btnType={"borderedfilledLightButton"}
          isDisabled={disable || !isEnabled}
          onClick={() => {
            setShow(false);
            action();
          }}
        >
          {buttonText ? buttonText : "Confirm"}
        </Button>
        {heading && (
          <Button customWidth="9.5em" btnType={"borderedfilledLightButton"} onClick={() => setShow(false)}>
            Cancel
          </Button>
        )}
      </RowContainer>
    </CustomModal>
  );
};
