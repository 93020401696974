import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    @media (max-width: ${screenSizes.M}px) {
        align-items: center;
    }
`
export const PoolContainer = styled.div`
    display: flex;
    gap: 1.5em;
    @media (max-width: ${screenSizes.M}px) {
        flex-direction: column;
    }
    
    @media (max-width: ${screenSizes.XS}px) {
        max-width: 350px;
    }
    @media (max-width: ${screenSizes.XXS}px) {
        max-width: 310px;
    }
    animation: 3s forwards, fade-in 700ms forwards;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        50% {
            opacity: .25;
        }
        100% {
            opacity: 1;
        }
    }
  
`

export const BtnsContainer = styled.div`
    display: flex; 
    gap: 1em;
    flex-direction: column;
    padding-top: 15px;
`
export const RowContainer = styled.div`
    display: flex; 
    width: 100%;
    gap: 1.5em;
    justify-content: center;
    animation: fade-in 500ms forwards;

    @keyframes fade-in {
        0% {
        opacity: 0;
        }
        50% {
        opacity: .5;
        }
        100% {
        opacity: 1;
        }
    }
`