import { RowFlex, SvgIcon } from "shared/styled";
import { Heading3, LightText, Para } from "shared/Typography";
import infoIconWhite from "assets/icons/info-icon-white.svg";
import { CustomToolTip, TipText } from "./style";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface ITextBarProps {
  leftText?: string;
  rightText?: any;
  rightTextColor?: string;
  toolTip?: string;
  custId?: string;
  link?: string;
  heading?: string;
  linkText?: string;
  fontSize?: string;
  fOpacity?: string;
  marginBottom?: string;
  tAlign?: string;
}
export const TextBar = (props: ITextBarProps) => {
  const { heading, leftText, rightText, rightTextColor, toolTip = "", custId = "", link = "", linkText, fontSize, fOpacity, marginBottom, tAlign } = props;

  const [isHover, setIsHover] = useState<boolean>(false);
  const tooltipPosition = useRef<DOMRect>();
  const element = useRef<HTMLDivElement>();

  // useEffect(() => {
  //   if (toolTip) {
  //     // @ts-ignore
  //     tooltipPosition.current = element?.current.getBoundingClientRect();
  //     // @ts-ignore
  //     document?.getElementById("iconWrapper").addEventListener("scroll", (e: any) => {
  //       // @ts-ignore
  //       tooltipPosition.current = element?.current.getBoundingClientRect();
  //     });
  //     document?.getElementById("iconWrapper")?.addEventListener("touchmove", (e: any) => {});
  //   }
  // }, [toolTip]);

  return (
    <RowFlex>
      <RowFlex align="center" customGap="5px">
        {heading ? (
          <Heading3 fSizeM="18px" fSize={fontSize ? fontSize : "24px"}>
            {leftText}
          </Heading3>
        ) : (
          <LightText fLineHeight="20px" fOpacity={fOpacity ? fOpacity : "54%"} fSize={fontSize ? fontSize : "14px"}>
            {leftText}
          </LightText>
          
        )}

        {toolTip && (
          <>
            <div
              id={custId}
              // onMouseEnter={() => setIsHover(true)}
              // onMouseLeave={() => setIsHover(false)}
              data-for={custId}
              data-tip
              data-iscapture="true"
              style={{
                marginTop: heading ? "2px" : "",
                marginLeft: heading ? "2px" : "",
                // marginRight: "1em"
              }}
              // ref={el => {
              //   if (!el) return;
              //   element.current = el;
              // }}
            >
              <SvgIcon marginBottom={marginBottom} height={heading ? "15px" : "10px"} src={infoIconWhite} alt="logo" />
            </div>
           {createPortal(
            <CustomToolTip
                  // className="extraClass"
                  // @ts-ignore
                  // style={{ top: tooltipPosition.current?.top + 7 }}
                  anchorSelect={`#${custId}`}
                  clickable
                  place="bottom"
                  type="dark"
                  backgroundColor="#0D0E21"
                >
                   <TipText>
                      {toolTip + " "}
                      {link && (
                        <a target="_blank" href={link} rel="noreferrer">
                          Click here{" "}
                        </a>
                      )}
                      {link ? (linkText ? `to learn more about ${linkText}` : "to learn more") : ""}
                    </TipText>
                </CustomToolTip>
                ,
                document.body,
              )}
          </>
        )}
      </RowFlex>
      {"  "}
      <Para tAlign={tAlign ? tAlign : 'end'} fLineHeight="20px" fWeight="500" fcolor={rightTextColor} isSpace>
        {rightText}
      </Para>
    </RowFlex>
  );
};
