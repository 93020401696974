import { useWeb3React } from "@web3-react/core";
import { envAllContractsDetails, HELPERS_INSTANCE, LUSD_TOKEN_INSTANCE, SORTED_TROVES, TROVE_MANAGER_INSTANCE, UNISWAP_ROUTER_INSTANCE } from "blockchain/contract/instance";
import { ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { setRefetchBalance, stabilityInitialValues } from "logic/redux/actions";
import { PageTitle } from "modules/app/pageTitle/PageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CustomInput } from "shared/customInput";
import { DISABLE_REASONS, envAllDescriptionDetails, formatNumbersWithComma, isValid, tokenToUSD, URL_READ_MORE_REDEMPTION_SUMMARY, validateNumberInput } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { Loader } from "shared/loader/loader";
import { Notification } from "shared/notification";
import { RowFlex, SvgIcon, TooltipTop } from "shared/styled";
import { TextBar } from "shared/textbar/TextBar";
import { Heading3, LightText, Para } from "shared/Typography";
import { BreakPoint, InputBreakPoints, RangeInputContainer, RangeToolTip } from "../trove/style";
import { Container, RedemptionBox, SpanTags } from "./style";
import { useRedemption } from "./useRedemption";
import infoIcon from "assets/icons/info-icon-white.svg";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";


const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const Redemption = () => {
  const { redemption } = useRedemption();
  const dispatch = useDispatch();
  const { account, active, library } = useConnectWallet();
  const navigate = useNavigate();
  const { ethToUSD } = useWallet();
  const globalSelector = useSelector((state: any) => state);
  const { refetchBalance, userContractAddress } = globalSelector.navbar;
  const { lusdInWalletDisplay } = globalSelector.stability;
  const [amount, setAmount] = useState<number | string>("");
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [hoverActive, setHoverActive] = useState<boolean>(false);
  const [rangeValueViaAmountChange, setRangeValueViaAmountChange] = useState<number | any>();
  const [ethChange, setEthChange] = useState<any>(0);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [enableNotification, setEnableNotification] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const [maxRedeemdAmountInDollar, setMaxRedeemdAmountInDollar] = useState<any>(0);
  const [maxRedemeedAmountInDisplay, setMaxRedemeedAmountInDisplay] = useState<any>(0);

  const handleInput = (e: any) => {
    if (isValid(e.target.value)) {
      const val = validateNumberInput(e);
      if (Number(val) <= Number(maxRedemeedAmountInDisplay) && Number(val) >= 0 && val) {
        const _rangeValue = (Number(val) / Number(maxRedemeedAmountInDisplay)) * 100;
        setRangeValueViaAmountChange(_rangeValue);
        setAmount(val);
        setIsDisable(false);
        setEnableNotification(false);
        setErrorMsg("");
        return;
      } else if (!val) {
        setAmount(val);
        setRangeValueViaAmountChange(0);
        setRangeValue(0);
        setIsDisable(true);
        setEnableNotification(false);
        setErrorMsg("");
        return;
      }
    }
  };

  useEffect(() => {
    redemeedAmount();
  }, [lusdInWalletDisplay]);

  const redemeedAmount = async () => {
    try {
      const ethInDollar = await ethToUSD();
      const { lusdUSD } = await tokenToUSD();
      const maxRedemeedAmount = await HELPERS_INSTANCE?.getRedemptionHints(
        parseUnits(lusdInWalletDisplay.toString()),
        parseUnits(ethInDollar.toString()), // customPrice
        0,
      );
      const _redemeedAmount: any = maxRedemeedAmount[2].sub(1).toString();
      const etherMaxRedemeedAmount = Number(_redemeedAmount) / 10 ** 18;

      const _tempRedemeed = parseInt("" + Number(etherMaxRedemeedAmount) * 1000) / 1000;
      const Convert_LUSD = lusdUSD ? Number(lusdUSD) * Number(_tempRedemeed) : 0.0;
      setMaxRedemeedAmountInDisplay(maxRedemeedAmount[2].toString() === "0" ? "0.00" : _tempRedemeed);
      setMaxRedeemdAmountInDollar(maxRedemeedAmount[2].toString() === "0" ? "0.00" : Convert_LUSD);
    } catch (e: any) {
      console.log(e);
    }
  };

  const checkingRedemption = async () => {
    try {
      const troves = await SORTED_TROVES?.getSize();
      const randNum = (Math.random() * 100).toFixed(0);
      const numTroves: any = ethers.BigNumber.from(troves);
      const ethInDollar = await ethToUSD();
      const numTrials = ethers.BigNumber.from(Math.sqrt(numTroves).toFixed(0).toString()).mul(ethers.BigNumber.from("15"));

      const {
        0: firstRedemptionHint,
        1: partialRedemptionNewICR,
        2: truncatedLUSDAmount,
      } = await HELPERS_INSTANCE?.getRedemptionHints(
        parseUnits(amount.toString()),
        parseUnits(ethInDollar.toString()), // customPrice
        0,
      );

      if (!Number(partialRedemptionNewICR?.toString()) || !Number(truncatedLUSDAmount?.toString())) {
        return 0;
      }

      const { 0: approxPartialRedemptionHint } = await HELPERS_INSTANCE.getApproxHint(partialRedemptionNewICR.toString(), numTrials, randNum);
      const exactPartialRedemptionHint: any = await SORTED_TROVES.findInsertPosition(partialRedemptionNewICR, approxPartialRedemptionHint, approxPartialRedemptionHint);
      const lusdBalance = await LUSD_TOKEN_INSTANCE?.balanceOf(account);
      const entireDebt = await TROVE_MANAGER_INSTANCE.getEntireSystemDebt();

      const baseRate = await TROVE_MANAGER_INSTANCE.baseRate();
      const MINIMUM_REDEMPTION_RATE = "500000000000000000";
      const _tempMin = baseRate.add(MINIMUM_REDEMPTION_RATE);
      const _tempMinConvert = formatEther(_tempMin);
      const minRedemptionRate = Math.min(Number(_tempMinConvert), 1);
      const maxRedemptionRate = minRedemptionRate + 0.001;
      const convertTruncatedAmt = formatEther(truncatedLUSDAmount);
      const maxRedemptionRateOrDefault = maxRedemptionRate !== undefined ? maxRedemptionRate : Math.min(Number(convertTruncatedAmt) + 0.001, 1);
      const maxFee = parseUnits(maxRedemptionRateOrDefault.toString());

      if (lusdBalance.gt(entireDebt)) {
        setIsDisable(true);
        setEnableNotification(true);
        setErrorMsg("Redeemer LUSD balance must be less than the total LUSD supply");
      } else {
        await TROVE_MANAGER_INSTANCE.callStatic.redeemCollateral(
          truncatedLUSDAmount,
          firstRedemptionHint,
          exactPartialRedemptionHint[0],
          exactPartialRedemptionHint[1],
          partialRedemptionNewICR,
          0,
          maxFee,
          { from: account },
        );
        setIsDisable(false);
        setEnableNotification(false);
        setErrorMsg("");
      }
    } catch (e: any) {
      setIsDisable(true);
      setEnableNotification(true);
      // setErrorMsg(DISABLE_REASONS[e.reason]);
      setErrorMsg(e.reason);
    }
  };

  const redemptionCall = async () => {
    try {
      setIsDisable(true);
      await redemption(`${amount}`);
      setEnableNotification(false);
      setErrorMsg("");
      setIsDisable(false);
      dispatch(setRefetchBalance(!refetchBalance));
      setRangeValue(0);
      setRangeValueViaAmountChange(0);
      setAmount("");
    } catch (err) {
      setIsDisable(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setTimeout(() => {
      setIsUpdating(false);
    }, 1000);
  }, [navigate]);

  useEffect(() => {
    if (userContractAddress) {
      dispatch(stabilityInitialValues(ethToUSD, account));
    }
  }, [library, userContractAddress, refetchBalance]);

  useEffect(() => {
    if (ethChange !== 0) {
      convertUSD();
    }
  }, [ethChange]);

  const convertUSD = async () => {
    const USDValue: any = await ethToUSD();
    const _tempEthChange = Number(ethChange) * 0.005;
    const Convert_ETHBal = USDValue ? Number(USDValue) * Number(_tempEthChange) : 0.0;
    setConvertEthBalance(Convert_ETHBal.toFixed(2));
  };

  useEffect(() => {
    if (Number(amount)) {
      checkingRedemption();
      const LUSD_TOKEN_ADDRESS = envAllContractsDetails.LusdToken[0];
      const WrappedETH = Number(envChainId) === ChainId?.eth ? "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6" : "0x8a810ea8b121d08342e9e7696f4a9915cbe494b7";
      setTimeout(async () => {
        const amWei = parseUnits(amount.toString());
        const price = await UNISWAP_ROUTER_INSTANCE?.getAmountsOut(amWei.toString(), [LUSD_TOKEN_ADDRESS, WrappedETH]);
        const ethPrice1 = formatEther(price[1]);
        setEthChange(Number(ethPrice1).toFixed(6));
      }, 300);
    } else {
      setEthChange(0);
      setConvertEthBalance(0);
    }
  }, [amount]);

  useEffect(() => {
    setRangeValueViaAmountChange(0);
    const newAmount: number = Number(maxRedemeedAmountInDisplay) * (Number(rangeValue) / 100);
    if (newAmount) setIsDisable(false);
    else setIsDisable(true);
    setAmount(newAmount ? newAmount : "");
    setEnableNotification(false);
    setErrorMsg("");
  }, [rangeValue]);

  const rangeHandler = (prop: any) => {
    if (maxRedemeedAmountInDisplay > 0) {
      setRangeValue(prop);
    }
  };

  const hoverFunction = (toggle: boolean) => {
    setHoverActive(toggle);
  };

  if (isUpdating || (!account && sessionStorage.getItem("walletConnected"))) {
    return <Loader />;
  }

  return (
    <>
      {!active && !account ? (
        navigate("/")
      ) : (
        <Container>
          <PageTitle justify="flex-start" readMoreURL={URL_READ_MORE_REDEMPTION_SUMMARY} bodyText={envAllDescriptionDetails.REDEMPTION_TEXT} readMoreText=" Read more about Redemption" />
          <RedemptionBox>
            <RowFlex>
              <span></span>
              {/* <span style={{ color: "#ffffff8a" }}>
                Collateral:
                <span style={{ color: "#ffff" }}> {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}</span>
              </span> */}
              <span style={{ color: "#ffffff8a", textAlign: 'right' }}>
                Max Redeemable:{" "}
                <span style={{ color: "#ffff" }}>
                  {" "}
                  {`${formatNumbersWithComma(maxRedemeedAmountInDisplay, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(
                    maxRedeemdAmountInDollar,
                    2,
                  )})`}
                </span>
              </span>
            </RowFlex>
            <CustomInput placeholder={"Enter Amount"} value={amount} icon={envAllDescriptionDetails.STABILITY_LOGO} onChange={(e: any) => handleInput(e)} />
            <RangeInputContainer
              id="inputRange"
              rangeValue={Number(rangeValueViaAmountChange) ? rangeValueViaAmountChange : rangeValue}
              onMouseEnter={() => hoverFunction(true)}
              onMouseLeave={() => hoverFunction(false)}
            >
              {!Number(rangeValueViaAmountChange) ? (
                <>
                  <InputBreakPoints onClick={() => rangeHandler("25")} left={"25%"} hide={rangeValue >= 22 && rangeValue <= 26} />
                  <InputBreakPoints onClick={() => rangeHandler("50")} left={"50%"} hide={rangeValue >= 48 && rangeValue <= 52} />
                  <InputBreakPoints onClick={() => rangeHandler("75")} left={"75%"} hide={rangeValue >= 73 && rangeValue <= 77} />
                  <InputBreakPoints onClick={() => rangeHandler("100")} right={"-2px"} hide={rangeValue >= 98 && rangeValue <= 100} />
                </>
              ) : (
                <>
                  <InputBreakPoints onClick={() => rangeHandler("25")} left={"25%"} hide={rangeValueViaAmountChange >= 22 && rangeValueViaAmountChange <= 26} />
                  <InputBreakPoints onClick={() => rangeHandler("50")} left={"50%"} hide={rangeValueViaAmountChange >= 48 && rangeValueViaAmountChange <= 52} />
                  <InputBreakPoints onClick={() => rangeHandler("75")} left={"75%"} hide={rangeValueViaAmountChange >= 73 && rangeValueViaAmountChange <= 77} />
                  <InputBreakPoints onClick={() => rangeHandler("100")} right={"-2px"} hide={rangeValueViaAmountChange >= 98 && rangeValueViaAmountChange <= 100} />
                </>
              )}
              <BreakPoint left={"23%"}>25%</BreakPoint>
              <BreakPoint left={"48%"}>50%</BreakPoint>
              <BreakPoint left={"73%"}>75%</BreakPoint>
              <BreakPoint right={"-2px"}>100%</BreakPoint>
              <input
                type="range"
                max={100}
                min={"0"}
                value={Number(rangeValueViaAmountChange) ? rangeValueViaAmountChange : rangeValue}
                onChange={(e: any) => {
                  rangeHandler(e.target.value);
                }}
                step="1"
              />
              <RangeToolTip left={`${Number(rangeValueViaAmountChange) ? rangeValueViaAmountChange : rangeValue}`} hoverActive={hoverActive}>
                {Number(rangeValueViaAmountChange) ? Number(rangeValueViaAmountChange).toFixed(0) + "%" : Number(rangeValue).toFixed(0) + "%"}
              </RangeToolTip>
            </RangeInputContainer>
            <SpanTags isSpace>
              <TextBar toolTip="Redemption Ratio" leftText="Redemption Ratio" custId="redemptionRatio" fontSize="16px" />
              <span>{Number(rangeValueViaAmountChange) ? rangeValueViaAmountChange.toFixed(1) : rangeValue}%</span>
            </SpanTags>
            <SpanTags>
              <TextBar leftText="Redemption Fee" custId="redemptionRatio" fontSize="16px" />
              <span>
                ({Number((Number(ethChange) * 0.005).toFixed(6)) > 0 ? (Number(ethChange) * 0.005).toFixed(6) : "0.000000"} {envAllDescriptionDetails.VAULT_TOKEN_TEXT}{" "}
                {`($${convertEthBalance})`}) 0.5%
              </span>
            </SpanTags>
            {enableNotification && errorMsg ? <Notification type="error" message={errorMsg} /> : ""}
            <Button btnType={"gradientFilledButton"} customHeight="60px" isDisabled={isDisable || !Number(amount)} onClick={() => redemptionCall()}>
              {"Confirm"}
            </Button>
            <SpanTags>
              <span style={{ color: "#ffff", textAlign: "left" }}>
                Note:{" "}
                <span style={{ color: "#ffffff8a", fontSize: '14px' }}>
                  Redemptions are not the same as paying back your Vault’s debt. To repay your loan, adjust your Vault on the Repay tab of the Borrow USDL page.
                </span>
              </span>
            </SpanTags>
          </RedemptionBox>
        </Container>
      )}
    </>
  );
};
