import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { USER_INSTANCE } from "blockchain/contract/instance";
import { strategyPath } from "logic/paths";
import { setAutomationLogModal, setAutomationModal, setIsAutomation, setTxHash } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "shared/customModal";
import { PageContainer } from "shared/flexBox/style";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { commonContractsDescription, envAllDescriptionDetails, URL_READ_MORE_AUTOPILOT } from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { Loader } from "shared/loader/loader";
import { Notification } from "shared/notification";
import { CommonContainer, ContainerBox, RowFlex } from "shared/styled";
import { ToggleSwitch } from "shared/switch";
import { Heading3, LightText, LinkPara, LinkText, Para } from "shared/Typography";
import { AutomationLogTable } from "./AutomationLogTable";
import { MaxCap } from "./MaxCap";
import { PrimaryBox } from "./PrimaryBox";
import { SecondaryBox } from "./SecondaryBox";
import { PoolContainer } from "./Style";
import { useWallet } from "shared/hook/useWallet";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const AutomationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { startSpinner, stopSpinner, handleError, authFailed } = useTransaction();
  const { selectedCompoundStrategyID, selectedCompoundStrategy, selectedLoanCompoundingTargetUsingTrove, targetCollateralRatio } = useSelector((s: any) => s.strategyRedu);
  const globalSelector = useSelector((state: any) => state);
  const { isAutomation: isEnabled, authStatus, isAutomationModalOpen, isAutomationLogModalOpen, automationBalance } = globalSelector.navbar;
  const { isLoading: loadingContract } = globalSelector.notification;
  const { account, active } = useConnectWallet();
  const [loading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [err, setErr] = useState<string>("");
  const { automaticLogOut, clearStorage } = useWallet();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setTimeout(() => {
      setIsUpdating(false);
    }, 1000);
  }, [navigate]);

  const handleAutomationChange = async () => {
    try {
      if (authStatus) {
        authFailed();
        return;
      }
      if (Number(automationBalance) === 0) {
        setErr(`Deposit ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} to cover the gas for automation`);
        setTimeout(() => {
          setErr("");
        }, 8000);
        return;
      }
      if (selectedCompoundStrategyID === "6") {
        setErr("No strategy has been selected for automation");
        setTimeout(() => {
          setErr("");
        }, 8000);
        return;
      } else if (
        selectedCompoundStrategyID !== "2" &&
        selectedCompoundStrategyID !== "4" &&
        (selectedCompoundStrategyID !== "5" || (selectedCompoundStrategyID === "5" && selectedLoanCompoundingTargetUsingTrove))
      ) {
        if (Number(targetCollateralRatio) < 500) {
          setErr("Target Collateral Ratio should be equal or greater than 500% for enabling automation.");
          setTimeout(() => {
            setErr("");
          }, 8000);
          return;
        }
      }
      dispatch(setIsAutomation(!isEnabled));
      startSpinner();
      setIsLoading(true);
      const result = await USER_INSTANCE?.setAutomation(Number(!isEnabled));
      dispatch(setTxHash(result.hash));
      const response = await result.wait();
      const data = response?.events?.[0]?.data;
      let isAutomated: any;
      if (data === "0x0000000000000000000000000000000000000000000000000000000000000001") {
        isAutomated = true;
      } else {
        isAutomated = false;
      }
      await updateBackend(isAutomated);
      stopSpinner();
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
      dispatch(setIsAutomation(isEnabled));
    }
  };

  const updateBackend = async (isAutomated: boolean) => {
    try {
      await axios.patch(POST_WALLET_URL, { isAutomated });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  if (loadingContract || isUpdating)
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );

  return (
    <>
      <PageContainer>
        {!active && !account ? (
          navigate("/")
        ) : (
          <CommonContainer>
            <ContainerBox maxWidth="850px">
              <RowFlex>
                <Heading3 fWeight="600">Enable Autopilot</Heading3>
                <ToggleSwitch isDisabled={loading} isEnabled={isEnabled} handleAutomationChange={handleAutomationChange} />
              </RowFlex>
              {err && <Notification message={err} type="error" />}
              <RowFlex align="flex-start">
                <LightText fSize="14px">Selected Compound Strategy</LightText>
                <LinkPara onClick={() => navigate(strategyPath)} tAlign="end" fWeight="500">
                  {selectedCompoundStrategy}
                </LinkPara>
              </RowFlex>
              <div>
                <Para>{commonContractsDescription.AUTOMATION_INFO_TEXT}</Para>
                <RowFlex>
                  <LinkText href={URL_READ_MORE_AUTOPILOT} target="blank">
                    Read more about Automation
                  </LinkText>
                </RowFlex>
              </div>
            </ContainerBox>
            <PoolContainer>
              <PrimaryBox />
              <SecondaryBox />
            </PoolContainer>
          </CommonContainer>
        )}
      </PageContainer>
      <CustomModal
        show={isAutomationModalOpen}
        toggleModal={(p: boolean) => {
          dispatch(setAutomationModal(false));
        }}
        heading={"Max Cap"}
      >
        <MaxCap />
      </CustomModal>
      <CustomModal
        custMaxWidth="90%"
        isOpacity
        show={isAutomationLogModalOpen}
        toggleModal={(p: boolean) => {
          dispatch(setAutomationLogModal(false));
        }}
        heading={"Automation Logs"}
      >
        <AutomationLogTable />
      </CustomModal>
    </>
  );
};
