import { useWeb3React } from "@web3-react/core";
import { TROVE_MANAGER_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { BigNumber, ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { initialValues, SET_BOTH_VALUES, SET_LUSD_VALUES } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { InputBox, InputLabel } from "../style";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Borrow = () => {
  const { LUSD, ETH, BOTH } = useSelector((state: any) => state.trove);
  const { debtDisplay, lusdInWalletDisplay, debt, totalDebt, isAdjustSliderShown } = LUSD;
  const { priceEth, collDisplay } = ETH;
  const { IcollateralRatio, isMarketRecovery, err } = BOTH;
  const { refetchBalance } = useSelector((state: any) => state.navbar);
  const { ethToUSD } = useWallet();
  const dispatch = useDispatch();
  const { library, account }: any = useConnectWallet();

  const [debtInputValue, setDebtInputValue] = useState<string>("0");
  const [debtInputUSDValue, setDebtInputUSDValue] = useState<number>(0);
  const [calDebtInputValue, setCalDebtInputValue] = useState<number>(0);
  const [debtRangeValue, setDebtRangeValue] = useState<number>(0);
  const [lusdInDollar, setLusdInDollar] = useState<any>(0);
  const [sliderMaxValue, setSliderMaxValue] = useState<number>(0);
  const [convertSliderMax, setConvertSliderMax] = useState<number>(0);
  const debouncedDebtInputValue = useDebounce(debtInputValue, 300);

  useEffect(() => {
    if (lusdInWalletDisplay) {
      convertUSD();
      fetchSliderMaxValue();
    }
    if (Number(debouncedDebtInputValue) === 0) {
      dispatch(initialValues(library, account, ethToUSD));
    } else {
      debtLoanInfo();
    }
  }, [lusdInWalletDisplay, debouncedDebtInputValue]);

  useEffect(() => {
    setDebtInputValue("0");
    setCalDebtInputValue(0);
    setDebtInputUSDValue(0);
    setDebtRangeValue(0);
  }, [refetchBalance]);

  const convertUSD = async () => {
    const { lusdUSD } = await tokenToUSD();
    setLusdInDollar(lusdUSD);
  };

  const fetchSliderMaxValue = async () => {
    // sliderMaxValue
    const divValue = isMarketRecovery ? BigNumber.from(150) : BigNumber.from(110);
    const ethInDollar = await ethToUSD();
    const ethInDollarBN = ethers.utils.parseUnits(ethInDollar.toString(), 18);
    const { lusdUSD } = await tokenToUSD();
    const debt_coll = await USER_INSTANCE.getEntireDebtAndColl();
    const collateralInWei = debt_coll[1];
    const collateralInWei_USD = collateralInWei.mul(ethInDollarBN).div(ethers.constants.WeiPerEther)
    const collUSD = Number(collDisplay) * ethInDollar;
    const convertCollUSD = parseUnits(collUSD.toString());
    const _borrowingFee: any = await fetchBorrowingFeePercentage();
    const borrowFactor = BigNumber.from(ethers.constants.WeiPerEther).add(BigNumber.from(_borrowingFee));
    let amountToBorrow: any;
    let inputAmount: any;

    if (isMarketRecovery) {
      amountToBorrow = convertCollUSD.mul(100).div(divValue).sub(debt_coll[0]);
    } else {
      const maxDebt = collateralInWei_USD.mul(100).div(divValue);
      const existingTotalDebt = debt_coll[0];
      amountToBorrow = maxDebt.sub(existingTotalDebt);
      inputAmount = amountToBorrow.mul(ethers.constants.WeiPerEther).div(borrowFactor);
      const BorrowingFee = await TROVE_MANAGER_INSTANCE.getBorrowingFeeWithDecay(inputAmount);
      const toBorrow = inputAmount.add(BorrowingFee);
    }

    let _tempRemaining = Number(formatEther(inputAmount));
    _tempRemaining = Math.trunc(_tempRemaining * Math.pow(10, 3)) / Math.pow(10, 3);
    _tempRemaining = Number(_tempRemaining) > 0 ? Number(_tempRemaining) : 0;
    setSliderMaxValue(_tempRemaining);
    let Convert_SliderMax = lusdUSD && Number(_tempRemaining) > 0 ? Number(lusdUSD) * Number(_tempRemaining) : 0.0;
    setConvertSliderMax(Convert_SliderMax);
  };

  const fetchBorrowingFeePercentage = async () => {
    try {
      const rate = TROVE_MANAGER_INSTANCE.getBorrowingRateWithDecay();
      return rate;
    } catch (e: any) {
      console.log({ e: e.reason });
    }
  };

  const handleUpdate = (debtValue: string, percentage: number) => {
    const convertDebt = formatEther(debt);
    const usdValue = lusdInDollar ? Number(lusdInDollar) * Number(debtValue) : 0.0;
    const newVal = Number(convertDebt) + Number(debtValue);
    setDebtInputValue(debtValue);
    setCalDebtInputValue(newVal);
    setDebtInputUSDValue(usdValue);
    setDebtRangeValue(percentage);
  };

  const handleDebtInput = async (e: any) => {
    const value = validateNumberInput(e)?.toString();
    let percentage: any = ((Number(value) / Number(sliderMaxValue)) * 100).toFixed(0);
    percentage = Number(percentage);
    if (Number(value) <= Number(sliderMaxValue)) {
      handleUpdate(value, percentage);
    }
  };

  const handleDebtRangeInput = (e: any) => {
    let percentToValue: any = Number(sliderMaxValue) * (Number(e) / 100);
    percentToValue = Math.trunc(percentToValue * Math.pow(10, 3)) / Math.pow(10, 3);
    const percentage = Number(e);
    handleUpdate(percentToValue, percentage);
  };

  const fetchBorrowingFee = async (value: any) => {
    try {
      let MAX_BORROWING_FEE = await TROVE_MANAGER_INSTANCE.getBorrowingFeeWithDecay(value);
      MAX_BORROWING_FEE = formatEther(MAX_BORROWING_FEE);
      return MAX_BORROWING_FEE;
    } catch (e: any) {
      console.log({ e: e.reason });
    }
  };

  const checking = (tempCollaterlRatio: any) => {
    let ErrorText;
    if (isMarketRecovery && Number(tempCollaterlRatio) < Number(IcollateralRatio)) {
      ErrorText = "You're not allowed to decrease your collateral ratio during Recovery Mode";
    } else if (Number(tempCollaterlRatio) < 110) {
      ErrorText = "You're not allowed to decrease your collateral ratio less than 110%";
    } else {
      ErrorText = "";
    }
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        err: ErrorText,
      },
    });
  };

  const debtLoanInfo = async () => {
    try {
      let _convertValue, _tempBorrowingFee, _tempTotalDebt;
      if (Number(debtInputValue) === 0) {
        _tempBorrowingFee = "0";
        _tempTotalDebt = totalDebt;
      } else {
        _convertValue = parseUnits(debtInputValue.toString(), "ether");
        _tempBorrowingFee = await fetchBorrowingFee(_convertValue);
        _tempTotalDebt = isMarketRecovery ? Number(calDebtInputValue) : Number(calDebtInputValue) + Number(_tempBorrowingFee);
      }
      handleLoanInformation(_tempTotalDebt);
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          priceUSDLDisplay: debtInputValue,
          priceUSDL: calDebtInputValue,
          borrowingFee: _tempBorrowingFee,
          totalDebt: _tempTotalDebt,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleLoanInformation = async (tempTotalDebt: any) => {
    let tempNormalLPrice, tempRecoveryLPrice, tempICollateralRatio;
    const ethInDollar = await ethToUSD();
    if (debtInputValue) {
      tempNormalLPrice = (110 * Number(tempTotalDebt)) / (Number(priceEth) * 100);
      tempRecoveryLPrice = (150 * Number(tempTotalDebt)) / (Number(priceEth) * 100);
      const ratio = (Number(priceEth) * Number(ethInDollar) * 100) / Number(tempTotalDebt);
      tempICollateralRatio = ratio.toFixed(2);
    } else {
      tempICollateralRatio = "0";
      tempRecoveryLPrice = 0;
      tempNormalLPrice = 0;
    }
    checking(tempICollateralRatio);
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        IcollateralRatio: tempICollateralRatio,
        recoveryLPrice: tempRecoveryLPrice,
        normalLPrice: tempNormalLPrice,
      },
    });
  };

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Borrow</span>
            <span className="text2">{envAllDescriptionDetails.STABILITY_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Max Borrow: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(sliderMaxValue, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(convertSliderMax, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput
          disable={isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown)}
          placeholder={"Enter Amount"}
          icon={envAllDescriptionDetails.STABILITY_LOGO}
          value={debtInputValue}
          onChange={handleDebtInput}
        ></CustomInput>
        {!(isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown)) ? <RangePointerInput rangeValue={debtRangeValue} rangeHandler={handleDebtRangeInput} /> : ""}
      </InputBox>
      {err === "" && isAdjustSliderShown && Number(debtInputValue) > 0 && debtInputValue !== "" ? (
        <Notification
          message={`You will receive ${formatNumbersWithComma(debtInputValue, 4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(
            debtInputUSDValue,
            2,
          )})`}
          type="error"
        />
      ) : (
        ""
      )}
    </>
  );
};
