import { useWeb3React } from "@web3-react/core";
import { formatEther } from "ethers/lib/utils";
import { setAutomationLogModal, setAutomationModal } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/button";
import { commonContractsDescription, envAllDescriptionDetails, formatNumbersWithComma } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { ContainerBox, RightBoxCntr, RowContainer, RowFlex, SvgIcon, Tooltip, TooltipTop } from "shared/styled";
import { LightText, Para } from "shared/Typography";
import { withTheme } from "styled-components";
import infoIcon from "assets/icons/info-icon-white.svg";
import DangerIcon from "assets/icons/Danger.svg";
import { TextBar } from "shared/textbar/TextBar";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const SecondaryBox = withTheme((props: any) => {
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const [convertAverageFee, setConvertAverageFee] = useState<any>(0);
  const [gasCost, setGasCost] = useState<any>(0);
  const [convertGasCost, setConvertGasCost] = useState<any>(0);
  const [convertMaxCap, setConvertMaxCap] = useState<any>(0);

  const dispatch = useDispatch();
  const { ethToUSD } = useWallet();
  const { disablingReason, estimateGas } = useSelector((state: any) => state.strategyRedu);
  const { refetchBalance, maxGap, averageFee, automationBalance, automationCanRun, isAutomation } = useSelector((state: any) => state.navbar);
  const { theme } = props;
  const { library } = useConnectWallet();

  useEffect(() => {
    init();
  }, [refetchBalance, maxGap, automationBalance, averageFee, estimateGas]);

  const init = async () => {
    try {
      const USDValue = await ethToUSD();
      const _averageFee = await Promise.resolve(library?.getGasPrice());
      const _formatAverageFee = Number(formatEther(_averageFee)).toFixed(10);
      const Convert_ETHBal = USDValue && automationBalance ? Number(USDValue) * Number(automationBalance) : 0.0;
      setConvertEthBalance(Convert_ETHBal);
      const Convert_AverageFee = USDValue ? Number(USDValue) * Number(_formatAverageFee) : 0.0;
      setConvertAverageFee(Convert_AverageFee);
      const _gasCost = averageFee && estimateGas ? ((averageFee * estimateGas) / 10 ** 9).toFixed(3) : 0.0;
      setGasCost(_gasCost);
      const Convert_GasCost = _gasCost && USDValue ? Number(_gasCost) * Number(USDValue) : 0.0;
      setConvertGasCost(Convert_GasCost);
      const ConvertMaxCap_Usd = USDValue && maxGap !== "N/A" ? Number(USDValue) * Number(maxGap) : 0.0;
      setConvertMaxCap(ConvertMaxCap_Usd);
    } catch (err: any) {
      console.log(err);
    }
  };

  const value = (isAutomation && automationCanRun <= 3) || !maxGap || maxGap === "0" ? theme.danger : "";

  return (
    <>
      <RightBoxCntr>
        <ContainerBox custFlex="0 1 0px" customGap={"0.5em"}>
          <LightText fOpacity="36%" fSize="10px" tAlign="start">
            AUTOMATION STATISTICS
          </LightText>
          <RowFlex customGap="7px" align="flex-start">
            <LightText fSize="14px">Current average gas price</LightText>
            <Para fWeight="500" tAlign="end">{` ${formatNumbersWithComma(averageFee, 3)} Gwei ($${formatNumbersWithComma(convertAverageFee, 2)})`}</Para>
          </RowFlex>
          <RowFlex customGap="7px" align="flex-start">
            <LightText fSize="14px">Estimated Gas Units Per automation</LightText>
            <div style={{ alignItems: "flex-start" }}>
              <Para fWeight="500" tAlign="end" fcolor={disablingReason ? "#FF6174" : ""}>{` ${estimateGas ? formatNumbersWithComma(estimateGas, 3) : "Can't estimate the gas"
                }`}</Para>
              {disablingReason && (
                <Tooltip left={"-18em"} customWidth="15em" data-tooltip={disablingReason} style={{ marginTop: "6px" }}>
                  <SvgIcon style={{ marginRight: "5px", marginLeft: "2px" }} src={DangerIcon} />
                </Tooltip>
              )}
            </div>
          </RowFlex>
          <RowFlex customGap="7px" align="flex-start">
            <LightText fSize="14px">Estimated Gas Cost Per Automation</LightText>
            <Para fWeight="500" tAlign="end">{` ${formatNumbersWithComma(gasCost, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertGasCost, 2)})`}</Para>
          </RowFlex>
          <RowFlex customGap="7px" align="flex-start">
            <LightText fSize="14px">ESTIMATED # of days automation can run</LightText>
            <RowFlex>
              <Para fcolor={value} fWeight="500" tAlign="end">
                {automationCanRun === "set a max cap" ? "set a max cap below" : automationCanRun}
              </Para>
            </RowFlex>
          </RowFlex>
          <RowFlex customGap="7px" align="flex-start">
            <LightText fSize="14px">Balance</LightText>
            <RowFlex customGap="5px">
              <div>
                <Para fWeight="500" tAlign="end">
                  {" "}
                  {automationBalance
                    ? ` ${parseInt("" + automationBalance * 1000) / 1000} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`
                    : `0.000 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
                </Para>
                {
                  automationBalance?.toString().length > 5 && (
                    <Tooltip
                      left={"-18em"}
                      customWidth="15em"
                      style={{ marginLeft: "5px" }}
                      data-tooltip={automationBalance ? `${automationBalance} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}` : `0.000 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
                    >
                      <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
                    </Tooltip>
                  )
                }

              </div>
              <Para fWeight="500"> {automationBalance ? `($${formatNumbersWithComma(convertEthBalance, 2)})` : "($0.00)"}</Para>
            </RowFlex>
          </RowFlex>
          <RowFlex isMakeStart customGap="7px" align="center">
            <div className="amountContainer">
              {/* <LightText fSize="14px"></LightText> */}
              <TextBar
                marginBottom="1px"
                leftText="Max Cap"
                custId="maxCapTooltip"
                toolTip={commonContractsDescription.MAXCAP_TEXT}
              />
              <Button btnType={"borderedfilledButton"} customHeight="30px" customWidth="70px" fSize="13px" fSizeMobile="12px" onClick={() => dispatch(setAutomationModal(true))}>
                Change
              </Button>
            </div>
            <RowFlex isMakeCol customGap="5px">
              <div>
                <Para fWeight="500" tAlign="end">
                  {" "}
                  {maxGap !== "N/A" ? `${Math.trunc(Number(maxGap) * Math.pow(10, 3)) / Math.pow(10, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}` : `0.000 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
                </Para>
                {
                  maxGap?.toString().length > 5 && (
                    <Tooltip
                      left={"-18em"}
                      customWidth="15em"
                      style={{ marginLeft: "5px" }}
                      data-tooltip={maxGap !== "N/A" ? `${maxGap} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}` : `0.000 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}
                    >
                      <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
                    </Tooltip>
                  )
                }

              </div>
              <Para fWeight="500"> {maxGap !== "N/A" ? `($${formatNumbersWithComma(convertMaxCap)})` : "($0.00)"}</Para>
            </RowFlex>
          </RowFlex>
          <RowContainer>
            <Button btnType={"borderedfilledButton"} wrapperWidth="100%" fSizeMobile="12px" onClick={() => dispatch(setAutomationLogModal(true))}>
              View automation logs
            </Button>
          </RowContainer>
        </ContainerBox>
      </RightBoxCntr>
    </>
  );
});
