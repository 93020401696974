import styled from "styled-components";
import loader from "assets/icons/loader.svg";

const LoaderContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.3); */
  img {
    width: 32px;
    height: 32px;
  }

  p {
    color: #f8f3f3;
    font-size: 16px;
    font-weight: 500;
  }
`;

export function Loader({ message }: any) {
  return (
    <LoaderContainer>
      <img src={loader} alt="Loading..." />
      <p>{message ? message : "Loading...."}</p>
    </LoaderContainer>
  );
}
