import { automationPath, redemptionPath, riskyVaultPath, stabilityPoolPath, stakingPath, strategyPath, trovePath } from "logic/paths";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Container, SvgIcon } from "shared/styled";
import { IconTextWrapper, Navigations, NavTextContainer } from "./style";
import SelectStrategyIcon from "../../../assets/icons/SelectStrategy.svg";
import AutomationIcon from "../../../assets/icons/Automation.svg";
import RedemptionGainIcon from "../../../assets/icons/RedemptionGain.svg";
import StabilityPoolIcon from "../../../assets/icons/StabilityPool.svg";
import StakingPoolIcon from "../../../assets/icons/StakingPool.svg";
import TroveIcon from "../../../assets/icons/Trove.svg";
import DangerIcon from "../../../assets/icons/Danger.svg";
import RiskyVaultIcon from "../../../assets/icons/RiskyVault.svg";

export const ExtendedNavbar = () => {
  const globalSelector = useSelector((state: any) => state);
  const { userContractAddress, automationCanRun, isAutomation } = globalSelector.navbar;

  return (
    <>
      <Container isHide>
        <Navigations>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={strategyPath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={SelectStrategyIcon} />
                  <p>Select Strategy</p>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={trovePath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={TroveIcon} />
                  <span>Trove/Vault</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={stabilityPoolPath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={StabilityPoolIcon} />
                  <span>Stability Pool</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={stakingPath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={StakingPoolIcon} />
                  <span>Staking</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink
              className={({ isActive }) => (isActive ? "active-route" : "inactive-route")}
              to={automationPath}
              onClick={(e: any) => !userContractAddress && e.preventDefault()}
            >
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={AutomationIcon} />
                  <span>Automation {isAutomation && automationCanRun <= 3 && <SvgIcon style={{ marginRight: "5px", marginLeft: "2px" }} src={DangerIcon} />}</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={riskyVaultPath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={RiskyVaultIcon} />
                  <span>Risky Vaults</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
          <div className="nav-btn">
            <NavLink className={({ isActive }) => (isActive ? "active-route" : "inactive-route")} to={redemptionPath} onClick={e => !userContractAddress && e.preventDefault()}>
              <NavTextContainer>
                <div className="borderTop"></div>
                <IconTextWrapper>
                  <SvgIcon height="20px" src={RedemptionGainIcon} />
                  <span>Redemption</span>
                </IconTextWrapper>
              </NavTextContainer>
            </NavLink>
          </div>
        </Navigations>
      </Container>
    </>
  );
};
