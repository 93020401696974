
import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 520px;
    gap: 1em;
    align-items: start;
    width: 80%;
    a {
        color: ${(props: any) => props.theme.primaryButton};
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
    }
   div {
        width: 100%;
        text-align: start;   
   }
   .termsTextContainer {
        text-align: center;
        p {
            font-size: 12px;
            font-family: Roboto;
            color: ${(props: any) => props.theme.fadedWhite};
            a {
                color: ${(props: any) => props.theme.white};
                font-family: Roboto;
                text-decoration: underline;
                font-size: 12px;
            }
        }
   }
`