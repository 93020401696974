import React from "react";
import { useSelector } from "react-redux";
import { Button } from "shared/button";
import { TooltipTop } from "shared/styled";

interface IProps {
  tempDisabledReason: string;
  tempDisabled?: boolean;
  buttonText: string;
  handleCompoundButton: () => any;
}

const Compound: any = (props: IProps) => {
  const {
    tempDisabledReason,
    tempDisabled,
    buttonText,
    handleCompoundButton,
  } = props;

  const { IsCompound} = useSelector((state:any)=>state.strategyRedu)
    
  
  return (
    <>
      {(tempDisabled || !IsCompound) ? (
        <TooltipTop
          fullWidth
          customWidth="15em"
          data-tooltip={tempDisabledReason}
        >
          <Button
            btnType={"borderedfilledLightButton"}
            onClick={handleCompoundButton}
            isDisabled={tempDisabled || !IsCompound}
            wrapperWidth="100%"
          >
            {buttonText}
          </Button>
        </TooltipTop>
      ) : (
        <Button
          btnType={"borderedfilledLightButton"}
          onClick={handleCompoundButton}
          isDisabled={tempDisabled || !IsCompound}
          wrapperWidth="100%"
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

export const CompoundMemo = React.memo(Compound)