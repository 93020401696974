import { Button } from "shared/button"
import { FlexContainer } from "./style"
import { PageTitle } from "modules/app/pageTitle/PageTitle"
import { useDispatch } from "react-redux"
import { setConnectWallet } from "logic/redux/actions"
import { URL_PRIVACY_POLICY, URL_TERMS } from "shared/helpers/utils"
import { LinkText } from "shared/Typography"
import { Link } from "react-router-dom"

/**
 * 
 * @param props Getting the props for showing the title
 * @returns Button to connect the wallet
 */

export const ConnectWallet = (props: any) => {

    const dispatch = useDispatch()
    const handleConnectWallet = () => {
        dispatch(setConnectWallet(true))
    }

    return (
        <FlexContainer style={{paddingBottom:'50px'}}>
            <PageTitle
                {...props}
            />
            <Button btnType={'borderedfilledButton'} customHeight="60px" onClick={handleConnectWallet} >Connect Wallet</Button>
            <div className="termsTextContainer">
            <p>By connecting, you agree to our <Link to="/terms" target="_blank">Terms & Conditions</Link> and <Link to="/privacy" target="_blank">Privacy Policy</Link></p>
            </div>
        </FlexContainer>
    )
}
