import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commonContractsDescription } from "shared/helpers/utils";
import { RadioBtn } from "shared/radioBtn/RadioBtn";
import { CustomText } from "shared/Typography";
import { ModalListContainer } from "./style";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

interface ILoanCompoundingTargetProps {
  setStrategy?: any;
}

export const SPCompoundingTarget = (props: ILoanCompoundingTargetProps) => {
  const { setStrategy } = props;

  const { selectedLoanCompoundingTargetUsingTrove, selectedSPCompoundingTargetID } = useSelector(
    (s: any) => s.strategyRedu
  );

  const [targets, setTargets] = useState<any>(null);
  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;


  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let selectors: any =
      sessionStorage.getItem(commonContractsDescription.SELECTORS) || "{}";
    selectors = JSON.parse(selectors);
    if (selectors) setTargets(selectors.stability);
  };

  return (
    <ModalListContainer>
      <CustomText fSizeM="20px" fLineHeight="28px" fSize="24px" tAlign="start">
        Stability Pool Compounding Target
      </CustomText>
      {targets?.map((opt: any, index: number) => {
        const description =
          Number(envChainId) === ChainId?.eth
            ? opt?.ethDescription
            : opt?.pulseDescription;
        const name =
          Number(envChainId) === ChainId?.eth
            ? selectedLoanCompoundingTargetUsingTrove
              ? opt?.ethNameWithTrove
              : opt?.ethNameWithOutTrove
            : selectedLoanCompoundingTargetUsingTrove
              ? opt?.pulseNameWithTrove
              : opt?.pulseNameWithOutTrove;

        return (
          <RadioBtn
            infoMsg={description || ""}
            name="SPCompoundingTarget"
            radioId={opt.id}
            value={name}
            onChange={setStrategy}
            checked={selectedSPCompoundingTargetID === opt.id}
            key={index}
          />
        );
      })}
    </ModalListContainer>
  );
};
