import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import { App } from "./modules/app";
import store from "logic/redux/store";
import { Provider } from "react-redux";
import { TransactionProvider } from "logic/context/context";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import { connectors } from "blockchain/wallets/helpers/connectors";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

//axios baseUrl configuration
const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = API_URL + "/api/v1";

const sanitryDsnUrl = process.env.DSN_SANITRY;

Sentry.init({
  dsn: sanitryDsnUrl,
  integrations: [new BrowserTracing()],
  debug: true,
  environment: process.env.REACT_APP_USER_BRANCH,
  tracesSampleRate: 1.0,
});

root.render(
  <Web3ReactProvider connectors={connectors} >
    <Provider store={store}>
      <TransactionProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TransactionProvider>
    </Provider>
  </Web3ReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
