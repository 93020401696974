import { useSelector } from "react-redux";

import { StakingBox } from "./StakingBox";
import { ColCntr } from "../style";
import { TroveBox } from "./TroveBox";
import { SPBox } from "./SPBox";
import { DeployContractCard } from "../DeployContractCard";
import { RedemptionBox } from "./RedemptionBox";
import { RiskyVaultsBox } from "./RiskyVaultsBox";

interface IProps {
  handleCompoundButton: () => any;
  handleCompoundStability: () => any;
  handleCompoundStaking: () => any;
}

export const LeftBox = (props: IProps) => {
  const { handleCompoundButton, handleCompoundStability, handleCompoundStaking } = props;

  const { userContractAddress } = useSelector((state: any) => state.navbar);
  if (!userContractAddress) return <DeployContractCard />;

  return (
    <ColCntr>
        <TroveBox handleCompoundButton={handleCompoundButton} />
        <SPBox handleCompoundButton={handleCompoundButton} handleCompoundStability={handleCompoundStability} />
        <StakingBox handleCompoundButton={handleCompoundButton} handleCompoundStaking={handleCompoundStaking} />
        <RedemptionBox />
        <RiskyVaultsBox />
    </ColCntr>
  );
};
