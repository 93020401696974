import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PageTitle } from "modules/app/pageTitle/PageTitle";
import { ConnectWallet } from "shared/connectWallet/ConnectWallet";
import { PageContainer } from "shared/flexBox/style";
import { commonContractsDescription, envAllDescriptionDetails, URL_READ_MORE_DASHBOARD, URL_READ_MORE_HOME } from "shared/helpers/utils";
import { Loader } from "shared/loader/loader";
import { Dashboard } from "./components/Dashboard";
import { DashboardCntr } from "./style";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

/**
 *
 * @returns we need to connect the wallet to deploy the contract then only we can see our statitcs page
 */

export const Home = () => {
  const { active, account } = useConnectWallet();
  const { isLoading } = useSelector((state: any) => state.notification);
  const { userContractAddress, isAuthStatusLoading } = useSelector((state: any) => state.navbar);

  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsUpdating(false);
    }, 2000);
  }, []);

  return (
    <PageContainer>
      {isUpdating || isLoading ? (
        <Loader />
      ) : (
        !active && !account && <ConnectWallet readMoreURL={URL_READ_MORE_HOME} bodyText={envAllDescriptionDetails?.DashboardText} readMoreText={"Learn More"} />
      )}
      {isUpdating || isLoading || isAuthStatusLoading ? (
        <Loader />
      ) : active && account ? (
        <DashboardCntr>
          {userContractAddress ? (
            <PageTitle
              justify="start"
              customPadding="0"
              titleText="Amplifier Summary"
              readMoreURL={URL_READ_MORE_DASHBOARD}
              bodyText={envAllDescriptionDetails.DASHBOARD_INFO_TEXT}
              readMoreText={envAllDescriptionDetails.DASHBOARD_INFO_LINK}
            />
          ) : (
            <PageTitle justify="start" customPadding="0" titleText="Initial Setup" bodyText={envAllDescriptionDetails.DeployContractInfoText} />
          )}
          <Dashboard />
        </DashboardCntr>
      ) : (
        ""
      )}
    </PageContainer>
  );
};
