import { useEffect, useState } from "react";
import { checkWallet, selectWalletHooks } from "../helpers/WalletHelper";
import { useWeb3React } from "@web3-react/core";
import { connectorsObject } from "../helpers/connectors";

export const useConnectWallet = () => {
  //Define Variables
  let chainId: number | undefined;
  let active: boolean;
  let address: any;
  let connectedWallet: any;
  let activating: boolean;
  let library: any;

  const { hooks: PriorityHook } = useWeb3React();
  const hook = selectWalletHooks();

  const { useChainId, useIsActive, useAccounts, useIsActivating, useProvider } = hook;

  const { usePriorityConnector } = PriorityHook;
  const { account: web3Account } = useWeb3React();

  // Set Hooks into Variables
  chainId = useChainId();
  active = useIsActive();
  address = useAccounts();
  connectedWallet = usePriorityConnector();
  activating = useIsActivating();
  library = useProvider();

  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN;

  const account = address ? address[0] : "";
  const activate = (connector: any, networkId: number) => {
    checkWallet(connector);
    connector
      .activate(Number(envChainId))
      .then(() => {})
      .catch((error: any) => {
        console.error("Activate Func error", error.message);
      });
  };

  useEffect(() => {
    if (web3Account) {
      localStorage.setItem("address", web3Account);
    }
  }, [web3Account]);

  const deactivate = (connector: any) => {
    try {
      if (connector?.deactivate) {
        connector.deactivate();
        connector.resetState();
      } else {
        connector.resetState();
      }
    } catch (error) {}
  };

  return {
    activate,
    deactivate,
    chainId,
    active,
    account,
    connectedWallet,
    activating,
    library,
  };
};
