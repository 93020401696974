import styled from "styled-components"

interface SwitchProps {
    flag?: boolean,
}

export const SwitchContainer = styled.label<SwitchProps>`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;

    input[type="checkbox"] {
        display: none;
    }

    .switch {
        position: absolute;
        cursor: pointer;
        background-color: #ccc;
        border-radius: 25px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: background-color 0.2s ease;
    }
    .switch::before {
        position: absolute;
        content: "";
        left: 2px;
        top: 2px;
        width: 21px;
        height: 21px;
        background-color: #aaa;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    input[type="checkbox"]:checked + .switch::before {
        transform: translateX(25px);
        background: linear-gradient(${(props: any) => props.theme.pink1 + ', ' + props.theme.pink4});
        left: 0px;
        top: 1px;
    }

    input[type="checkbox"]:checked + .switch {
        background-color: ${(props: any) => props.theme.purple};
        border: 1px solid ${(props: any) => props.theme.pink1};
    }

`