import { setIsRatioShown, setSelectedLoanCompoundingTargetUsingTrove } from "logic/redux/actions/strategy";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonContractsDescription } from "shared/helpers/utils";
import { RadioBtn } from "shared/radioBtn/RadioBtn";
import { RowFlex } from "shared/styled";
import { CustomText } from "shared/Typography";
import { CustomStrategy } from "./CustomStrategy";
import { ModalListContainer } from "./style";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

interface ILoanCompoundingTargetProps {
  setStrategy?: any;
  customStrategies?: any;
  openTopOffSelector?: any;
  removeTopOffSelector?: any;
  reRender?: boolean;
  setStrategyInput?: any;
  handleCancelCS?: any;
}

export const LoanCompoundingTarget = (props: ILoanCompoundingTargetProps) => {
  const {
    setStrategy,
    handleCancelCS,
    setStrategyInput,
    reRender,
    customStrategies,
    openTopOffSelector,
    removeTopOffSelector,
  } = props;

  const { selectedLoanCompoundingTargetUsingTrove, selectedLoanCompoundingTargetID } = useSelector((s: any) => s.strategyRedu);

  const [targets, setTargets] = useState<any>(null);
  const [isUsingTrove, setIsUsingTrove] = useState<string>("");
  const dispatch = useDispatch();
  const [filterCompounds, setFilterCompounds] = useState<[]>();

  useEffect(() => {
    init();
    if (selectedLoanCompoundingTargetUsingTrove) {
      setIsUsingTrove("Yes")
    } else {
      setIsUsingTrove("No")
    }
  }, [selectedLoanCompoundingTargetUsingTrove]);

  const init = async () => {
    let selectors: any =
      sessionStorage.getItem(commonContractsDescription.SELECTORS) || "{}";
    selectors = JSON.parse(selectors);
    if (selectors) setTargets(selectors.loanCompounding);
  };

  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

  const handleOnChange = (e: any) => {
    let _tempIsUsingTrove: boolean
    setIsUsingTrove(e.target.value);
    if (e.target.value === "Yes") {
      _tempIsUsingTrove = true
    } else {
      _tempIsUsingTrove = false
    }
    dispatch(setIsRatioShown(_tempIsUsingTrove))
    dispatch(setSelectedLoanCompoundingTargetUsingTrove(_tempIsUsingTrove))

  };

  const filterArray = () => {
    const usingTroveArray = targets?.filter((opt: any) => {
      return isUsingTrove === "Yes" ? opt.trove === true : opt.trove !== true;
    });
    setFilterCompounds(usingTroveArray);
  };

  useEffect(() => {
    if (isUsingTrove && targets) {
      filterArray();
    }
  }, [isUsingTrove, targets]);


  return (
    <>
      <ModalListContainer>
        <CustomText fWeight="500" tAlign="start">
          Want to utilize a loan from trove ?{" "}
        </CustomText>
        <RowFlex maxWidth="30%">
          <RadioBtn
            name="usingTrove"
            radioId={"1"}
            value={"Yes"}
            onChange={handleOnChange}
            checked={isUsingTrove === "Yes"}
            width="auto"
          />
          <RadioBtn
            name="usingTrove"
            radioId={"2"}
            value={"No"}
            onChange={handleOnChange}
            checked={isUsingTrove === "No"}
            width="auto"
          />
        </RowFlex>
        {isUsingTrove !== "" && (
          <CustomText fSizeM="20px" fLineHeight="28px" fSize="24px" tAlign="start">
            {isUsingTrove === "Yes" ? "Loan Compounding Targets" : "Other Compounding Targets"}
          </CustomText>
        )}
        {filterCompounds?.map((opt: any, index: number) => {
          const description =
            Number(envChainId) === ChainId?.eth
              ? opt?.ethDescription
              : opt?.pulseDescription;
          const name = Number(envChainId) === ChainId?.eth ? opt?.ethName : opt?.pulseName;
          return (
            <RadioBtn
              infoMsg={description || ""}
              name="loanCompoundingTarget"
              radioId={opt.id}
              value={name}
              onChange={setStrategy}
              checked={selectedLoanCompoundingTargetID === opt.id}
              key={index}
            />
          );
        })}
        {isUsingTrove === "Yes" && selectedLoanCompoundingTargetID === "4" ? (
          <CustomStrategy
            handleCancelCS={handleCancelCS}
            setStrategyInput={setStrategyInput}
            reRender={reRender}
            customStrategies={customStrategies}
            openTopOffSelector={openTopOffSelector}
            removeTopOffSelector={removeTopOffSelector}
          />
        ) : (
          ""
        )}
      </ModalListContainer>
    </>
  );
};
