
import styled from 'styled-components';
import { screenSizes } from 'styles/theme';

export const NotFoundContainer = styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    span {
      color: ${(props: any) => props.theme.fadedWhite};
      font-size: 16px;
      font-weight: 400;
      a {
        color: ${(props: any) => props.theme.primaryButton};
        text-decoration: none;
        font-weight: 600;
      }
      @media (max-width: ${screenSizes.M}px) {
        font-size: 12px;
        padding: 0 20px;
      }
    }
`