import styled from "styled-components";
import { colors, screenSizes } from "styles/theme";

interface IProps {
  noMaxWidth?: boolean;
}
export const CustomTable = styled.div<IProps>`
  width: 100%;
  border-radius: 5px;
  max-width: 1050px;
  overflow-x: auto;
  table {
    border-spacing: 0;
    width: 100%;

    tr {
      color: ${colors.white};
      text-align: center;
      :nth-child(even) {
        background: #363646;
      }
      :nth-child(odd) {
        background: rgba(46, 46, 62, 0.2);
      }
      :last-child {
        td {
          border-bottom: 0;
          :nth-child(1) {
            border-bottom-left-radius: 12px;
          }

          :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
      div > span {
        line-height: 30px;
      }
    }

    th {
      background: #363646;
      color: ${colors.white};
      :nth-child(1) {
        border-top-left-radius: 12px;
      }

      :last-child {
        border-top-right-radius: 12px;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: center;
      /* border-right: 1px solid black; */
      :last-child {
        border-right: 0;
      }
    }
  }

  /* @media (max-width: ${screenSizes.L}px) {
        max-width: 900px;
    }
    @media (max-width: ${screenSizes.ML}px) {
        max-width: 700px;
    }

    @media (max-width: ${screenSizes.M}px) {
        max-width: ${props => (props.noMaxWidth ? "" : "500px")}
    }

    @media (max-width: ${screenSizes.XS}px) {
      max-width: ${props => (props.noMaxWidth ? "" : "350px")}
    }
    @media (max-width: ${screenSizes.XXS}px) {
      max-width: ${props => (props.noMaxWidth ? "" : "300px")}
    } */
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 1050px;
  width: 100%;
  margin-bottom: 80px;
  gap: 20px;

  @media (max-width: ${screenSizes.XL}px) {
    width: 90%;
  }
`;

export const AddressWrapper = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 55px;
`;

export const ColumnData = styled.span<any>`
  font-weight: bold;
  text-align: start;
  padding-left: ${props => props.paddingLeft};
`;
export const Navigators = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextPrev = styled.div<any>`
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${props => (props.disabled ? "none" : "")};
`;
