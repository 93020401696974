import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { useSelector } from "react-redux";
import { ethereumContracts, pulseContracts } from "./contractsMapper";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

let SIGNER: any,
  FACTORY_INSTANCE: any,
  USER_INSTANCE: any,
  TROVE_MANAGER_INSTANCE: any,
  LUSD_TOKEN_INSTANCE: any,
  LQTY_TOKEN_INSTANCE: any,
  STAKING_POOL_INSTANCE: any,
  STABILITY_POOL_INSTANCE: any,
  PRICE_FEED_INSTANCE: any,
  PULSE_STACKER_INSTANCE: any,
  PULSE_STACKER_STATIC_INSTANCE: any,
  MAILBOXMONEY_INSTANCE: any,
  HALF_AND_HALF_INSTANCE: any,
  HALF_AND_HALF_STATIC_INSTANCE: any,
  ACTIVE_POOL_INSTANCE: any,
  SORTED_TROVES: any,
  PASSIVE_PULSE_INSTANCE: any,
  PASSIVE_PULSE_STATIC_INSTANCE: any,
  // COMPOUND_ALL_INSTANCE: any,
  CUSTOM_INSTANCE: any,
  CUSTOM_STATIC_INSTANCE: any,
  HELPERS_INSTANCE: any,
  MULTI_TROVE_INSTANCE: any,
  PAYDOWN_METHOD_INSTANCE: any,
  UNISWAP_ROUTER_INSTANCE: any,
  ESCROW_INSTANCE: any,
  envChainId: string,
  envBranch: string,
  envAllContractsDetails: any;

function useInstance() {
  const { library }: any = useConnectWallet();
  const { userContractAddress } = useSelector((state: any) => state.navbar);

  envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;
  envBranch = process.env.REACT_APP_USER_BRANCH as string;
  envAllContractsDetails = Number(envChainId) === ChainId.eth ? ethereumContracts[envBranch] : pulseContracts[envBranch];

  SIGNER = library?.getSigner();

  if (library) {
    FACTORY_INSTANCE = new Contract(envAllContractsDetails.Factory[0], envAllContractsDetails.Factory[1], SIGNER);

    PAYDOWN_METHOD_INSTANCE = new Contract(envAllContractsDetails.PayDown[0], envAllContractsDetails.PayDown[1], SIGNER);

    UNISWAP_ROUTER_INSTANCE = new Contract(envAllContractsDetails.UniswapRouter[0], envAllContractsDetails.UniswapRouter[1], SIGNER);
    HELPERS_INSTANCE = new Contract(envAllContractsDetails.Helpers[0], envAllContractsDetails.Helpers[1], SIGNER);
    MULTI_TROVE_INSTANCE = new Contract(envAllContractsDetails.MultiTrove[0], envAllContractsDetails.MultiTrove[1], SIGNER);
    ESCROW_INSTANCE = new Contract(envAllContractsDetails.Escrow[0], envAllContractsDetails.Escrow[1]);
    LUSD_TOKEN_INSTANCE = new Contract(envAllContractsDetails.LusdToken[0], envAllContractsDetails.LusdToken[1], SIGNER);

    STABILITY_POOL_INSTANCE = new Contract(envAllContractsDetails.StabiltyPool[0], envAllContractsDetails.StabiltyPool[1], SIGNER);

    if (userContractAddress) {
      USER_INSTANCE = new Contract(userContractAddress, envAllContractsDetails.User[0], SIGNER);
    }

    TROVE_MANAGER_INSTANCE = new Contract(envAllContractsDetails.TroveManager[0], envAllContractsDetails.TroveManager[1], SIGNER);

    LQTY_TOKEN_INSTANCE = new Contract(envAllContractsDetails.LqtyToken[0], envAllContractsDetails.LqtyToken[1], SIGNER);
    STAKING_POOL_INSTANCE = new Contract(envAllContractsDetails.StakingPool[0], envAllContractsDetails.StakingPool[1], SIGNER);

    PULSE_STACKER_INSTANCE = new Contract(envAllContractsDetails.PulseStaker[0], envAllContractsDetails.PulseStaker[1], SIGNER);

    PULSE_STACKER_STATIC_INSTANCE = new Contract(envAllContractsDetails.PulseStakerStatic[0], envAllContractsDetails.PulseStakerStatic[1], SIGNER);

    MAILBOXMONEY_INSTANCE = new Contract(envAllContractsDetails.MailBoxMoney[0], envAllContractsDetails.MailBoxMoney[1], SIGNER);

    HALF_AND_HALF_INSTANCE = new Contract(envAllContractsDetails.HalfandHalf[0], envAllContractsDetails.HalfandHalf[1], SIGNER);

    HALF_AND_HALF_STATIC_INSTANCE = new Contract(envAllContractsDetails.HalfandHalfStatic[0], envAllContractsDetails.HalfandHalfStatic[1], SIGNER);

    CUSTOM_INSTANCE = new Contract(envAllContractsDetails.CustomStragey[0], envAllContractsDetails.CustomStragey[1], SIGNER);

    CUSTOM_STATIC_INSTANCE = new Contract(envAllContractsDetails.CustomStatic[0], envAllContractsDetails.CustomStatic[1], SIGNER);

    PRICE_FEED_INSTANCE = new Contract(envAllContractsDetails.PriceFeed[0], envAllContractsDetails.PriceFeed[1], SIGNER);
    ACTIVE_POOL_INSTANCE = new Contract(envAllContractsDetails.ActivePool[0], envAllContractsDetails.ActivePool[1], SIGNER);

    SORTED_TROVES = new Contract(envAllContractsDetails.SortedTroves[0], envAllContractsDetails.SortedTroves[1], SIGNER);

    PASSIVE_PULSE_INSTANCE = new Contract(envAllContractsDetails.PassivePulse[0], envAllContractsDetails.PassivePulse[1], SIGNER);

    PASSIVE_PULSE_STATIC_INSTANCE = new Contract(envAllContractsDetails.PassivePulseStatic[0], envAllContractsDetails.PassivePulseStatic[1], SIGNER);

    // COMPOUND_ALL_INSTANCE = new Contract(envAllContractsDetails.CompoundAll[0], envAllContractsDetails.CompoundAll[1], SIGNER);
  }
}

export {
  FACTORY_INSTANCE,
  USER_INSTANCE,
  TROVE_MANAGER_INSTANCE,
  LUSD_TOKEN_INSTANCE,
  STABILITY_POOL_INSTANCE,
  LQTY_TOKEN_INSTANCE,
  STAKING_POOL_INSTANCE,
  PULSE_STACKER_INSTANCE,
  PULSE_STACKER_STATIC_INSTANCE,
  PRICE_FEED_INSTANCE,
  MAILBOXMONEY_INSTANCE,
  HALF_AND_HALF_INSTANCE,
  HALF_AND_HALF_STATIC_INSTANCE,
  CUSTOM_INSTANCE,
  CUSTOM_STATIC_INSTANCE,
  PASSIVE_PULSE_INSTANCE,
  PASSIVE_PULSE_STATIC_INSTANCE,
  ACTIVE_POOL_INSTANCE,
  SORTED_TROVES,
  // COMPOUND_ALL_INSTANCE,
  HELPERS_INSTANCE,
  MULTI_TROVE_INSTANCE,
  PAYDOWN_METHOD_INSTANCE,
  UNISWAP_ROUTER_INSTANCE,
  ESCROW_INSTANCE,
  SIGNER,
  envAllContractsDetails,
  useInstance,
};
