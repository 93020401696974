import { useTable } from "react-table";
import React from "react";

interface TableProp {
  data?: any;
  columns?: any;
}

export const Table: React.FC<TableProp> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, manualPagination: true });

  return (
    <>
      {
        <table {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th className="th" {...column.getHeaderProps()} style={{ textAlign: "start" }}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows?.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ textAlign: "start" }}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()} style={{ textAlign: "start" }}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      }
    </>
  );
};
