
import styled from 'styled-components';
import { screenSizes } from 'styles/theme';

export const DashboardCntr = styled.div`
    width: 80%;
    max-width: 1100px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    @media screen and (max-width: ${screenSizes.ML}px) {
        width: unset;
        max-width: 720px;
        margin-top: 2em;
    }
`

interface IBoxProps {
    customGap?: string,
    minWidth?: string,
    maxWidth?: string,
    maxheight?: string,
    align?: string,
    customPadding?: string,
    custFlex?: string,
    heightM?: string,
    mblPad?: string,
    gapM?: string,
}
export const CoinContainer = styled.div<IBoxProps>`
    display: flex;
    justify-content: space-between;
    padding: ${(props: any) => props.customPadding};
    align-items: ${(props: any) => props.align};
    gap: ${(props: any) => props.customGap};

    @media (max-width: ${screenSizes.S}px) {
        gap: ${(props: any) => props.gapM || '2em'};
    }
    @media (max-width: ${screenSizes.M}px) {
        gap: 5px
    }
`

export const StatsContainerBox = styled.div<IBoxProps>`
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: ${(props: any) => props.theme.purple};
    border-radius: 10px;
    gap: ${(props: any) => props.customGap ? props.customGap : '1em'};
    flex: ${(props: any) => props.custFlex || '1 1 0px'};
    box-shadow: 0 0 10px rgb(18 247 156);

    height: 100%;
    .btnWrapper {
        width: 50%;
    }
    .amountContainer {
        display: flex;
        align-items: center;
        gap: 0.5em;
    }
    max-width: 555px;
    @media (max-width: ${screenSizes.M}px) {
        padding: 12px;
        box-shadow: none;
    }

    animation: 2000ms forwards, fade-in 600ms forwards;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        50% {
            opacity: .25;
        }
        100% {
            opacity: 1;
        }
    }
`

export const DeployContractCntr = styled.div`
    width: 80%;
    max-width: 1100px;
    padding-bottom: 50px;
    display: flex;
    gap: 1.5em;
    justify-content: center;
    @media (max-width: 667px) {
        width: 80%;
    }
`