import left from "assets/icons/arrowLeft.svg";
import right from "assets/icons/arrowRight.svg";
import { Navigators, NextPrev } from "modules/pages/riskyVaults/style";
import { SvgIcon } from "shared/styled";

const Pagination = ({ totalCount, setCurrentPage, currentPage, itemsPerPage }: any) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const nextPage = () => {
    setCurrentPage((prevPage: any) => prevPage + 1);
  };
  const prevPage = () => {
    setCurrentPage((prevPage: any) => prevPage - 1);
  };

  return (
    <Navigators>
      {currentPage > 1 && (
        <NextPrev onClick={prevPage}>
          <SvgIcon src={left} alt={"left"} height={"40px"} width={"40px"} />
        </NextPrev>
      )}
      {currentPage < totalPages && (
        <NextPrev onClick={nextPage}>
          <SvgIcon src={right} alt={"right"} height={"40px"} width={"40px"} />
        </NextPrev>
      )}
    </Navigators>
  );
};

export default Pagination;
