import { useWeb3React } from "@web3-react/core";
import infoIcon from "assets/icons/info-icon-white.svg";
import { envAllContractsDetails, FACTORY_INSTANCE, LUSD_TOKEN_INSTANCE, PAYDOWN_METHOD_INSTANCE, TROVE_MANAGER_INSTANCE, USER_INSTANCE } from "blockchain/contract/instance";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { strategyPath } from "logic/paths";
import { initialValues, setRefetchBalance, setTxHash } from "logic/redux/actions";
import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CompoundMemo } from "shared/compound/Compound";
import { CustomInput } from "shared/customInput";
import CustomModal from "shared/customModal";
import {
  commonContractsDescription,
  DISABLE_PAYDOWN_REASON,
  envAllDescriptionDetails,
  formatNumbersWithComma,
  isValid,
  tokenToUSD,
  validateNumberInput,
} from "shared/helpers/utils";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { ColFlex, ContainerBox, RightBoxCntr, RowFlex, SvgIcon, Tooltip } from "shared/styled";
import { AmountText, Greytext, LightText, LinkPara, Para } from "shared/Typography";
import { withTheme } from "styled-components";
import { fontSizes } from "styles/theme";
import { AmountCRPayDownDiv, BottonWrapperPayDown, CheckBoxDiv, CrBox, CrInner, PayDownBox, TargetDebtBox } from "../style";
import { TooltipTop } from "./../../../../shared/styled";
import { TroveLoanCompoundingPath } from "./loanCompoundingPath/LoanCompoundingPath";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface Iprops {
  theme?: any;
}

interface Istabilty {
  lqty?: boolean;
  eth?: boolean;
}
interface Istaking {
  lusd?: boolean;
  eth?: boolean;
}

export const SecondaryBox = withTheme((props: Iprops) => {
  const { theme } = props;
  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

  const { selectedCompoundStrategyID, selectedCompoundStrategy, disablingReason, selectedLoanCompoundingTargetUsingTrove, targetCollateralRatio, triggerCollateralRatio } =
    useSelector((s: any) => s.strategyRedu);

  const { library, account } = useConnectWallet();
  const { ethToUSD } = useWallet();
  const globalSelector = useSelector((state: any) => state);
  const { BOTH, LUSD, ETH } = globalSelector.trove;
  const { collDisplay } = ETH;
  const { IcollateralRatio, isMarketRecovery, normalLPrice, recoveryLPrice } = BOTH;
  const { liquidationReserve, borrowingFee, totalDebt } = LUSD;

  const temp_borrowingFee = isMarketRecovery ? "0.00" : Number(borrowingFee).toFixed(2);

  const { userContractAddress, refetchBalance, isAutomation } = globalSelector.navbar;

  const navigate = useNavigate();
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const { handleCompoundButton } = useStrategy();
  const dispatch = useDispatch();

  const [borrowingFeePercentage, setBorrowingFeePercentage] = useState<number>(0);
  const [lqUSD, setLqUSD] = useState<number>(0);
  const [borrowingUSD, setBorrowingUSD] = useState<number>(0);
  const [totalDebtUSD, setTotalDebtUSD] = useState<number>(0);
  const [enablePayDownOption, setEnablePayDownOptions] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [totalPayDownDebt, setTotalPayDownDebt] = useState<any>();
  const [initialCr, setInitialCr] = useState<any>();
  const [actualDebt, setActualDebt] = useState<any>();
  const [stakingEthGain, setStakingEthGain] = useState<number | any>();
  const [stakingLusdGain, setStakingLusdGain] = useState<number | any>();
  const [stabilityEthGain, setStabilityEthGain] = useState<number | any>();
  const [stabilityLqtyGain, setStabilityLqtyGain] = useState<number | any>();
  const [lusdDeposited, setLusdDeposited] = useState<number | any>();
  const [lqtyDeposited, setLqtyDeposited] = useState<number | any>();
  const [colateralval, setColateralVal] = useState<number | any>();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [stablityCheck, setStablityCheck] = useState<Istabilty>({
    lqty: false,
    eth: false,
  });
  const [stakingCheck, setStakingCheck] = useState<Istaking>({
    lusd: false,
    eth: false,
  });

  const [lusdDepositedChange, setLusdDepositedChange] = useState<any>();
  const [lqtyDepositedChange, setLqtyDepositedChange] = useState<any>();
  const [colateralvalChange, setColateralValChange] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [projectedCR, setProjectedCR] = useState<any>("");
  const [amountToPaydown, setAmountToPaydown] = useState<any>("");
  const [ethInDollers, setEthInDollers] = useState<any>("");
  const [convertCollBalance, setConvertCollBalance] = useState<any>(0);
  const [lusdInDollers, setLusdInDollers] = useState<any>("");
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const localTab = sessionStorage.getItem("currentAdjustTab");

  useEffect(() => {
    convertUSD();
    fetchPayDownStaticValues();
  }, [IcollateralRatio, enablePayDownOption, borrowingFee, ethInDollers, collDisplay]);

  useEffect(() => {
    let data: any = sessionStorage.getItem("stats");
    if (data) {
      data = JSON.parse(data);
      setBorrowingFeePercentage(data.borrowPerc)
    }
  }, []);

  useEffect(() => {
    const _localTab = sessionStorage.getItem("currentAdjustTab");
    setActive(_localTab ? Number(_localTab) : 1);
  }, [localTab]);

  const handleInput = (e: any, toCompareVal: any, toSetMethod: Function) => {
    if (isValid(e.target.value)) {
      const val = validateNumberInput(e);
      if (Number(val) <= Number(toCompareVal) && Number(val) >= 0 && val) {
        toSetMethod(val);
        setIsCalculated(false);
        setIsError(false);
        setErrorMsg("");
        return;
      } else if (!val) {
        setIsCalculated(false);
        toSetMethod(val);
        setIsError(false);
        setErrorMsg("");
        return;
      }
    }
  };

  const convertUSD = async () => {
    const { lusdUSD } = await tokenToUSD();
    const Convert_liquidationReserve = lusdUSD ? Number(lusdUSD) * Number(liquidationReserve) : 0.0;
    const Convert_borrowingFee = lusdUSD ? Number(lusdUSD) * Number(temp_borrowingFee) : 0.0;
    const Convert_totalDebt = lusdUSD ? Number(lusdUSD) * Number(totalDebt) : 0.0;
    const Convert_CollBal = ethInDollers ? Number(ethInDollers) * Number(collDisplay) : 0.0;
    setConvertCollBalance(Convert_CollBal);
    setLqUSD(Convert_liquidationReserve);
    setBorrowingUSD(Convert_borrowingFee);
    setTotalDebtUSD(Convert_totalDebt);
  };

  const handlePaydownOption = (flag: boolean) => {
    setEnablePayDownOptions(flag);
    //document.body.style.overflow = "hidden";
  };

  const fetchPayDownStaticValues = async () => {
    try {
      const minDebt = 2000;
      const [ethGain_, lusdGain, debtEntire, lqtygain_, ethGainStab_, lusdInStability, lqtyStaked, ethInDollar, lusdIndolller] = await Promise.all([
        USER_INSTANCE?.getPendingETHGain(),
        USER_INSTANCE?.getPendingLUSDGain(),
        USER_INSTANCE?.getEntireDebtAndColl(),
        USER_INSTANCE?.getDepositorLQTYGain(),
        USER_INSTANCE?.getDepositorETHGain(),
        USER_INSTANCE?.getCompoundedLUSDDeposit(),
        LUSD_TOKEN_INSTANCE?.balanceOf(account),
        ethToUSD(),
        tokenToUSD(),
      ]);

      const debt: any = debtEntire[0]?.toString();
      const coll = debtEntire[1]?.toString();
      const Initialratio: any = ((Number(formatEther(coll)) * ethInDollar) / Number(formatEther(debt))) * 100;
      const totalDebtToPaydown: any = Number(formatEther(debt)) - minDebt;

      const issuanceGain = Number(formatEther(lusdGain)).toFixed(6);
      const ethGainVal = Number(formatEther(ethGain_)).toFixed(6);

      const lqtyGain = Number(formatEther(lqtygain_)).toFixed(6);
      const ethGainstab = Number(formatEther(ethGainStab_)).toFixed(6);

      const lusdStab = Number(formatEther(lusdInStability)).toFixed(6);
      const lqtyStake = Number(formatEther(lqtyStaked)).toFixed(6);

      setColateralVal(Number(formatEther(coll)) ? formatEther(coll) : 0);
      setLqtyDeposited(Number(lqtyStake) ? lqtyStake : 0);
      setLusdDeposited(Number(lusdStab) ? lusdStab : 0);
      setStabilityEthGain(Number(ethGainstab) ? ethGainstab : 0);
      setStabilityLqtyGain(Number(lqtyGain) ? lqtyGain : 0);
      setStakingLusdGain(Number(issuanceGain) ? issuanceGain : 0);
      setStakingEthGain(Number(ethGainVal) ? ethGainVal : 0);
      setTotalPayDownDebt(totalDebtToPaydown.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      setActualDebt(formatEther(debt));
      setInitialCr(isNaN(Initialratio) ? "0" : Initialratio?.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      setEthInDollers(ethInDollar ? ethInDollar : 0);
      setLusdInDollers(lusdIndolller.lusdUSD ? lusdIndolller.lusdUSD : 0);
    } catch (err) {
      console.error("fetchPayDownStaticValues", err);
    }
  };

  const payDownOptionExecute = async () => {
    try {
      startSpinner();
      const maxFee = parseUnits("0.05", "ether").toString();
      const PAYDOWN_ADDRESS = envAllContractsDetails.PayDown[0];
      const allowance = await LUSD_TOKEN_INSTANCE.allowance(account, PAYDOWN_ADDRESS);

      const convertAmt = parseUnits(Number(lqtyDepositedChange) ? lqtyDepositedChange : "0").toString();
      if (Number(formatEther(convertAmt)) > Number(formatEther(allowance))) {
        const approve_res = await LUSD_TOKEN_INSTANCE.approve(PAYDOWN_ADDRESS, convertAmt);
        await approve_res.wait();
      }
      const randNum = (Math.random() * 100).toFixed(0);

      const hintAddresses = await PAYDOWN_METHOD_INSTANCE?.callStatic.executePaydownStaticCall(
        [stakingCheck.lusd, stakingCheck.eth, stablityCheck.lqty, stablityCheck.eth],
        [
          parseUnits(Number(lusdDepositedChange) ? lusdDepositedChange : "0").toString(),
          parseUnits(Number(colateralvalChange) ? colateralvalChange : "0").toString(),
          parseUnits(Number(lqtyDepositedChange) ? lqtyDepositedChange : "0").toString(),
        ],
        maxFee,
        randNum
      );

      const estimateGas = await PAYDOWN_METHOD_INSTANCE.estimateGas.executePaydown(
        [stakingCheck.lusd, stakingCheck.eth, stablityCheck.lqty, stablityCheck.eth],
        [
          parseUnits(Number(lusdDepositedChange) ? lusdDepositedChange : "0").toString(),
          parseUnits(Number(colateralvalChange) ? colateralvalChange : "0").toString(),
          parseUnits(Number(lqtyDepositedChange) ? lqtyDepositedChange : "0").toString(),
        ],
        maxFee,
        hintAddresses[4],
        hintAddresses[5],
        hintAddresses[6],
        hintAddresses[7],
        hintAddresses[8],
        hintAddresses[9],
        hintAddresses[10],
        hintAddresses[11],
      );

      const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;


      const final_res = await PAYDOWN_METHOD_INSTANCE.executePaydown(
        [stakingCheck.lusd, stakingCheck.eth, stablityCheck.lqty, stablityCheck.eth],
        [
          parseUnits(Number(lusdDepositedChange) ? lusdDepositedChange : "0").toString(),
          parseUnits(Number(colateralvalChange) ? colateralvalChange : "0").toString(),
          parseUnits(Number(lqtyDepositedChange) ? lqtyDepositedChange : "0").toString(),
        ],
        maxFee,
        hintAddresses[4],
        hintAddresses[5],
        hintAddresses[6],
        hintAddresses[7],
        hintAddresses[8],
        hintAddresses[9],
        hintAddresses[10],
        hintAddresses[11],
        { gasLimit: bufferedGas.toFixed(0) },
      );
      dispatch(setTxHash(final_res.hash));
      await final_res.wait();
      dispatch(setTxHash(final_res.hash));
      await fetchPayDownStaticValues();
      dispatch(initialValues(USER_INSTANCE, LUSD_TOKEN_INSTANCE, FACTORY_INSTANCE, library, account, ethToUSD));
      dispatch(setRefetchBalance(!refetchBalance));
      resetStates();
      stopSpinner();
    } catch (err) {
      console.error("payDownOptionExecute", err);
      resetStates();
      handleError(err);
    }
  };

  const checkValidate = async () => {
    try {
      const price = await ethToUSD();
      const checkValues = await PAYDOWN_METHOD_INSTANCE.callStatic.checkOptions(
        userContractAddress,
        [stakingCheck.lusd, stakingCheck.eth, stablityCheck.lqty, stablityCheck.eth],
        [
          parseUnits(Number(lusdDepositedChange) ? lusdDepositedChange : "0").toString(),
          parseUnits(Number(colateralvalChange) ? colateralvalChange : "0").toString(),
          parseUnits(Number(lqtyDepositedChange) ? lqtyDepositedChange : "0").toString(),
        ],
        parseUnits(price.toString()),
      );

      setAmountToPaydown(Number(formatEther(checkValues[1])).toFixed(2));
      setProjectedCR(Number(formatEther(checkValues[3])).toFixed(2));
      setIsDisable(false);
      setIsCalculated(true);
    } catch (err: any) {
      console.error("checkValidate", err.reason);
      setErrorMsg(DISABLE_PAYDOWN_REASON[err.reason]);
      setAmountToPaydown("N/A");
      setProjectedCR("N/A");
      setIsDisable(true);
      setIsCalculated(true);
      setIsError(true);
    }
  };

  const resetStates = () => {
    setStablityCheck({
      lqty: false,
      eth: false,
    });
    setStakingCheck({
      lusd: false,
      eth: false,
    });

    setLusdDepositedChange("");
    setLqtyDepositedChange("");
    setColateralValChange("");
    setIsCalculated(false);
    setIsError(false);
    setErrorMsg("");
  };

  const tempDisabled =
    selectedCompoundStrategyID !== "1" &&
    selectedCompoundStrategyID !== "3" &&
    (selectedCompoundStrategyID !== "5" || (selectedCompoundStrategyID === "5" && !selectedLoanCompoundingTargetUsingTrove));
  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Trove." : disablingReason;
  const buttonText = selectedCompoundStrategyID === "5" && selectedLoanCompoundingTargetUsingTrove ? "Compound Custom" : "Compound Debt";

  const tempNormalLprice = Number(envChainId) === ChainId?.eth ? formatNumbersWithComma(Number(normalLPrice), 2) : formatNumbersWithComma(Number(normalLPrice), 6);
  const tempRecoveryLprice = Number(envChainId) === ChainId?.eth ? formatNumbersWithComma(Number(recoveryLPrice), 2) : formatNumbersWithComma(Number(recoveryLPrice), 6);

  return (
    <>
      <RightBoxCntr>
        <ContainerBox custFlex="0 1 0" customGap={"0.5em"}>
          <LightText fOpacity="36%" fSize="10px">
            LOAN INFORMATION
          </LightText>
          <RowFlex>
            <LightText fSize="14px">Liquidation Reserve</LightText>
            <Para tAlign="end" fWeight="500">
              {`${formatNumbersWithComma(liquidationReserve, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(lqUSD, 2)})`}
            </Para>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">Borrowing Fee Percentage</LightText>
            <Para tAlign="end" fWeight="500">
              {borrowingFeePercentage}%
            </Para>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">Borrowing Fee</LightText>
            <Para tAlign="end" fWeight="500">
              {`${formatNumbersWithComma(temp_borrowingFee, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(borrowingUSD, 2)})`}
            </Para>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">{`Deposited ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}</LightText>
            <Para tAlign="end" fWeight="500">
              {` ${formatNumbersWithComma(collDisplay, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertCollBalance, 2)})`}
            </Para>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">Total Debt</LightText>
            <Para tAlign="end" fWeight="500">
              {Number(totalDebt) > 200
                ? `${formatNumbersWithComma(totalDebt, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(totalDebtUSD, 2)})`
                : `0.00 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
            </Para>
          </RowFlex>
          <RowFlex>
            <RowFlex align="center" customGap="5px">
              <LightText fSize="14px">Collateral Ratio</LightText>
              <Tooltip
                customWidth="15em"
                // customPad="0 10px 0 0"
                data-tooltip={envAllDescriptionDetails.COLLATERAL_RATIO_TIP_TEXT}
              >
                <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
              </Tooltip>
            </RowFlex>
            <Para tAlign="end" fcolor={theme.primaryButton} fWeight="500">
              {IcollateralRatio ? IcollateralRatio + "%" : "N/A"}
            </Para>
          </RowFlex>
          <RowFlex>
            <RowFlex align="center" customGap="5px">
              <LightText fSize="14px">Liquidation Price (Normal Mode)</LightText>
              <TooltipTop customWidth="15em" data-tooltip={commonContractsDescription.LIQUIDATION_PRICE_NORAMAL_TEXT}>
                <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
              </TooltipTop>
            </RowFlex>
            <Para tAlign="end" fWeight="500">
              {normalLPrice ? "$" + tempNormalLprice : "N/A"}
            </Para>
          </RowFlex>
          <RowFlex>
            <RowFlex align="center" customGap="5px">
              <LightText fSize="14px">Liquidation Price (Recovery Mode)</LightText>
              <TooltipTop customWidth="15em" data-tooltip={commonContractsDescription.LIQUIDATION_PRICE_RECOVERY_TEXT}>
                <SvgIcon height="10px" src={infoIcon} alt="info-icon" />
              </TooltipTop>
            </RowFlex>
            <Para tAlign="end" fWeight="500">
              {recoveryLPrice ? "$" + tempRecoveryLprice : "N/A"}
            </Para>
          </RowFlex>
          <>
            {selectedCompoundStrategyID === "1" || selectedCompoundStrategyID === "3" || (selectedCompoundStrategyID === "5" && selectedLoanCompoundingTargetUsingTrove) ? (
              <>
                <RowFlex>
                  <LightText fSize="14px">Target Collateral Ratio</LightText>
                  <Para tAlign="end" fcolor={theme.primaryButton} fWeight="500">
                    {`${Number(targetCollateralRatio) ? targetCollateralRatio : 0}%`}
                  </Para>
                </RowFlex>
                <RowFlex>
                  <LightText fSize="14px">Trigger Collateral Ratio</LightText>
                  <Para tAlign="end" fcolor={theme.primaryButton} fWeight="500">
                    {`${Number(triggerCollateralRatio) ? triggerCollateralRatio : 0}%`}
                  </Para>
                </RowFlex>
              </>
            ) : (
              ""
            )}
          </>
          <RowFlex>
            <LightText fSize="14px">Recovery Mode</LightText>
            <Para tAlign="end" fcolor={theme.primaryButton} fWeight="500">
              {isMarketRecovery ? "Yes" : "No"}
            </Para>
          </RowFlex>
          {selectedCompoundStrategy ? (
            <RowFlex>
              <LightText fSize="14px">Selected Compound Strategy</LightText>
              <LinkPara onClick={() => navigate(strategyPath)} tAlign="end" fWeight="500">
                {selectedCompoundStrategy}
              </LinkPara>
            </RowFlex>
          ) : (
            ""
          )}
          <RowFlex>
            <LightText fSize="14px">Autopilot Enabled?</LightText>
            <Para tAlign="end" fWeight="500">
              {isAutomation ? "Yes" : "No"}
            </Para>
          </RowFlex>
          <TroveLoanCompoundingPath />
          <RowFlex>
            <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText={buttonText} handleCompoundButton={handleCompoundButton} />
          </RowFlex>
          {active === 4 ? (
            <ColFlex>
              <Button
                btnType={"borderedfilledLightButton"}
                fSizeMobile="12px"
                onClick={() => {
                  handlePaydownOption(true);
                }}
                wrapperWidth="100%"
                isDisabled={!Number(totalDebt)}
              >
                Other Paydown Methods
              </Button>
            </ColFlex>
          ) : (
            ""
          )}
        </ContainerBox>
      </RightBoxCntr>

      {/* paydown option */}
      <CustomModal
        isToolTip
        isScroll
        isPersistent
        show={enablePayDownOption}
        toggleModal={(p: boolean) => {
          handlePaydownOption(p);
          resetStates();
          //document.body.style.overflow = "unset";
        }}
        heading={"Other Paydown Methods"}
        custMaxWidth="410px"
      >
        <TargetDebtBox>
          <PayDownBox>
            <Greytext>Target Debt to Paydown </Greytext>
            <AmountText>
              {Number(totalPayDownDebt) > 0
                ? formatNumbersWithComma(totalPayDownDebt, 3) + " " + envAllDescriptionDetails.STABILITY_TOKEN_TEXT
                : `0.00 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}{" "}
              <Greytext fontWeight="500" fontSize="12px" >
                {`($${formatNumbersWithComma(Number(totalPayDownDebt) > 0 ? Number(totalPayDownDebt) * Number(lusdInDollers) : 0 * Number(lusdInDollers), 2)})`}
              </Greytext>{" "}
            </AmountText>
          </PayDownBox>
          <CrBox>
            <CrInner isBorder={true}>
              <Greytext fontWeight="400" fontSize="12px">
                Initial CR
              </Greytext>
              <AmountText fontWeight="500" fontSize="12px">
                {Number(initialCr) ? initialCr + "%" : "0.00%"}
              </AmountText>
            </CrInner>
            <CrInner align="center" isBorder={true}>
              <Greytext fontWeight="400" fontSize="12px">
                Actual Debt
              </Greytext>
              <AmountText fontWeight="500" fontSize="12px">
                {formatNumbersWithComma(actualDebt, 3)} {envAllDescriptionDetails.STABILITY_TOKEN_TEXT}{" "}
              </AmountText>
              <p style={{ color: "#ffffff80", fontSize: '12px' }}>{`($${formatNumbersWithComma(actualDebt * Number(lusdInDollers), 2)})`}</p>
            </CrInner>
            <CrInner align="center">
              <Greytext fontWeight="400" fontSize="12px">
                Min. Debt
              </Greytext>
              <AmountText fontWeight="500" fontSize="12px">
                {`2,000 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
              </AmountText>
              <p style={{ color: "#ffffff80", fontSize: '12px' }}>{"($" + formatNumbersWithComma(Number(2000 * Number(lusdInDollers)) ? 2000 * Number(lusdInDollers) : 0, 2) + ")"}</p>
            </CrInner>
          </CrBox>
        </TargetDebtBox>
        <ColFlex customPaddingBottom="10px" borderBottom={"1px solid #667085"} align="flex-start">
          <Greytext fontSize="16px">Staking Pool Rewards</Greytext>
          <RowFlex align="center" maxWidth="100%">
            <CheckBoxDiv isDisable={!Number(stakingLusdGain)}>
              <input type="checkbox" name="scales" id="scales" checked={stakingCheck.lusd} />
              <label
                htmlFor="scales"
                onClick={() => {
                  setIsCalculated(false);
                  setStakingCheck({
                    ...stakingCheck,
                    lusd: !stakingCheck.lusd,
                  });
                }}
              ></label>
              <AmountText fontSize="12px">{`${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} Rewards`}</AmountText>
            </CheckBoxDiv>
            <AmountText fontSize="12px">
              {`${formatNumbersWithComma(Number(stakingLusdGain) ? stakingLusdGain : 0, 6)}  ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
            </AmountText>
          </RowFlex>
          <RowFlex align="center" maxWidth="100%">
            <CheckBoxDiv isDisable={!Number(stakingEthGain)}>
              <input type="checkbox" name="scales" id="scales" checked={stakingCheck.eth} />
              <label
                htmlFor="scales"
                onClick={(e: any) => {
                  setIsCalculated(false);
                  setStakingCheck({ ...stakingCheck, eth: !stakingCheck.eth });
                }}
              ></label>
              <AmountText fontSize="12px" fontWeight="500">
                {envAllDescriptionDetails.VAULT_TOKEN_TEXT} Rewards
              </AmountText>
              <Tooltip customWidth="15em" data-tooltip={envAllDescriptionDetails.PAYDOWN_TOOLTIP}>
                <SvgIcon marginLeft="4px" heightM="12px" width="12px" src={infoIcon} alt="info-icon" />
              </Tooltip>
            </CheckBoxDiv>
            <AmountText fontSize="12px">{`${formatNumbersWithComma(Number(stakingEthGain) ? stakingEthGain : 0, 6)}  ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}</AmountText>
          </RowFlex>
        </ColFlex>
        <ColFlex customPaddingBottom="10px" borderBottom={"1px solid #667085"} align="flex-start">
          <Greytext fontSize="16px">Stability Pool Rewards</Greytext>
          <RowFlex align="center" maxWidth="100%">
            <CheckBoxDiv isDisable={!Number(stabilityLqtyGain)}>
              <input type="checkbox" name="scales" id="scales" checked={stablityCheck.lqty} />
              <label
                htmlFor="scales"
                onClick={() => {
                  setIsCalculated(false);
                  setStablityCheck({
                    ...stablityCheck,
                    lqty: !stablityCheck.lqty,
                  });
                }}
              ></label>
              <AmountText fontSize="12px">{`${envAllDescriptionDetails.STAKING_TOKEN_TEXT} Rewards`}</AmountText>
            </CheckBoxDiv>
            <AmountText fontSize="12px">
              {`${formatNumbersWithComma(Number(stabilityLqtyGain) ? stabilityLqtyGain : 0, 6)}  ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
            </AmountText>
          </RowFlex>
          <RowFlex align="center" maxWidth="100%">
            <CheckBoxDiv isDisable={!Number(stabilityEthGain)}>
              <input type="checkbox" name="scales" id="scales" checked={stablityCheck.eth} />
              <label
                htmlFor="scales"
                onClick={() => {
                  setIsCalculated(false);
                  setStablityCheck({
                    ...stablityCheck,
                    eth: !stablityCheck.eth,
                  });
                }}
              ></label>
              <AmountText fontSize="12px" fontWeight="500">
                {envAllDescriptionDetails.VAULT_TOKEN_TEXT} Rewards
              </AmountText>
              <Tooltip customWidth="15em" data-tooltip={envAllDescriptionDetails.PAYDOWN_TOOLTIP}>
                <SvgIcon marginLeft="4px" heightM="12px" width="12px" src={infoIcon} alt="info-icon" />
              </Tooltip>
            </CheckBoxDiv>
            <AmountText fontSize="12px">{`${formatNumbersWithComma(Number(stabilityEthGain) ? stabilityEthGain : 0, 6)}  ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}</AmountText>
          </RowFlex>
        </ColFlex>
        <ColFlex marginBottom="20px">
          <RowFlex justify="space-between">
            <AmountText fontSize="11px">
              {" "}
              <Greytext fontSize="11px">Withdraw </Greytext>
              {envAllDescriptionDetails.STABILITY_TOKEN_TEXT} <Greytext fontSize="11px">(from stability Pool)</Greytext>
            </AmountText>
            <AmountText fontSize="11px">
              <Greytext fontSize="11px">Balance: </Greytext>
              {`${formatNumbersWithComma(Number(lusdDeposited) ? lusdDeposited : 0, 6)}  ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}{" "}
              <Greytext fontSize="11px">{`($${formatNumbersWithComma(lusdDeposited * lusdInDollers, 2)})`}</Greytext>
            </AmountText>
          </RowFlex>
          <CustomInput
            fSize="14px"
            custImageHeight="16px"
            custImageWidth="16px"
            icon={envAllDescriptionDetails.STABILITY_LOGO}
            placeholder={`0.00 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($0.00)`}
            value={lusdDepositedChange}
            onChange={(e: any) => {
              handleInput(e, lusdDeposited, setLusdDepositedChange);
            }}
          />
        </ColFlex>
        <ColFlex marginBottom="20px">
          <RowFlex justify="space-between">
            <AmountText fontSize="11px">
              <Greytext fontSize="11px">Withdraw </Greytext>
              {envAllDescriptionDetails.VAULT_TOKEN_TEXT} <Greytext fontSize="11px">(from Trove)</Greytext>
            </AmountText>
            <AmountText fontSize="11px">
              <Greytext fontSize="11px">Balance: </Greytext>
              {`${formatNumbersWithComma(Number(colateralval) ? colateralval : 0, 6)}  ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`}{" "}
              <Greytext fontSize="11px">{`($${formatNumbersWithComma(colateralval * ethInDollers, 2)})`}</Greytext>
            </AmountText>
          </RowFlex>
          <CustomInput
            fSize="14px"
            custImageHeight="16px"
            custImageWidth="16px"
            placeholder={`0.00 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($0.00)`}
            icon={envAllDescriptionDetails.TROVE_LOGO}
            value={colateralvalChange}
            onChange={(e: any) => {
              handleInput(e, colateralval, setColateralValChange);
            }}
          />
        </ColFlex>
        <ColFlex>
          <RowFlex justify="space-between">
            <AmountText fontSize="11px">
              <Greytext fontSize="11px">Withdraw </Greytext>
              {envAllDescriptionDetails.STABILITY_TOKEN_TEXT} <Greytext fontSize="11px">(from wallet)</Greytext>
            </AmountText>
            <AmountText fontSize="11px">
              <Greytext fontSize="11px">Balance: </Greytext>{" "}
              {`${formatNumbersWithComma(Number(lqtyDeposited) ? lqtyDeposited : 0, 6)}  ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}{" "}
              <Greytext fontSize="11px">{`($${formatNumbersWithComma(lqtyDeposited * lusdInDollers, 2)})`}</Greytext>
            </AmountText>
          </RowFlex>
          <CustomInput
            fSize="14px"
            custImageHeight="16px"
            custImageWidth="16px"
            icon={envAllDescriptionDetails.STABILITY_LOGO}
            placeholder={`0.00 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($0.00)`}
            value={lqtyDepositedChange}
            onChange={(e: any) => {
              handleInput(e, lqtyDeposited, setLqtyDepositedChange);
            }}
          />
        </ColFlex>
        {isCalculated ? (
          <ColFlex align="flex-start">
            <Greytext marginBottom="5px" fontWeight="500" fontSize="14px">
              Projected Value and Paydown Details
            </Greytext>
            <RowFlex maxWidth="100%">
              <AmountCRPayDownDiv>
                <Greytext fontSize="12px">Projected Debt</Greytext>
                <AmountText fontSize="18px">
                  {Number(amountToPaydown)
                    ? formatNumbersWithComma(Number(amountToPaydown), 6) + envAllDescriptionDetails.STABILITY_TOKEN_TEXT
                    : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`}
                </AmountText>
                <p style={{ color: "#ffffff80", fontSize: "18px" }}>
                  {Number(amountToPaydown) ? "($" + formatNumbersWithComma(Number(amountToPaydown) * lusdInDollers, 2) + ")" : "$0"}
                </p>
              </AmountCRPayDownDiv>
              <AmountCRPayDownDiv>
                <Greytext fontSize="12px">Projected CR of Vault</Greytext>
                <AmountText Color={true} fontSize="18px">
                  {Number(projectedCR) ? projectedCR + " %" : "N/A"}
                </AmountText>
              </AmountCRPayDownDiv>
            </RowFlex>
          </ColFlex>
        ) : (
          ""
        )}
        {isError && errorMsg ? <Notification type="error" message={errorMsg}></Notification> : ""}
        <BottonWrapperPayDown>
          {!isCalculated ? (
            <Button
              btnType={"gradientFilledButton"}
              customWidth={"10em"}
              fSizeMobile="12px"
              isDisabled={
                !stablityCheck.lqty &&
                !stablityCheck.eth &&
                !stakingCheck.lusd &&
                !stakingCheck.eth &&
                !Number(lusdDepositedChange) &&
                !Number(lqtyDepositedChange) &&
                !Number(colateralvalChange)
              }
              onClick={async () => {
                await checkValidate();
              }}
            >
              Calculate
            </Button>
          ) : (
            <Button
              btnType={"gradientFilledButton"}
              customWidth={"10em"}
              fSizeMobile="12px"
              isDisabled={isDisable}
              onClick={async () => {
                handlePaydownOption(false);
                //document.body.style.overflow = "unset";
                await payDownOptionExecute();
              }}
            >
              Confirm
            </Button>
          )}

          <Button
            btnType={"borderedfilledButton"}
            className="btnWrapper"
            fSizeMobile="12px"
            customWidth={"10em"}
            onClick={() => {
              resetStates();
              handlePaydownOption(false);
              //document.body.style.overflow = "unset";
            }}
          >
            Cancel
          </Button>
        </BottonWrapperPayDown>
      </CustomModal>
    </>
  );
});
