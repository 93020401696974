// navbar
export const SET_CONNECT_WALLET = "SET_CONNECT_WALLET";
export const SET_DISCONNECT_WALLET = "SET_DISCONNECT_WALLET";
export const SET_USERADDRESS = "SET_USERADDRESS";
export const SET_SHOW_STATS = "SET_SHOW_STATS";
export const SET_IS_LOGOFF = "SET_IS_LOGOFF";
export const SET_IS_AUTOMATION = "SET_IS_AUTOMATION";
export const SET_IS_CONNECTOR_ID = "SET_IS_CONNECTOR_ID";
export const SET_IS_SYNCED_WITH_API = "SET_IS_SYNCED_WITH_API";
export const REFETCH_BALANCE = "REFETCH_BALANCE";
export const ISAUTOMATION_MODAL_OPEN = "ISAUTOMATION_MODAL_OPEN";
export const ISAUTOMATION_LOG_MODAL_OPEN = "ISAUTOMATION_LOG_MODAL_OPEN";
export const SET_AUTOMATION_LOGS = "SET_AUTOMATION_LOGS";
export const SET_MAX_CAP = "SET_MAX_CAP";
export const SET_AUTOMATION_RUN = "SET_AUTOMATION_RUN";
export const SET_AUTOMATION_BALANCE = "SET_AUTOMATION_BALANCE";
export const SET_AVERAGE_FEE = "SET_AVERAGE_FEE";
export const SET_ISFETCHED = "SET_ISFETCHED"

// notification
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const TX_HASH = "TX_HASH";

// JWT
export const SIGN_VERIFICATION = "SIGN_VERIFICATION";

//Hide
export const HIDE_MODULE = "HIDE_MODULE";

//Trove
export const SET_ETH_VALUES = "SET_ETH_VALUES";
export const SET_LUSD_VALUES = "SET_LUSD_VALUES";
export const SET_BOTH_VALUES = "SET_BOTH_VALUES";

//Stake
export const SET_STAKE_VALUES = "SET_STAKE_VALUES";
export const SET_STAKE_ACTIVE_TAB = "SET_STAKE_ACTIVE_TAB";

//Stability
export const SET_STABILITY_VALUES = "SET_STABILITY_VALUES";

// Strategy Constants
export const ERROR_STRAT = "ERROR_STRAT";
export const ISLOADING = "ISLOADING";
export const ISMODALON = "ISMODALON";
export const MODALVIEW = "MODALVIEW";
export const SELECTEDCOMPOUNDSTRATEGY = "SELECTEDCOMPOUNDSTRATEGY";
export const SELECTEDCOMPOUNDSTRATEGYID = "SELECTEDCOMPOUNDSTRATEGYID";
export const SELECTEDLOANCOMPOUNDINGTARGET = "SELECTEDLOANCOMPOUNDINGTARGET";
export const SELECTEDLOANCOMPOUNDINGTARGETID = "SELECTEDLOANCOMPOUNDINGTARGETID";
export const SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE = "SELECTEDLOANCOMPOUNDINGTARGETUSINGTROVE";
export const SELECTEDSPCOMPOUNDINGTARGETID = "SELECTEDSPCOMPOUNDINGTARGETID";
export const SELECTEDSTAKINGCOMPOUNDINGTARGETID = "SELECTEDSTAKINGCOMPOUNDINGTARGETID";
export const SELECTEDSPCOMPOUNDINGTARGET = "SELECTEDSPCOMPOUNDINGTARGET";
export const SELECTEDSTAKINGCOMPOUNDINGTARGET = "SELECTEDSTAKINGCOMPOUNDINGTARGET";
export const UPDATABLEKEY = "UPDATABLEKEY";
export const ISRATIOSHOWN = "ISRATIOSHOWN";
export const STRATEGYINSTANCE = "STRATEGYINSTANCE";
export const ISCOMPOUND = "ISCOMPOUND";
export const ISSTABILITYCOMPOUND = "ISSTABILITYCOMPOUND";
export const ISSTAKINGCOMPOUND = "ISSTAKINGCOMPOUND";
export const STRATEGYADDRESS = "STRATEGYADDRESS";
export const TARGETCOLLATERALRATIO = "TARGETCOLLATERALRATIO";
export const TRIGGERCOLLATERALRATIO = "TRIGGERCOLLATERALRATIO";
export const DISABLINGREASON = "DISABLINGREASON";
export const DISABLINGSTABILITYREASON = "DISABLINGSTABILITYREASON";
export const DISABLINGSTAKINGREASON = "DISABLINGSTAKINGREASON";
export const DISABLE = "DISABLE";
export const ISVALUESCHANGED = "ISVALUESCHANGED";
export const ESTIMATE_GAS = "ESTIMATE_GAS";
export const IS_LIQUIDATE_TROVE = "IS_LIQUIDATE_TROVE";
export const LIQUIDATE_TROVE_DATE = "LIQUIDATE_TROVE_DATE";
export const SLIPPAGE = "SLIPPAGE";

