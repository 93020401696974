
import styled from "styled-components";
import { colors, screenSizes } from "styles/theme";


export const FlexContainer = styled.div`
    width: 80%;
    max-width: 1100px;

    @media (max-width: 820px) {
      width: unset;
      max-width: 720px;
    
    }

    a {
        color: ${(props: any) => props.theme.pink1};
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
    }
`
export const TroveContainer = styled.div`
    display: flex;
    gap: 1.5em;

    @media (max-width: ${screenSizes.M}px) {
        flex-direction: column;
    }
`


export const InputBox = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
`
export const InputLabel = styled.div`
    display: flex;
    justify-content: space-between;
    .leftLabel {
        .text1 {
            color: ${(props) => props.theme.fadedWhite};
            margin-right: 4px;
        }
        .text2 {
            color: ${(props) => props.theme.white};
        }
    }
    .rightLabel {
      text-align: right;
        .text1 {
            color: ${(props) => props.theme.fadedWhite};
            font-size: 12px;
        }
        .text2 {
            color: ${(props) => props.theme.white};
            font-size: 12px;
        }
    }
`
export const InputValueBox = styled.div`
display: flex;
align-items: center;
gap: 1em;

>div {
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #FFFFFF;
}
`


export const InputControlsCntr = styled.div`
    display: flex;
    justify-content: end;
`
export const RowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
`
interface ITileProps {
  hov?: boolean
}
export const DarkTile = styled.div<ITileProps>`
  border-radius: 8px;
  padding: 7px 12px;
  background: ${(props: any) => props.theme.darkestGray};
  color: ${(props: any) => props.theme.white};
  cursor: ${(props: any) => props.hov ? 'pointer' : ''};
`
export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
export const BtnRowContainer = styled.div<IInputContainerProps>`
  display: flex;
  width: 100%;
  gap: 1em;
  justify-content: ${({ justify }: any) => justify || 'space-between'};
`
interface IInputContainerProps {
  width?: string
  justify?: string
  minwidth?: string
}
export const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  min-width: ${({ minwidth }: any) => minwidth};
  width: ${({ width }: any) => width};
  gap: 1em;
  justify-content: start;
`

export const ModalListContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`
export const AddMoreCntr = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

`
interface IinputBreakpoints {
  left?: string,
  hide?: boolean,
  right?: string,
  hoverActive?: boolean,
  setHoverActive?: any,
  rangeValue?: number | any,
  target?: string,
  trigger?: string,
  isPointer?: boolean
}

export const InputBreakPoints = styled.div<IinputBreakpoints>`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  z-index: ${(props) => props.hide ? "-1" : "1"};
  box-shadow: 0 0 0 2px white;
  
  @supports (-moz-appearance:none) {
    top: 46%;
  }
`

export const BreakPoint = styled.span<IinputBreakpoints>`
    position: absolute;
    top: 120%;
    transform: translateY(-50%);
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    color: ${colors.greyText};
`

export const RangeToolTip = styled.div<IinputBreakpoints>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background: black;
  padding: 5px 5px 5px 5px;
  width: 50px;
  color: white;
  border-radius: 8px;
  left: ${(props) => `calc(${props.left}% + (${-18 - Number(props.left) * 0.15}px))`};
  right: ${(props) => props.right};
  display: ${(props) => props.hoverActive ? "block" : "none"};
  &::after {
    content: '';
    position: absolute;
    -webkit-transform: translate(0, -50%) rotate(0deg);
    -ms-transform: translate(0, -50%) rotate(0deg);
    -webkit-transform: translate(0, -50%) rotate(0deg);
    -ms-transform: translate(0, -50%) rotate(0deg);
    transform:  translate(0, -50%) rotate(0deg);
    top: -12%;
    left: 34%;
    border-radius: 2px;
    border-color: transparent transparent black;
    border-style: solid;
    border-width: 0px 8px 8px;
    /* z-index: 3000; */
  }
`
export const RangeInputContainer = styled.div<IinputBreakpoints>`
position: relative;
margin-bottom:${(props: any) =>props?.isPointer ? "20px" : "" };
input[type=range] {
    height: 26px;
    -webkit-appearance: none;
    width: 100%;
    background: inherit;
  }

  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: ${(props: any) => `linear-gradient(to right, #12f79c 0%, #12f79c ${props.rangeValue}%, #66708580 ${props.rangeValue}%, #66708580 100%)`
  };
    border-radius: 10px;
    border: 0px solid #000100;
  }
  
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 0px #000031;
    border: 1px solid #12f79c;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #F9FAFB;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
    /* z-index: 99999999999 !important; */
  }
  
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: ${(props: any) => `linear-gradient(to right, #12f79c 0%, #12f79c ${props.rangeValue}%, #66708580 ${props.rangeValue}%, #66708580 100%)`

  };
     border-radius: 10px;
    border: 0px solid #000100;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 0px #000031;
    border: 1px solid #12f79c;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #F9FAFB;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
    /* z-index: 99999999999 !important; */
  }

  input[type=range]::-ms-track {
     width: 100%;
    height: 8px;
    cursor: pointer;
    background: ${(props: any) => `linear-gradient(to right, #12f79c 0%, #12f79c ${props.rangeValue}%, #66708580 ${props.rangeValue}%, #66708580 100%)`

  };
     border-radius: 10px;
    border: 0px solid #000100;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px 0px #000031;
    border: 1px solid #12f79c;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #F9FAFB;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
    /* z-index: 99999999999 !important; */
  }  
`

export const TargetDebtBox = styled.div`
/* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px;
    gap: 13px;
  
    border: 1px solid #12F79C;
    border-radius: 6px;


`

export const PayDownBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #667085;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
`

export const CrBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;

`
interface ICrInner {
  isBorder?: boolean
  align?: string
}
export const CrInner = styled.div<ICrInner>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: ${(props) => props.align ? props.align : 'flex-start'};
  padding: 5px;
  border-right: ${(props) => props.isBorder ? '1px solid #667085' : ""};
  height: 60px;
  width: 30%;
`

interface ICheckbox {
  isDisable?: boolean
}

export const CheckBoxDiv = styled.div<ICheckbox>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => props.isDisable ? "0.5" : "1"};
  pointer-events: ${(props) => props.isDisable ? "none" : ""};
  
   input[type=checkbox] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
    z-index: 10;
  }
   label {
    position: relative;
    /* pointer-events: none; */
  }
   label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2;
    margin: 5px;
    width: 16px;
    height: 16px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    background: #667085;
  }
   label:after {
    content: "";
    display: block;
    width: 8px;
    height: 5px;
    border-bottom: 2px solid #12f79c;
    border-left: 2px solid #12f79c;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 12px;
    left: 8px;
  }
   input[type=checkbox]:checked ~ label::before {
    color: #7bbe72;
  }
   input[type=checkbox]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
   label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    /* display: block; */
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    vertical-align: sub;
  }
   input[type=checkbox]:focus + label::before {
    outline: 0;
  }
`

export const AmountCRPayDownDiv = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 13px;
  width: 40%;
  height: 80px;
  border: 1px solid #12F79C;
  border-radius: 6px;

  @media (max-width: ${screenSizes.M}px) {
    width: 35%;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-start;

    p{
      font-size: 15px;
    }
  }
`

export const BottonWrapperPayDown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${screenSizes.XXS}px) {
   button{
    min-width: 9em;
    width: 9em;
   }
  }
`