import { setTxHash } from "logic/redux/actions";
import { createContext, Reducer, useReducer } from "react";
import { useDispatch } from "react-redux";

export const InitialState: any = {
  transactionModal: false,
  transactionHeading: "",
  transactionImage: "",
  isSpinner: false,
  isPersistent: false,
};

export const TransactionContext = createContext(InitialState);

export const transactionReducer = (state: any, action: any) => {
  if (action.type === "reset") {
    return InitialState;
  }
  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

export const TransactionProvider = ({ children }: any) => {
  const [transactionStatus, dispatchTransactionEvents] = useReducer<Reducer<any, any>, any>(transactionReducer, InitialState, () => InitialState);
  const reduxDispatch = useDispatch();

  const dispatchTransactionContextEvents = (actionType: any, payload: any) => {
    switch (actionType) {
      case "START_SPINNER": {
        //document.body.style.overflow = "hidden";
        dispatchTransactionEvents({ type: "isSpinner", value: true });
        dispatchTransactionEvents({ type: "isPersistent", value: true });
        dispatchTransactionEvents({
          type: "transactionHeading",
          value: payload.transactionHeading,
        });
        dispatchTransactionEvents({ type: "transactionModal", value: true });
        dispatchTransactionEvents({
          type: "transactionImage",
          value: payload.transactionImage,
        });
        break;
      }
      case "STOP_SPINNER": {
        //document.body.style.overflow = "unset";
        dispatchTransactionEvents({ type: "isSpinner", value: false });
        dispatchTransactionEvents({ type: "isPersistent", value: false });
        dispatchTransactionEvents({
          type: "transactionHeading",
          value: payload.transactionHeading,
        });
        dispatchTransactionEvents({ type: "transactionModal", value: true });
        dispatchTransactionEvents({
          type: "transactionImage",
          value: payload.transactionImage,
        });
        if (payload.transactionHeading === "Transaction Successful" || payload.transactionHeading === "Transaction Failed") {
          setTimeout(() => {
            dispatchTransactionEvents({ type: "transactionModal", value: false });
            reduxDispatch(setTxHash(""));
          }, 5000);
        }
        break;
      }
      default:
        dispatchTransactionEvents({ type: "reset" });
        break;
    }
  };
  return <TransactionContext.Provider value={{ transactionStatus, dispatchTransactionContextEvents }}>{children}</TransactionContext.Provider>;
};
