import { RadioBtn } from "shared/radioBtn/RadioBtn";
import { CustomText } from "shared/Typography";
import { ModalListContainer } from "./style";
import { useEffect, useState } from "react";
import { commonContractsDescription } from "shared/helpers/utils";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

interface ICompoundStrategyProps {
  setStrategy?: any;
  isDeploy?: string;
}

export const CompoundStrategy = (props: ICompoundStrategyProps) => {
  const { setStrategy, isDeploy } = props;

  const Location = useLocation();
  const activePath = Location.pathname.split("/")[1];
  let selectors: any = sessionStorage.getItem(commonContractsDescription.SELECTORS) || "{}";
  const [strategies, setStrategies] = useState<any>(null);
  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

  const { selectedCompoundStrategy } = useSelector((state: any) => state.strategyRedu);

  useEffect(() => {
    init();
  }, [selectors]);

  const getStrategiForPage = (allStrategies: any) => {
    if (Location.pathname === "/" || Location.pathname === "/strategy") return allStrategies;
    return allStrategies.filter((el: any) => el.strategyType.toLowerCase() === activePath || el.strategyType.toLowerCase() === commonContractsDescription.COMMON);
  };
  const init = async () => {
    selectors = JSON.parse(selectors);
    if (selectors) {
      const storedCompoundStrategy = selectors?.strategies;
      let pageStrategy = getStrategiForPage(storedCompoundStrategy);
      if (isDeploy === "yes") {
        pageStrategy = pageStrategy?.filter((strategy: any) => strategy.id !== "5");
      }
      setStrategies(pageStrategy);
    }
  };

  return (
    <ModalListContainer>
      <CustomText fLineHeight="28px" fSizeM="20px" fSize="24px" tAlign="start">
        Select Strategy
      </CustomText>
      {strategies?.map((opt: any, index: number) => {
        const description = Number(envChainId) === ChainId?.eth ? opt?.ethDescription : opt?.pulseDescription;
        return (
          <RadioBtn
            infoMsg={description || ""}
            name="strategy"
            radioId={opt.id}
            value={opt.strategyName}
            onChange={setStrategy}
            checked={selectedCompoundStrategy === opt.strategyName}
            key={index}
          />
        );
      })}
    </ModalListContainer>
  );
};
