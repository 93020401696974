import { SET_ETH_VALUES, SET_LUSD_VALUES, SET_BOTH_VALUES } from "../actions/constant";

interface IETHState {
  priceEthDisplay: any;
  priceEth: any;
  convertPriceEth: any;
  balance: any;
  collDisplay: number;
  coll: any;
  rangeValue: number;
}
interface ILUSDState {
  priceUSDLDisplay: any;
  priceUSDL: any;
  totalDebt: any;
  borrowingFee: any;
  powerCityFee: string;
  lusdInWallet: any;
  lusdInWalletDisplay: any;
  convertPriceUSDL: any;
  liquidationReserve: any;
  debt: any;
  debtDisplay: any;
  sliderValue: number;
  sliderMaxValue: number;
  isAdjustSliderShown: boolean;
}
interface IBOTHState {
  mode: any;
  IcollateralRatio: any;
  normalLPrice: any;
  recoveryLPrice: any;
  isMarketRecovery: boolean;
  err: string;
  viewCollateralRatio: any;
  isLoading: boolean;
  isAdjusting: boolean;
}

interface IInitialState {
  ETH: IETHState;
  LUSD: ILUSDState;
  BOTH: IBOTHState;
}
const initialState: IInitialState = {
  ETH: {
    priceEthDisplay: "0",
    priceEth: "0",
    convertPriceEth: 0,
    balance: 0,
    collDisplay: 0,
    coll: 0,
    rangeValue: 0,
  },
  LUSD: {
    priceUSDLDisplay: "0",
    priceUSDL: "0",
    convertPriceUSDL: 0,
    totalDebt: "0",
    liquidationReserve: "0",
    borrowingFee: "0",
    powerCityFee: "0",
    lusdInWallet: "",
    lusdInWalletDisplay: "",
    debt: 0,
    debtDisplay: 0,
    sliderValue: 0,
    sliderMaxValue: 0,
    isAdjustSliderShown: true,
  },
  BOTH: {
    mode: 0,
    IcollateralRatio: "0",
    normalLPrice: 0,
    recoveryLPrice: 0,
    isMarketRecovery: false,
    err: "",
    viewCollateralRatio: 0,
    isLoading: true,
    isAdjusting: false,
  },
};

export const troveReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case SET_ETH_VALUES:
      return {
        ...state,
        ETH: {
          ...state.ETH,
          ...payload,
        },
      };
    case SET_LUSD_VALUES:
      return {
        ...state,
        LUSD: {
          ...state.LUSD,
          ...payload,
        },
      };
    case SET_BOTH_VALUES:
      return {
        ...state,
        BOTH: {
          ...state.BOTH,
          ...payload,
        },
      };
    case 'RESET':
       return initialState
      
    default:
      return state;
  }
};
