import { useWeb3React } from "@web3-react/core";
import { strategyPath } from "logic/paths";
import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompoundMemo } from "shared/compound/Compound";
import { CompoundStakingNotExecuteMemo } from "shared/compound/CompoundStakingNotExecute";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD } from "shared/helpers/utils";
import { ContainerBox, RightBoxCntr, RowFlex } from "shared/styled";
import { LightText, LinkPara, Para } from "shared/Typography";
import { StakingLoanCompoundingPath } from "./loanCompoundingPath/LoanCompoundingPath";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const SecondaryBox = () => {
  const { poolShare, stakedLQTY, newStakeLQTY, lusdGainInDollar, liquidationEthGainInDollar, lusdGain, liquidationEthGain, TotalStake, stakedLQTYInDollar } = useSelector(
    (state: any) => state.stake,
  );
  const { isAutomation } = useSelector((state: any) => state.navbar);
  const { selectedCompoundStrategyID, selectedCompoundStrategy, disablingReason } = useSelector((s: any) => s.strategyRedu);
  const navigate = useNavigate();
  const { library } = useConnectWallet();
  const [active, setActive] = useState<number>(1);
  const { handleCompoundButton, handleCompoundStaking } = useStrategy();

  const [newStakeInDollar, setNewStakeInDollar] = useState<number>(0);
  const [TotalStakeInDollar, setTotalStakeInDollar] = useState<number>(0);
  const localTab: any = sessionStorage.getItem("currentStakingTab");

  useEffect(() => {
    const _localTab = sessionStorage.getItem("currentStakingTab");
    setActive(_localTab ? Number(_localTab) : 1);
    convertUSD();
  }, [library, newStakeLQTY, TotalStake, localTab]);

  const convertUSD = async () => {
    const { lqtyUSD } = await tokenToUSD();
    const Convert_NewStakedBal = lqtyUSD ? Number(lqtyUSD) * Number(newStakeLQTY) : 0.0;
    const Convert_TotalStakedBal = lqtyUSD ? Number(lqtyUSD) * Number(TotalStake) : 0.0;
    setNewStakeInDollar(Convert_NewStakedBal);
    setTotalStakeInDollar(Convert_TotalStakedBal);
  };

  const tempDisabled = selectedCompoundStrategyID !== "2" && selectedCompoundStrategyID !== "4" && selectedCompoundStrategyID !== "5";

  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Staking pool." : disablingReason;

  const isNotExecuteStrategy = selectedCompoundStrategyID === "2" || selectedCompoundStrategyID === "5";

  return (
    <RightBoxCntr>
      <ContainerBox custFlex="0 1 0px" customGap={"0.5em"}>
        <LightText fOpacity="36%" fSize="10px">
          STAKING INFORMATION
        </LightText>
        <RowFlex>
          <LightText fSize="14px">{active === 1 ? "Your New Stake" : "Your Unstake"}</LightText>
          <Para tAlign="right" fWeight="500">
            {newStakeLQTY
              ? `${formatNumbersWithComma(newStakeLQTY, 3)} ${envAllDescriptionDetails.STAKING_TOKEN_TEXT} ($${formatNumbersWithComma(newStakeInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Your Existing Stakes</LightText>
          <Para tAlign="right" fWeight="500">
            {stakedLQTY
              ? `${formatNumbersWithComma(stakedLQTY, 3)} ${envAllDescriptionDetails.STAKING_TOKEN_TEXT} ($${formatNumbersWithComma(stakedLQTYInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Your Total Staking Balance</LightText>
          <Para tAlign="right" fWeight="500">
            {TotalStake
              ? `${formatNumbersWithComma(TotalStake, 3)} ${envAllDescriptionDetails.STAKING_TOKEN_TEXT} ($${formatNumbersWithComma(TotalStakeInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STAKING_TOKEN_TEXT}`}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Pool Share</LightText>
          <Para tAlign="right" fWeight="500">
            {Number(poolShare) ? Number(poolShare) + "%" : "0%"}
          </Para>
        </RowFlex>
        <RowFlex>
          <LightText fSize="14px">Kickback Rate</LightText>
          <Para fWeight="500">100%</Para>
        </RowFlex>
        {liquidationEthGain ? (
          <>
            <RowFlex>
              <LightText fSize="14px">Share of redemption fees</LightText>
              <Para tAlign="right" fWeight="500">
                {`${Number(liquidationEthGain)
                  ? formatNumbersWithComma(liquidationEthGain, 6) +
                  " " +
                  envAllDescriptionDetails.VAULT_TOKEN_TEXT +
                  " " +
                  `($${formatNumbersWithComma(liquidationEthGainInDollar, 2)})`
                  : ` 0 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`
                  }`}
              </Para>
            </RowFlex>
            <RowFlex>
              <LightText fSize="14px">Share of debt fees</LightText>
              <Para tAlign="right" fWeight="500">
                {`${Number(lusdGain)
                  ? formatNumbersWithComma(lusdGain, 6) + " " + envAllDescriptionDetails.STABILITY_TOKEN_TEXT + " " + `($${formatNumbersWithComma(lusdGainInDollar, 2)})`
                  : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`
                  }
                `}
              </Para>
            </RowFlex>
          </>
        ) : (
          ""
        )}
        {selectedCompoundStrategy && (
          <RowFlex>
            <LightText fSize="14px">Selected Compound Strategy</LightText>
            <LinkPara onClick={() => navigate(strategyPath)} tAlign="end" fWeight="500">
              {selectedCompoundStrategy}
            </LinkPara>
          </RowFlex>
        )}
        <RowFlex>
          <LightText fSize="14px">Autopilot Enabled?</LightText>
          <Para tAlign="end" fWeight="500">
            {isAutomation ? "Yes" : "No"}
          </Para>
        </RowFlex>
        <StakingLoanCompoundingPath />
        <RowFlex>
          {isNotExecuteStrategy ? (
            <CompoundStakingNotExecuteMemo tempDisabled={tempDisabled} buttonText="Compound Staking" handleCompoundStaking={handleCompoundStaking} />
          ) : (
            <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText="Compound Staking" handleCompoundButton={handleCompoundButton} />
          )}
        </RowFlex>
      </ContainerBox>
    </RightBoxCntr>
  );
};
