import axios from "axios";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { setAutomationModal, setMaxGap, setRefetchBalance } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/button";
import { CustomInput } from "shared/customInput";
import { POST_WALLET_URL } from "shared/helpers/apis";
import { envAllDescriptionDetails, validateNumberInput } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { InputBox, InputLabel, RowContainer } from "shared/styled";
import { LightText } from "shared/Typography";

export const MaxCap = () => {
  const globalSelector = useSelector((state: any) => state);
  const { isAutomationModalOpen, refetchBalance } = globalSelector.navbar;
  const [amount, setAmount] = useState<string>("0");
  const [notifyText, setNotifyText] = useState<string>("");
  const dispatch = useDispatch();
  const {automaticLogOut,clearStorage} = useWallet()

  useEffect(() => {
    if (!isAutomationModalOpen) {
      setAmount("");
    }
  }, [isAutomationModalOpen]);

  const handleInput = async (e: any) => {
    let value = validateNumberInput(e, 19)?.toString();
    setAmount(value);
  };

  const handleDeposit = async () => {
    try {
      const convertedGas = parseUnits(amount.toString());
      const res = await axios.patch(`${POST_WALLET_URL}/gasfee`, { gasFee: Number(convertedGas) });
      const gasFee = res?.data?.data?.gasFee;
      const formatMaxGap = Number(gasFee) ? formatEther(gasFee) : 0.00;
      dispatch(setMaxGap(formatMaxGap));
      setAmount("");
      dispatch(setRefetchBalance(!refetchBalance));
      setNotifyText("Successfully Updated!");
      setTimeout(() => {
        dispatch(setAutomationModal(false));
        setNotifyText("");
      }, 3000);
    } catch (err:any) {
      if (err?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const disabledReason = amount === "" || Number(amount) < 0;

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <LightText>Max Cap on Gas</LightText>
          </div>
        </InputLabel>
        <CustomInput noMax maxLimit={99} hideIcon placeholder={`Enter Amount in ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`} value={amount} onChange={(e: any) => handleInput(e)}></CustomInput>
      </InputBox>
      {notifyText ? <Notification message={notifyText} type="success" /> : ""}
      <LightText style={{textAlign: "justify"}}>If you are setting a higher max cap then automation may run fewer times resulting it might yield lower rewards. So be cautious choosing your limits during period of high gas.</LightText>
      <RowContainer>
        <Button btnType={"gradientFilledButton"} wrapperWidth="100%" onClick={handleDeposit} isDisabled={disabledReason}>
          Confirm
        </Button>
      </RowContainer>
    </>
  );
};
