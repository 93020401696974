import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const Label = styled.label<any>`
    width: ${(props: any) => props.lblWidth};
    display: flex;
    gap: ${(props: any) => props.isMatic ? "16px" : "12px"};
    border: 1px solid ${(props: any) => props.theme.gray};
    border-radius: ${(props: any) => props.bRadius || '12px'};
    background: ${(props: any) => props.theme.darkestGray};
    padding: ${(props: any) => props.padding || '12px'};
    cursor: text;
    justify-content: ${(props: any) => props.justify ? props.justify : "space-between"};
    border: 1px solid #12f79c66;
    min-width: ${(props: any) => props.minWidth || '150px'};
    max-width: ${({ maxWidth }: any) => maxWidth};
    @media (max-width: ${screenSizes.S}px) {
        min-width: ${(props: any) => props.cntrMaxWidth || '120px'};
        max-width: ${(props: any) => props.cntrMaxWidth};
    }
    :focus-within {
        border: 1px solid #12f79c66;
    }
    >div {
        display: flex;
        align-items: center;
        gap: 1em;
        width: ${(props: any) => props.widthInputCntr};
        >input {
            outline: none;
            border: none;
            background: transparent;
            font-size: ${(props: any) => props.fSize || '24px'};
            color: ${(props: any) => props.theme.white};
            ::-webkit-input-placeholder {
                text-align: ${(props: any) => props.tAlign || 'start'};
                color: ${(props: any) => props.theme.white};
                opacity: .6;
            }
            text-align: ${(props: any) => props.tAlign || 'start'};
            font-family: Roboto;
            width: ${(props: any) => props.inputWidth};
            max-height: ${(props: any) => props.inputHeight};
            height: ${(props: any) => props.height};
             
            @media (max-width: ${screenSizes.L}px) {
                max-width: 'fit-content';
            }

            @media (max-width: ${screenSizes.M}px) {
                max-width: ${(props: any) => props.maxWidthMb};
                font-size: ${(props: any) => props.fSizeMobile || '20px'};
            }
        }
    }
    .maxOutButton{
        color: ${(props: any) => props.theme.primaryText}
    }
    .maxOutButton:hover{
        cursor: pointer;
    }

    .askingPrice{
        outline: none;
        border: none;
        background: transparent;
        ::-webkit-input-placeholder {
            text-align: left;
        }
        text-align: left;
        font-family: TTNormsProExtraBold;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }

    
`

