import { ColFlex, ContainerBox, RowFlex, SvgIcon, Tooltip } from "shared/styled";

import { useWeb3React } from "@web3-react/core";
import { USER_INSTANCE } from "blockchain/contract/instance";
import { formatEther } from "ethers/lib/utils";
import { automationPath, strategyPath } from "logic/paths";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CompoundAll } from "shared/compoundAll/CompoundAll";
import { commonContractsDescription, envAllDescriptionDetails, formatNumbersWithComma, numberFormatter } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { TextBar } from "shared/textbar/TextBar";
import { LightText, LinkPara, Para } from "shared/Typography";
import DangerIcon from "assets/icons/Danger.svg";
import { TroveLoanCompoundingPath } from "modules/pages/trove/components/loanCompoundingPath/LoanCompoundingPath";
import { StabilityLoanCompoundingPath } from "modules/pages/stability/components/loanCompoundingPath/LoanCompoundingPath";
import { StakingLoanCompoundingPath } from "modules/pages/staking/components/loanCompoundingPath/LoanCompoundingPath";
import { withTheme } from "styled-components";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const StrategyBox = withTheme((props: any) => {
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const { selectedCompoundStrategy, selectedLoanCompoundingTarget, disablingReason } = useSelector((s: any) => s.strategyRedu);
  const { theme } = props;
  const navigate = useNavigate();
  const { active, account, chainId } = useConnectWallet();
  const { isAutomation, automationCanRun, automationBalance, maxGap } = useSelector((s: any) => s.navbar);
  const { ethToUSD } = useWallet();
  const value = (isAutomation && automationCanRun <= 3) || !maxGap || maxGap === "0" ? theme.danger : "";

  const fetchDebtAndColl = async () => {
    try {
      const [temp_isOpened, ethInDollar]: any = await Promise.all([USER_INSTANCE?.getEntireDebtAndColl(), ethToUSD()]);
      const debt = temp_isOpened[0]?.toString();
      const coll = temp_isOpened[1]?.toString();
      const ratio = ((Number(formatEther(coll)) * ethInDollar) / Number(formatEther(debt))) * 100;
      const Convert_ETHBal = ethInDollar ? Number(ethInDollar) * Number(automationBalance) : 0.0;
      setConvertEthBalance(Convert_ETHBal);
      let toBeStoredData = {
        coll: numberFormatter(Number(formatEther(coll)), 2),
        debt: numberFormatter(Number(formatEther(debt)), 2),
        collRatio: ratio,
        strategy: selectedCompoundStrategy.toUpperCase(),
        isAutomated: isAutomation ? "YES" : "NO",
      };
      let objInString = JSON.stringify(toBeStoredData);
      sessionStorage.setItem("TroveBox", objInString);
    } catch (err) {
      console.error("fetchDebtAndColl", err);
    }
  };

  useEffect(() => {
    if (account) {
      fetchDebtAndColl();
    }
  }, [account, active, selectedCompoundStrategy, selectedLoanCompoundingTarget]);

  const handleManageStrategies = () => {
    navigate(strategyPath);
  };

  return (
    <ContainerBox minWidth="555px">
      <ColFlex customGap={"1em"}>
        <RowFlex align="center" customGap="1em">
          <TextBar
            leftText="Selected Strategy"
            heading="yes"
            custId="stratHeadingTooltip"
            toolTip={envAllDescriptionDetails.STRATEGY_DASHBOARD_TIP_TEXT}
            link={commonContractsDescription.URL_STRATEGY_TIP_TEXT_RM}
            linkText="strategies."
          />
          <Button btnType={"borderedfilledButton"} customWidth="9em" customHeight="35px" onClick={handleManageStrategies}>
            Details
          </Button>
        </RowFlex>
        <ColFlex customGap={".5em"}>
          <RowFlex>
            <TextBar
              toolTip={commonContractsDescription.STRATEGY_TIP_TEXT}
              link={commonContractsDescription.URL_STRATEGY_TIP_TEXT_RM}
              leftText="Strategy"
              custId="strategyText"
              linkText="Strategies"
            />
            <LinkPara
              onClick={() => {
                navigate(strategyPath);
              }}
              tAlign="end"
              fWeight="500"
            >
              {selectedCompoundStrategy}
            </LinkPara>
          </RowFlex>
          <RowFlex>
            <TextBar marginBottom="1px" toolTip={commonContractsDescription.AUTOMATION_TIP_TEXT} leftText="Autopilot Enabled?" custId="automationText" />
            <div>
              <LinkPara
                onClick={() => {
                  navigate(automationPath);
                }}
                tAlign="end"
                fWeight="500"
              >
                {isAutomation ? "Yes" : "No"}
              </LinkPara>
              {isAutomation && automationCanRun <= 3 && (
                <Tooltip
                  left={"-18em"}
                  customWidth="15em"
                  data-tooltip={automationCanRun === 0 ? "No automation left" : `Only ${automationCanRun} ${automationCanRun === 1 ? "automation" : "automations"} left`}
                >
                  <SvgIcon style={{ marginRight: "5px", marginLeft: "2px" }} src={DangerIcon} />
                </Tooltip>
              )}
            </div>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">ESTIMATED # of days automation can run</LightText>
            <RowFlex>
              <LinkPara onClick={() => { navigate(automationPath); }}>
                <Para fcolor={value} fWeight="500" tAlign="end">
                  {automationCanRun}
                </Para>
              </LinkPara>
              {(isAutomation && disablingReason) && (
                <Tooltip
                  left={"-18em"}
                  customWidth="15em"
                  data-tooltip={disablingReason}
                >
                  <SvgIcon style={{ marginRight: "5px", marginLeft: "2px" }} src={DangerIcon} />
                </Tooltip>
              )}
            </RowFlex>
          </RowFlex>
          <RowFlex>
            <LightText fSize="14px">Balance</LightText>
            <Para fWeight="500">{` ${formatNumbersWithComma(automationBalance, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(
              convertEthBalance,
              2,
            )})`}</Para>
          </RowFlex>
          <TroveLoanCompoundingPath prefix={chainId === ChainId?.eth ? "Trove" : "Vault"} />
          <StabilityLoanCompoundingPath prefix="Stability" />
          <StakingLoanCompoundingPath prefix="Staking" />
        </ColFlex>
      </ColFlex>
      <CompoundAll />
    </ContainerBox>
  );
});
