import { ColFlex, ContainerBox, RowFlex } from "shared/styled";
import { TextBar } from "shared/textbar/TextBar";
import { redemptionPath } from "logic/paths";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";

export const RedemptionBox = () => {
  const navigate = useNavigate();

  const handleDeposite = () => {
    navigate(redemptionPath);
  };

  return (
    <ContainerBox minWidth="555px">
      <ColFlex customGap={"12px"}>
        <RowFlex align="center">
          <TextBar leftText="Redemption" heading="yes" />
          <Button btnType={"borderedfilledButton"} customWidth="9em" customHeight="35px" onClick={handleDeposite}>
            Details
          </Button>
        </RowFlex>
      </ColFlex>
    </ContainerBox>
  );
};
