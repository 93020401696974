import { useWeb3React } from "@web3-react/core";
import { setIsValuesChanged, setTargetCollateralRatio } from "logic/redux/actions/strategy";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomText, Heading3, LightText, LinkText, Para } from "shared/Typography";
import { Button } from "shared/button";
import { ConfirmationPopup } from "shared/confirmationModal/ConfirmationPopup";
import { CustomInput } from "shared/customInput";
import CustomModal from "shared/customModal";
import { PageContainer } from "shared/flexBox/style";
import { URL_READ_MORE_STRATERGY, commonContractsDescription, envAllDescriptionDetails, validateNumberInput } from "shared/helpers/utils";
import { Loader } from "shared/loader/loader";
import { Notification } from "shared/notification";
import { CompoundStrategy } from "shared/selectors/CompoundStrategy";
import { LoanCompoundingTarget } from "shared/selectors/LoanCompoundingTarget";
import { SPCompoundingTarget } from "shared/selectors/SPCompoundingTarget";
import { StakingCompoundingTarget } from "shared/selectors/StakingCompoundingTarget";
import { TopOffSelector } from "shared/selectors/TopOffSelector";
import { CommonContainer, ContainerBox, RowContainer, RowFlex, SelectActionsContainer, SelectContainer } from "shared/styled";
import { TextBar } from "shared/textbar/TextBar";
import { InputControlsCntr, RangeInputContainer } from "../trove/style";
import { useStrategy } from "./hooks/useStrategy";
import { useUtilityStrategy } from "./hooks/useUtilityStrategy";
import { USER_INSTANCE } from "blockchain/contract/instance";
import { setSlippage } from "logic/redux/actions/strategy";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";


export const StrategyForm = () => {
  const [isAdjusting, setIsAdjusting] = useState<boolean>(false);
  const { active, account } = useConnectWallet();
  const [isUpdating, setIsUpdating] = useState(true);
  const [targetRange, setTargetRange] = useState<number>(0);
  const [triggerRange, setTriggerRange] = useState<number>(0);
  const [tempSlippage, setTempSlippage] = useState<string>('0');
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setTimeout(() => {
      setIsUpdating(false);
    }, 1000);
  }, [navigate]);

  const {
    isLoading,
    isModalOn,
    modalView,
    isRatioShown,
    selectedCompoundStrategy,
    selectedCompoundStrategyID,
    selectedLoanCompoundingTarget,
    selectedLoanCompoundingTargetID,
    selectedSPCompoundingTarget,
    targetCollateralRatio,
    triggerCollateralRatio,
    disable,
    isValueChanged,
  } = useSelector((s: any) => s.strategyRedu);

  const {
    MAX_COLL_VAL,
    reRender,
    customStrategies,
    fetchWalletDetails,
    strategyEnabledViaAction,
    registerStrategyViaAction,
    isValueChangedCheckingActivation,
    handleCollateralChange,
    handleSetSPCompoundingTarget,
    handleSetStakingCompoundingTarget,
    handleTriggerCollateralRatio,
    setStrategyInput,
    setCustomStrategies,
    handleCancelCSViaAction,
  } = useStrategy();

  const {
    handleTargetCollRatioInput,
    handleOnBlurTargetCollRatio,
    handleTriggerCollRatioInput,
    handleOnBlurTriggerCollRatio,
    handleToogleModal,
    handleSetCompoundStrategy,
    handleSetLoanCompoundingTarget,
    selectCompoundStrategy,
    selectLoanCompoundingTarget,
    selectSPCompoundingTarget,
    selectStakingCompoundingTarget,
    openTopOffSelector,
    removeTopOffSelector,
  } = useUtilityStrategy();

  const { isAutomation, userContractAddress, isFetched } = useSelector((s: any) => s.navbar);

  useEffect(() => {
    if (account && userContractAddress) {
      fetchWalletDetails();
    }
  }, [account, userContractAddress])

  useEffect(() => {
    getSlippage();
  }, [])

  const getSlippage = async () => {
    let getSlippage = await USER_INSTANCE?.getSlippageFactor();
    let _getSlippage = Number(getSlippage) / 10;
    setTempSlippage(_getSlippage.toString());
    dispatch(setSlippage(_getSlippage))
  }

  useEffect(() => {
    if (targetCollateralRatio === "") {
      setTargetRange(0);
    } else if (triggerCollateralRatio === "") {
      setTriggerRange(0);
    } else {
      fetchRangeValues();
    }
  }, [account, targetCollateralRatio, triggerCollateralRatio]);

  const fetchRangeValues = async () => {
    const minTarget = isAutomation ? 500 : 110;
    const rangeTarget = 2000 - minTarget;
    const correctedTargetValue = Number(targetCollateralRatio) - minTarget;
    let targetPercentage: any = (correctedTargetValue * 100) / rangeTarget;
    setTargetRange(targetPercentage);
    const rangeTrigger = 100 - 10;
    const correctedTriggerValue = Number(triggerCollateralRatio) - 10;
    let triggerPercentage = (correctedTriggerValue * 100) / rangeTrigger;
    setTriggerRange(triggerPercentage);
  };


  const _data: any = JSON.parse(sessionStorage.getItem("FETCHED_USER_DATA") || "{}");
  const _spCompoundingPath: any = sessionStorage.getItem("Top Up Stability Pool");
  const _stakingCompoundingPath: any = sessionStorage.getItem("Top Up Staking Pool");

  const handleSlippage = (e: any) => {
    const value = validateNumberInput(e, 2)?.toString();
    setTempSlippage(value);
    dispatch(setSlippage(Number(value)))
  }

  if (active && (isLoading || isUpdating || (userContractAddress && !selectedCompoundStrategy) || !isFetched))
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );

  return (
    <PageContainer>
      {!active && !account ? (
        navigate("/")
      ) : (
        <>
          <CommonContainer>
            <ContainerBox>
              <>
                <RowFlex>
                  <Heading3 fWeight="600">Strategy Selection</Heading3>
                </RowFlex>
                <div>
                  <Para>{commonContractsDescription.STRATEGY_FORM_HEADING}</Para>
                  <LinkText href={URL_READ_MORE_STRATERGY} target="_blank" rel="noreferrer">
                    Read more about Strategies
                  </LinkText>
                </div>
                <SelectActionsContainer>
                  <SelectContainer>
                    <LightText>Compound Strategy</LightText>
                    <Button onClick={selectCompoundStrategy} btnType={"borderedfilledLightButton"} fSize="12px" customHeight="30px" bRadius="4px" isDisabled={!isAdjusting}>
                      {selectedCompoundStrategy || "Select"}
                    </Button>
                  </SelectContainer>
                  <SelectContainer>
                    <LightText>Autopilot Enabled?</LightText>
                    <LightText>{isAutomation ? "YES" : "NO"}</LightText>
                  </SelectContainer>
                  {selectedCompoundStrategyID === "5" ? (
                    <SelectContainer>
                      <LightText>Loan Compounding Target</LightText>
                      <Button
                        onClick={selectLoanCompoundingTarget}
                        btnType={"borderedfilledLightButton"}
                        fSize="12px"
                        customHeight="30px"
                        minWidthMb="150px"
                        bRadius="4px"
                        isDisabled={!isAdjusting}
                      >
                        {selectedLoanCompoundingTarget || "Select"}
                      </Button>
                    </SelectContainer>
                  ) : (
                    ""
                  )}
                  {selectedCompoundStrategyID === "5" && selectedLoanCompoundingTargetID === "4" ? (
                    Object.keys(customStrategies).length !== 0 ? (
                      <>
                        <SelectContainer>
                          <LightText>Top Up Vault</LightText>
                          <LightText>{customStrategies?.["Top Up Vault"]?.[0] || "0"}%</LightText>
                        </SelectContainer>
                        {
                          Object.keys(customStrategies)?.includes("Top Up Stability Pool") || Object.keys(customStrategies)?.includes("Top Up Staking Pool")
                            ?
                            <>
                              <SelectContainer>
                                <LightText>Top Up Stability Pool</LightText>
                                <LightText>{customStrategies?.["Top Up Stability Pool"]?.[0] || "0"}%</LightText>
                              </SelectContainer>
                              <SelectContainer>
                                <LightText>Top Up Staking Pool</LightText>
                                <LightText>{customStrategies?.["Top Up Staking Pool"]?.[0] || "0"}%</LightText>
                              </SelectContainer>
                              <SelectContainer>
                                <LightText>Stability Compounding Target</LightText>
                                <LightText>{_spCompoundingPath} </LightText>
                              </SelectContainer>
                              <SelectContainer>
                                <LightText>Staking Compounding Target</LightText>
                                <LightText>{_stakingCompoundingPath} </LightText>
                              </SelectContainer>
                            </>
                            : ""
                        }

                      </>
                    ) : (
                      <>
                        {_data?.loanCompoundingCustom?.map((stat: any) => {
                          return (
                            <>
                              <SelectContainer>
                                <LightText>{stat?.loanCompoundingStrategies}</LightText>
                                <LightText>{stat?.inputPercentage}%</LightText>
                              </SelectContainer>
                            </>
                          );
                        })}
                        <SelectContainer>
                          <LightText>Stability Compounding Target</LightText>
                          <LightText>{_spCompoundingPath} </LightText>
                        </SelectContainer>
                        <SelectContainer>
                          <LightText>Staking Compounding Target</LightText>
                          <LightText>{_stakingCompoundingPath} </LightText>
                        </SelectContainer>
                      </>
                    )
                  ) : (
                    ""
                  )}
                  <SelectorButtons
                    isAdjusting={isAdjusting}
                    selectSPCompoundingTarget={selectSPCompoundingTarget}
                    selectStakingCompoundingTarget={selectStakingCompoundingTarget}
                  />
                </SelectActionsContainer>
                {(selectedCompoundStrategyID === "1" || selectedCompoundStrategyID === "4" || selectedCompoundStrategyID === "5") &&
                  (
                    <RowFlex align="center">
                      <TextBar toolTip={commonContractsDescription.SLIPPAGE_TEXT} leftText="Slippage" custId="slippage" />
                      <InputControlsCntr>
                        <CustomInput
                          justify="center"
                          tAlign="center"
                          maxLimit={5}
                          value={tempSlippage}
                          onChange={handleSlippage}
                          lblWidth="50%"
                          fSize="16px"
                          placeholder="Slippage %"
                          padding="0"
                          height="35px"
                          minWidth="80px"
                          maxWidthMb="100px"
                          bRadius="8px"
                          hideIcon
                          disable={!isAdjusting}
                        />
                      </InputControlsCntr>
                    </RowFlex>
                  )
                }

                {isRatioShown ? (
                  <>
                    <RangeInputContainer>
                      <RowFlex align="center">
                        <TextBar toolTip={envAllDescriptionDetails.TARGET_TIP_TEXT} leftText="Target Collateral Ratio" custId="targetText" />
                        <InputControlsCntr>
                          <CustomInput
                            justify="center"
                            tAlign="center"
                            maxLimit={2000}
                            minLimit={isAutomation ? 500 : 110}
                            onBlur={handleOnBlurTargetCollRatio}
                            onChange={handleTargetCollRatioInput}
                            value={targetCollateralRatio}
                            lblWidth="50%"
                            fSize="16px"
                            placeholder="Target %"
                            padding="0"
                            height="35px"
                            minWidth="80px"
                            maxWidthMb="100px"
                            bRadius="8px"
                            hideIcon
                            disable={!isAdjusting}
                          />
                        </InputControlsCntr>
                      </RowFlex>
                      <RangeInputContainer rangeValue={targetRange} target={isAutomation ? "500 yes" : "yes"}>
                        <input
                          type="range"
                          max={MAX_COLL_VAL}
                          min={isAutomation ? "500" : "110"}
                          value={targetCollateralRatio}
                          onChange={handleCollateralChange}
                          step="10"
                          disabled={!isAdjusting}
                        />
                      </RangeInputContainer>
                      <RowFlex>
                        <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                          {isAutomation ? 500 : 110}%
                        </CustomText>
                        <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                          {MAX_COLL_VAL}%
                        </CustomText>
                      </RowFlex>
                    </RangeInputContainer>

                    <RangeInputContainer>
                      <RowFlex align="center">
                        <TextBar toolTip={envAllDescriptionDetails.TRIGGER_TIP_TEXT} leftText="Trigger Collateral Ratio" custId="triggerText" />
                        <InputControlsCntr>
                          <CustomInput
                            justify="center"
                            tAlign="center"
                            maxLimit={100}
                            minLimit={10}
                            onBlur={handleOnBlurTriggerCollRatio}
                            onChange={handleTriggerCollRatioInput}
                            value={triggerCollateralRatio}
                            lblWidth="50%"
                            fSize="16px"
                            placeholder="Trigger %"
                            padding="0"
                            height="35px"
                            minWidth="80px"
                            maxWidthMb="100px"
                            bRadius="8px"
                            hideIcon
                            disable={!isAdjusting}
                          />
                        </InputControlsCntr>
                      </RowFlex>
                      <RangeInputContainer rangeValue={triggerRange} trigger="yes">
                        <input type="range" max={100} min={10} value={triggerCollateralRatio} onChange={handleTriggerCollateralRatio} disabled={!isAdjusting} />
                      </RangeInputContainer>
                      <RowFlex>
                        <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                          10%
                        </CustomText>
                        <CustomText fSize="12px" fWeight="400" fOpacity="54%">
                          100%
                        </CustomText>
                      </RowFlex>
                    </RangeInputContainer>
                  </>
                ) : (
                  ""
                )}
                <ActionButtons
                  customStrategies={customStrategies}
                  isValueChangedCheckingActivation={isValueChangedCheckingActivation}
                  registerContract={registerStrategyViaAction}
                  isAdjusting={isAdjusting}
                  setIsAdjusting={setIsAdjusting}
                  fetchWalletDetails={fetchWalletDetails}
                  stratergyEnabled={strategyEnabledViaAction}
                  disable={disable}
                  isValueChanged={isValueChanged}
                  selectedLoanCompoundingTarget={selectedLoanCompoundingTarget}
                />
              </>
            </ContainerBox>
          </CommonContainer>
          <CustomModal
            closeFunction={selectedLoanCompoundingTargetID === "4" ? handleCancelCSViaAction : null}
            isScroll
            show={isModalOn}
            isPersistent={[
              commonContractsDescription.COMPOUND_STRATEGY,
              commonContractsDescription.TOP_OFF_SELECTOR,
              commonContractsDescription.LOAN_COMPOUNDING_TARGET,
              commonContractsDescription.SP_COMPOUNDING_TARGET,
              commonContractsDescription.STAKING_COMPOUNDING_TARGET,
            ].includes(modalView)}
            toggleModal={() => {
              handleToogleModal();
            }}
            custMinHeight="50%"
            titlePadding="0"
          >
            <Selectors
              modalView={modalView}
              handleSetCompoundStrategy={handleSetCompoundStrategy}
              setStrategyInput={setStrategyInput}
              customStrategies={customStrategies}
              reRender={reRender}
              openTopOffSelector={openTopOffSelector}
              removeTopOffSelector={removeTopOffSelector}
              handleSetLoanCompoundingTarget={handleSetLoanCompoundingTarget}
              selectedSPCompoundingTarget={selectedSPCompoundingTarget}
              handleSetSPCompoundingTarget={handleSetSPCompoundingTarget}
              handleSetStakingCompoundingTarget={handleSetStakingCompoundingTarget}
              setCustomStrategies={setCustomStrategies}
              handleCancelCSViaAction={handleCancelCSViaAction}
            />
          </CustomModal>
        </>
      )}
    </PageContainer>
  );
};

interface ISelectorProps {
  modalView: string;
  selectedSPCompoundingTarget: string;
  handleSetCompoundStrategy: any;
  setStrategyInput: any;
  customStrategies: any;
  reRender: any;
  openTopOffSelector: any;
  removeTopOffSelector: any;
  handleSetLoanCompoundingTarget: any;
  handleSetSPCompoundingTarget: any;
  handleSetStakingCompoundingTarget: any;
  setCustomStrategies: any;
  handleCancelCSViaAction: any;
}

const Selectors = (props: ISelectorProps) => {
  const {
    modalView,
    handleSetCompoundStrategy,
    setStrategyInput,
    customStrategies,
    reRender,
    openTopOffSelector,
    removeTopOffSelector,
    handleSetLoanCompoundingTarget,
    handleSetSPCompoundingTarget,
    handleSetStakingCompoundingTarget,
    setCustomStrategies,
    handleCancelCSViaAction,
  } = props;

  switch (modalView) {
    case commonContractsDescription.COMPOUND_STRATEGY: {
      return <CompoundStrategy setStrategy={handleSetCompoundStrategy} />;
    }
    case commonContractsDescription.LOAN_COMPOUNDING_TARGET: {
      return (
        <LoanCompoundingTarget
          setStrategyInput={setStrategyInput}
          customStrategies={customStrategies}
          reRender={reRender}
          openTopOffSelector={openTopOffSelector}
          removeTopOffSelector={removeTopOffSelector}
          setStrategy={handleSetLoanCompoundingTarget}
          handleCancelCS={handleCancelCSViaAction}
        />
      );
    }
    case commonContractsDescription.SP_COMPOUNDING_TARGET: {
      return <SPCompoundingTarget setStrategy={handleSetSPCompoundingTarget} />;
    }
    case commonContractsDescription.STAKING_COMPOUNDING_TARGET: {
      return <StakingCompoundingTarget setStrategy={handleSetStakingCompoundingTarget} />;
    }
    case commonContractsDescription.TOP_OFF_SELECTOR: {
      return <TopOffSelector customStrategies={customStrategies} setStrategy={setCustomStrategies} />;
    }
    default:
      return <></>;
  }
};

interface IActionButtonsProps {
  isAdjusting: boolean;
  setIsAdjusting: any;
  registerContract: (payload: any) => any;
  fetchWalletDetails: (address: any) => any;
  stratergyEnabled: () => any;
  isValueChangedCheckingActivation: () => any;
  disable: boolean;
  isValueChanged: boolean;
  customStrategies: any;
  selectedLoanCompoundingTarget: string;
}

const ActionButtons = (props: IActionButtonsProps) => {
  const { isAdjusting, setIsAdjusting, registerContract, fetchWalletDetails, stratergyEnabled, disable, isValueChangedCheckingActivation } = props;
  const { userContractAddress } = useSelector((s: any) => s.navbar);
  const { selectedCompoundStrategyID, selectedLoanCompoundingTarget, slippage } = useSelector((s: any) => s.strategyRedu);
  const { isAutomation } = useSelector((s: any) => s.navbar);
  const [payload, setPayload] = useState(null);

  const [show, setShow] = useState<boolean>(false);
  const [err, setErr] = useState<string>("");
  const dispatch = useDispatch();

  const AfterAdjusting = () => {
    setIsAdjusting(false);
    fetchWalletDetails(userContractAddress);
    stratergyEnabled();
  };

  const WhileAdjusting = async () => {
    try {
      const tempIsAdjusting = await registerContract(payload);
      if (!tempIsAdjusting) {
        setIsAdjusting(false)
      }
    } catch (e: any) {
      setIsAdjusting(false);
    }
  };

  const beforeAdjusting = async () => {
    const tempPayload = await isValueChangedCheckingActivation();
    if (Object.keys(tempPayload)?.length > 0) {
      setPayload(tempPayload);
    } else {
      setErr("You've selected the same strategy");
      setTimeout(() => {
        setErr("");
        dispatch(setIsValuesChanged(true));
      }, 3000);
      return;
    }
    if (slippage < 0.1) {
      setErr("Minimum Slippage should be 0.1");
      setTimeout(() => {
        setErr("");
      }, 5000);
      return
    }
    if (selectedCompoundStrategyID === "5") {
      if (!selectedLoanCompoundingTarget) {
        setErr("Please fill the required fields!");
        setTimeout(() => setErr(""), 5000);
        return;
      }
    }
    if (isAutomation) {
      if (selectedCompoundStrategyID === "6") {
        setErr("Can't set to No Strategy since Automation is enabled for compounding");
        setTimeout(() => setErr(""), 5000);
        return;
      }
    }
    setShow(true);
  };

  if (!isAdjusting) {
    return (
      <Button btnType={"borderedfilledButton"} wrapperWidth="100%" fSize="20px" onClick={() => setIsAdjusting((prev: any) => !prev)}>
        Click to Edit Strategy
      </Button>
    );
  }

  return (
    <>
      {err && <Notification message={err} type="err" />}
      <RowContainer>
        {/* registerEnabled && mode === 2 */}
        <Button btnType={"gradientFilledButton"} onClick={() => beforeAdjusting()} wrapperWidth="100%" isDisabled={err !== ""}>
          Confirm
        </Button>
        <Button btnType={"borderedfilledButton"} onClick={AfterAdjusting} wrapperWidth="100%">
          Cancel
        </Button>
      </RowContainer>
      <ConfirmationPopup
        heading="Agreement"
        buttonText="Accept"
        text1={envAllDescriptionDetails.ACCEPT_CRITERIA_TEXT1}
        text2={envAllDescriptionDetails.ACCEPT_CRITERIA_TEXT2}
        text3={envAllDescriptionDetails.ACCEPT_CRITERIA_TEXT3}
        show={show}
        setShow={setShow}
        action={WhileAdjusting}
        disable={disable}
      />
    </>
  );
};

interface ISelectorButtonsProps {
  isAdjusting: any;
  selectSPCompoundingTarget: any;
  selectStakingCompoundingTarget: any;
}

const SelectorButtons = (props: ISelectorButtonsProps) => {
  const { isAdjusting, selectSPCompoundingTarget, selectStakingCompoundingTarget } = props;

  const { selectedLoanCompoundingTargetID, selectedSPCompoundingTarget, selectedStakingCompoundingTarget } = useSelector((s: any) => s.strategyRedu);

  return (
    <>
      {(selectedLoanCompoundingTargetID === "2" ||
        selectedLoanCompoundingTargetID === "3" ||
        selectedLoanCompoundingTargetID === "5" ||
        selectedLoanCompoundingTargetID === "7") && (
          <SelectContainer>
            <LightText>Stability Pool Compounding Target</LightText>
            <Button
              minWidthMb="130px"
              onClick={selectSPCompoundingTarget}
              btnType={"borderedfilledLightButton"}
              fSize="12px"
              customHeight="30px"
              bRadius="4px"
              isDisabled={!isAdjusting}
            >
              {selectedSPCompoundingTarget || "Select"}
            </Button>
          </SelectContainer>
        )}
      {(selectedLoanCompoundingTargetID === "2" ||
        selectedLoanCompoundingTargetID === "3" ||
        selectedLoanCompoundingTargetID === "6" ||
        selectedLoanCompoundingTargetID === "7") && (
          <SelectContainer>
            <LightText>Staking Pool Compounding Target</LightText>
            <Button
              minWidthMb="130px"
              onClick={selectStakingCompoundingTarget}
              btnType={"borderedfilledLightButton"}
              fSize="12px"
              customHeight="30px"
              bRadius="4px"
              isDisabled={!isAdjusting}
            >
              {selectedStakingCompoundingTarget || "Select"}
            </Button>
          </SelectContainer>
        )}
    </>
  );
};
