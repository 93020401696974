import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { initialValues, SET_BOTH_VALUES, SET_ETH_VALUES } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { LightText } from "shared/Typography";
import { InputBox, InputLabel } from "../style";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Withdraw = () => {
  const { LUSD, ETH, BOTH } = useSelector((state: any) => state.trove);
  const { refetchBalance } = useSelector((state: any) => state.navbar);
  const { collDisplay, coll } = ETH;
  const { totalDebt, debt, isAdjustSliderShown } = LUSD;
  const { IcollateralRatio, isMarketRecovery, err } = BOTH;
  const { ethToUSD } = useWallet();
  const dispatch = useDispatch();
  const { library, account, chainId }: any = useConnectWallet();
  const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;


  const [collateralInputValue, setCollateralInputValue] = useState<string>("0");
  const [collateralInputUSDValue, setCollateralInputUSDValue] = useState<number>(0);
  const [calCollateralInputValue, setCalCollateralInputValue] = useState<number>(0);
  const [collRangevalue, setCollRangeValue] = useState<number>(0);
  const [convertCollBalance, setConvertCollBalance] = useState<any>(0);
  const [ethInDollar, setEthInDollar] = useState<any>(0);
  const [maxColl, setMaxColl] = useState<any>("0");
  const debouncedCollateralInputValue = useDebounce(collateralInputValue, 300);

  useEffect(() => {
    if (collDisplay) {
      convertUSD();
      fetchMaxValue();
    }
    if (Number(debouncedCollateralInputValue) === 0) {
      dispatch(initialValues(library, account, ethToUSD));
    } else {
      handleLoanInformation();
    }
  }, [collDisplay, debouncedCollateralInputValue]);

  useEffect(() => {
    setCollateralInputValue("0");
    setCalCollateralInputValue(0);
    setCollateralInputUSDValue(0);
    setCollRangeValue(0);
  }, [refetchBalance]);

  const convertUSD = async () => {
    const USDValue = await ethToUSD();
    setEthInDollar(USDValue);
  };

  const fetchMaxValue = async () => {
    const _ethInDollar = await ethToUSD();
    const ethInDollarBN = ethers.utils.parseUnits(_ethInDollar.toString(), 18);
    const desiredCollRatio = isMarketRecovery ? 150 : 110;
    const _MinCollateralValue = BigNumber.from(debt).mul(desiredCollRatio).div(100);
    const _MinColl = Number(envChainId) === ChainId?.eth ? _MinCollateralValue.div(ethInDollar) : _MinCollateralValue.div(ethInDollarBN)
    const toWithdraw = Number(envChainId) === ChainId?.eth ? BigNumber.from(coll).sub(_MinColl) : BigNumber.from(coll).sub(_MinColl.mul(ethers.constants.WeiPerEther));
    let _tempRemaining = Number(formatEther(toWithdraw));
    _tempRemaining = Number(envChainId) === ChainId?.eth ? _tempRemaining - 0.00000000000000009 : _tempRemaining - 1
    _tempRemaining = Number(_tempRemaining) > 0 ? _tempRemaining : 0;

    setMaxColl(_tempRemaining);
    const Convert_CollBal = _ethInDollar ? Number(_ethInDollar) * Number(_tempRemaining) : 0.0;
    setConvertCollBalance(Convert_CollBal);
  };

  const checking = (tempCollateralRatio: any) => {
    let ErrorText;
    if (isMarketRecovery && Number(tempCollateralRatio) < Number(IcollateralRatio)) {
      ErrorText = "You're not allowed to decrease your collateral ratio during Recovery Mode";
    } else if (Number(tempCollateralRatio) < 110) {
      ErrorText = "You're not allowed to decrease your collateral ratio less than 110%";
    } else {
      ErrorText = "";
    }
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        err: ErrorText,
      },
    });
  };

  const handleUpdate = (collValue: string, percentToValueDisplay: any, percentage: number) => {
    const usdValue = ethInDollar ? Number(ethInDollar) * Number(collValue) : 0.0;
    setCollateralInputValue(percentToValueDisplay);
    setCalCollateralInputValue(Number(collValue));
    setCollateralInputUSDValue(usdValue);
    setCollRangeValue(percentage);
  };

  const handleCollateralInput = async (e: any) => {
    const value = validateNumberInput(e)?.toString();
    let percentage: any = ((Number(value) / Number(maxColl)) * 100).toFixed(0);
    percentage = Number(percentage);
    if (Number(value) <= Number(maxColl)) {
      handleUpdate(value, value, percentage);
    }
  };

  const handleCollRangeInput = (e: any) => {
    let percentToValue: any = Number(maxColl) * (Number(e) / 100);
    let percentToValueDisplay = Math.trunc(percentToValue * Math.pow(10, 4)) / Math.pow(10, 4);
    const percentage = Number(e);
    handleUpdate(percentToValue, percentToValueDisplay, percentage);
  };

  const handleLoanInformation = async () => {
    const convertColl = formatEther(coll);
    let tempNormalLPrice, tempRecoveryLPrice, tempICollateralRatio;
    if (collateralInputValue && Number(calCollateralInputValue) > 0) {
      tempNormalLPrice = (110 * Number(totalDebt)) / (Number(calCollateralInputValue) * 100);
      tempRecoveryLPrice = (150 * Number(totalDebt)) / (Number(calCollateralInputValue) * 100);
      const ratio = ((Number(convertColl) - Number(calCollateralInputValue)) * Number(ethInDollar) * 100) / Number(totalDebt);
      tempICollateralRatio = Math.trunc(ratio * Math.pow(10, 2)) / Math.pow(10, 2);
    } else {
      tempICollateralRatio = "0";
      tempRecoveryLPrice = 0;
      tempNormalLPrice = 0;
    }
    checking(tempICollateralRatio);
    dispatch({
      type: SET_ETH_VALUES,
      payload: {
        priceEthDisplay: collateralInputValue,
        priceEth: calCollateralInputValue,
      },
    });
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        IcollateralRatio: tempICollateralRatio,
        recoveryLPrice: tempRecoveryLPrice,
        normalLPrice: tempNormalLPrice,
      },
    });
  };
  const _tempRemainingDisplay = Math.trunc(maxColl * Math.pow(10, 4)) / Math.pow(10, 4);
  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Withdraw</span>
            <span className="text2">{envAllDescriptionDetails.VAULT_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Max Withdraw: </span>
            <span className="text2">{` ${_tempRemainingDisplay} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertCollBalance, 2)})`}</span>
          </div>
        </InputLabel>
        <CustomInput
          disable={isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown)}
          placeholder={"Enter Amount"}
          value={collateralInputValue}
          onChange={handleCollateralInput}
          icon={envAllDescriptionDetails.TROVE_LOGO}
        ></CustomInput>
        {!(isMarketRecovery || (!isMarketRecovery && !isAdjustSliderShown)) && <RangePointerInput rangeValue={collRangevalue} rangeHandler={handleCollRangeInput} />}
      </InputBox>
      {err === "" && Number(collateralInputValue) > 0 && collateralInputValue !== "" ? (
        <Notification
          message={`You are withdrawing ${formatNumbersWithComma(collateralInputValue, 4)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(
            collateralInputUSDValue,
            2,
          )})`}
          type="error"
        />
      ) : (
        ""
      )}
      {isMarketRecovery ? (
        <LightText>You're not allowed to decrease your collateral ratio during Recovery Mode</LightText>
      ) : (
        <LightText>You must maintain atleast 110% CR during Normal Mode</LightText>
      )}
    </>
  );
};