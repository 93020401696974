import React from "react";
import { useSelector } from "react-redux";
import { Button } from "shared/button";
import { TooltipTop } from "shared/styled";

interface IProps {
  tempDisabled?: boolean;
  buttonText: string;
  handleCompoundStability: () => any;
}

const CompoundStabilityNotExecute: any = (props: IProps) => {
  const { tempDisabled, buttonText, handleCompoundStability } = props;

  const { IsStabilityCompound, disablingStabilityReason } = useSelector((state: any) => state.strategyRedu);

  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Stability pool." : disablingStabilityReason;

  return (
    <>
      {tempDisabled || !IsStabilityCompound ? (
        < TooltipTop fullWidth customWidth="15em" data-tooltip={tempDisabledReason}>
          <Button btnType={"borderedfilledLightButton"} onClick={handleCompoundStability} isDisabled={tempDisabled || !IsStabilityCompound} wrapperWidth="100%">
            {buttonText}
          </Button>
        </ TooltipTop>
      ) : (
        <Button btnType={"borderedfilledLightButton"} onClick={handleCompoundStability} isDisabled={tempDisabled || !IsStabilityCompound} wrapperWidth="100%">
          {buttonText}
        </Button>
      )}
    </>
  );
};

export const CompoundStabilityNotExecuteMemo = React.memo(CompoundStabilityNotExecute);
