import { ModalBody, ModalContent, ModelHead, ModalContainer } from "./style";
import closeIcon from "assets/icons/closeIcon.svg";
import { CustomText } from "shared/Typography";
import { useState } from "react";
import { TextBar } from "shared/textbar/TextBar";
import { envAllDescriptionDetails } from "shared/helpers/utils";

interface IModalProps {
  show?: boolean;
  toggleModal?: any;
  borderRadius?: string;
  heading?: string;
  styles?: any;
  headerFSize?: string;
  children: any;
  headerFWeight?: string;
  contentMT?: string;
  titlePadding?: string;
  contentTop?: string;
  custMinHeight?: string;
  custMaxWidth?: string;
  hideHead?: boolean;
  isPersistent?: boolean;
  custPad?: string;
  closeFunction?: any;
  isScroll?: boolean;
  isToolTip?: boolean;
  isOpacity?: boolean;
  custMinHeight1?: string;
}
const CustomModal = (props: IModalProps) => {
  const {
    children,
    custPad,
    contentTop,
    show = false,
    hideHead,
    isPersistent,
    toggleModal,
    custMaxWidth,
    custMinHeight,
    custMinHeight1,
    borderRadius,
    heading,
    styles,
    headerFSize,
    headerFWeight,
    contentMT,
    titlePadding,
    closeFunction,
    isScroll,
    isToolTip,
    isOpacity,
  } = props;

  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget && !isPersistent) {
      handleToggle(false);
    }
  };

  const [closingModal, setClosingModal] = useState("");

  const handleClose = () => {
    if (closeFunction !== null) {
      closeFunction(false);
      handleToggle(!show);
    }
  };

  const handleToggle = (_show: boolean) => {
    toggleModal(_show);
    setClosingModal("closingModal");
    setTimeout(() => {
      setClosingModal("");
    }, 300);
  };

  return (
    <ModalContainer className={closingModal} onMouseDown={handleClickOutside} show={show}>
      <ModalBody custMaxWidth={custMaxWidth} custPad={custPad} style={{ ...styles }} custMinHeight={custMinHeight} custMinHeight1={custMinHeight1}>
        {!hideHead ? (
          <ModelHead titlePadding={titlePadding}>
            {isToolTip ? (
              <TextBar leftText={heading} heading="yes" custId="paydownId" toolTip={envAllDescriptionDetails.OTHER_PAYDOWN_TOOLTIP} />
            ) : (
              <CustomText fWeight={headerFWeight} fSize={headerFSize}>
                {heading}
              </CustomText>
            )}
            <img src={closeIcon} alt="close-button" onClick={() => (closeFunction ? handleClose() : handleToggle(!show))} />
          </ModelHead>
        ) : (
          ""
        )}
        <ModalContent isScroll={isScroll} hideHead={hideHead} contentTop={contentTop} contentMT={contentMT} borderRadius={borderRadius}>
          {children}
        </ModalContent>
      </ModalBody>
    </ModalContainer>
  );
};
export default CustomModal;
