import { useWeb3React } from "@web3-react/core";
import { formatEther } from "ethers/lib/utils";
import { initialValues, SET_BOTH_VALUES, SET_ETH_VALUES } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { InputBox, InputLabel } from "../style";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Deposit = () => {
  const { ETH, LUSD } = useSelector((state: any) => state.trove);
  const { balance, coll } = ETH;
  const { totalDebt } = LUSD;
  const { refetchBalance } = useSelector((state: any) => state.navbar);
  const { ethToUSD } = useWallet();
  const dispatch = useDispatch();
  const { library, account }: any = useConnectWallet();

  const [collateralInputValue, setCollateralInputValue] = useState<string>("0");
  const [collateralInputUSDValue, setCollateralInputUSDValue] = useState<number>(0);
  const [calCollateralInputValue, setCalCollateralInputValue] = useState<number>(0);
  const [collRangevalue, setCollRangeValue] = useState<number>(0);
  const [convertEthBalance, setConvertEthBalance] = useState<any>(0);
  const [ethInDollar, setEthInDollar] = useState<any>(0);
  const [err, setErr] = useState<string>("");
  const debouncedCollateralInputValue = useDebounce(collateralInputValue, 300);

  useEffect(() => {
    if (balance) {
      convertUSD();
    }
    if (Number(debouncedCollateralInputValue) === 0) {
      dispatch(initialValues(library, account, ethToUSD));
    } else {
      handleLoanInformation();
    }
  }, [balance, debouncedCollateralInputValue]);

  useEffect(() => {
    setCollateralInputValue("0");
    setCalCollateralInputValue(0);
    setCollateralInputUSDValue(0);
    setCollRangeValue(0);
  }, [refetchBalance]);

  const convertUSD = async () => {
    const USDValue = await ethToUSD();
    setEthInDollar(USDValue);
    const Convert_ETHBal = USDValue ? Number(USDValue) * Number(balance) : 0.0;
    setConvertEthBalance(Convert_ETHBal);
  };

  const checking = () => {
    let diffBal, ErrorText;
    if (Number(collateralInputValue) > Number(balance)) {
      diffBal = (Number(collateralInputValue) - Number(balance)).toFixed(4);
      const USDBal: any = Number(diffBal) * Number(ethInDollar);
      ErrorText = `The amount you're trying to deposit exceeds your balance by ${formatNumbersWithComma(diffBal)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT
        }   (${formatNumbersWithComma(USDBal, 2)} USD)`;
    } else {
      ErrorText = "";
    }
    setErr(ErrorText);
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        err: ErrorText,
      },
    });
  };

  const handleUpdate = (collValue: string, percentage: number) => {
    const convertColl = formatEther(coll);
    const usdValue = ethInDollar ? Number(ethInDollar) * Number(collValue) : 0.0;
    const newVal = Number(collValue) + Number(convertColl);
    setCollateralInputValue(collValue);
    setCalCollateralInputValue(newVal);
    setCollateralInputUSDValue(usdValue);
    setCollRangeValue(percentage);
  };
  const handleCollateralInput = async (e: any) => {
    const value = validateNumberInput(e)?.toString();
    let percentage: any = ((Number(value) / Number(balance)) * 100).toFixed(0);
    percentage = Number(percentage);
    if (Number(value) <= Number(balance)) {
      handleUpdate(value, percentage);
    }
  };

  const handleCollRangeInput = (e: any) => {
    let percentToValue: any = Number(balance) * (Number(e) / 100);
    percentToValue = percentToValue?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0];
    const percentage = Number(e);
    handleUpdate(percentToValue, percentage);
  };

  const handleLoanInformation = async () => {
    let tempNormalLPrice, tempRecoveryLPrice, tempICollateralRatio;
    if (collateralInputValue) {
      tempNormalLPrice = (110 * Number(totalDebt)) / (Number(calCollateralInputValue) * 100);
      tempRecoveryLPrice = (150 * Number(totalDebt)) / (Number(calCollateralInputValue) * 100);
      const ratio = ((Number(calCollateralInputValue) * Number(ethInDollar)) / Number(totalDebt)) * 100;
      tempICollateralRatio = ratio.toFixed(2);
    } else {
      tempICollateralRatio = "0";
      tempRecoveryLPrice = 0;
      tempNormalLPrice = 0;
    }
    checking();
    dispatch({
      type: SET_ETH_VALUES,
      payload: {
        priceEthDisplay: collateralInputValue,
        priceEth: calCollateralInputValue,
      },
    });

    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        IcollateralRatio: tempICollateralRatio,
        recoveryLPrice: tempRecoveryLPrice,
        normalLPrice: tempNormalLPrice,
      },
    });
  };

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Deposit</span>
            <span className="text2">{envAllDescriptionDetails.VAULT_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Balance: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(balance, 3)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(convertEthBalance, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput placeholder={"Enter Amount"} value={collateralInputValue} onChange={handleCollateralInput} icon={envAllDescriptionDetails.TROVE_LOGO}></CustomInput>
        <RangePointerInput rangeValue={collRangevalue} rangeHandler={handleCollRangeInput} />
      </InputBox>
      {err === "" && Number(collateralInputValue) > 0 && collateralInputValue !== "" ? (
        <Notification
          message={`You are depositing ${formatNumbersWithComma(collateralInputValue, 4)} ${envAllDescriptionDetails.VAULT_TOKEN_TEXT} ($${formatNumbersWithComma(
            collateralInputUSDValue,
            2,
          )})`}
          type="error"
        />
      ) : (
        ""
      )}
    </>
  );
};
