import { useWeb3React } from "@web3-react/core";
import failedImg from "assets/icons/failed.svg";
import axios from "axios";
import { LOGOUT_URL, POST_AUTH_TOKEN_URL } from "shared/helpers/apis";
import { SiweMessage } from "siwe";
import { useDispatch } from "react-redux";
import { initialValues, setAuthStatus, setIsFetched, setIsLogOff, stabilityInitialValues, stakeInitialValues } from "logic/redux/actions";
import { formatEther } from "ethers/lib/utils";
import { PRICE_FEED_INSTANCE } from "blockchain/contract/instance";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const useWallet = (setTransactionStatus?: any, transactionStatus?: any) => {
  const { activate, chainId, account, library, deactivate }: any = useConnectWallet();
  const { ethereum }: any = window;

  const dispatch = useDispatch();

  const fetchBalance = async () => {
    try {
      if (account) {
        return await library?.getBalance(account);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const switchEthereum = async (connector: any) => {
    try {
      if (Number(chainId) !== ChainId?.eth) {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x5" }],
        });
        await activate(connector);
      }
    } catch (err: any) {
      if (err.code === 4902) {
        ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x5",
                chainName: "Goerli Testnet",
                nativeCurrency: {
                  name: "GoerliETH",
                  symbol: "GoerliETH",
                  decimals: 18,
                },
                rpcUrls: ["https://goerli.infura.io/v3/"],
                blockExplorerUrls: ["https://goerli.etherscan.io"],
              },
            ],
          })
          .then(() => {
            activate(connector);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
      if (err.code === 4001) {
        dispatch(setIsFetched(false));
        sessionStorage.clear();
        setTransactionStatus({
          ...transactionStatus,
          modal: true,
          heading: "Failed",
          image: failedImg,
        });
      }
    }
  };

  const switchPulse = async (connector: any) => {
    try {
      if (Number(chainId) !== ChainId?.pls) {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x3af" }],
        });
        await activate(connector);
      }
    } catch (err: any) {
      if (err.code === 4902) {
        ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x3af",
                chainName: "PulseChain Testnet v4",
                nativeCurrency: {
                  name: "tPLS",
                  symbol: "tPLS",
                  decimals: 18,
                },
                rpcUrls: ["https://rpc.v4.testnet.pulsechain.com/"],
                blockExplorerUrls: ["http://scan.v4.testnet.pulsechain.com"],
              },
            ],
          })
          .then(() => {
            activate(connector);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
      if (err.code === 4001) {
        dispatch(setIsFetched(false));
        sessionStorage.clear();
        setTransactionStatus({
          ...transactionStatus,
          modal: true,
          heading: "Failed",
          image: failedImg,
        });
      }
    }
  };

  const generateAuthToken = async () => {
    try {
      dispatch(
        setAuthStatus({
          isAuthStatusLoading: true,
        }),
      );
      const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
      const signer = library?.getSigner();
      library?.send("eth_requestAccounts", []).catch(() => console.log("user rejected request"));
      const message = await createSiweMessage("Sign in Powercity Amplifier App");
      const signature = await signer?.signMessage(message);
      const response = await axios.post(
        `${POST_AUTH_TOKEN_URL}/verify`,
        { message, signature },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const token_info: any = { ...token, [`${account}`]: response.data?.data };
      sessionStorage.setItem("Token", JSON.stringify(token_info));
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data?.data}`;
      // invalid_Token(account);
      dispatch(
        setAuthStatus({
          isAuthStatusLoading: false,
          authStatus: false,
        }),
      );
    } catch (e: any) {
      console.log(e);
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const generateRefreshToken = async () => {
    const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
    try {
      const response = await axios.post(`${POST_AUTH_TOKEN_URL}/refreshToken`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const _account: any = sessionStorage.getItem("Account");
      const token_info: any = { ...token, [`${_account}`]: response.data?.data };
      sessionStorage.setItem("Token", JSON.stringify(token_info));
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data?.data}`;
      console.log("^^lastrefresh");
    } catch (e: any) {
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const clearStorage = async () => {
    const _account: any = sessionStorage.getItem("Account");
    const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
    delete token[_account];
    await logOut();
    deactivate();
    dispatch(setIsFetched(false));
    sessionStorage.clear();
    sessionStorage.setItem("Token", JSON.stringify(token));
    dispatch(
      setAuthStatus({
        isAuthStatusLoading: false,
        authStatus: true,
      }),
    );
  };

  const automaticLogOut = async () => {
    // dispatch(setIsLogOff(true));
    clearStorage();
    // setTimeout(() => {
    //   dispatch(setIsLogOff(false));
    // }, 3000);
  };

  const createSiweMessage = async (statement: string) => {
    const domain = window.location.host;
    const origin = window.location.origin;
    const res = await axios.get(`${POST_AUTH_TOKEN_URL}/nonce`);

    const address: any = account;

    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: chainId,
      nonce: res?.data?.data,
    });
    return message.prepareMessage();
  };

  const logOut = async () => {
    try {
      const _account: any = sessionStorage.getItem("Account");
      const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
      if (token[_account]) {
        dispatch(setIsFetched(false));
        sessionStorage.clear();
        await axios.post(`${LOGOUT_URL}`, {
          token: `Bearer ${token[_account]}`,
        });
        dispatch(
          setAuthStatus({
            authStatus: true,
          }),
        );
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const ethToUSD: any = async () => {
    //For production we have to use 0x4c517D4e2C851CA76d7eC94B805269Df0f2201De & lastGoodPrice
    const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;
    let temp_price: any;
    try {
      if (Number(envChainId) === ChainId?.eth) {
        temp_price = await PRICE_FEED_INSTANCE?.mockPrice();
      } else {
        temp_price = await PRICE_FEED_INSTANCE?.lastGoodPrice();
      }
      let existData: any = sessionStorage.getItem("price");
      existData = JSON.parse(existData);
      const data = JSON.stringify({ ...existData, ethPrice: Number(formatEther(temp_price)) });
      sessionStorage.setItem("price", data);
      return Number(formatEther(temp_price));
    } catch (e: any) {
      console.log(e);
    }
  };
  const InitialLoad = () => {
    const _userContractAddress = sessionStorage.getItem("deployed");
    dispatch(initialValues(library, account, ethToUSD));
    dispatch(stakeInitialValues(_userContractAddress, ethToUSD, account));
    dispatch(stabilityInitialValues(ethToUSD, account));
  };

  return {
    InitialLoad,
    switchEthereum,
    switchPulse,
    fetchBalance,
    generateAuthToken,
    generateRefreshToken,
    ethToUSD,
    logOut,
    clearStorage,
    automaticLogOut,
  };
};
