import { combineReducers } from "redux";
import { navbarReducer } from "./navbar.reducer";
import { notificationReducer } from "./notification.reducer";
import { stabilityReducer } from "./stability.reducer";
import { stakeReducer } from "./stake.reducer";
import { strategyReducer } from "./strategy.reducer";
import { troveReducer } from "./trove.reducer";

const rootReducer = combineReducers({
  navbar: navbarReducer,
  notification: notificationReducer,
  trove: troveReducer,
  stake: stakeReducer,
  stability: stabilityReducer,
  strategyRedu: strategyReducer,
});
export default rootReducer;
