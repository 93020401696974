import styled from "styled-components";
import { screenSizes } from "styles/theme";

interface IProps {
  width?: string;
}
export const RadioInput = styled.input``;

export const InputLabel = styled.label<IProps>`
  color: ${(props: any) => props.theme.white};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: start;
  @media (max-width: ${screenSizes.XS}px) {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: ${(props: any) => props.width};
    font-size: 14px;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-right: 15px;

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: none;
    margin: 0;
    font: inherit;
    color: ${(props: any) => props.theme.darkGray};
    width: 1.15em;
    height: 1.15em;
    border: 2px solid ${(props: any) => props.theme.darkGray};
    border-radius: 50%;
    display: grid;
    place-content: center;
  }
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${(props: any) => props.theme.primaryButton};
  }
  input[type="radio"]:checked {
    border: 0.15em solid ${(props: any) => props.theme.primaryButton};
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
`;
