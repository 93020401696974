import {
  HIDE_MODULE,
  ISAUTOMATION_MODAL_OPEN,
  REFETCH_BALANCE,
  SET_AUTOMATION_BALANCE,
  SET_AUTOMATION_LOGS,
  SET_AUTOMATION_RUN,
  SET_AVERAGE_FEE,
  SET_CONNECT_WALLET,
  SET_DISCONNECT_WALLET,
  SET_IS_AUTOMATION,
  SET_IS_CONNECTOR_ID,
  SET_IS_LOGOFF,
  SET_IS_SYNCED_WITH_API,
  SET_MAX_CAP,
  SET_SHOW_STATS,
  SET_USERADDRESS,
  SIGN_VERIFICATION,
  ISAUTOMATION_LOG_MODAL_OPEN,
  SET_ISFETCHED,
} from "../actions/constant";

const initialState = {
  connected: false,
  connectWallet: false,
  disconnectWallet: false,
  userContractAddress: "",
  isShowStats: false,
  isLogOff: false,
  isAutomation: false,
  connectorID: -1,
  isContractSyncedWithAPI: false,
  isHideModule: false,
  refetchBalance: false,
  isAuthStatusLoading: false,
  authStatus: true,
  isAutomationModalOpen: false,
  isAutomationLogModalOpen: false,
  automationLogs: null,
  maxGap: "0",
  automationCanRun: "0",
  automationBalance: 0,
  averageFee: 0,
  isFetched: false
};

export const navbarReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case SET_CONNECT_WALLET:
      return {
        ...state,
        connectWallet: payload,
      };
    case SET_DISCONNECT_WALLET:
      return {
        ...state,
        disconnectWallet: payload,
      };
    case SET_USERADDRESS:
      return {
        ...state,
        userContractAddress: payload,
      };
    case SET_SHOW_STATS:
      return {
        ...state,
        isShowStats: payload,
      };
    case SET_IS_LOGOFF:
      return {
        ...state,
        isLogOff: payload,
      };
    case SET_IS_AUTOMATION:
      return {
        ...state,
        isAutomation: payload,
      };
    case SET_IS_CONNECTOR_ID:
      return {
        ...state,
        connectorID: payload,
      };
    case SET_IS_SYNCED_WITH_API:
      return {
        ...state,
        isContractSyncedWithAPI: payload,
      };
    case HIDE_MODULE:
      return {
        ...state,
        isHideModule: payload,
      };
    case REFETCH_BALANCE:
      return {
        ...state,
        refetchBalance: payload,
      };
    case SIGN_VERIFICATION:
      return {
        ...state,
        ...payload,
      };
    case ISAUTOMATION_MODAL_OPEN:
      return {
        ...state,
        isAutomationModalOpen: payload,
      };
    case ISAUTOMATION_LOG_MODAL_OPEN:
      return {
        ...state,
        isAutomationLogModalOpen: payload,
      };
    case SET_AUTOMATION_LOGS:
      return {
        ...state,
        automationLogs: payload,
      };
    case SET_MAX_CAP:
      return {
        ...state,
        maxGap: payload,
      };
    case SET_AUTOMATION_RUN:
      return {
        ...state,
        automationCanRun: payload,
      };
    case SET_AUTOMATION_BALANCE:
      return {
        ...state,
        automationBalance: payload,
      };
    case SET_AVERAGE_FEE:
      return {
        ...state,
        averageFee: payload,
      };
    case SET_ISFETCHED:
      return {
        ...state,
        isFetched: payload
      };
    default:
      return state;
  }
};
