import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { connectorsObject } from "./connectors";

export const wallets = {
  metamask: {
    id: 1,
    connector: MetaMask,
    connectWallet: connectorsObject?.metamask,
  },

  walletConnect: {
    id: 3,
    connector: WalletConnect,
    connectWallet: connectorsObject?.walletConnect,
  },
};

export const selecWallet = (type: number): any => {
  switch (type) {
    case 1:
      return connectorsObject["metamask"].connector;
    case 2:
      return connectorsObject["walletConnect"].connector;
  }
};

export const checkWallet = <T>(connector: T): void => {
  const connectorInstant = Object.values(wallets).filter((val: { [key: string]: any }) => {
    if (connector instanceof val.connector) {
      return val.id;
    }
  });

  localStorage.setItem("wallet", JSON.stringify(connectorInstant[0].id));
};

export const selectWalletHooks = () => {
  //@ts-ignore
  const walletId = JSON.parse(localStorage.getItem("wallet"));

  const connectorInstant = Object.values(wallets).filter((val: { [key: string]: any }) => val.id === walletId);

  if (connectorInstant?.length > 0) {
    return connectorInstant[0]?.connectWallet?.hooks;
  }

  return wallets?.metamask?.connectWallet?.hooks;
};

export const ChainId = {
  eth: 5,
  pls: 943,
};

export const WalletTypes = {
  default: 0,
  metamask: 1,
  walletConnect: 2,
  authereum: 3,
  burnerConnect: 4,
  uniLogin: 5,
  mewWallet: 6,
  binance: 7,
};

export const Networks = {
  mainnet: "0x1",
  ropsten: "0x3",
  rinkeby: "0x4",
  goerli: "0x5",
  kovan: "0x2a",
  moonBaseAlpha: "0x507",
};
