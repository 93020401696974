import { ContentContainer } from "./style"
import { Link } from "react-router-dom";
import { PageContainer } from "shared/flexBox/style";
import { rootPath } from "logic/paths";
import { LightText } from "shared/Typography";

export const PageNotFound = () => {
   
    return (
        <PageContainer>
            <ContentContainer>
                <LightText fSizeM='30px' fSize='40px' fOpacity='1'>
                    The page does not exist
                </LightText>
                <span>
                    Sorry the page you are looking could not found. Checkout the <Link to={rootPath}>home page</Link> instead.
                </span>
            </ContentContainer>
        </PageContainer>
    )
}
