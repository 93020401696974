import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useSelector } from "react-redux";
import { Loader } from "shared/loader/loader";
import { Container } from "shared/styled";
import { LeftBox } from "./left/LeftBox";
import { RightBox } from "./right/RightBox";
import { DashContainer } from "./style";


export const Dashboard = () => {
  const { userContractAddress,isFetched } = useSelector((state: any) => state.navbar);

  const { handleCompoundButton, handleCompoundStability, handleCompoundStaking } = useStrategy();

  const { selectedCompoundStrategy } = useSelector((s: any) => s.strategyRedu);



  if ((userContractAddress && !selectedCompoundStrategy) || !isFetched) {
    return <Loader />;
  }


  return (
    <Container width="100%">
      <DashContainer>
        <LeftBox handleCompoundButton={handleCompoundButton} handleCompoundStability={handleCompoundStability} handleCompoundStaking={handleCompoundStaking} />
        <RightBox />
      </DashContainer>
    </Container>
  );
};
