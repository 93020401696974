import { ContainerBox, Tab, TabBox } from "shared/styled";
import { useState } from "react";
import { Deposit } from "./Deposit";
import { Withdraw } from "./Withdraw";

export const PrimaryBox = () => {
  const localTab = sessionStorage.getItem("currentAutomationTab");
  const [active, setActive] = useState<number>(localTab ? Number(localTab) : 1);

  const handleConfirmTabs = (current: number) => {
    setActive(current);
    sessionStorage.setItem("currentAutomationTab", current.toString());
  };

  return (
    <>
      <ContainerBox maxWidth="400px">
        <TabBox>
          <Tab border={active === 1 ? "yes" : ""} onClick={() => handleConfirmTabs(1)}>
            Deposit{" "}
          </Tab>
          <Tab border={active === 2 ? "yes" : ""} onClick={() => handleConfirmTabs(2)}>
            Withdraw
          </Tab>
        </TabBox>
        {active === 1 ? <Deposit /> : <Withdraw />}
      </ContainerBox>
    </>
  );
};
