
import styled from "styled-components";
import { screenSizes } from "styles/theme";

export const BtnRowContainer = styled.div<IInputContainerProps>`
  display: flex;
  width: 100%;
  gap: 1em;
  justify-content: ${({ justify }: any) => justify || 'space-between'};
  @media (max-width: ${screenSizes.XS}px) {
    justify-content: start;
    gap: .5em;
  }
`
interface IInputContainerProps {
  width?: string
  justify?: string
  minwidth?: string
  flexVal?: string
}
export const InputContainer = styled.div<IInputContainerProps>`
  position: relative;
  display: flex;
  min-width: ${({ minwidth }: any) => minwidth};
  width: ${({ width }: any) => width};
  gap: 1em;
  justify-content: start;
  flex: ${({ flexVal }: any) => flexVal};
  @media (max-width: ${screenSizes.M}px) {
    min-width: 150px;
  }
  @media (max-width: ${screenSizes.XS}px) {
    min-width: unset;
    max-width: 100px;
    gap: .5em;
  }
 
`

export const ModalListContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  @media (max-width: ${screenSizes.M}px) {
    gap: .5em;
  }
`
export const AddMoreCntr = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

`
export const CloseIconWrapper = styled.div`
position: absolute;
top:-30%;
z-index: 999;
border:2px solid #000;
background-color: #000;
padding: 2px 3px;
border-radius: 50%;
cursor: pointer;
img{
  height: 50%;
  width: 50%;
  transform: translateY(-40%);
}
`
