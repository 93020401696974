import PLSLogo from "assets/icons/PLS.svg";
import EtherLogo from "assets/icons/ether.svg";
import LUSDLogo from "assets/icons/LUSD.png";
import USDLLogo from "assets/icons/USDL.svg";
import LQTYLogo from "assets/icons/LQTY.png";
import LOANLogo from "assets/icons/LOAN.svg";
import axios from "axios";
import BigNumber from "bignumber.js";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const formatAddress = (walletAddress?: any, numOfCharsL = 6, numOfCharsR = 4) => {
  const address = walletAddress?.toString();

  return `${address?.substr(0, numOfCharsL)}...${address?.substr(address.length - numOfCharsR, address.length)}`;
};

export const formatNumbersWithComma = (value: any, max = 3) => {
  const decimalMultiplier = Math.pow(10, max);
  const truncatedValue = Math.trunc(value * decimalMultiplier) / decimalMultiplier;
  return truncatedValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: max });
  // return Number(value).toLocaleString(undefined, { maximumFractionDigits: max, minimumFractionDigits: 2 });
};

export type Uuid = string;
export type Hash = string;
export type UrlPath<T> = string & { _?: T }; // Basically a string.  The second clause is to peg the generic type

export function matchesPath(path: string, value: string, exact: boolean): boolean {
  const generalized = path.replace(/:\w+/g, "[^/]*?");
  const pattern = `^${generalized}${exact ? "$" : ""}`;
  const regex = new RegExp(pattern);
  return !!value.match(regex);
}

export const validateNumberInput = (e: any, decimal = 4) => {
  let { value, max } = e.target;

  // Add leading zero if value starts with a decimal point
  if (value.startsWith(".")) {
    value = "0" + value;
  }

  if (value.indexOf(".") >= 0) {
    const whole = value.substr(0, value.indexOf("."));
    const rest = value.substr(value.indexOf("."), decimal);
    let dec = rest.split(".")[1];
    value = whole + "." + dec.substr(0, decimal);
  }

  if (isValid(value)) {
    if (max) {
      if (Number(value) <= max) {
        return value;
      }
      return max;
    }
    return value;
  }
  return "";
};

export function isValid(str: any) {
  return !/[~`!@#$%\^&*()+=\-\_\[\]\\';,\/{}|\\":<>\\A-Za-z\? ]/g.test(str);
}

export const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  return function () {
    if (timeoutId) clearInterval(timeoutId);
    timeoutId = setTimeout(() => {
      fn();
    }, delay);
  };
};

export const deepEqual = (a: any, b: any) => {
  if (typeof a === "object" && a !== null && typeof b === "object" && b !== null) {
    var count = [0, 0];
    for (var key in a) count[0]++;
    for (var key in b) count[1]++;
    if (count[0] - count[1] !== 0) {
      return false;
    }
    for (var key in a) {
      if (!(key in b) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }
    for (var key in b) {
      if (!(key in a) || !deepEqual(b[key], a[key])) {
        return false;
      }
    }
    return true;
  } else {
    return a === b;
  }
};

export const numberFormatter = (num: number, digits: number) => {
  // if (num > 0 && num < 0.0001) return "0.0001";
  if (num < 1) return parseFloat(Number(num).toFixed(4));
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    .slice()
    .reverse()
    .find(function (i) {
      return num >= i.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const firstCharToUpper = (text: string) => {
  if (!text || text === "") return;
  const splitArr = text.split(" ");
  const firstUpperArr = splitArr.map(el => {
    return el.charAt(0).toUpperCase() + el.slice(1);
  });
  return firstUpperArr.join(" ");
};

export const validateNumberInputOnChange = (e: any) => {
  let { value, max, min } = e.target;
  if (max && min) {
    if (Number(value) >= min && Number(value) <= max) return value;
    if (Number(value) > max) return max;
    return min;
  }
  if (max) {
    if (Number(value) > max) return max;
    return value;
  }
  if (min) {
    if (Number(value) < min) return min;
    return value;
  }
  return value;
};

export const storeTokenUSD = async () => {
  try {
    let data = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cliquity%2Cliquity-usd&vs_currencies=usd");
    let data_: any = await data.data;
    const priceData = {
      lqty: Number(envChainId) === ChainId?.eth ? data_?.liquity?.usd : 1.0,
      lusd: Number(envChainId) === ChainId?.eth ? data_?.["liquity-usd"]?.usd : 1.0,
    };
    sessionStorage.setItem("price", JSON.stringify(priceData));
  } catch (e: any) {
    console.log(e, "error in coingecko");
  }
};

export const tokenToUSD = async () => {
  const value = JSON.parse(sessionStorage.getItem("price") || "{}");
  return { lusdUSD: Number(value?.["lusd"]), lqtyUSD: Number(value?.["lqty"]) };
};

export const sendToContract = (value: string, decimal: number) => {
  return new BigNumber(value).times(10 ** decimal).toFixed(0);
};

export const receiveFromContract = (value: string, decimal: number, fixed: number) => {
  return new BigNumber(value).dividedBy(10 ** decimal).toFixed(fixed);
};

export const URL_TERMS = "https://powercity.io/new/#";
export const URL_PRIVACY_POLICY = "https://powercity.io/new/#";
export const URL_READ_MORE = "https://powercity.io/new/#";

export const URL_READ_MORE_TROVE: UrlPath<string> = "https://docs.liquity.org/faq/borrowing#what-is-a-trove";
export const URL_READ_MORE_SP: UrlPath<string> = "https://docs.liquity.org/faq/stability-pool-and-liquidations#what-is-the-stability-pool";
export const URL_READ_MORE_STAKING: UrlPath<string> = "https://docs.liquity.org/faq/staking#how-does-staking-work-in-liquity";
export const URL_READ_MORE_DASHBOARD: UrlPath<string> = "https://docs.powercity.io/amplifier/";
export const URL_READ_MORE_HOME: UrlPath<string> = "https://docs.powercity.io/amplifier/";
export const URL_READ_MORE_STRATERGY: UrlPath<string> = "https://docs.powercity.io/amplifier/strategies";
export const URL_READ_MORE_AUTOPILOT: UrlPath<string> = "https://docs.powercity.io/amplifier/automation";
export const URL_READ_MORE_TROVE_SUMMARY: UrlPath<string> = "https://docs.powercity.io/amplifier/how-to.../create-or-edit-vault";
export const URL_READ_MORE_STABILITY_SUMMARY: UrlPath<string> = "https://docs.powercity.io/amplifier/how-to.../stake-in-stability-pool";
export const URL_READ_MORE_STAKING_SUMMARY: UrlPath<string> = "https://docs.powercity.io/amplifier/how-to.../stake-in-staking-pool";
export const URL_READ_MORE_REDEMPTION_SUMMARY: UrlPath<string> = "https://docs.powercity.io/amplifier/how-to.../redeem-stablecoins";

const ethContractsDescriptions = {
  VAULT_TOKEN_TEXT: "ETH",
  STABILITY_TOKEN_TEXT: "LUSD",
  STAKING_TOKEN_TEXT: "LQTY",
  DashboardText:
    "Amplifier is an automated front-end to the Liquity lending protocol on Ethereum (and any forked implementations such as Liquid Loans on PulseChain). Liquity is a trustless crypto loan protocol that mints a stablecoin by locking up Ether as collateral. Amplifier simplifies the operation of Liquity’s many functions while also automating several leveraged trading strategies so you don’t need to continually track market prices to time the strategies to their maximum potential.",
  DeployContractInfoText:
    "Amplifier automates the Liquity lending protocol.  To do this, you must first deploy a unique copy of the Amplifier strategy contract to your wallet.  This contract will be owned by your wallet and the Liquity Trove will be created owned by this contract.  Funds added to that contract can only be accessed by your wallet and the Amplifier Autopilot, if optionally engaged (not required).  No administrator of POWERCITY can access your funds in your wallet or this contract.  Once the contract is created, you can then fund your Liquity Trove, borrow LUSD, stake LUSD in the Stability Pool and stake LQTY tokens in the Staking Pool.",
  STRATEGY_DASHBOARD_TIP_TEXT: "Amplifier requires that you select a strategy, whether predefined or customize by you, to help automate the Liquity lending protocol",
  STABILITY_DASHBOARD_TIP_TEXT:
    "The Stability Pool is funded by users who stake their LUSD.  This LUSD is redeemed in the event a Trove/Vault is liquidated, rewarding the stakers with that trove's ETH collateral.  Staking in the Stability pool will earn LQTY token rewards.",
  STABILITY_DASHBOARD_TIP_RM: "https://docs.liquity.org/faq/stability-pool-and-liquidations",
  STAKING_DASHBOARD_TIP_TEXT: "Stake LQTY tokens to earn a share of Liquity's lending and redemption fees.  Staking rewards are collected in ETH and LUSD.",
  STAKING_DASHBOARD_TIP_RM: "https://docs.liquity.org/faq/staking",
  TROVE_DASHBOARD_TIP_TEXT:
    "Deposit ETH into a Trove/Vault to mint LUSD stablecoins.  The ratio of ETH value to borrowed LUSD is the Collateralization Ratio, which will determine how much LUSD you can borrow in total and enable Amplifier to leverage your value according to your selected strategies. ",
  TROVE_DASHBOARD_TIP_RM: "https://docs.liquity.org/faq/borrowing#what-is-a-trove",
  DEBT_TEXT: "This is the amount of LUSD that has been borrowed.  To close your trove, this amount of LUSD would need to be deposited",
  URL_DEBT_TIP_TEXT_RM: "https://docs.liquity.org/faq/borrowing",
  POWERCITY_FEE_TEXT: "The Amplifier charges a fee for utilizing the automated functions within.",
  DASHBOARD_INFO_TEXT:
    "The Amplifier Dashboard provides a summary of all functions with links to the details for each component.  Click the Details to update each component individually.  The Selected Strategy can be updated at any time.  Compounding functions based on the selected strategy can be called for each component manually or for all components through the Compound All function.",
  DASHBOARD_INFO_LINK: "Please read more about Amplifier and Liquity here.",
  TARGET_TIP_TEXT:
    "Compounding loans will only borrow enough LUSD to bring the Collateralization Ratio (CR) to this level.  Compounded loans will never borrow more than this level, but market price changes can lower the CR below this level.  Minimum amount of collateral is 110% unless Autopilot is enabled.  To enable Autopilot, the minimum Collateral Ratio to maintain is 500% to ensure the trove is not easily liquidated due to market price changes",
  TRIGGER_TIP_TEXT:
    "Autopilot loan compounding will wait until the Collateral Ratio (CR) has increased by this amount above the Target Collateral Ratio.  This is the amount of market value increase in collateral you want to wait before automatically taking the next loan as part of your selected strategy.",
  ACCEPT_CRITERIA_TEXT1:
    " I understand that I am choosing to use Amplifier to access the Liquity lending platform and may allow Amplifier to automate those tasks. When the Amplifier is used to ‘lend’ or ‘deposit’ tokens into a third-party smart-contract system, the funds are not an obligation of, guaranteed or insured by POWERCITY. Borrowing in the Amplifier and Liquity systems is a promise to pay back that debt backed by the value of the crypto you provided as collateral. If the value of your collateral falls below 110% of the value of the debt, your loan may be liquidated and your collateral forfeited. Use lending tools responsibly.",
  ACCEPT_CRITERIA_TEXT2:
    "The transactions you can effect through the Liquity decentralized lending protocol, while superficially similar to traditional financial transactions in some ways, are in fact very different. DeFi and TradFi each have unique costs and benefits, risks and protection mechanisms. Please bear this fact in mind when using this website, and do not use Amplifier, the Amplifier Strategies, or any other system described on this website without a sufficient understanding of their unique risks and how they differ from traditional financial transactions. The only way to fully understand such risks is to have a strong understanding of the relevant technical systems and the incentive design mechanisms they embody--we strongly encourage you to review Amplifier's technical documentation before use.",
  ACCEPT_CRITERIA_TEXT3:
    "I acknowledge my understanding of the risks of borrowing from, staking within and interacting with decentralized smart contracts on the Ethereum Blockchain. A malfunctioning, exploited or underperforming smart contract cannot be forced (in court or otherwise) to pay the ‘borrowed’ tokens back.",
  TROVE_SUMMAR_TEXT:
    "You can borrow LUSD by opening a Liquity trove and depositing ETH collateral.  The minimum collateral ratio (total debt / value of collateral) is 110%, but a higher ratio is recommended to prevent liquidation and forfeit of the ETH collateral in the trove.  Amplifier allows the new LUSD minted to be routed to other components of Liquity - see the compounding target and the Amplifier Select Strategy screens for more information.",
  CLAIM_LQTY_MOVE_ETH_TEXT: "Claim all earned ETH from trove liquidations and deposit it to Trove, Claim LQTY rewards to your wallet.",
  STABILITY_SUMMARY_TEXT:
    "You can earn ETH and LQTY rewards by depositing LUSD. When a loan is liquidated, a portion of your LUSD is burned and replaced with a larger amount of ETH.  The stability pool participants are rewarded with LQTY tokens for providing this service.  LQTY tokens are staked in the Staking Pool to earn a share of the Liquity protocol fees.",
  STAKING_SUMMARY_TEXT:
    "Stake LQTY tokens in the Staking Pool to earn Liquity protocol fees in ETH and LUSD. LQTY tokens can be purchased or earned by staking LUSD in the Stability Pool.",
  CLAIM_STABILITY_REWARDS_TEXT: "Claim all earned ETH from trove liquidations and Claim LQTY rewards to your wallet",
  STAKED_TEXT: "The total amount of LQTY that is staked for earning fee revenue.",
  REWARD_TEXT:
    "Although the LQTY rewards accrue every minute, the value on the UI only updates when a user transacts with the Stability Pool. Therefore you may receive more rewards than is displayed when you claim or adjust your deposit.",
  REWARDS_TIP_TEXT:
    "Although the LQTY rewards accrue every minute, the value on the UI only updates when a user transacts with the Stability Pool. Therefore you may receive more rewards than is displayed when you claim or adjust your deposit.",
  KICKBACK_FEE:
    "A rate between 0 and 100% set by the Frontend Operator that determines the fraction of LQTY that will be paid out as a kickback to the Stability Providers using the frontend.",
  OTHER_PAYDOWN_TOOLTIP:
    "Paying down the current debt will claim any accrued LUSD staking rewards, may unstake some or all of the LUSD staked in the Stability Pool, and if selected, may sell some of the excess collateral ETH in the trove to pay off as much of the current debt as possible.  A minimum balance of $2,000 debt is required to keep the Liquity Trove open.  The Amplifier Paydown Debt function will attempt to repay as much of the total debt, minus the $2,000 minimum debt amount, using the assets you selected in the Debt Paydown Selector. If you wish to fully repay your debt and close your trove, please use the CLOSE TROVE function",
  BORROWING_FEE_TEXT:
    "The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in LUSD) and is part of a Trove's debt. The fee varies between 0.5% and 5% depending on LUSD redemption volumes.",
  SUPPLY_TEXT: "The total LUSD minted by the Liquity Protocol.",
  POOL_TEXT: "The total LUSD currently held in the Stability Pool, expressed as an amount and a fraction of the LUSD supply.",
  CR_TEXT:
    "The ratio between the dollar value of the collateral and the debt (in LUSD) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.",
  COLLATERAL_RATIO_TIP_TEXT:
    "The ratio between the dollar value of the collateral and the debt (in LUSD) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.",
  TVL_TEXT: "The Total Value Locked (TVL) is the total value of Ether locked as collateral in the system, given in ETH and USD.",
  TCR_TEXT: "The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt.",
  RECOVERY_MODE_PRICE_THRESHHOLD: "The Dollar value of ETH below which the Total Collateral Ratio will drop below 150% and the system will enter Recovery Mode",
  STABILITY_CLAIM_MOVE_REWARD: "Claim LQTY and move ETH",
  TROVE_LOAN_COMPOUNDING_TOPUP_VAULT: "LUSD -> ETH -> Trove",
  TROVE_NOEXECUTE_COMPOUND: "LUSD -> Stability Pool",
  TROVE_LOAN_COMPOUNDING_HALF_HALF: "LQTY Rewards -> Staking Pool, ETH Rewards -> trove",
  STABILITY_LOAN_COMPOUNDING_MAILBOX: "ETH -> LUSD -> Stability Pool, Send 50% to wallet and Stake 50% of LQTY",
  STABILITY_LOAN_COMPOUNDING_TOPUP_STABILITY: "ETH -> Wallet, LQTY -> Wallet",
  STABILITY_LOAN_NOEXECUTE_COMPOUNDING_HALF_HALF: "LQTY Rewards -> Staking Pool, ETH Rewards -> trove",
  STAKING_LOAN_NOEXECUTE_COMPOUNDING_MAILBOX: "ETH Rewards -> Wallet, LUSD Rewards -> Stability Pool",
  STAKING_LOAN_NOEXECUTE_COMPOUNDING_HALF_AND_HALF: "LUSD Rewards -> Wallet, ETH Rewards -> Wallet",
  STAKING_LOAN_COMPOUNDING_PASSIVE: "LUSD -> ETH, ETH Rewards -> Trove",
  STAKING_LOAN_COMPOUNDING_TOPUP_STAKING: "ETH -> Wallet, LUSD -> Wallet",
  PAYDOWN_TOOLTIP: "Swap Eth -> LUSD. Final price is subject to change.",
  TOPOFF_TOPUP_VAULT_TEXT: "Mint LUSD, swap to ETH and add that ETH to the trove to increase collateral.",
  TOPOFF_TOPUP_STABILITY_TEXT: "Mint LUSD and stake in the stability pool.",
  TOPOFF_TOPUP_STAKING_TEXT: "Mint LUSD, swap to LQTY and stake in the Staking Pool to earn LUSD and ETH.",
  STATS_HEADING: "Liquity Statistics",
  REDEMPTION_TEXT:
    "Redemptions are one of the Liquity lending protocol most unique and important protocol features. The redemption mechanism gives LUSD holders the ability to redeem the underlying ETH collateral at face value at any time. Redemptions pay off the debt of the riskiest Troves, in return for their collateral. IMPORTANT: Redemptions are not the same as paying back your Trove's debt. To repay your loan, adjust your Trove on the Repay tab of the Borrow LUSD page.",
  TROVE_LOGO: EtherLogo,
  STABILITY_LOGO: LUSDLogo,
  STAKING_LOGO: LQTYLogo,
  DISABLE_REASON_MM_GAINS: "No LUSD/ETH Gains for compounding Staking Pool",
  DISABLE_REASONS_HH_GAINS: "No LQTY/ETH Gains for compounding Stability Pool",
  DISABLE_REASON_CR_NOT_ZERO: "Target CR and Trigger CR can't be zero",
  DISABLE_REASON_LESS_CR: "Current CR is less than Trigger CR",
  DISABLE_REASON_NO_LQTY_GAINS: "No LQTY Gains for compounding",
  DISABLE_REASON_NO_LUSD_GAINS: "No LUSD Gains for compounding",
  DISABLE_REASONS_NO_REWARDS: "No rewards/gain for compounding",
  DISABLE_REASONS_NO_TROVE: "Trove/Vault does not exist or is closed",
  DISABLE_REASONS_NO_DEPOSIT_STABILITY: "You have no deposits in the stability pool",
  DISABLE_REASONS_NO_DEPOSIT_STAKING: "You have no deposits in the staking pool",
  DISABLE_PAYDOWN_NO_OPTION_SELECTED: "DebtPaydown Option is not selected!",
  DISABLE_AUTHORIZATION_ERROR: "Authorization Error!",
  DISABLE_PAYDOWN_NO_REWARDS_FOR_PAYDOWN: "No Rewards in StakingPool or LUSD in StabilityPool to Paydown Debt",
  DISABLE_PAYDOWN_CR_FALLS_120: "Unable to execute as new collateral ration falls below 120",
  DISABLE_PAYDOWN_DESELECTING_STAKING_ETH_REWARDS: "De-select Staking Pool ETH Rewards Option to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STAKING_LUSD_REWARDS: "De-select Staking Pool LUSD Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_LQTY_REWARDS: "De-select Stability Pool LQTY Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_ETH_REWARDS: "De-select Stability Pool ETH Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_WITHDRAW_AMOUNT: "Decrease Stability Pool Withdraw Amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_CR_LESS_THAN_110: "Cannot withdraw ETH from Trove when CR <= 110%",
  DISABLE_PAYDOWN_DECREASING_ETH_FROM_TROVE: "Decrease ETH from Trove amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DECREASING_LUSD_FROM_WALLET: "Decrease LUSD From Wallet amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_LESS_CR: "Collateral ratio will become less than 110%",
  DEFAULT_STABILITY_COMPOUNDING_TARGET: "ETH -> Wallet, LQTY -> Wallet",
  DEFAULT_STAKING_COMPOUNDING_TARGET: "ETH -> Wallet, LUSD -> Wallet",
  DISABLE_NO_ETH: "No Eth Gains to Claim.",
  DISABLE_NO_TROVE_FOR_WITHDRAW: "You must have an active trove to withdraw Eth Gains to trove",
  DISABLE_NOTHING_REDEMEED: "Nothing can be redeemed out of the provided LUSD amount.",
  DISABLE_REDEMPTION_LESS_THAN_LUSD: "User's LUSD balance must be greater than or equal to Requested redemption amount",
  DISABLE_CLAIM_REASON: "You don't have an active vault to withdraw ETHGain to",
};
const pulseContractDescriptions = {
  VAULT_TOKEN_TEXT: "PLS",
  STABILITY_TOKEN_TEXT: "USDL",
  STAKING_TOKEN_TEXT: "LOAN",
  DashboardText:
    "Amplifier is an automated front-end to the Liquid Loans lending protocol on PulseChain. Liquid Loans is a trustless crypto loan protocol that mints a stablecoin by locking up Pulse as collateral. Amplifier simplifies the operation of Liquid Loan’s many functions while also automating several leveraged trading strategies so you don’t need to continually track market prices to time the strategies to their maximum potential.",
  DeployContractInfoText:
    "Amplifier automates the Liquid Loans lending protocol.  To do this, you must first deploy a unique copy of the Amplifier strategy contract to your wallet.  This contract will be owned by your wallet and the Liquid Loans vault will be created owned by this contract.  Funds added to that contract can only be accessed by your wallet and the Amplifier Autopilot, if optionally engaged (not required).  No administrator of POWERCITY can access your funds in your wallet or this contract.  Once the contract is created, you can then fund your Liquid Loans vault, borrow USDL, stake USDL in the Stability Pool and stake LOAN tokens in the Staking Pool.",
  STRATEGY_DASHBOARD_TIP_TEXT: "Amplifier requires that you select a strategy, whether predefined or customize by you, to help automate the Liquid Loans lending protocol.",
  STABILITY_DASHBOARD_TIP_TEXT:
    "The Stability Pool is funded by users who stake their USDL.  This USDL is redeemed in the event a Trove/Vault is liquidated, rewarding the stakers with that trove's PLS collateral.  Staking in the Stability pool will earn LOAN token rewards.  Learn more about the Stability Pool here.",
  STABILITY_DASHBOARD_TIP_RM: "https://docs.liquidloans.io/stability-pool-and-liquidations#what-is-the-stability-pool",
  STAKING_DASHBOARD_TIP_TEXT:
    "Stake LOAN tokens to earn a share of Liquid Loan's lending and redemption fees.  Staking rewards are collected in PLS and USDL.  Learn more about the Staking Pool here.",
  STAKING_DASHBOARD_TIP_RM: "https://docs.liquidloans.io/loan-staking",
  TROVE_DASHBOARD_TIP_TEXT:
    "Deposit PLS into a Trove/Vault to mint USDL stablecoins.  The ratio of PLS value to borrowed USDL is the Collateralization Ratio, which will determine how much USDL you can borrow in total and enable Amplifier to leverage your value according to your selected strategies.  Learn more about Troves here",
  TROVE_DASHBOARD_TIP_RM: "https://docs.liquidloans.io/borrowing#what-is-a-vault",
  DEBT_TEXT: "This is the amount of USDL that has been borrowed.  To close your trove, this amount of USDL would need to be deposited. Learn more here",
  URL_DEBT_TIP_TEXT_RM: "https://docs.liquidloans.io/borrowing",
  POWERCITY_FEE_TEXT: "The Amplifier charges a fee for utilizing the automated functions within.  This fee will be deducted each time USDL is borrowed from your Trove/Vault.",
  DASHBOARD_INFO_TEXT:
    "The Amplifier Dashboard provides a summary of all functions with links to the details for each component.  Click the Details to update each component individually.  The Selected Strategy can be updated at any time.  Compounding functions based on the selected strategy can be called for each component manually or for all components through the Compound All function.",
  DASHBOARD_INFO_LINK: "Please read more about Amplifier and Liquid Loans here",
  TARGET_TIP_TEXT:
    "Compounding loans will only borrow enough USDL to bring the Collateralization Ratio (CR) to this level.  Compounded loans will never borrow more than this level, but market price changes can lower the CR below this level.  Minimum amount of collateral is 110% unless Autopilot is enabled.  To enable Autopilot, the minimum Collateral Ratio to maintain is 500% to ensure the trove is not easily liquidated due to market price changes.",
  TRIGGER_TIP_TEXT:
    "Autopilot loan compounding will wait until the Collateral Ratio (CR) has increased by this amount above the Target Collateral Ratio.  This is the amount of market value increase in collateral you want to wait before automatically taking the next loan as part of your selected strategy.",
  ACCEPT_CRITERIA_TEXT1:
    "I understand that I am choosing to use Amplifier to access the Liquid Loans lending platform and may allow Amplifier to automate those tasks.  When the Amplifier is used to ‘lend’ or ‘deposit’ tokens into a third-party smart-contract system, the funds are not an obligation of, guaranteed or insured by POWERCITY.  Borrowing in the Amplifier and Liquid Loans systems is a promise to pay back that debt backed by the value of the crypto you provided as collateral.  If the value of your collateral falls below 110% of the value of the debt, your loan may be liquidated and your collateral forfeited.  Use lending tools responsibly. ",
  ACCEPT_CRITERIA_TEXT2:
    "The transactions you can effect through the Liquid Loans decentralized lending protocol, while superficially similar to traditional financial transactions in some ways, are in fact very different. DeFi and TradFi each have unique costs and benefits, risks and protection mechanisms. Please bear this fact in mind when using this website, and do not use Amplifier, the Amplifier Strategies, or any other system described on this website without a sufficient understanding of their unique risks and how they differ from traditional financial transactions. The only way to fully understand such risks is to have a strong understanding of the relevant technical systems and the incentive design mechanisms they embody--we strongly encourage you to review Amplifier's technical documentation before use.",
  ACCEPT_CRITERIA_TEXT3:
    "I acknowledge my understanding of the risks of borrowing from, staking within and interacting with decentralized smart contracts on the Ethereum Blockchain.   A malfunctioning, exploited or underperforming smart contract cannot be forced (in court or otherwise) to pay the ‘borrowed’ tokens back.",
  TROVE_SUMMAR_TEXT:
    "You can borrow USDL by opening a Liquid Loans vault and depositing PLS collateral.  The minimum collateral ratio (total debt / value of collateral) is 110%, but a higher ratio is recommended to prevent liquidation and forfeit of the PLS collateral in the trove.  Amplifier allows the new USDL minted to be routed to other components of Liquid Loans - see the compounding target and the Amplifier Select Strategy screens for more information.",
  CLAIM_LQTY_MOVE_ETH_TEXT: "Claim all earned PLS from vault liquidations and LOAN rewards to your wallet",
  STABILITY_SUMMARY_TEXT:
    "You can earn PLS and LOAN rewards by depositing USDL. When a loan is liquidated, a portion of your USDL is burned and replaced with a larger amount of PLS. The stability pool participants are rewarded with LOAN tokens for providing this service.  LOAN tokens are staked in the Staking Pool to earn a share of the Liquid Loans protocol fees.",
  STAKING_SUMMARY_TEXT:
    "Stake LOAN tokens in the Staking Pool to earn Liquid Loans protocol fees in PLS and USDL. LOAN tokens can be purchased or earned by staking USDL in the Stability Pool.",
  CLAIM_STABILITY_REWARDS_TEXT: "Claim all earned PLS from trove liquidations and Claim LOAN rewards to your wallet",
  STAKED_TEXT: "The total amount of LOAN that is staked for earning fee revenue.",
  REWARD_TEXT:
    "Although the LOAN rewards accrue every minute, the value on the UI only updates when a user transacts with the Stability Pool. Therefore you may receive more rewards than is displayed when you claim or adjust your deposit.",
  REWARDS_TIP_TEXT:
    "Although the LOAN rewards accrue every minute, the value on the UI only updates when a user transacts with the Stability Pool. Therefore you may receive more rewards than is displayed when you claim or adjust your deposit.",
  KICKBACK_FEE:
    "A rate between 0 and 100% set by the Frontend Operator that determines the fraction of LOAN that will be paid out as a kickback to the Stability Providers using the frontend.",
  OTHER_PAYDOWN_TOOLTIP:
    "Paying down the current debt will claim any accrued USDL staking rewards, may unstake some or all of the USDL staked in the Stability Pool, and if selected, may sell some of the excess collateral PLS in the vault to pay off as much of the current debt as possible.  A minimum balance of $2,000 debt is required to keep the Liquid Loans vault open.  The Amplifier Paydown Debt function will attempt to repay as much of the total debt, minus the $2,000 minimum debt amount, using the assets you selected in the Debt Paydown Selector.  If you wish to fully repay your debt and close your trove, please use the CLOSE TROVE function",
  BORROWING_FEE_TEXT:
    "The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in USDL) and is part of a Trove's debt. The fee varies between 0.5% and 5% depending on USDL redemption volumes.",
  SUPPLY_TEXT: "The total USDL minted by the Liquid Loans protocol.",
  POOL_TEXT: "The total USDL currently held in the Stability Pool, expressed as an amount and a fraction of the USDL supply.",
  CR_TEXT:
    "The ratio between the dollar value of the collateral and the debt (in USDL) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.",
  COLLATERAL_RATIO_TIP_TEXT:
    "The ratio between the dollar value of the collateral and the debt (in USDL) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.",
  TVL_TEXT: "The Total Value Locked (TVL) is the total value of Ether locked as collateral in the system, given in PLS and USD.",
  TCR_TEXT: "The ratio of the Dollar value of the entire system collateral at the current PLS:USD price, to the entire system debt.",
  RECOVERY_MODE_PRICE_THRESHHOLD: "The Dollar value of PLS below which the Total Collateral Ratio will drop below 150% and the system will enter Recovery Mode",
  STABILITY_CLAIM_MOVE_REWARD: "Claim LOAN and move PLS",
  TROVE_NOEXECUTE_COMPOUND: "USDL -> Stability Pool",
  TROVE_LOAN_COMPOUNDING_TOPUP_VAULT: "USDL -> PLS -> Vault",
  STABILITY_LOAN_COMPOUNDING_MAILBOX: "PLS -> USDL -> Stability Pool,  Send 50% to wallet and Stake 50% of LOAN",
  STABILITY_LOAN_COMPOUNDING_TOPUP_STABILITY: "PLS -> Wallet, LOAN -> Wallet",
  STABILITY_LOAN_NOEXECUTE_COMPOUNDING_HALF_HALF: "LOAN Rewards -> Staking Pool, PLS Rewards -> Vault",
  STAKING_LOAN_NOEXECUTE_COMPOUNDING_MAILBOX: "PLS Rewards -> Wallet, USDL Rewards -> Stability Pool",
  STAKING_LOAN_NOEXECUTE_COMPOUNDING_HALF_AND_HALF: "USDL Rewards -> Wallet, PLS Rewards -> Wallet",
  STAKING_LOAN_COMPOUNDING_PASSIVE: "USDL -> PLS, PLS Rewards -> Vault",
  STAKING_LOAN_COMPOUNDING_TOPUP_STAKING: "PLS -> Wallet, USDL -> Wallet",
  PAYDOWN_TOOLTIP: "Swap PLS -> USDL. Final price is subject to change.",
  TOPOFF_TOPUP_VAULT_TEXT: "Mint USDL, swap to PLS and add that PLS to the trove to increase collateral.",
  TOPOFF_TOPUP_STABILITY_TEXT: "Mint USDL and stake in the stability pool.",
  TOPOFF_TOPUP_STAKING_TEXT: "Mint USDL, swap to LOAN and stake in the Staking Pool to earn USDL and PLS.",
  STATS_HEADING: "Liquid Loans Statistics",
  REDEMPTION_TEXT:
    "Redemptions are one of the Liquid Loans most unique and important protocol features. The redemption mechanism gives USDL holders the ability to redeem the underlying PLS collateral at face value at any time. Redemptions pay off the debt of the riskiest Vaults, in return for their collateral. IMPORTANT: Redemptions are not the same as paying back your Vault's debt. To repay your loan, adjust your Vault on the Repay tab of the Borrow USDL page.",
  TROVE_LOGO: PLSLogo,
  STABILITY_LOGO: USDLLogo,
  STAKING_LOGO: LOANLogo,
  DISABLE_REASON_MM_GAINS: "No USDL/PLS Gains for compounding Staking Pool",
  DISABLE_REASONS_HH_GAINS: "No LOAN/PLS Gains for compounding Stability Pool",
  DISABLE_REASON_CR_NOT_ZERO: "Target CR and Trigger CR can't be zero",
  DISABLE_REASON_LESS_CR: "Current CR is less than Trigger CR",
  DISABLE_REASON_NO_LQTY_GAINS: "No LOAN Gains for compounding",
  DISABLE_REASON_NO_LUSD_GAINS: "No USDL Gains for compounding",
  DISABLE_REASONS_NO_REWARDS: "No rewards/gain for compounding",
  DISABLE_REASONS_NO_TROVE: "Trove/Vault does not exist or is closed",
  DISABLE_REASONS_NO_DEPOSIT_STABILITY: "You have no deposits in the stability pool",
  DISABLE_REASONS_NO_DEPOSIT_STAKING: "You have no deposits in the staking pool",
  DISABLE_PAYDOWN_NO_OPTION_SELECTED: "DebtPaydown Option is not selected!",
  DISABLE_AUTHORIZATION_ERROR: "Authorization Error!",
  DISABLE_PAYDOWN_NO_REWARDS_FOR_PAYDOWN: "No Rewards in StakingPool or USDL in StabilityPool to Paydown Debt",
  DISABLE_PAYDOWN_CR_FALLS_120: "Unable to execute as new collateral ration falls below 120",
  DISABLE_PAYDOWN_DESELECTING_STAKING_ETH_REWARDS: "De-select Staking Pool PLS Rewards Option to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STAKING_LUSD_REWARDS: "De-select Staking Pool USDL Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_LQTY_REWARDS: "De-select Stability Pool LOAN Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_ETH_REWARDS: "De-select Stability Pool PLS Rewards to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DESELECTING_STABILITY_WITHDRAW_AMOUNT: "Decrease Stability Pool Withdraw Amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_CR_LESS_THAN_110: "Cannot withdraw PLS from Trove when CR <= 110%",
  DISABLE_PAYDOWN_DECREASING_ETH_FROM_TROVE: "Decrease PLS from Trove amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_DECREASING_LUSD_FROM_WALLET: "Decrease USDL From Wallet amount to maintain trove's minimum debt",
  DISABLE_PAYDOWN_LESS_CR: "Collateral ratio will become less than 110%",
  DEFAULT_STABILITY_COMPOUNDING_TARGET: "PLS -> Wallet, LOAN -> Wallet",
  DEFAULT_STAKING_COMPOUNDING_TARGET: "PLS -> Wallet, USDL -> Wallet",
  DISABLE_NO_ETH: "No PLS Gains to Claim.",
  DISABLE_NO_TROVE_FOR_WITHDRAW: "You must have an active trove to withdraw PLS Gains to trove",
  DISABLE_NOTHING_REDEMEED: "Nothing can be redeemed out of the provided USDL amount.",
  DISABLE_REDEMPTION_LESS_THAN_LUSD: "User's USDL balance must be greater than or equal to Requested redemption amount",
  DISABLE_CLAIM_REASON: "You don't have an active vault to withdraw PLSGain to",
};

export const envAllDescriptionDetails = Number(envChainId) === ChainId?.eth ? ethContractsDescriptions : pulseContractDescriptions;

export const commonContractsDescription = {
  MAXCAP_TEXT: "Avoid spending more gas than a safe maxed cap value",
  AUTOMATION_INFO_TEXT:
    "Turning on Automation will allow the Amplifier to automatically compound your Trove/Vault, Stability Pool and Staking Pool every day per the currently selected strategy. Strategies can be changed without having to turn off automation first. Turning off Automation will stop daily compounding and leave all trove/vault and pool balances in place, ready for you to return to manual operation",
  DISCONNECT_INFO_TEXT: "View your wallet address and the  unique Amplifier user contract address assigned to your wallet.",
  CLAIM_STAKING_REWARDS_TEXT: "Claim all earned share of redemption fees and debt fees to wallet.",
  STRATEGY_FORM_HEADING:
    "Choose a compounding strategy to determine how new loans and staking rewards are handled when claimed. This strategy will determine how the rest of Amplifier operates for your Trove/Vault. You can change this strategy at any time.",
  TROVES_TEXT: "The total number of active Troves in the system.",
  RECOVERY_MODE_TEXT:
    "Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Trove can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Trove's debt. Operations are also restricted that would negatively impact the TCR.",
  LIQUIDATION_PRICE_NORAMAL_TEXT:
    "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level..",
  LIQUIDATION_PRICE_RECOVERY_TEXT:
    "The dollar value per unit of collateral at which your Trove will drop below a 150% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level..",
  STRATEGY_TIP_TEXT: "Select the lending strategy you would like the Amplifier to implement for you.",
  AUTOMATION_TIP_TEXT:
    "Amplifier Autopilot will compound your loans, stability pool and staking pool rewards according to your selected strategy automatically every day so you don't have to.",
  URL_STRATEGY_TIP_TEXT_RM: "https://docs.powercity.io/amplifier/strategies",
  URL_POWERCITY_FEE_TEXT_RM: "https://powercitymeta.gitbook.io/powercity/amplifier-loan-booster/fees",
  SELECTORS: "SELECTORS",
  COMPOUND_STRATEGY: "COMPOUND_STRATEGY",
  LOAN_COMPOUNDING_TARGET: "LOAN_COMPOUNDING_TARGET",
  SP_COMPOUNDING_TARGET: "SP_COMPOUNDING_TARGET",
  STAKING_COMPOUNDING_TARGET: "STAKING_COMPOUNDING_TARGET",
  LOAN_PAY_DOWN_SELECTOR: "LOAN_PAY_DOWN_SELECTOR",
  TOP_OFF_SELECTOR: "TOP_OFF_SELECTOR",
  COMMON: "common",
  ICR_MCR: "This operation would result in CR < 110% which is not permitted",
  DEFAULT_LOAN_COMPOUNDING_TARGET: "Compounding Both Stability and Staking Pool",
  DISABLE_REDEEM_TCR_MCR: "Cannot redeem when Entire System's CR < Minimum CR(110%)",
  DISABLE_REDEEM_FEE: "Max fee percentage must be between 0.5% and 100%",
  DISABLE_UNABLE_REDEEM: "Unable to redeem any amount",
  DISABLE_EXCEED_MAX: "Fee exceeded provided maximum",
  SLIPPAGE_TEXT: "The slippage should be between 0.1% to 5%",
};

export const DISABLE_REASONS: any = {
  "ps:!cr": envAllDescriptionDetails.DISABLE_REASON_CR_NOT_ZERO,
  "ps:!tri": envAllDescriptionDetails.DISABLE_REASON_LESS_CR,
  "ps:!t/v": envAllDescriptionDetails.DISABLE_REASONS_NO_TROVE,
  "mm:!lqtyGain": envAllDescriptionDetails.DISABLE_REASON_NO_LQTY_GAINS,
  "mm:!gains": envAllDescriptionDetails.DISABLE_REASON_MM_GAINS,
  "hh:!cr": envAllDescriptionDetails.DISABLE_REASON_CR_NOT_ZERO,
  "hh:!t/v": envAllDescriptionDetails.DISABLE_REASONS_NO_TROVE,
  "hh:!gains": envAllDescriptionDetails.DISABLE_REASONS_HH_GAINS,
  "hh:!tri": envAllDescriptionDetails.DISABLE_REASON_LESS_CR,
  "pp:!lusdGain": envAllDescriptionDetails.DISABLE_REASON_NO_LUSD_GAINS,
  "cu:!cr": envAllDescriptionDetails.DISABLE_REASON_CR_NOT_ZERO,
  "cu:!tri": envAllDescriptionDetails.DISABLE_REASON_LESS_CR,
  "cu:!t/v": envAllDescriptionDetails.DISABLE_REASONS_NO_TROVE,
  "cu:!gain": envAllDescriptionDetails.DISABLE_REASONS_NO_REWARDS,
  "cu:!gain2": envAllDescriptionDetails.DISABLE_REASONS_NO_REWARDS,
  "ca:!gains": envAllDescriptionDetails.DISABLE_REASONS_NO_REWARDS,
  "StabilityPool: User must have a non-zero deposit": envAllDescriptionDetails.DISABLE_REASONS_NO_DEPOSIT_STABILITY,
  "LQTYStaking: User must have a non-zero stake": envAllDescriptionDetails.DISABLE_REASONS_NO_DEPOSIT_STAKING,
  "BorrowerOps: An operation that would result in ICR < MCR is not permitted": commonContractsDescription.ICR_MCR,
  "StabilityPool: caller must have an active trove to withdraw ETHGain to": envAllDescriptionDetails.DISABLE_NO_TROVE_FOR_WITHDRAW,
  "StabilityPool: caller must have an active vault to withdraw PLSGain to": envAllDescriptionDetails.DISABLE_CLAIM_REASON,
  "StabilityPool: caller must have non-zero ETH Gain": envAllDescriptionDetails.DISABLE_NO_ETH,
  "StabilityPool: caller must have non-zero PLS Gain": envAllDescriptionDetails.DISABLE_NO_ETH,
  "TroveManager: Cannot redeem when TCR < MCR": commonContractsDescription.DISABLE_REDEEM_TCR_MCR,
  "TroveManager: Amount must be greater than zero": envAllDescriptionDetails.DISABLE_NOTHING_REDEMEED,
  "TroveManager: Requested redemption amount must be <= user's LUSD token balance": envAllDescriptionDetails.DISABLE_REDEMPTION_LESS_THAN_LUSD,
  "Max fee percentage must be between 0.5% and 100%": commonContractsDescription.DISABLE_REDEEM_FEE,
  "TroveManager: Unable to redeem any amount": commonContractsDescription.DISABLE_UNABLE_REDEEM,
  "Fee exceeded provided maximum": commonContractsDescription.DISABLE_EXCEED_MAX,
};

export const DISABLE_PAYDOWN_REASON: any = {
  "lp:!option": envAllDescriptionDetails.DISABLE_PAYDOWN_NO_OPTION_SELECTED,
  "pc:!auth": envAllDescriptionDetails.DISABLE_AUTHORIZATION_ERROR,
  "BorrowerOps: There must be either a collateral change or a debt change": envAllDescriptionDetails.DISABLE_PAYDOWN_NO_REWARDS_FOR_PAYDOWN,
  "lp:newCr<120": envAllDescriptionDetails.DISABLE_PAYDOWN_CR_FALLS_120,
  "!T/V": envAllDescriptionDetails.DISABLE_REASONS_NO_TROVE,
  "!t/v": envAllDescriptionDetails.DISABLE_REASONS_NO_TROVE,
  "!minDebtEthGain_stk": envAllDescriptionDetails.DISABLE_PAYDOWN_DESELECTING_STAKING_ETH_REWARDS,
  "!minDebtLusdGain_stk": envAllDescriptionDetails.DISABLE_PAYDOWN_DESELECTING_STAKING_LUSD_REWARDS,
  "!minDebtLqtyGain_stb": envAllDescriptionDetails.DISABLE_PAYDOWN_DESELECTING_STABILITY_LQTY_REWARDS,
  "!minDebtEthGain_stb": envAllDescriptionDetails.DISABLE_PAYDOWN_DESELECTING_STABILITY_ETH_REWARDS,
  "!minDebtSP": envAllDescriptionDetails.DISABLE_PAYDOWN_DESELECTING_STABILITY_WITHDRAW_AMOUNT,
  "cr < 110": envAllDescriptionDetails.DISABLE_PAYDOWN_CR_LESS_THAN_110,
  "!minDebtTrove": envAllDescriptionDetails.DISABLE_PAYDOWN_DECREASING_ETH_FROM_TROVE,
  "!minDebtWallet": envAllDescriptionDetails.DISABLE_PAYDOWN_DECREASING_LUSD_FROM_WALLET,
  "!ethToLusd": envAllDescriptionDetails.DISABLE_PAYDOWN_LESS_CR,
};
