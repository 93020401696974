import { ColFlex, ContainerBox, RowFlex } from "shared/styled";

import { useWeb3React } from "@web3-react/core";
import { stakingPath } from "logic/paths";
import { stakeInitialValues } from "logic/redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/button";
import { CompoundMemo } from "shared/compound/Compound";
import { URL_READ_MORE_STAKING_SUMMARY, envAllDescriptionDetails, formatNumbersWithComma } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { TextBar } from "shared/textbar/TextBar";
import { Heading3 } from "shared/Typography";
import { CompoundStakingNotExecuteMemo } from "shared/compound/CompoundStakingNotExecute";
import { StakingLoanCompoundingPath } from "modules/pages/staking/components/loanCompoundingPath/LoanCompoundingPath";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

interface IProps {
  handleCompoundButton: () => any;
  handleCompoundStaking: () => any;
}

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const StakingBox = (props: IProps) => {
  const { handleCompoundButton, handleCompoundStaking } = props;

  const { selectedCompoundStrategyID, disablingReason } = useSelector((s: any) => s.strategyRedu);

  const { poolShare, stakedLQTY, lusdGainInDollar, liquidationEthGainInDollar, lusdGain, liquidationEthGain, stakedLQTYInDollar } = useSelector((state: any) => state.stake);

  const { ethToUSD } = useWallet();
  const navigate = useNavigate();
  const { account, library } = useConnectWallet();
  const { userContractAddress } = useSelector((s: any) => s.navbar);

  const dispatch = useDispatch();

  const handleStake = () => {
    navigate(stakingPath);
  };

  useEffect(() => {
    dispatch(stakeInitialValues(userContractAddress, ethToUSD, account));
  }, [library, userContractAddress]);

  const tempDisabled = selectedCompoundStrategyID !== "2" && selectedCompoundStrategyID !== "4" && selectedCompoundStrategyID !== "5";

  const tempDisabledReason = tempDisabled ? "The strategy selected does not compound Staking pool." : disablingReason;

  const isNotExecuteStrategy = selectedCompoundStrategyID === "2" || selectedCompoundStrategyID === "5";

  return (
    <ContainerBox minWidth="555px">
      <ColFlex customGap={"12px"}>
        <RowFlex align="center">
          <TextBar
            leftText="Staking"
            heading="yes"
            custId="stakingHeadingTooltip"
            toolTip={envAllDescriptionDetails.STAKING_DASHBOARD_TIP_TEXT}
            link={URL_READ_MORE_STAKING_SUMMARY}
            linkText="Staking Pool"
          />
          <Button btnType={"borderedfilledButton"} customWidth="9em" customHeight="35px" onClick={handleStake}>
            Details
          </Button>
        </RowFlex>
        <RowFlex>
          <div className="amountContainer">
            <img src={envAllDescriptionDetails.STAKING_LOGO} alt="logo-crypto" height={Number(envChainId) === ChainId?.eth ? 30 : "auto"} />
            <Heading3 fSizeM="16px">
              {Number(stakedLQTY) ? formatNumbersWithComma(stakedLQTY, 3) : "0.00"} {envAllDescriptionDetails.STAKING_TOKEN_TEXT}{" "}
            </Heading3>
            <Heading3 fSizeM="16px">
              ($
              {Number(stakedLQTYInDollar) ? formatNumbersWithComma(stakedLQTYInDollar, 2) : "0.00"})
            </Heading3>
          </div>
        </RowFlex>
        <ColFlex customGap=".5em">
          <TextBar leftText="Pool Share" rightText={`${poolShare !== "N/A" && Number(poolShare) ? poolShare + "%" : "0%"}`} />
          <TextBar
            leftText="Share of redemption fees"
            rightText={`${Number(liquidationEthGain)
              ? formatNumbersWithComma(liquidationEthGain, 6) +
              " " +
              envAllDescriptionDetails.VAULT_TOKEN_TEXT +
              " " +
              `($${formatNumbersWithComma(liquidationEthGainInDollar, 2)})`
              : ` 0 ${envAllDescriptionDetails.VAULT_TOKEN_TEXT}`
              }`}
          />
          <TextBar
            leftText="Share of debt fees"
            rightText={`${Number(lusdGain)
              ? formatNumbersWithComma(lusdGain, 6) + " " + envAllDescriptionDetails.STABILITY_TOKEN_TEXT + " " + `($${formatNumbersWithComma(lusdGainInDollar, 2)})`
              : `0 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT}`
              }`}
          />
          <StakingLoanCompoundingPath />
        </ColFlex>
      </ColFlex>
      <RowFlex>
        {isNotExecuteStrategy ? (
          <CompoundStakingNotExecuteMemo tempDisabled={tempDisabled} buttonText="Compound Staking" handleCompoundStaking={handleCompoundStaking} />
        ) : (
          <CompoundMemo tempDisabledReason={tempDisabledReason} tempDisabled={tempDisabled} buttonText="Compound Staking" handleCompoundButton={handleCompoundButton} />
        )}
      </RowFlex>
    </ContainerBox>
  );
};
