import { useWeb3React } from "@web3-react/core";
import { CUSTOM_STATIC_INSTANCE, HALF_AND_HALF_STATIC_INSTANCE, PASSIVE_PULSE_STATIC_INSTANCE, PULSE_STACKER_STATIC_INSTANCE } from "blockchain/contract/instance";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { parseUnits } from "ethers/lib/utils";
import { initialValues, setTxHash, stabilityInitialValues, stakeInitialValues } from "logic/redux/actions";
import { stabilityNotExecuteEnabled, stakingNotExecuteEnabled, stratergyEnabled } from "logic/redux/actions/strategy";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/button";
import { useTransaction } from "shared/hook/useTransaction";
import { useWallet } from "shared/hook/useWallet";
import { RowContainer, TooltipTop } from "shared/styled";

export const CompoundAll = () => {
  const { userContractAddress } = useSelector((s: any) => s.navbar);
  const { startSpinner, stopSpinner, handleError }: any = useTransaction();
  const { account, library }: any = useConnectWallet();
  const maxFee = parseUnits("0.05", "ether").toString();

  const dispatch = useDispatch();
  const { ethToUSD } = useWallet();

  const { selectedCompoundStrategyID, disablingReason, IsCompound, strategyInstance } = useSelector((s: any) => s.strategyRedu);

  const handleCompoundAll = async () => {
    try {
      let res;
      startSpinner();
      const randNum = (Math.random() * 100).toFixed(0);
      if (selectedCompoundStrategyID !== "2") {

        switch (selectedCompoundStrategyID) {
          case "1":
            //pulse stacker
            {
              const hintAddresses = await PULSE_STACKER_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(
                account,
                maxFee,
                ...hintAddresses.slice(0, 5)
              );
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 5), {
                gasLimit: bufferedGas.toFixed(0),
              });
            }
            break;

          case "3":
            // half n half
            {
              const hintAddresses = await HALF_AND_HALF_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, maxFee, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(
                account,
                maxFee,

                ...hintAddresses.slice(0, 4)
              );
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 4), {
                gasLimit: bufferedGas.toFixed(0),
              });
            }
            break;

          case "4":
            // passive pulse
            {
              const hintAddresses = await PASSIVE_PULSE_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(account, randNum);

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3));
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(account, maxFee, ...hintAddresses.slice(0, 3), {
                gasLimit: bufferedGas.toFixed(0),
              });
            }
            break;

          case "5":
            // custom strategy
            {
              const hintAddresses = await CUSTOM_STATIC_INSTANCE?.callStatic.executeStrategyStaticCall(
                account,
                maxFee,
                randNum
              );

              const estimateGas = await strategyInstance.estimateGas.executeStrategy(
                account,
                maxFee,
                ...hintAddresses.slice(0, 17)
              );
              const bufferedGas = Number(estimateGas.toString()) + Number(estimateGas.toString()) * 0.5;
              res = await strategyInstance?.executeStrategy(
                account,
                maxFee,
                ...hintAddresses.slice(0, 17),
                { gasLimit: bufferedGas.toFixed(0) }
              );

            }
            break;

          default:
            break;
        }

      } else {
        //  mailbox
        const estimateGas1 = await strategyInstance.estimateGas.executeStrategy(account);
        const bufferedGas1 = Number(estimateGas1.toString()) + Number(estimateGas1.toString()) * 0.5;
        res = await strategyInstance?.executeStrategy(account, {
          gasLimit: bufferedGas1.toFixed(0),
        });
      }
      dispatch(setTxHash(res.hash));
      await res.wait();
      dispatch(initialValues(library, account, ethToUSD));
      dispatch(stakeInitialValues(userContractAddress, ethToUSD, account));
      dispatch(stabilityInitialValues(ethToUSD, account));
      dispatch(stratergyEnabled({ account }));
      dispatch(stabilityNotExecuteEnabled({ account, userContractAddress }));
      dispatch(stakingNotExecuteEnabled({ account, userContractAddress }));
      stopSpinner();
    } catch (err) {
      handleError(err);
    }
  };

  const disabled = !IsCompound || selectedCompoundStrategyID === "6";
  const disabledReason = selectedCompoundStrategyID === "6" ? "No strategy has been selected for compounding" : disablingReason;

  return (
    <>
      <RowContainer>
        {disabled ? (
          <TooltipTop fullWidth customWidth="15em" data-tooltip={disabledReason}>
            <Button btnType={"borderedfilledLightButton"} onClick={handleCompoundAll} wrapperWidth="100%" isDisabled={disabled}>
              Compound All{" "}
            </Button>
          </TooltipTop>
        ) : (
          <Button btnType={"borderedfilledLightButton"} onClick={handleCompoundAll} wrapperWidth="100%">
            Compound All{" "}
          </Button>
        )}
      </RowContainer>
    </>
  );
};
