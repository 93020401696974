import {
  SET_CONNECT_WALLET,
  SET_DISCONNECT_WALLET,
  SET_IS_AUTOMATION,
  SET_SHOW_STATS,
  SET_USERADDRESS,
  SET_IS_CONNECTOR_ID,
  SET_IS_SYNCED_WITH_API,
  HIDE_MODULE,
  SET_IS_LOGOFF,
  SIGN_VERIFICATION,
  ISAUTOMATION_MODAL_OPEN,
  ISAUTOMATION_LOG_MODAL_OPEN,
  SET_AUTOMATION_LOGS,
  SET_MAX_CAP,
  SET_AUTOMATION_RUN,
  SET_AUTOMATION_BALANCE,
  SET_AVERAGE_FEE,
  SET_ISFETCHED,
} from "./constant";

interface IProps {
  type: string;
  payload: any;
}

export const setConnectWallet = (status: boolean): IProps => {
  return {
    type: SET_CONNECT_WALLET,
    payload: status,
  };
};

export const setDisConnectWallet = (status: boolean): IProps => {
  return {
    type: SET_DISCONNECT_WALLET,
    payload: status,
  };
};

export const setUserContract = (address: string): IProps => {
  return {
    type: SET_USERADDRESS,
    payload: address,
  };
};

export const setShowStats = (status: boolean): IProps => {
  return {
    type: SET_SHOW_STATS,
    payload: status,
  };
};

export const setIsLogOff = (status: boolean): IProps => {
  return {
    type: SET_IS_LOGOFF,
    payload: status,
  };
};

export const setIsAutomation = (status: boolean): IProps => {
  return {
    type: SET_IS_AUTOMATION,
    payload: status,
  };
};

export const setConnectorID = (status: number): IProps => {
  return {
    type: SET_IS_CONNECTOR_ID,
    payload: status,
  };
};

export const setIsSyncedWithAPI = (status: boolean): IProps => {
  return {
    type: SET_IS_SYNCED_WITH_API,
    payload: status,
  };
};
export const setIsHideModule = (flag: boolean): IProps => {
  return {
    type: HIDE_MODULE,
    payload: flag,
  };
};
export const setAuthStatus = (data: any): IProps => {
  return {
    type: SIGN_VERIFICATION,
    payload: data,
  };
};

export const setAutomationModal = (flag: any) => {
  return {
    type: ISAUTOMATION_MODAL_OPEN,
    payload: flag,
  };
};

export const setAutomationLogModal = (flag: any) => {
  return {
    type: ISAUTOMATION_LOG_MODAL_OPEN,
    payload: flag,
  };
};

export const setAutomationLogs = (data: any) => {
  return {
    type: SET_AUTOMATION_LOGS,
    payload: data,
  };
};

export const setMaxGap = (data: any) => {
  return {
    type: SET_MAX_CAP,
    payload: data,
  };
};

export const setAutomationRun = (data: any) => {
  return {
    type: SET_AUTOMATION_RUN,
    payload: data,
  };
};
export const setAutomationBalance = (data: any) => {
  return {
    type: SET_AUTOMATION_BALANCE,
    payload: data,
  };
};
export const setAverageFee = (data: any) => {
  return {
    type: SET_AVERAGE_FEE,
    payload: data,
  };
};
export const setIsFetched = (data: any) => {
  return {
    type: SET_ISFETCHED,
    payload: data,
  };
};
