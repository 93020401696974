
export type UrlPath<T> = string & { _?: T } // Basically a string.  The second clause is to peg the generic type

export const GET_STRATEGIES_URL: UrlPath<string> = '/strategies'
export const GET_LOAN_CP_TARGET_URL: UrlPath<string> = '/compounding-targets/loan'
export const GET_LOAN_PAYDOWN_TARGET_URL: UrlPath<string> = '/compounding-targets/loan-pay-down'
export const GET_SP_COMPOUNDING_TARGET_URL: UrlPath<string> = '/compounding-targets/stability'
export const GET_STAKING_COMPOUNDING_TARGET_URL: UrlPath<string> = '/compounding-targets/staking'

export const POST_WALLET_URL: UrlPath<string> = '/wallet'
export const GET_SELECTORS_URL: UrlPath<string> = '/compounding-targets/all-compounding-targets'
export const POST_AUTH_TOKEN_URL: UrlPath<string> = '/auth'
export const LOGOUT_URL: UrlPath<string> = '/auth/logout'