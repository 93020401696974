import { useState } from "react";
import { InputContainer, InputLabel, RadioInput } from "./style";
import { SvgIcon } from "shared/styled";
import closeDropDownIcon from "assets/icons/close-drop-down-icon.svg";
import openDropDownIcon from "assets/icons/open-drop-down-icon.svg";
import { Para } from "shared/Typography";

interface IRadioBtnProps {
  radioId: string;
  name: string;
  value: any;
  onChange: any;
  infoMsg?: string;
  checked?: boolean;
  width?: string;
}

export const RadioBtn = (props: IRadioBtnProps) => {
  const { radioId, name, value, onChange, infoMsg, checked, width } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onDropDownClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClick = () => {
    if (checked) onChange();
  };
  
  return (
    <>
      <InputContainer>
        <RadioInput
          onClick={handleClick}
          checked={checked}
          onChange={onChange}
          type="radio"
          id={radioId}
          name={name}
          value={value}
        />
        <InputLabel width={width? width : "220px"} htmlFor={radioId}>{value}</InputLabel>
        {infoMsg && (
          <SvgIcon
            height={"5px"}
            width={"10px"}
            onClick={onDropDownClick}
            src={isOpen ? closeDropDownIcon : openDropDownIcon}
            alt="open-in-new-tab-logo"
          />
        )}
      </InputContainer>
      {isOpen ? (
        <Para
          fSizeM="12px"
          fLineHeightMb="14px"
          tAlign="start"
          maxWidth="300px"
        >
          {infoMsg}
        </Para>
      ) : (
        ""
      )}
    </>
  );
};
