import styled from "styled-components";
import { useLocation } from "react-router-dom";

import React, { ReactComponentElement } from "react";
import { Navbar } from "modules/app/navbar/Navbar";
import { Footer } from "modules/app/footer/Footer";
import { ExtendedNavbar } from "modules/app/navbar/ExtendedNavbar";
import { Body, SubBody } from "./style";

export interface HeaderLinks {
  path?: string;
  defaultUrl?: string;
  title: string;
  image: ReactComponentElement<any>;
}

const FixedHeader = styled.div<any>`
  z-index: 890;
  width: 99%;
  top: 0;
  background: none;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  max-height: 100px;
`;

const Header = () => {
  return (
    <FixedHeader>
      <Navbar />
    </FixedHeader>
  );
};

const HeadExt = () => {
  const { pathname = "" } = useLocation();
  if (pathname === "/" || pathname === "/terms" || pathname === "/privacy" || pathname === "/termservice") return <></>;
  return <ExtendedNavbar />;
};

const HeadExtMemo: any = React.memo(() => <HeadExt />);
const HeaderMemo: any = React.memo(() => <Header />);

export const withHeader = (InnerComponent: any) => {
  return (props: any) => (
    <React.Fragment>
      {window.location.pathname === "/loaderio-9a118c25e0578cf572663370eaa32f89/" ? (
        <SubBody>
          <div style={{ color: "black" }}>loaderio-9a118c25e0578cf572663370eaa32f89</div>
        </SubBody>
      ) : (
        <>
          <Body footerHeight={"200"}>
            <HeaderMemo />
            <HeadExtMemo />
            <InnerComponent {...props} />
          </Body>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};
