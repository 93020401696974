import { AddMoreCntr, BtnRowContainer, CloseIconWrapper, InputContainer, ModalListContainer } from "./style";
import plusIcon from "assets/icons/plus-icon.svg";
import { SvgIcon } from "shared/styled";
import { Button } from "shared/button";
import { CustomInput } from "shared/customInput";
import { useState, useEffect } from "react";
import { commonContractsDescription } from "shared/helpers/utils";
import { Notification } from "shared/notification";
import closeIcon from "assets/icons/closeIcon.svg";

interface ILoanCompoundingTargetProps {
  setStrategy?: any;
  customStrategies?: any;
  openTopOffSelector?: any;
  removeTopOffSelector?: any;
  reRender?: boolean;
  setStrategyInput?: any;
  handleCancelCS?: any;
}

export const CustomStrategy = (props: ILoanCompoundingTargetProps) => {
  const { customStrategies = {}, handleCancelCS, openTopOffSelector, removeTopOffSelector, setStrategyInput } = props;
  const [addedCount, setAddedCount] = useState<number>(0);
  const [err, setErr] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    const added = Object.keys(customStrategies).length;
    setAddedCount(added || 0);
  }, []);

  useEffect(() => {
    inputValidation();
  }, [customStrategies]);

  const openSelector = (key?: string) => {
    if (addedCount >= 3) return;
    if (key) {
      openTopOffSelector(commonContractsDescription.TOP_OFF_SELECTOR, key);
      return;
    }
    openTopOffSelector(commonContractsDescription.TOP_OFF_SELECTOR);
  };

  const removeSelector = (key?: string) => {
    if (key) {
      removeTopOffSelector(key);
      setAddedCount(addedCount - 1);
    }
  };

  const openCPTargetSelector = (key: string) => {
    if (key === "Top Up Stability Pool") {
      openTopOffSelector(commonContractsDescription.SP_COMPOUNDING_TARGET);
    } else if (key === "Top Up Staking Pool") {
      openTopOffSelector(commonContractsDescription.STAKING_COMPOUNDING_TARGET);
    }
  };

  const handleConfirmStrategy = () => {
    let totalInput = 0;
    Object.keys(customStrategies).forEach((key, index) => {
      const values = customStrategies[key] || [];
      if (values[0] === "") {
        delete customStrategies[key];
      } else {
        totalInput = Number(totalInput) + Number(values[0]);
      }
    });
    if (totalInput !== 100) {
      return;
    }
    handleCancelCS(true);
  };

  const inputValidation = () => {
    const inputValue = Object.values(customStrategies)?.map((value: any) => {
      return Number(value[0]);
    });
    const totalValue = inputValue.reduce((a, b) => a + b, 0);
    if (totalValue < 100 || totalValue > 100) {
      setErr("Shares must be 100%");
    } else if (disable) {
      setErr("Allocation can't be zero");
    } else {
      setErr("");
    }
  };

  return (
    <>
      <ModalListContainer>
        <AddMoreCntr>
          <SvgIcon src={plusIcon} alt="plus-icon" onClick={() => openSelector()} />
        </AddMoreCntr>
        {Object.keys(customStrategies).length <= 0 ? (
          <>
            <BtnRowContainer justify="start">
              <InputContainer width="100%">
                <Button
                  onClick={() => openSelector()}
                  customWidth="99%"
                  bRadius="10px"
                  wrapperWidth="100%"
                  btnType={"dimBorderedfilledButton"}
                  fSize="12px"
                  customHeight="45px"
                  fSizeMobile="10px"
                >
                  Loan Target
                </Button>
              </InputContainer>
              <InputContainer width="100%">
                <CustomInput
                  widthInputCntr="100%"
                  justify="center"
                  tAlign="center"
                  type="number"
                  maxLimit={100}
                  lblWidth="100%"
                  fSize="16px"
                  placeholder="Allocation %"
                  padding="0"
                  inputHeight="45px"
                  inputWidth="100%"
                  hideIcon
                  disable
                />
              </InputContainer>
            </BtnRowContainer>
            <BtnRowContainer justify="center">
              <Button wrapperWidth="100%" onClick={handleConfirmStrategy} btnType={"gradientFilledButton"} className="btnWrapper" isDisabled={disable || err !== ""}>
                Confirm
              </Button>
              <Button wrapperWidth="100%" onClick={() => handleCancelCS(false)} btnType={"borderedfilledLightButton"} className="btnWrapper">
                Cancel
              </Button>
            </BtnRowContainer>
          </>
        ) : (
          <>
            {Object.keys(customStrategies)?.map((key, index) => {
              return (
                <OptionsCard
                  customStrategies={customStrategies}
                  addedCount={addedCount}
                  openSelector={openSelector}
                  removeSelector={removeSelector}
                  setStrategyInput={setStrategyInput}
                  openCPTargetSelector={openCPTargetSelector}
                  strategy={key}
                  key={index}
                  setDisable={setDisable}
                />
              );
            })}
            {err && <Notification message={err} type="err" />}
            <BtnRowContainer justify="center">
              <Button wrapperWidth="100%" onClick={handleConfirmStrategy} btnType={"gradientFilledButton"} className="btnWrapper" isDisabled={disable || err !== ""}>
                Confirm
              </Button>
              <Button wrapperWidth="100%" onClick={() => handleCancelCS(false)} btnType={"borderedfilledLightButton"} className="btnWrapper">
                Cancel
              </Button>
            </BtnRowContainer>
          </>
        )}
      </ModalListContainer>
    </>
  );
};
interface IOptionCardProps {
  customStrategies?: any;
  addedCount?: any;
  openSelector?: any;
  removeSelector?: any;
  setStrategyInput?: any;
  openCPTargetSelector?: any;
  strategy: any;
  setDisable: any;
}
export const OptionsCard = (props: IOptionCardProps) => {
  const { customStrategies, addedCount, openSelector, removeSelector, setStrategyInput, openCPTargetSelector, strategy, setDisable } = props;

  useEffect(() => {
    if (customStrategies[strategy][0] === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [customStrategies]);

  if (!customStrategies) return <></>;

  return (
    <BtnRowContainer justify={strategy === "Top Up Vault" ? "start" : "space-between"}>
      <InputContainer minwidth="140px">
        <CloseIconWrapper onClick={() => removeSelector(strategy)}>
          <img src={closeIcon} alt="close-button" />
        </CloseIconWrapper>
        <Button
          isDisabled={addedCount > 2}
          bRadius="10px"
          customWidth="99%"
          wrapperWidth="100%"
          btnType={"dimBorderedfilledButton"}
          fSize="12px"
          customHeight="45px"
          onClick={() => openSelector(strategy)}
          fSizeMobile="10px"
          minWidthSMb="100px"
        >
          {strategy || "Loan Target"}
        </Button>
      </InputContainer>
      <InputContainer flexVal="1 1 0px" minwidth={strategy !== "Top Up Vault" ? "25em" : ""}>
        <CustomInput
          justify="center"
          tAlign="center"
          type="number"
          maxLimit={100}
          onChange={(e: any) => setStrategyInput(e, strategy)}
          value={customStrategies[strategy][0]}
          lblWidth="50%"
          fSize="16px"
          placeholder="Allocation %"
          padding="0"
          inputHeight="45px"
          inputWidth="100%"
          widthInputCntr="100%"
          maxWidth="150px"
          fSizeMobile="10px"
          cntrMaxWidth="50px"
          maxWidthMb="100%"
          hideIcon
        />
        {strategy !== "Top Up Vault" ? (
          <Button
            bRadius="10px"
            btnType={"dimBorderedfilledButton"}
            fSize="12px"
            customHeight="45px"
            onClick={() => openCPTargetSelector(strategy)}
            fSizeMobile="10px"
            minWidthMb="125px"
            minWidthSMb="100px"
            minWidthSpan="80px"
          >
            {customStrategies[strategy]?.[1] || "Compounding Target"}
          </Button>
        ) : (
          ""
        )}
      </InputContainer>
    </BtnRowContainer>
  );
};
