import {
  BreakPoint,
  InputBreakPoints,
  RangeInputContainer,
  RangeToolTip,
} from "modules/pages/trove/style";
import { useState } from "react";

interface IProps {
  rangeValue: any;
  rangeHandler: (e: any) => any;
}

export const RangePointerInput = (props: IProps) => {
  const { rangeValue, rangeHandler } = props;

  const [hoverActive, setHoverActive] = useState<boolean>(false);

  const hoverFunction = (toggle: boolean) => {
    setHoverActive(toggle);
  };

  return (
    <RangeInputContainer
      isPointer
      id="inputRange"
      rangeValue={rangeValue}
      onMouseEnter={() => hoverFunction(true)}
      onMouseLeave={() => hoverFunction(false)}
    >
       <InputBreakPoints
        onClick={() => rangeHandler("10")}
        left={"10%"}
        hide={rangeValue >= 8 && rangeValue <= 12}
      />
      <InputBreakPoints
        onClick={() => rangeHandler("25")}
        left={"25%"}
        hide={rangeValue >= 22 && rangeValue <= 26}
      />
      <InputBreakPoints
        onClick={() => rangeHandler("50")}
        left={"50%"}
        hide={rangeValue >= 48 && rangeValue <= 52}
      />
      <InputBreakPoints
        onClick={() => rangeHandler("75")}
        left={"75%"}
        hide={rangeValue >= 73 && rangeValue <= 77}
      />
      <InputBreakPoints
        onClick={() => rangeHandler("100")}
        right={"2px"}
        hide={rangeValue >= 98 && rangeValue <= 100}
      />
      <BreakPoint left={"8%"}>10%</BreakPoint>
      <BreakPoint left={"23%"}>25%</BreakPoint>
      <BreakPoint left={"48%"}>50%</BreakPoint>
      <BreakPoint left={"73%"}>75%</BreakPoint>
      <BreakPoint right={"-2px"}>100%</BreakPoint>
      <input
        type="range"
        max={100}
        min={"0"}
        value={rangeValue}
        onChange={(e: any) => {
            rangeHandler(e.target.value)
        }}
        step="1"
      />
      <RangeToolTip left={`${rangeValue}`} hoverActive={hoverActive}>
        {rangeValue + "%"}
      </RangeToolTip>
    </RangeInputContainer>
  );
};
