import { withTheme } from "styled-components";

import { PageTitle } from "modules/app/pageTitle/PageTitle";
import {
  envAllDescriptionDetails, URL_READ_MORE_STABILITY_SUMMARY
} from "shared/helpers/utils";
import { Container } from "shared/styled";
import { FlexContainer, PoolContainer } from "../style";
import { PrimaryBox } from "./PrimaryBox";
import { SecondaryBox } from "./SecondaryBox";

export const Deposit = withTheme((props: any) => {
  const { theme } = props;

  return (
    <>
      <Container>
        <FlexContainer>
            <PageTitle
              readMoreURL={URL_READ_MORE_STABILITY_SUMMARY}
              bodyText={envAllDescriptionDetails.STABILITY_SUMMARY_TEXT}
              readMoreText="Learn More"
              justify="flex-start"
            />
          <PoolContainer>
            <PrimaryBox
            />
            <SecondaryBox
              theme={theme}
            />
          </PoolContainer>
        </FlexContainer>
      </Container>
    </>
  );
});
