import { useWeb3React } from "@web3-react/core";
import { formatEther } from "ethers/lib/utils";
import { initialValues, SET_BOTH_VALUES, SET_LUSD_VALUES } from "logic/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/customInput";
import { envAllDescriptionDetails, formatNumbersWithComma, tokenToUSD, validateNumberInput } from "shared/helpers/utils";
import useDebounce from "shared/hook/useDebounce";
import { useWallet } from "shared/hook/useWallet";
import { Notification } from "shared/notification";
import { RangePointerInput } from "shared/RangePointerInput/RangePointerInput";
import { LightText } from "shared/Typography";
import { InputBox, InputLabel } from "../style";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";

export const Payback = () => {
  const { LUSD, ETH } = useSelector((state: any) => state.trove);
  const { debt, debtDisplay, totalDebt, lusdInWalletDisplay } = LUSD;
  const { priceEth } = ETH;
  const { refetchBalance } = useSelector((state: any) => state.navbar);
  const { ethToUSD } = useWallet();
  const dispatch = useDispatch();
  const { library, account }: any = useConnectWallet();

  const [debtInputValue, setDebtInputValue] = useState<string>("0");
  const [debtInputUSDValue, setDebtInputUSDValue] = useState<number>(0);
  const [calDebtInputValue, setCalDebtInputValue] = useState<number>(0);
  const [debtRangevalue, setDebtRangeValue] = useState<number>(0);
  const [lusdInDollar, setLusdInDollar] = useState<any>(0);
  const [maxPayBack, setMaxPayBack] = useState<number>(0);
  const [convertMaxPayBack, setConvertMaxPayBack] = useState<number>(0);
  const [err, setErr] = useState<string>("");
  const debouncedDebtInputValue = useDebounce(debtInputValue, 300);

  useEffect(() => {
    if (debtDisplay) {
      convertUSD();
    }
    if (Number(debouncedDebtInputValue) === 0) {
      dispatch(initialValues(library, account, ethToUSD));
    } else {
      debtLoanInfo();
    }
  }, [debtDisplay, debouncedDebtInputValue]);

  useEffect(() => {
    setDebtInputValue("0");
    setCalDebtInputValue(0);
    setDebtInputUSDValue(0);
    setDebtRangeValue(0);
  }, [refetchBalance]);

  const convertUSD = async () => {
    const { lusdUSD } = await tokenToUSD();
    setLusdInDollar(lusdUSD);
    const _MaxPayBack = Number(debtDisplay) - 1800;
    const Convert_MaxPayBack = lusdUSD ? Number(lusdUSD) * Number(_MaxPayBack) : 0.0;
    setMaxPayBack(_MaxPayBack);
    setConvertMaxPayBack(Convert_MaxPayBack);
  };

  const checking = (value: any, diffDebt: any) => {
    let ErrorText;
    if (Number(value) > Number(lusdInWalletDisplay)) {
      ErrorText = "Insufficient Funds!";
    } else if (diffDebt < 2000) {
      ErrorText = `Total debt must be at least 2,000 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} (${formatNumbersWithComma(2000 * lusdInDollar)} USD)`;
    } else {
      ErrorText = "";
    }
    setErr(ErrorText);
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        err: ErrorText,
      },
    });
  };

  const handleUpdate = (debtValue: string, percentage: number) => {
    const convertDebt = formatEther(debt);
    const usdValue = lusdInDollar ? Number(lusdInDollar) * Number(debtValue) : 0.0;
    const newVal = Number(convertDebt) - Number(debtValue);
    setDebtInputValue(debtValue);
    setCalDebtInputValue(newVal);
    setDebtInputUSDValue(usdValue);
    setDebtRangeValue(percentage);
    checking(debtValue, newVal);
  };

  const handleDebtInput = async (e: any) => {
    const value = validateNumberInput(e)?.toString();
    const MinPayBack = Number(debtDisplay) - 1800;
    let percentage: any = ((Number(value) / MinPayBack) * 100).toFixed(0);
    percentage = Number(percentage);
    if (Number(value) <= Number(maxPayBack)) {
      handleUpdate(value, percentage);
    }
  };

  const handleDebtRangeInput = (e: any) => {
    let percentToValue: any = Number(maxPayBack) * (Number(e) / 100);
    percentToValue = percentToValue?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0];
    const percentage = Number(e);
    handleUpdate(percentToValue, percentage);
  };

  const debtLoanInfo = async () => {
    try {
      let _tempTotalDebt;
      if (Number(debtInputValue) === 0) {
        _tempTotalDebt = totalDebt;
      } else {
        _tempTotalDebt = Number(calDebtInputValue);
      }
      handleLoanInformation(_tempTotalDebt);
      dispatch({
        type: SET_LUSD_VALUES,
        payload: {
          priceUSDLDisplay: debtInputValue,
          priceUSDL: calDebtInputValue,
          totalDebt: _tempTotalDebt,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleLoanInformation = async (tempTotalDebt: any) => {
    let tempNormalLPrice, tempRecoveryLPrice, tempICollateralRatio;
    const ethInDollar = await ethToUSD();
    if (debtInputValue && Number(calDebtInputValue) >= 2000) {
      tempNormalLPrice = (110 * Number(tempTotalDebt)) / (Number(priceEth) * 100);
      tempRecoveryLPrice = (150 * Number(tempTotalDebt)) / (Number(priceEth) * 100);
      const ratio = ((Number(priceEth) * Number(ethInDollar)) * 100 / Number(tempTotalDebt));
      tempICollateralRatio = ratio.toFixed(2);
    } else {
      tempICollateralRatio = "0";
      tempRecoveryLPrice = 0;
      tempNormalLPrice = 0;
    }
    dispatch({
      type: SET_BOTH_VALUES,
      payload: {
        IcollateralRatio: tempICollateralRatio,
        recoveryLPrice: tempRecoveryLPrice,
        normalLPrice: tempNormalLPrice,
      },
    });
  };

  return (
    <>
      <InputBox>
        <InputLabel>
          <div className="leftLabel">
            <span className="text1">Payback</span>
            <span className="text2">{envAllDescriptionDetails.STABILITY_TOKEN_TEXT}</span>
          </div>
          <div className="rightLabel">
            <span className="text1">Max Payback: </span>
            <span className="text2">
              {` ${formatNumbersWithComma(maxPayBack, 3)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(convertMaxPayBack, 2)})`}
            </span>
          </div>
        </InputLabel>
        <CustomInput placeholder={"Enter Amount"} icon={envAllDescriptionDetails.STABILITY_LOGO} value={debtInputValue} onChange={handleDebtInput}></CustomInput>
        <RangePointerInput rangeValue={debtRangevalue} rangeHandler={handleDebtRangeInput} />
      </InputBox>
      {err === "" && Number(debtInputValue) > 0 && debtInputValue !== "" ? (
        <Notification
          message={`You will payback ${formatNumbersWithComma(debtInputValue, 4)} ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} ($${formatNumbersWithComma(
            debtInputUSDValue,
            2,
          )})`}
          type="error"
        />
      ) : (
        ""
      )}
      <LightText>{`You must keep atleast 1,800 ${envAllDescriptionDetails.STABILITY_TOKEN_TEXT} to maintain a trove`}</LightText>
    </>
  );
};
