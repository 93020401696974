import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import {
  ACTIVE_POOL_INSTANCE,
  envAllContractsDetails,
  ESCROW_INSTANCE,
  FACTORY_INSTANCE,
  LUSD_TOKEN_INSTANCE,
  PRICE_FEED_INSTANCE,
  SIGNER,
  SORTED_TROVES,
  STAKING_POOL_INSTANCE,
  TROVE_MANAGER_INSTANCE,
  useInstance,
  USER_INSTANCE,
} from "blockchain/contract/instance";
import { BigNumber, ethers } from "ethers";
import { formatEther, formatUnits, parseUnits } from "ethers/lib/utils";
import jwt_decode from "jwt-decode";
import { setAuthStatus, setAutomationBalance, setAutomationRun, setAverageFee, setIsFetched, setUserContract, startLoading, stopLoading } from "logic/redux/actions";
import { setIsLiquidateTrove } from "logic/redux/actions/strategy";
import { useStrategy } from "modules/pages/strategy/hooks/useStrategy";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { GET_SELECTORS_URL, GET_STRATEGIES_URL, POST_WALLET_URL } from "shared/helpers/apis";
import { commonContractsDescription, numberFormatter, storeTokenUSD, tokenToUSD } from "shared/helpers/utils";
import { useWallet } from "shared/hook/useWallet";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "styles/globalStyle";
import { getTheme, Themes } from "../../styles/theme";
import { RoutesComponent } from "./routes/Routes";
import { ChainId } from "blockchain/wallets/helpers/WalletHelper";
import { useConnectWallet } from "blockchain/wallets/hooks/useConnectWallet";
import { eagerConnection } from "blockchain/wallets/helpers/EagerConnect";

const envChainId = process.env.REACT_APP_DEPLOYED_CHAIN as string;

export const App = () => {
  const currentTheme = { ...getTheme(Themes.BASIC), selected: Themes.BASIC };

  const navigate = useNavigate();
  const { userContractAddress } = useSelector((s: any) => s.navbar);
  const { BOTH, ETH, LUSD } = useSelector((s: any) => s.trove);
  const { mode } = BOTH;
  const { refetchBalance, maxGap } = useSelector((state: any) => state.navbar);
  const { estimateGas, strategyInstance } = useSelector((state: any) => state.strategyRedu);
  const { account, library }: any = useConnectWallet();
  useInstance();
  const { fetchWalletDetails } = useStrategy();
  const dispatch = useDispatch();
  const { generateAuthToken, automaticLogOut, generateRefreshToken, clearStorage, ethToUSD, InitialLoad } = useWallet();
  useEffect(() => {
    if (userContractAddress) {
      InitialLoad();
      automationInitialValue();
    }
  }, [account, userContractAddress, refetchBalance, mode, strategyInstance, estimateGas]);

  useEffect(() => {
    dispatch(setIsLiquidateTrove(false));
    if (account) {
      InitialValue();
    }
  }, [account]);

  useEffect(() => {
    eagerConnection();
  }, []);

  const InitialValue = async () => {
    try {
      dispatch(startLoading());
      await storeTokenUSD();
      await ethToUSD();
      await createUserContract();
      await getToken();
      await fetchingData();
      dispatch(stopLoading());
    } catch (e: any) {
      dispatch(stopLoading());
    }
  };

  const fetchingData = async () => {
    // toast.dismiss();
    await getSelectors();
    await fetchingPriceCoinGeeko();
    const _userContractAddress = sessionStorage.getItem("deployed");
    if (_userContractAddress) {
      await updateStrategies();
      await fetchWalletDetails();
    }
  };

  const getToken = async () => {
    const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
    if (Object.keys(token).length === 0 || !token.hasOwnProperty(account)) {
      await generateAuthToken();
    } else if (token[account]) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token[account]}`;
      dispatch(
        setAuthStatus({
          authStatus: false,
        }),
      );
    }
    checkUserActivity();
  };

  const checkUserActivity = async () => {
    if (!account) {
      return;
    }

    let inactivityTime = 0;
    const maxInactivityTime = 3 * 60 * 1000; // 5 min

    function resetInactivityTimer() {
      inactivityTime = 0;
    }

    const trackInactivityTime = async () => {
      try {
        const _account: any = sessionStorage.getItem("Account");
        inactivityTime += 5000; //5000
        const token: any = JSON.parse(sessionStorage.getItem("Token") || "{}");
        const decoded: any = jwt_decode(token[_account]);
        const diff = decoded.exp * 1000 - Date.now();
        // diff < 5min
        if (diff < 5 * 60 * 1000 && inactivityTime < maxInactivityTime) {
          console.log("**in refresh");
          try {
            await generateRefreshToken();
            await fetchingData();
            resetInactivityTimer();
          } catch (e: any) {
            resetInactivityTimer();
            throw new Error();
          }
        }
        if (diff < 5 * 60 * 1000 && inactivityTime >= maxInactivityTime) {
          try {
            console.log("**in logOut");
            await automaticLogOut();
            resetInactivityTimer();
          } catch (e: any) {
            resetInactivityTimer();
            throw new Error();
          }
        }
        setTimeout(trackInactivityTime, 5000); // 5sec
      } catch (e: any) {
        console.log("error", e);
      }
    };

    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);
    document.addEventListener("mousedown", resetInactivityTimer);
    document.addEventListener("touchstart", resetInactivityTimer);
    document.addEventListener("touchmove", resetInactivityTimer);

    setTimeout(trackInactivityTime, 5000); // 5sec

    const cleanup = () => {
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("keydown", resetInactivityTimer);
      document.removeEventListener("mousedown", resetInactivityTimer);
      document.removeEventListener("touchstart", resetInactivityTimer);
      document.removeEventListener("touchmove", resetInactivityTimer);
    };

    // Return the cleanup function from checkUserActivity
    return cleanup;
  };

  const automationInitialValue = async () => {
    try {
      const _balance = await ESCROW_INSTANCE?.connect(SIGNER).balanceOf(account);
      const decimals = 18;
      const _formatBal = ethers.utils.formatUnits(_balance, decimals);
      dispatch(setAutomationBalance(_formatBal));
      const _averageFee = await Promise.resolve(library?.getGasPrice());
      const _formatAverageFee = parseInt("" + Number(formatUnits(_averageFee, "gwei")) * 1000) / 1000;
      dispatch(setAverageFee(_formatAverageFee));
      let _automationCanRun;
      const _convertMaxCap = maxGap && maxGap !== "0" ? BigNumber.from(maxGap.replace(".", "").padEnd(decimals + 1, "0")) : 0.0;
      // const _automationCanRun = (maxGap && maxGap !== "0") ? Number(_formatBal) !== 0.0 && Number(_formatBal) >= Number(maxGap) ? _balance.div(_convertMaxCap).toString() : 0.00 : 1
      if (maxGap && maxGap !== "0" && Number(_formatBal) !== 0.0 && Number(_formatBal) >= Number(maxGap)) {
        _automationCanRun = Number(_balance.div(_convertMaxCap).toString());
      } else if (!(maxGap && maxGap !== "0") && Number(_formatBal) !== 0.0 && Number(_formatBal) >= Number(maxGap)) {
        _automationCanRun = "set a max cap";
      } else if (maxGap && maxGap !== "0" && !(Number(_formatBal) !== 0.0 && Number(_formatBal) >= Number(maxGap))) {
        _automationCanRun = 0;
      } else {
        _automationCanRun = "set a max cap";
      }
      dispatch(setAutomationRun(_automationCanRun));
    } catch (err: any) {
      console.log(err);
    }
  };

  const createUserContract = async () => {
    try {
      dispatch(setUserContract(""));
      sessionStorage.removeItem("deployed");
      const SIGNER = library.getSigner();
      const SignerAddress = await SIGNER.getAddress();
      const userContractAddr = await FACTORY_INSTANCE?.getContract(SignerAddress);
      if (userContractAddr !== "0x0000000000000000000000000000000000000000") {
        sessionStorage.setItem("deployed", userContractAddr);
        dispatch(setUserContract(userContractAddr));
      } else {
        dispatch(stopLoading());
        navigate("/");
      }
      sessionStorage.setItem("Account", account);
    } catch (e: any) {
      console.log(e);
      throw new Error();
    }
  };

  const updateStrategies = async () => {
    try {
      const _userContractAddress = sessionStorage.getItem("deployed");
      const Automation_Trove_Status = await USER_INSTANCE.getAutomationAndTrove();
      const isAutomated = Automation_Trove_Status[0] === 0 ? false : true;
      await axios.post(POST_WALLET_URL, { userContractAddress: _userContractAddress, isAutomated });
    } catch (e: any) {
      console.log(e);
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const getSelectors = async () => {
    try {
      let selectors: any = sessionStorage.getItem(commonContractsDescription.SELECTORS);
      if (!selectors) {
        const data = await axios.get(GET_SELECTORS_URL);
        selectors = {
          ...data?.data?.data,
          updatedAt: Date.now(),
        };
        sessionStorage.setItem(commonContractsDescription.SELECTORS, JSON.stringify(selectors));
      }
    } catch (e: any) {
      console.log(e);
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  const fetchingPriceCoinGeeko = async () => {
    try {
      const STABILITY_POOL_ADDRESS = envAllContractsDetails?.StabiltyPool[0];
      console.log({ STABILITY_POOL_ADDRESS }, "%%");
      const convertEthUSD = await ethToUSD();
      const { lusdUSD, lqtyUSD } = await tokenToUSD();
      const data = await axios.get(`${GET_STRATEGIES_URL}/statistics`);
      const lockedEth = data?.data?.data?.eth;
      let total_vault_token: any = lockedEth ? formatEther(lockedEth) : "0";
      const Convert_Locked_Eth = convertEthUSD && lockedEth ? Number(convertEthUSD) * Number(total_vault_token) : "0";
      const lockedLusd = data?.data?.data?.lusd;
      let total_stability_token: any = lockedLusd ? formatEther(lockedLusd) : "0";
      const Convert_Locked_Lusd = lusdUSD && lockedLusd ? Number(lusdUSD) * Number(total_stability_token) : "0";
      const lockedLqty = data?.data?.data?.lqty;
      let total_staking_token: any = lockedLqty ? formatEther(lockedLqty) : "0";
      const Convert_Locked_Lqty = lqtyUSD && lockedLqty ? Number(lqtyUSD) * Number(total_staking_token) : "0";
      // let [baseRate, minRate, maxRate, oraclePriceETH_] = await Promise.all([
      //   TROVE_MANAGER_INSTANCE?.baseRate(),
      //   TROVE_MANAGER_INSTANCE?.BORROWING_FEE_FLOOR(),
      //   TROVE_MANAGER_INSTANCE?.MAX_BORROWING_FEE(),
      //   Number(envChainId) === 5 ? PRICE_FEED_INSTANCE?.mockPrice() : PRICE_FEED_INSTANCE?.getPrice(),
      //   //lastGoodPrice method will be called for mainnet
      // ]);
      let [oraclePriceETH_] = await Promise.all([Number(envChainId) === ChainId?.eth ? PRICE_FEED_INSTANCE?.mockPrice() : PRICE_FEED_INSTANCE?.lastGoodPrice()]);
      let rate = await TROVE_MANAGER_INSTANCE.getBorrowingRateWithDecay();
      rate = formatEther(rate);
      // rate =  parseInt("" + rate * 1000) / 1000;
      rate = rate * 100;
      rate = rate.toFixed(2);
      // rate = Math.trunc(rate * Math.pow(10, 2)) / Math.pow(10, 2);

      let [TVL, troves_, lusdsupply, stabiityLusd, lqtyStaked, ethPrice]: any = await Promise.all([
        Number(envChainId) === ChainId?.eth ? ACTIVE_POOL_INSTANCE?.getETH() : ACTIVE_POOL_INSTANCE?.getPLS(),
        SORTED_TROVES?.getSize(),
        LUSD_TOKEN_INSTANCE?.totalSupply(),
        LUSD_TOKEN_INSTANCE?.balanceOf(STABILITY_POOL_ADDRESS),
        Number(envChainId) === ChainId?.eth ? STAKING_POOL_INSTANCE?.totalLQTYStaked() : STAKING_POOL_INSTANCE?.totalLOANStaked(),
        ethToUSD(),
      ]);
      let price = parseUnits(ethPrice.toString(), "ether");

      let [colR, recM] = await Promise.all([TROVE_MANAGER_INSTANCE.getTCR(price.toString()), TROVE_MANAGER_INSTANCE.checkRecoveryMode(price.toString())]);

      lqtyStaked = formatEther(lqtyStaked);
      colR = Number(formatEther(colR)) * 100;
      stabiityLusd = formatEther(stabiityLusd);
      lusdsupply = formatEther(lusdsupply);
      TVL = formatEther(TVL);
      // let minBorrowRatePercent: any = Math.min(Number(formatEther(minRate)) + Number(formatEther(baseRate)), Number(formatEther(maxRate)));
      // minBorrowRatePercent = minBorrowRatePercent * 100;
      let recoveryMP = (lusdsupply * 150) / (TVL * 100);
      const trovesVal = numberFormatter(Number(troves_.toString()), 0);

      const tvl_ = numberFormatter(Number(TVL), 2);
      const lusdSupply_ = numberFormatter(Number(lusdsupply.toString()), 2);
      const lusdInStability_ = numberFormatter(Number(stabiityLusd.toString()), 2);
      const stakedLQTY = numberFormatter(Number(lqtyStaked.toString()), 2);
      const tempCR: any = parseInt("" + Number(colR) * 100) / 100;
      const colRatio: string = tempCR.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "%";

      const Convert_TVL = convertEthUSD ? Number(convertEthUSD) * Number(TVL) : 0.0;
      const Convert_lusdSupply = lusdUSD ? Number(lusdUSD) * Number(lusdsupply) : 0.0;
      const Convert_lusdInStability = lusdUSD ? Number(lusdUSD) * Number(stabiityLusd) : 0.0;
      const Convert_stakedLQTY = lqtyUSD ? Number(lqtyUSD) * Number(lqtyStaked) : 0.0;

      const recMode = recM ? "Yes" : "No";
      // const borrowPerc = parseInt("" + minBorrowRatePercent * 100) / 100;
      const recoveryModePrice_ = "$" + numberFormatter(recoveryMP, 2);
      const oraclePrice = formatEther(oraclePriceETH_);

      const localstorageData = {
        troves: trovesVal,
        tvl: tvl_,
        lusdSupply: lusdSupply_,
        lusdInStability: lusdInStability_,
        stakedLQTY,
        colRatio,
        recMode,
        borrowPerc: rate,
        recoveryModePrice: recoveryModePrice_,
        oraclePrice,
        Convert_TVL,
        Convert_lusdSupply,
        Convert_lusdInStability,
        Convert_stakedLQTY,
        totalEthSupply: total_vault_token,
        totalLusdSupply: total_stability_token,
        totalLqtySupply: total_staking_token,
        totalEthUsdSupply: Convert_Locked_Eth,
        totalLqtyUsdSupply: Convert_Locked_Lqty,
        totalLusdUsdSupply: Convert_Locked_Lusd,
        amplifyStatics: data?.data?.data,
        stabilityLusdSupplyPer: Number(stabiityLusd).toFixed(6),
        stakedLqtySupplyPer: Number(lqtyStaked).toFixed(6),
      };
      const localData: any = JSON.stringify(localstorageData);
      sessionStorage.setItem("stats", localData);
      dispatch(setIsFetched(true));
    } catch (e: any) {
      console.log(e);
      if (e?.response?.status === 401) {
        automaticLogOut();
      } else {
        clearStorage();
      }
      throw new Error();
    }
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <ToastContainer position="bottom-right" autoClose={false} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      <RoutesComponent />
    </ThemeProvider>
  );
};

// export const toaster = () => {
//   // const { automaticLogOut, clearStorage } = useWallet();
//   return <img src={closeIcon} alt="close" onClick={() => handleUpdateLiquidatedStatus()} />;
// };
